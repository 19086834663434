import React, { useState, useCallback, useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Pagination from "../Pagination";

import MultiSelect from "../Multi-Select";
import InputField from "../Input-Field";

import { SingleGroupItemSet } from "./types";

import generateCSV from "./export-to-csv";
import useFilters from "./filter-effects";
import useSort from "./sort-effects";

const ARROWS = {
  asc: <span> &uarr;</span>,
  dsc: <span> &darr;</span>,
  null: <span> &harr;</span>,
};

interface PropsInterface {
  data: SingleGroupItemSet[];
  transaction_column: string;
  item_column: string;
  grouping_columns?: string[];
  min_support: number;
  item_class?: string | ((item: string) => string);
}

const FrequentItemSets: React.FC<PropsInterface> = ({
  data,
  transaction_column,
  item_column,
  grouping_columns,
  min_support,
  item_class = "tag is-info",
}) => {
  const { sorter, setSorter, sortD, setSortD, patterns } = useSort(data);
  const {
    itemList,
    showingItems,
    setShowingItems,
    groupingList,
    showingGroups,
    setShowingGroups,
    minPatternLength,
    setMinPatternLength,
    patternsToShow,
  } = useFilters(patterns, grouping_columns);

  const [page, setPage] = useState<number>(1);

  const minPatternLengthCallback = useCallback(
    (v) =>
      v === null
        ? setMinPatternLength(1)
        : setMinPatternLength(parseInt(v.toString())),
    [setMinPatternLength]
  );

  return (
    <div>
      <p className="title">Frequent Patterns</p>
      <p className="subtitle">
        Frequent Patterns are items that <b>occur together</b> frequently in the
        data.
      </p>
      <div className="is-clearfix">
        <p
          className="has-text-right is-link button is-pulled-right"
          id="patterns-export"
          onClick={() =>
            generateCSV(data, transaction_column, item_column, grouping_columns)
          }
        >
          EXPORT TO CSV
        </p>
      </div>
      <div id="pattern-filters">
        <InputField
          type="number"
          label="Minimum Pattern Length"
          value={minPatternLength}
          onValueChange={minPatternLengthCallback}
          help="Only patterns which are at least this long will be displayed."
        />
        <MultiSelect
          label={`${item_column} to Show/Hide:`}
          name="items_to_show_hide"
          options={useMemo(
            () => itemList.map((v) => ({ value: v, label: v })),
            [itemList]
          )}
          onValueChange={setShowingItems}
          selected={showingItems}
          enableSelectAll={true}
          showSelected={true}
          showExcluded={true}
        />
        {(grouping_columns || []).map((g) => (
          <MultiSelect
            key={g}
            label={`${g} to SHOW/HIDE:`}
            options={groupingList[g].map((value) => ({ value, label: value }))}
            onValueChange={(values) =>
              setShowingGroups({
                ...showingGroups,
                [g]: values.map((v) => v.toString()),
              })
            }
            selected={showingGroups[g]}
            enableSelectAll={true}
            showSelected={true}
            showExcluded={true}
          />
        ))}
      </div>
      <hr />
      <p>
        We were able to find {patterns.length.toLocaleString()} patterns in your
        data that had at least {min_support.toLocaleString()}{" "}
        {transaction_column}.
      </p>

      <hr />
      {patternsToShow.length === patterns.length ? (
        <p>Showing ALL {patterns.length} patterns</p>
      ) : (
        <p>
          Showing {patternsToShow.length}/{patterns.length} patterns after
          applying filters.
        </p>
      )}
      {patternsToShow.length > 0 && (
        <>
          <Pagination
            currentPage={page}
            pageCount={Math.floor(patternsToShow.length / 100) + 1}
            onClick={setPage}
          />
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>
                  <p>Pattern ({item_column})</p>
                  <p>
                    A transaction with this pattern HAS ALL THESE items, but CAN
                    ALSO HAVE other items in it.
                  </p>
                </th>
                {(grouping_columns || []).length >= 1 && (
                  <>
                    {(grouping_columns || []).map((g) => (
                      <th key={g}>{g}</th>
                    ))}
                  </>
                )}
                <th
                  onClick={() =>
                    sorter === "count"
                      ? setSorter("l")
                      : sortD === "asc"
                      ? setSortD("dsc")
                      : setSortD("asc")
                  }
                >
                  Pattern Length{" "}
                  {sorter === "l" ? ARROWS[sortD] : ARROWS["null"]}
                </th>
                <th
                  onClick={() =>
                    sorter === "l"
                      ? setSorter("count")
                      : sortD === "asc"
                      ? setSortD("dsc")
                      : setSortD("asc")
                  }
                >
                  <span>{transaction_column} count </span>{" "}
                  <span
                    className=" is-small tooltip has-tooltip-multiline"
                    data-tooltip={`Number of ${transaction_column} that have this pattern.`}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="fas icon" />
                  </span>{" "}
                  {sorter === "count" ? ARROWS[sortD] : ARROWS["null"]}
                </th>
              </tr>
            </thead>
            <tbody>
              {patternsToShow.slice((page - 1) * 100, page * 100).map((p) => (
                <tr
                  key={JSON.stringify([p.itemSet, p.grouping || []])}
                  id="pattern-row"
                >
                  <td>
                    <p className="tags">
                      {p.itemSet.map((s) => (
                        <span
                          key={s}
                          className={
                            (typeof item_class === "function"
                              ? item_class(s)
                              : "tag is-info") || "tag is-info"
                          }
                        >
                          {s}
                        </span>
                      ))}
                    </p>
                  </td>
                  {(grouping_columns || []).length === 1 && (
                    <td>{p.grouping}</td>
                  )}
                  {(grouping_columns || []).length >= 2 &&
                    (p.grouping || []).map((g) => <td key={g}>{g}</td>)}
                  <td>{p.l.toLocaleString()}</td>
                  <td>
                    {p.count.toLocaleString()} ({(p.p * 100).toLocaleString()}%)
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={page}
            pageCount={Math.floor(patternsToShow.length / 100) + 1}
            onClick={setPage}
          />
        </>
      )}
    </div>
  );
};

export default FrequentItemSets;
