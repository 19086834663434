import { formatValue } from "utils";

import { Operator, ColumnType, FilterRange } from "./types";

const make_range_text = (
  operator: Operator,
  type: ColumnType | "time" | "" | undefined,
  range: FilterRange
) => {
  const n_t =
    type === ""
      ? ["ge", "gt", "lt", "le", "eq", "ne"].includes(operator)
        ? "numeric"
        : "char"
      : type;

  if (n_t && !["int", "float", "numeric", "date", "time"].includes(n_t)) {
    const r = range.map(r => r.toString()).join(", ");
    switch (operator) {
      case "in":
        return `is one of ${r}`;
      case "not in":
        return `is NOT one of ${r}`;
      case "sw":
        return `starts with ANY OF ${r} `;
      case "nsw":
        return `does NOT start with ANY OF ${r} `;
      case "ew":
        return `ends with ANY OF ${r} `;
      case "new":
        return `does NOT end with ANY OF ${r} `;
      case "cn":
        return `contains ANY OF ${r} `;
      case "ncn":
        return `does NOT contain ANY OF ${r} `;
      default:
        return "";
    }
  }

  range = range.map(r => formatValue(r, type));

  switch (operator) {
    case "between":
    case "in":
      return `is between ${range[0]} and ${range[1]}`;
    case "gt":
      return `${n_t === "date" ? "is after" : "is greater than"} ${range[0]}`;
    case "ge":
      return `${
        n_t === "date" ? "is ON or after" : "is greater than or equal to"
      } ${range[0]}`;
    case "lt":
      return `${n_t === "date" ? "is before" : "is less than"} ${range[0]}`;
    case "le":
      return `${
        n_t === "date" ? "is ON or before" : "is less than or equal to"
      } ${range[0]}`;
    case "eq":
      return `${n_t === "date" ? "is ON" : "is equal to"} ${range[0]}`;
    case "ne":
      return `${n_t === "date" ? "is NOT ON" : "is NOT equal to"} ${range[0]}`;
    default:
      return "";
  }
};

export default make_range_text;
