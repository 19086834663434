import React, { useState } from "react";
import { ViewPort, ScaleType, VarType } from "Visualizations/types";

import { getScale, getMargin } from "Visualizations/utils";

import getMovementPosition from "./movement-position";
import getZoomValues from "./zoom-values";

import OverlayBox from "./Overlay-Box";
import MouseContainer from "./Mouse-Container";
import Limits from "./Limits";

interface PropsInterface extends ViewPort {
  /**Full Domain of the chart. Mouse movements shall be captured on the full domain. */
  domain: (string | number | Date)[];
  /**Part of the domain to highlight. A grey overlay box. Defaults to Full domain */
  zoomedDomain?: (string | number | Date)[];
  /**Is the brush horizontal or vertical. */
  brushType: "horizontal" | "vertical";
  /**Scale type to use */
  scaleType?: ScaleType;
  varType: VarType;
  onZoom(domain: (string | number | Date)[]): any;
}

const Brush: React.FunctionComponent<PropsInterface> = props => {
  const [topDrag, setTopDrag] = useState(false);
  const [bottomDrag, setBottomDrag] = useState(false);
  const [leftDrag, setLeftDrag] = useState(false);
  const [rightDrag, setRightDrag] = useState(false);

  const { brushType, varType, scaleType } = props;
  const { height, width } = props;

  const margin = getMargin(props.margin);

  let { domain } = props;
  if (varType === "date" || varType === "time") {
    domain = domain.map(d => new Date(d));
  }
  const zoomedDomain = props.zoomedDomain || domain;
  const axisLength =
    brushType === "vertical"
      ? height - (margin.top + margin.bottom)
      : width - (margin.left + margin.right);

  const scale = getScale({ varType, domain, scaleType, axisLength });

  const getNewZoomValues = (event: React.MouseEvent) => {
    const moveLower = brushType === "vertical" ? bottomDrag : leftDrag;
    const v = getMovementPosition(event, brushType);
    let new_zoom = getZoomValues(v, moveLower, scale, domain, zoomedDomain);

    if (varType === "date") {
      new_zoom = new_zoom.map(d => new Date(d));
    }
    return new_zoom;
  };

  const resetState = () => {
    setTopDrag(false);
    setBottomDrag(false);
    setRightDrag(false);
    setLeftDrag(false);
  };

  const stopDrag = (event: React.MouseEvent) => {
    if (!(topDrag || bottomDrag || rightDrag || leftDrag)) {
      return;
    }
    props.onZoom(getNewZoomValues(event));
    resetState();
  };

  return (
    <g
      transform={`translate(${margin.left}, ${margin.top})`}
      fill="none"
      pointerEvents="all"
    >
      <OverlayBox
        boxType={brushType}
        scale={scale}
        domain={zoomedDomain}
        height={height}
        width={width}
        margin={margin}
      />
      <MouseContainer
        width={width}
        height={height}
        margin={margin}
        stopDrag={stopDrag}
      />
      <Limits
        type={brushType}
        scale={scale}
        domain={zoomedDomain}
        height={height}
        width={width}
        margin={margin}
        updateBottomDragStatus={setBottomDrag}
        updateTopDragStatus={setTopDrag}
        updateLeftDragStatus={setLeftDrag}
        updateRightDragStatus={setRightDrag}
        changeZoom={getNewZoomValues}
      />
    </g>
  );
};

export default Brush;
