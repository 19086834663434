import React, { useState } from "react";

import { extent } from "d3-array";

import {
  ViewPort,
  InteractionProps,
  FillProps,
  Fill,
  VarType
} from "Visualizations/types";

import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR
} from "Visualizations/default-style-settings";

import { filterObjectsByRange } from "Visualizations/data-utils";
import {
  getMargin,
  getScale,
  getOpacityScale,
  isCategorical
} from "Visualizations/utils";

interface NumCatGridCell {
  leftEdge: Date | number;
  rightEdge: Date | number;
  level: string | number;
  value: number;
}

const extract_numeric_domain = (
  data: NumCatGridCell[],
  domain: (Date | number)[]
): (Date | number)[] => {
  if (Array.isArray(domain) && domain.length === 2) {
    return domain;
  }

  const l = extent(data.map(d => d.leftEdge))[0];
  const r = extent(data.map(d => d.rightEdge))[1];
  return [l === undefined ? 0 : l, r === undefined ? 0 : r];
};

const extract_cat_domain = (
  data: NumCatGridCell[],
  domain: (string | number)[]
): (string | number)[] => {
  if (Array.isArray(domain) && domain.length >= 1) {
    return domain;
  }

  const values = data.map(d => d.level);
  const s = new Set(values);

  return Array.from(s.values());
};

const Cell = ({
  item,
  xType,
  yLength,
  xScale,
  yScale,
  opacityScale,
  fill,
  hover,
  onClick,
  onMouseOver,
  onMouseOut
}: {
  item: NumCatGridCell;
  xType: VarType;
  yLength: number;
  xScale: any;
  yScale: any;
  opacityScale: any;
  fill: Fill<NumCatGridCell>;
  hover: Fill<NumCatGridCell>;
  onClick: (d: NumCatGridCell) => any;
  onMouseOver: (d: NumCatGridCell, e: React.MouseEvent) => any;
  onMouseOut: () => any;
}) => {
  const [hoverStatus, setHover] = useState(false);

  if (!(typeof fill === "string")) {
    fill = fill(item);
  }

  if (!(typeof hover === "string")) {
    hover = hover(item);
  }

  let x, y, width, height;
  if (isCategorical(xType)) {
    x = xScale(item.level);
    width = xScale.bandwidth();
    y = yLength - yScale(item.rightEdge);
    height = Math.max(yScale(item.rightEdge) - yScale(item.leftEdge), 1);
  } else {
    x = xScale(item.leftEdge);
    width = Math.max(xScale(item.rightEdge) - xScale(item.leftEdge), 1);
    y = yLength - yScale(item.level) - yScale.bandwidth();
    height = yScale.bandwidth();
  }

  return (
    <rect
      x={x}
      width={width}
      y={y}
      height={height}
      fillOpacity={opacityScale(item.value)}
      onClick={() => onClick(item)}
      onMouseOver={e => {
        setHover(true);
        return onMouseOver(item, e);
      }}
      onMouseMove={e => onMouseOver(item, e)}
      onMouseOut={() => {
        setHover(false);
        onMouseOut();
      }}
      fill={hoverStatus ? hover : fill}
    />
  );
};

interface PropsInterface
  extends ViewPort,
    InteractionProps<NumCatGridCell>,
    FillProps<NumCatGridCell> {
  data: NumCatGridCell[];

  xType: VarType;
  yType: VarType;

  numDomain?: (Date | number)[];
  catDomain?: (string | number)[];
  valueRange?: number[];

  /** If true, shades the cell color using d.value */
  varyOpacity?: boolean;
}

const DEFAULT_NUM_DOMAIN: (Date | number)[] = [];
const DEFAULT_CAT_DOMAIN: (string | number)[] = [];
const DEFAULT_VALUE_RANGE: number[] = [];

const DEFAULT_INTERACTION = () => {};

const NumCatGrid: React.FC<PropsInterface> = ({
  data,
  xType,
  yType,
  numDomain = DEFAULT_NUM_DOMAIN,
  catDomain = DEFAULT_CAT_DOMAIN,
  valueRange = DEFAULT_VALUE_RANGE,
  varyOpacity = true,
  fillColor = FILL_COLOR,
  hoverColor = HOVER_COLOR,
  width,
  height,
  margin = DEFAULT_MARGIN,
  onClick = DEFAULT_INTERACTION,
  onMouseOver = DEFAULT_INTERACTION,
  onMouseOut = DEFAULT_INTERACTION
}) => {
  if (isCategorical(xType) === isCategorical(yType)) {
    console.log("THIS is NUM-CAT. Passed: ", xType, yType);
    return null;
  }
  const new_margin = getMargin(margin, DEFAULT_MARGIN);

  numDomain = extract_numeric_domain(data, numDomain);
  catDomain = extract_cat_domain(data, catDomain);

  const numType = isCategorical(xType) ? yType : xType;

  data = filterObjectsByRange(data, "level", "categorical", catDomain);
  data = filterObjectsByRange(data, "leftEdge", numType, numDomain);
  data = filterObjectsByRange(data, "rightEdge", numType, numDomain);

  const xLength = width - (new_margin.left + new_margin.right);
  const yLength = height - (new_margin.top + new_margin.bottom);

  const xScale = getScale({
    varType: xType,

    domain: isCategorical(xType) ? catDomain : numDomain,
    axisLength: xLength
  });
  const yScale = getScale({
    varType: yType,

    domain: isCategorical(xType) ? numDomain : catDomain,
    axisLength: yLength
  });

  const opacityScale = getOpacityScale(data, valueRange, varyOpacity);
  return (
    <g transform={`translate(${new_margin.left}, ${new_margin.top})`}>
      {data.map((item, i) => (
        <Cell
          key={i}
          item={item}
          xType={xType}
          yLength={yLength}
          xScale={xScale}
          yScale={yScale}
          opacityScale={opacityScale}
          fill={fillColor}
          hover={hoverColor}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      ))}
    </g>
  );
};

export default NumCatGrid;
