const paths = {
  login: "/login",
  signup: "/sign-up",
  changePassword: "/change-password",

  dataFiles: "/data-files",
  createData: "/data-files/create",
  uploadData: "/data-files/create/upload",

  recipes: "/recipes",
  createWorkspace: "/create-model",
  workSpaces: "/predictive-models/workspace",
  predictiveModels: "/predictive-models",
  modelDetails: "/predictive-models/model",
  modelListingForWorkspace: "/predictive-models/workspace",

  downloads: "/downloads",

  toc: "/toc",
  privacy: "/privacy",
};

export default paths;
