import React from "react";

interface PropsInterface {
  currentPage: number;
  pageCount: number;
  onClick: (page: number) => any;
}

const Pagination: React.FC<PropsInterface> = ({
  currentPage,
  pageCount,
  onClick
}) => {
  if (pageCount === 0) {
    return null;
  }

  if (currentPage > pageCount) {
    console.log(
      "Current Page is more than page count. Defaulting to last page"
    );
    currentPage = pageCount;
  }

  const currentPageBtn = (
    <li key="current">
      <p className="pagination-link is-current has-pointer is-link">
        {currentPage}
      </p>
    </li>
  );

  if (currentPage === 1 && pageCount === 1) {
    return (
      <nav className="pagination is-centered is-rounded">
        <ul className="pagination-list">{currentPageBtn}</ul>
      </nav>
    );
  }

  let buttons = [];

  if (currentPage > 1) {
    if (currentPage > 2) {
      buttons.push(
        <li key="first">
          <p
            className="pagination-link has-pointer is-link"
            onClick={onClick.bind(null, 1)}
          >
            1
          </p>
        </li>
      );
    }
    if (currentPage > 3) {
      buttons.push(
        <li key="prev-span">
          <span className="pagination-ellipsis">...</span>
        </li>
      );
    }
    buttons.push(
      <li key="prev">
        <p
          className="pagination-link is-link"
          onClick={onClick.bind(null, currentPage - 1)}
        >
          {currentPage - 1}
        </p>
      </li>
    );
  }

  buttons.push(currentPageBtn);

  if (currentPage < pageCount) {
    buttons.push(
      <li key="next">
        <p
          className="pagination-link is-link"
          onClick={onClick.bind(null, currentPage + 1)}
        >
          {currentPage + 1}
        </p>
      </li>
    );

    if (currentPage < pageCount - 2) {
      buttons.push(
        <li key="next-span">
          <span className="pagination-ellipsis">...</span>
        </li>
      );
    }

    if (currentPage < pageCount - 1) {
      buttons.push(
        <li key="last">
          <p
            className="pagination-link is-link"
            onClick={onClick.bind(null, pageCount)}
          >
            {pageCount}
          </p>
        </li>
      );
    }
  }

  return (
    <nav
      className="pagination is-rounded"
      role="navigation"
      aria-label="pagination"
    >
      {currentPage > 1 && (
        <p
          className="pagination-previous is-link"
          onClick={() => onClick(currentPage - 1)}
        >
          Previous
        </p>
      )}
      {currentPage < pageCount && (
        <p
          className="pagination-next is-link"
          onClick={() => onClick(currentPage + 1)}
        >
          Next page
        </p>
      )}

      <ul className="pagination-list">{buttons}</ul>
    </nav>
  );
};

export default Pagination;
