import React, { useEffect } from "react";

import * as Sentry from "@sentry/browser";

import { useStoreState } from "store";

class ErrorBoundary extends React.Component<
  {},
  { eventId: string | null; hasError?: boolean }
> {
  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error | null, errorInfo: object) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { eventId, hasError } = this.state;
    if (eventId && hasError) {
      //render fallback UI
      return (
        <button onClick={() => Sentry.showReportDialog({ eventId: eventId })}>
          Report feedback
        </button>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

const ErrorWrapper: React.FC = ({ children }) => {
  const logged_in = useStoreState(state => state.auth.logged_in);
  const user_details = useStoreState(state => state.auth.user_details);
  useEffect(() => {
    try {
      if (logged_in && user_details) {
        Sentry.setUser({ email: user_details.email });
      }
    } catch {}
  });

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default ErrorWrapper;
