import React from "react";

import * as firebase from "firebase/app";
import * as firebaseUI from "firebaseui";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { useStoreActions } from "store";

const Login: React.FC = () => {
  const updateLoginStatus = useStoreActions(
    (actions) => actions.auth.updateLoginStatus
  );
  const fetchUserDetails = useStoreActions(
    (actions) => actions.auth.fetchUserDetails
  );
  const uiConfig = {
    credentialHelper: firebaseUI.auth.CredentialHelper.NONE,
    // Popup signin flow rather than redirect flow.
    signInFlow: "redirect",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: "select_account",
        },
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        updateLoginStatus({ success: true });
        fetchUserDetails();
        // Avoid redirects after sign-in.
        return false;
      },
    },
  };
  return (
    <section className="section">
      <div className="container">
        <StyledFirebaseAuth
          uiCallback={(ui) => ui.disableAutoSignIn()}
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    </section>
  );
};

export default Login;
