import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { useStoreState } from "store";

const HubSpotListener: React.FC = ({ children }) => {
  const [last_path, setLastPath] = useState("/");

  const logged_in = useStoreState(state => state.auth.logged_in);
  const user_details = useStoreState(state => state.auth.user_details);

  const { pathname } = useLocation();

  useEffect(() => {
    if (logged_in && user_details) {
      // @ts-ignore
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(["identify", { email: user_details?.email }]);
      _hsq.push(["trackPageView"]);
    }
  }, [logged_in, user_details]);

  useEffect(() => {
    // @ts-ignore
    const _hsq = (window._hsq = window._hsq || []);
    if (last_path !== pathname) {
      _hsq.push(["setPath", pathname]);
      _hsq.push(["trackPageView"]);
      setLastPath(pathname);
    }
  }, [pathname, last_path, setLastPath]);

  return <>{children}</>;
};

export default HubSpotListener;
