import React from "react";

import { ViewPort, VarType, ScaleType } from "Visualizations/types";

import { DEFAULT_MARGIN } from "Visualizations/default-style-settings";
import { getScale, getMargin } from "Visualizations/utils";

import YAxis from "../Y-Axis";

interface PropsInterface extends ViewPort {
  /** Inner Var Type */
  varType: VarType;
  /** Inner Scale Type */
  scaleType: ScaleType;
  axisColor?: string;
  tickColor?: string;
  outerDomain: any[];
  innerDomain: any[];
  /**Defaults to "left" */
  orientation?: "left" | "right";
  /** Defaults to true */
  showTicks?: boolean;
  /**Defaults to true */
  showText?: boolean;
  /** Defaults to true */
  showInnerTicks?: boolean;
  /**Defaults to true */
  showInnerText?: boolean;
}

const NestedYAxis: React.FunctionComponent<PropsInterface> = ({
  varType,
  scaleType,
  axisColor = "black",
  tickColor,
  outerDomain,
  innerDomain,
  orientation = "left",
  showText = true,
  showTicks = true,
  showInnerText = true,
  showInnerTicks = true,
  width,
  height,
  margin = DEFAULT_MARGIN
}) => {
  const new_margin = getMargin(margin, DEFAULT_MARGIN);
  if (orientation === "left") {
    new_margin.left = new_margin.left / 2;
  } else {
    new_margin.right = new_margin.right / 2;
  }

  const axisLength = height - (new_margin.top + new_margin.bottom);

  const outerScale = getScale({
    varType: "char",
    domain: outerDomain,
    axisLength
  });

  const innerMargin = {
    ...new_margin,
    top: (outerScale.padding() * axisLength) / 2,
    bottom: (outerScale.padding() * axisLength) / 2
  };

  return (
    <>
      <YAxis
        varType="char"
        scaleType="categorical"
        domain={outerDomain}
        width={width}
        height={height}
        showText={showText}
        showTicks={showTicks}
        axisColor={axisColor}
        tickColor={tickColor}
        margin={new_margin}
        orientation={orientation}
      />
      {outerDomain.map(d => (
        <g
          key={d}
          transform={`translate(${
            orientation === "left"
              ? new_margin.left
              : width - new_margin.right * 2
          }, ${(outerScale(d) || 0) + new_margin.top})`}
          textAnchor={"middle"}
          className="axis"
        >
          <YAxis
            varType={varType}
            scaleType={scaleType}
            domain={innerDomain}
            width={orientation === "left" ? new_margin.left : new_margin.right}
            height={outerScale.bandwidth() - outerScale.padding()}
            showText={showInnerText}
            showTicks={showInnerTicks}
            axisColor={axisColor}
            tickColor={tickColor}
            margin={innerMargin}
            orientation={orientation}
          />
        </g>
      ))}
    </>
  );
};

export default NestedYAxis;
