import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec } from "./types";

const stringifySpec = (spec: Spec): string => JSON.stringify(spec);

// Change key
const INSIGHT_TYPE = "data-file-document-topics";

const model: StateInterface = {
  // Add params. Each of them: param: {[file_id]: ...}
  text_column: {},
  num_topics: {},
  word_frequency_threshold: {},
  doc_frequency_threshold: {},
  doc_size_threshold: {},
  secondary_topic_thresh: {},

  // Set Params
  setParams: action((state, payload) => {
    const { file_id, params } = payload;
    // Add other params as required.

    if (params.text_column !== null && params.text_column !== undefined) {
      state.text_column[file_id] = params.text_column;
    }
    if (params.num_topics !== null && params.num_topics !== undefined) {
      state.num_topics[file_id] = params.num_topics;
    }
    if (
      params.word_frequency_threshold !== null &&
      params.word_frequency_threshold !== undefined
    ) {
      state.word_frequency_threshold[file_id] = params.word_frequency_threshold;
    }
    if (
      params.doc_frequency_threshold !== null &&
      params.doc_frequency_threshold !== undefined
    ) {
      state.doc_frequency_threshold[file_id] = params.doc_frequency_threshold;
    }
    if (
      params.doc_size_threshold !== null &&
      params.doc_size_threshold !== undefined
    ) {
      state.doc_size_threshold[file_id] = params.doc_size_threshold;
    }
    if (
      params.secondary_topic_thresh !== null &&
      params.secondary_topic_thresh !== undefined
    ) {
      state.secondary_topic_thresh[file_id] = params.secondary_topic_thresh;
    }
  }),

  downloads: {},

  updateDownload: action((state, payload) => {
    const { file_id, status, spec } = payload;
    const specString = stringifySpec(spec);
    if (!state.downloads[file_id]) {
      state.downloads[file_id] = { [specString]: status };
    } else {
      state.downloads[file_id][specString] = status;
    }
  }),

  // Create Request
  create: thunk(
    async (actions, file_id, { injections, getState, getStoreActions }) => {
      const state = getState();

      if (state.resource(file_id)) {
        return;
      }

      const spec = state.getSpec(file_id);
      if (!spec) {
        return;
      }

      const { urls } = injections;
      const spec_string = stringifySpec(spec);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_document_topics}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec,
        spec_string
      });
    }
  ),

  exportProfiles: thunk(
    async (actions, file_id, { injections, getState, getStoreActions }) => {
      const state = getState();

      const spec = state.getSpec(file_id);
      if (!spec) {
        return;
      }

      const { urls } = injections;

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_export_document_topics}`;
      actions.updateDownload({ file_id, spec, status: "in-progress" });
      await getStoreActions().api_client.fetch({
        url,
        method: "POST",
        data: spec,
        onComplete: ({ success }) =>
          actions.updateDownload({
            file_id,
            spec,
            status: success === true ? "completed" : "error"
          })
      });
    }
  ),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [storeActions.reset.reset],
    state => {
      // Reset params
      state.text_column = {};
      state.num_topics = {};
      state.word_frequency_threshold = {};
      state.doc_frequency_threshold = {};
      state.doc_size_threshold = {};
      state.secondary_topic_thresh = {};
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getParams: computed(state => file => {
    const text_column = state.text_column[file];
    const num_topics = state.num_topics[file];
    const word_frequency_threshold = state.word_frequency_threshold[file];
    const doc_frequency_threshold = state.doc_frequency_threshold[file];
    const doc_size_threshold = state.doc_size_threshold[file];
    const secondary_topic_thresh = state.secondary_topic_thresh[file];

    return {
      text_column,
      num_topics,
      word_frequency_threshold,
      doc_frequency_threshold,
      doc_size_threshold,
      secondary_topic_thresh
    };
  }),

  getSpec: computed(
    [
      state => state,
      (state, globalState) => globalState.data_files_user_settings
    ],
    (state, settings) => file => {
      const text_column = state.text_column[file];
      const num_topics = state.num_topics[file];
      const word_frequency_threshold = state.word_frequency_threshold[file];
      const doc_frequency_threshold = state.doc_frequency_threshold[file];
      const doc_size_threshold = state.doc_size_threshold[file];
      const secondary_topic_thresh = state.secondary_topic_thresh[file];
      // Change spec as required.
      const filters = settings.getFiltersForFile(file);

      const spec: Spec = {
        text_column,
        num_topics,
        word_frequency_threshold,
        doc_frequency_threshold,
        doc_size_threshold,
        secondary_topic_thresh,
        filters
      };
      return spec;
    }
  ),

  resource: computed(
    [
      state => state.getSpec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (getSpec, getInsight) => file_id => {
      const spec = getSpec(file_id);
      if (spec === null) {
        return;
      }
      const spec_string = stringifySpec(spec);
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getData: computed(state => file_id => state.resource(file_id)?.data),

  getComputeStatus: computed(state => file_id =>
    state.resource(file_id)?.compute_status
  ),

  getExportStatus: computed(state => file_id => {
    const spec = state.getSpec(file_id);
    if (spec === null) {
      return;
    }

    return (state.downloads[file_id] || {})[stringifySpec(spec)];
  })
};

export default model;
