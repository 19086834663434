import { useState } from "react";

import { SingleRule } from "./types";
import getItemListing from "./item-listing";

export default (listing: SingleRule[]) => {
  const { ante_items, cons_items } = getItemListing(listing);

  const [anteDisplayItems, setAnteDisplayItems] = useState(ante_items);
  const [consDisplayItems, setConsDisplayItems] = useState(cons_items);
  const [minAnteLength, setMinAnteLength] = useState<number>(1);
  const [minConsLength, setMinConsLength] = useState<number>(1);

  const filtered_rules = listing
    .filter(
      r =>
        r["antecedent"].length >= minAnteLength &&
        r["consequent"].length >= minConsLength
    )
    .filter(
      r =>
        r["antecedent"].filter(item => anteDisplayItems.includes(item))
          .length === r["antecedent"].length
    )
    .filter(
      r =>
        r["consequent"].filter(item => consDisplayItems.includes(item))
          .length === r["consequent"].length
    );

  return {
    ante_items,
    cons_items,
    anteDisplayItems,
    setAnteDisplayItems,
    consDisplayItems,
    setConsDisplayItems,
    minAnteLength,
    setMinAnteLength,
    minConsLength,
    setMinConsLength,
    filtered_rules
  };
};
