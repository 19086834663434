import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec } from "./types";

const stringifySpec = ({
  column1,
  column2,
  column1_type,
  column2_type,
  filters
}: Spec): string =>
  `${column1}__${column2}__${column1_type}__${column2_type}__${filters}`;

// Change key
const INSIGHT_TYPE = "data-file-compare-columns";

const model: StateInterface = {
  // State
  current_file: null,

  // Add params. Each of them: param: {[file_id]: ...}
  column1: {},
  column2: {},

  submit_error: {},

  // Set Params
  setParams: action((state, payload) => {
    const { file_id, params } = payload;

    if (file_id === null) {
      state.current_file = null;
      return;
    }

    const current_file = file_id || state.current_file;
    if (!current_file) {
      return;
    }

    state.current_file = current_file;

    if (!params) {
      return;
    }

    // Add other params as required.
    if (params.column1) {
      state.column1[current_file] = params.column1;
    }
    if (params.column2) {
      state.column2[current_file] = params.column2;
    }
  }),

  resetParams: action(state => {
    // Reset all params.
    state.current_file = null;
    state.column1 = {};
    state.column2 = {};
  }),

  setSubmitError: action((state, error) => {
    if (state.current_file) {
      state.submit_error[state.current_file] = error;
    }
  }),

  // Create Request
  create: thunk(
    async (actions, payload, { injections, getState, getStoreActions }) => {
      const state = getState();
      if (!state.current_file) {
        return;
      }

      if (state.resource) {
        return;
      }
      const params = state.getParams();
      if (!params.column1 || !params.column2) {
        actions.setSubmitError("Column1 and Column2 are both mandatory!!!");
      }

      const spec = state.spec;

      if (!spec) {
        return;
      }

      const { urls } = injections;
      const file_id = state.current_file;

      const spec_string = stringifySpec(spec);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_compare_columns}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec,
        spec_string
      });
    }
  ),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [storeActions.reset.reset],
    state => {
      state.submit_error = {};
      state.current_file = null;
      // Reset other params as well
      state.column1 = {};
      state.column2 = {};
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getParams: computed(state => () => {
    const { current_file } = state;
    if (!current_file) {
      return {};
    }

    // Add more if required
    return {
      column1: state.column1[current_file],
      column2: state.column2[current_file]
    };
  }),

  spec: computed(
    [
      state => state.current_file,
      state => state.getParams(),
      (_, globalState) => globalState.data_files_user_settings
    ],
    (file, params, settings) => {
      if (!file) {
        return null;
      }
      const { column1, column2 } = params;

      if (!column1 || !column2) {
        return null;
      }

      // Change spec as required.
      const types = settings.getTypesForFile(file);
      const filters = settings.getFiltersForFile(file);

      const spec: Spec = {
        column1,
        column2,
        column1_type: types[column1],
        column2_type: types[column2],
        filters
      };
      return spec;
    }
  ),

  resource: computed(
    [
      state => state.current_file || "",
      state => state.spec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (file_id, spec, getInsight) => {
      if (!spec) {
        return;
      }
      const spec_string = stringifySpec(spec);
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getData: computed(state => () => state.resource?.data),

  getComputeStatus: computed(state => () => state.resource?.compute_status)
};

export default model;
