import React from "react";

interface PropsInterface {
  title?: string | number | React.ReactNode;
  footer?: React.ReactNode;
  highlight?: string | number | React.ReactNode;
  subtitle?: string | React.ReactNode;
  text?: string | number | React.ReactNode;
  tags?: string[];
}

/** Generates a Card with a heading, highlight, text and a link. */
const Card: React.FC<PropsInterface> = props => {
  const { title, footer, highlight, subtitle, text, tags } = props;
  const titleComponent = title ? (
    <div className="card-header">
      <p className="card-header-title">{title}</p>
    </div>
  ) : null;

  const highlightComponent =
    highlight !== null && highlight !== undefined ? (
      <p className="title has-text-centered has-text-primary">{highlight}</p>
    ) : null;

  const subtitleComponent =
    subtitle !== null && subtitle !== undefined ? (
      <p className="subtitle has-text-centered">{subtitle}</p>
    ) : null;
  const textComponent =
    text !== null && text !== undefined ? (
      <div className="has-text-centered">{text}</div>
    ) : null;
  const tagsComponent = Array.isArray(tags) ? (
    <p className="tags">
      {(tags || []).map((tag, i) => (
        <span
          key={i}
          className="tag is-rounded is-primary"
          style={{ margin: "0.2rem" }}
        >
          {tag}
        </span>
      ))}
    </p>
  ) : null;

  if (
    titleComponent === null &&
    highlightComponent === null &&
    subtitleComponent === null &&
    textComponent === null &&
    tagsComponent === null
  ) {
    return null;
  }
  return (
    <div className="column is-3-desktop is-4-tablet is-6-mobile">
      <div className="card">
        {titleComponent}
        <div
          className={"card-content"}
          style={{ maxHeight: "250px", height: "400px", overflow: "scroll" }}
        >
          {tagsComponent}
          {highlightComponent}
          {subtitleComponent}
          {textComponent}
        </div>
        {footer && (
          <footer className="card-footer">
            <div className="card-footer-item">{footer}</div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default Card;
