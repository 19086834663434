import { thunk } from "easy-peasy";

import { delay } from "utils";
import StateInterface from "./types";

const model: StateInterface = {
  createFile: thunk(async (actions, payload, { getStoreActions }) => {
    const { url, spec, updateTaskStatus } = payload;

    await getStoreActions().api_client.fetch({
      url,
      data: spec,
      method: "POST",
      onComplete: ({ success, data, error }) => {
        if (success) {
          const { request_id } = data;
          updateTaskStatus({
            spec,
            status: { completed: false, error: false }
          });
          actions.fetchTaskStatus({
            taskID: request_id,
            updateResource: ({ compute_status, data }) => {
              updateTaskStatus({
                spec,
                status: compute_status,
                result: data && Array.isArray(data) ? data[0] : undefined
              });
            }
          });
        } else {
          updateTaskStatus({
            spec,
            status: { completed: true, error: true, error_message: error }
          });
        }
      }
    });
  }),

  fetchTaskStatus: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const { urls } = injections;
      const { taskID, updateResource } = payload;

      const url = `${urls.task_status}/${taskID}`;
      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: async ({ success, error, data }) => {
          if (!success) {
            if (error === "Login Failed") {
              await delay(5000);
              actions.fetchTaskStatus(payload);
            } else {
              updateResource({
                compute_status: { error: true, error_message: error }
              });
            }
            return;
          }

          if (data.status === "FAILURE") {
            updateResource({
              compute_status: {
                error: true,
                error_message: "Unable to compute..."
              }
            });
          } else if (data.status === "SUCCESS") {
            if (data.result.error) {
              updateResource({
                compute_status: {
                  error: true,
                  error_message:
                    data.result.error_message || data.result.message
                }
              });
            } else if (data.result.completed === true) {
              updateResource({
                compute_status: {
                  error: false,
                  completed: true,
                  data: data.result.data
                },
                data: data.result.data
              });
            }
          } else {
            await delay(2000);
            actions.fetchTaskStatus(payload);
          }
        }
      });
    }
  )
};

export default model;
