import { useState, useMemo } from "react";

import { Pattern } from "./types";

export const get_item_list = (patterns: Pattern[]) => {
  let itemList: string[] = [];
  patterns.forEach(p => itemList.push(...p.itemSet));

  return Array.from(new Set(itemList));
};

export const get_grouping_list = (
  patterns: Pattern[],
  grouping_columns: string[] | null | undefined
) => {
  let groupingList: { [col: string]: string[] } = {};
  if (grouping_columns !== null && grouping_columns !== undefined) {
    if (grouping_columns.length >= 2) {
      grouping_columns.forEach((g, i) => {
        const values = patterns.map(p => (p.grouping || [])[i]);
        groupingList[g] = Array.from(new Set(values)).map(v => v.toString());
      });
    } else if (grouping_columns.length === 1) {
      groupingList[grouping_columns[0]] = Array.from(
        new Set(
          patterns.map(p =>
            p.grouping === null || p.grouping === undefined
              ? ""
              : p.grouping.toString()
          )
        )
      );
    }
  }
  return groupingList;
};

export default (
  patterns: Pattern[],
  grouping_columns: string[] | null | undefined
) => {
  const itemList = useMemo(() => get_item_list(patterns), [patterns]);
  const groupingList = get_grouping_list(patterns, grouping_columns);

  const [showingItems, setShowingItems] = useState<string[]>(itemList);
  const [minPatternLength, setMinPatternLength] = useState<number>(1);
  const [showingGroups, setShowingGroups] = useState<{
    [col: string]: string[];
  }>(groupingList);

  let patternsToShow = patterns
    .filter(
      p =>
        p.itemSet.filter(s => showingItems.includes(s)).length ===
        p.itemSet.length
    )
    .filter(p => p.itemSet.length >= minPatternLength);

  if (Array.isArray(grouping_columns)) {
    patternsToShow = patternsToShow.filter(p => {
      if (grouping_columns.length >= 2) {
        return (
          (p.grouping || []).filter((g, i) =>
            showingGroups[grouping_columns[i]].includes(g.toString())
          ).length === (p.grouping || []).length
        );
      } else if (grouping_columns.length === 1) {
        return showingGroups[grouping_columns[0]].includes(
          p.grouping === null || p.grouping === undefined
            ? ""
            : p.grouping.toString()
        );
      } else {
        return true;
      }
    });
  }

  return {
    itemList,
    showingItems,
    setShowingItems,
    groupingList,
    showingGroups,
    setShowingGroups,
    minPatternLength,
    setMinPatternLength,
    patternsToShow
  };
};
