import {
  // faCoins,
  faFileUpload,
  faFilter,
  faLayerGroup,
  faObjectGroup,
  faBoxes,
  faChartLine,
  faClipboardCheck,
  faDrawPolygon
  // faSitemap
} from "@fortawesome/free-solid-svg-icons";

export const paths = {
  upload: "upload",
  clean: "clean",
  filter: "filter",
  augment: "add-columns",
  grouped_aggregates: "grouped-aggregates",
  time_series: "time-series",
  join: "join",
  stack: "stack",
  subset: "split"
};

export default [
  {
    title: "Upload Data",
    subtitle: "Upload Data into the Platform.",
    text: "Local or cloud/shared drives. Excel, CSV or Parquet formats.",
    link: paths.upload,
    icon: faFileUpload
  },
  {
    title: "Clean/Prepare",
    subtitle: "Clean/Prepare Existing Data to Create a New File",
    text: "Missing Value Treatment, Outlier Treatment, Type-casts, etc.",
    link: paths.clean,
    icon: faClipboardCheck
  },
  {
    title: "Augment (Add New Columns)",
    subtitle: "Add New Columns to Existing Data and Create a New File",
    text:
      "Arithmetic Transforms, Running and Time-Window Stats, Shifts/Leads/Lags, Geo-Coding, etc.",
    link: paths.augment,
    icon: faDrawPolygon
  },
  {
    title: "Convert to Time-Series",
    subtitle:
      "Generate Time-Series from existing data using grouping variables and time-resolution.",
    text: "Mean, First, Last, Sum, Count, Max, Min, etc.",
    link: paths.time_series,
    icon: faChartLine
  },
  {
    title: "Aggregate Data by Group",
    subtitle: "Aggregates Data using Grouping Variables to Create a New File",
    text:
      "Mean, Median, Sum, Differences, Unique-Counts, Concentration Indices, First, Last, Contents, etc.",
    link: paths.grouped_aggregates,
    icon: faBoxes
  },
  {
    title: "Filter Data",
    subtitle: "Extract a Subset of Data into a separate File",
    text: "Apply Filters on Data, and make filtered data-sets.",
    link: paths.filter,
    icon: faFilter
  },
  {
    title: "Join Data (DB-Style)",
    subtitle: "Join Data (DB-Style) to Create a New File.",
    text:
      "Indexed joins (including lookup-style). Inner and Outer Joins. And multiple files.",
    link: paths.join,
    icon: faObjectGroup
  },
  {
    title: "Stack Data to Single File",
    subtitle: "Stack Files on Top of Each Other to Create a New File",
    text:
      "Files with same columns, but corresponding to different groups, e.g., Year, Gender",
    link: paths.stack,
    icon: faLayerGroup
  }
  // {
  //   title: "Split Data to Multiple Files",
  //   subtitle: "Divide Data into Multiple Files",
  //   text: "Use sub-setting criteria to easily split Data into multiple parts.",
  //   link: paths.subset,
  //   icon: faSitemap
  // }
];
