import authStateModel from "./auth/types";
import apiStateModel from "./api/types";
import downloadsStateModel from "./downloads-exports/types";
import resetStateModel from "./reset/types";
import crumbsStateModel from "./bread-crumbs/types";
import tourGuideStateModel from "./tour-guide/types";
import recipeStateModel from "./recipes/types";
import createNewModelState from "./workspace-create-new-model/types";
import createIterativeModelState from "./workspace-create-iterate-model/types";
import predictiveModelListing from "./predictive-model-listing/types";
import predictiveModelActions from "./predictive-model-actions/types";
import predictiveModelInsights from "./predictive-model-insights/types";
import predictiveModelExports from "./predictive-model-exports/types";
import dataFilesStateModel from "./data-files/types";
import dataFilesUserSettingsModel from "./data-file-user-settings/types";
import dataFileInsightsStateModel from "./data-file-insights/types";
import dataFileAssociationRulesModel from "./data-file-association-rules/types";
import dataFileFrequentPatternsModel from "./data-file-frequent-patterns/types";
import dataFileTimeSeriesAnomaliesModel from "./data-file-time-series-anomalies/types";
import dataFileTimeSeriesChangePointsModel from "./data-file-time-series-change-points/types";
import dataFileTimeSeriesDistributionsModel from "./data-file-time-series-distributions/types";
import dataFileDocumentTopicsModel from "./data-file-document-topics/types";
import dataFileIdProfilesModel from "./data-file-id-profiles/types";
import dataFileGroupDifferencesModel from "./data-file-group-differences/types";
import dataFileGroupedDistributionsModel from "./data-file-grouped-distributions/types";
import dataFileRelationshipsModel from "./data-file-relationships/types";
import dataFileCompareColumnsModel from "./data-file-compare-columns/types";
import dataFileHygieneModel from "./data-file-hygiene/types";
import dataFileColumnTypesModel from "./data-file-column-types/types";
import dataFileColumnProfilesModel from "./data-file-column-profiles/types";
import dataFileSampleModel from "./data-file-sample/types";
import dataFileCreateStatus from "./data-file-create-status/types";
import dataFileCreateUpload from "./data-file-create-upload/types";
import dataFileCreateFilter from "./data-file-create-filter/types";
import dataFileCreateJoin from "./data-file-create-join/types";
import dataFileCreateStack from "./data-file-create-stack/types";
import dataFileCreateAggregates from "./data-file-create-aggregates/types";
import dataFileCreateTimeSeries from "./data-file-create-time-series/types";
import dataFileCreateAugmentData from "./data-file-create-augmented-data/types";
import dataFileCreateClean from "./data-file-create-clean/types";

export * from "./auth/types";
export * from "./reset/types";
export * from "./bread-crumbs/types";
export * from "./recipes/types";
export * from "./data-files/types";
export * from "./data-file-user-settings/types";
export * from "./data-file-column-profiles/types";
export * from "./data-file-insights/types";
export * from "./data-file-sample/types";
export * from "./data-file-create-augmented-data/augment-spec";
export * from "./data-file-create-clean/prepare-spec";
export * from "./predictive-model-listing/types";

export type AUTH_URL_TYPE =
  | "create_jwt"
  | "refresh_jwt"
  | "destroy_jwt"
  | "me"
  | "change_password";

export type TASK_STATUS = "task_status" | "downloads";

export type DATA_FILES = "data" | "data_hygiene";

export type DATA_FILE_CREATE =
  | "upload_data"
  | "create_data_filter"
  | "create_data_join"
  | "create_data_stack"
  | "create_data_aggregates"
  | "create_data_time_series"
  | "create_data_augment"
  | "create_data_clean";

export type DATA_FILE_INSIGHTS =
  | "data_sample"
  | "data_column_profile"
  | "data_column_types"
  | "data_hygiene"
  | "data_compare_columns"
  | "data_relationship_matrix"
  | "data_grouped_distributions"
  | "data_group_differences_summary"
  | "data_group_differences_column_details"
  | "data_id_profiles"
  | "data_export_id_profiles"
  | "data_document_topics"
  | "data_export_document_topics"
  | "data_time_series_distributions"
  | "data_time_series_change_points"
  | "data_time_series_anomalies"
  | "data_frequent_itemsets"
  | "data_association_rules";

export type PREDICTIVE_MODEL_URLS =
  | "predictive_models"
  | "create_new_model"
  | "model_details";

export type RECIPE_URLS = "recipes";

export type ALLOWED_URLS =
  | AUTH_URL_TYPE
  | TASK_STATUS
  | DATA_FILES
  | DATA_FILE_CREATE
  | DATA_FILE_INSIGHTS
  | RECIPE_URLS
  | PREDICTIVE_MODEL_URLS;

export interface ComputeStatus {
  completed?: boolean;
  error?: boolean;
  error_message?: string | string[] | { [key: string]: string | string[] };
  status?: string;
  data?: { [key: string]: any };
}

export interface Resource<T> {
  data?: T;
  compute_status: ComputeStatus;
}

export interface Injections {
  // apiClient: ApiClientType;
  urls: Record<ALLOWED_URLS, string>;
}

export interface StoreModel {
  data_file_association_rules: dataFileAssociationRulesModel;
  data_file_frequent_patterns: dataFileFrequentPatternsModel;
  data_file_time_series_anomalies: dataFileTimeSeriesAnomaliesModel;
  data_file_time_series_change_points: dataFileTimeSeriesChangePointsModel;
  data_file_time_series_distributions: dataFileTimeSeriesDistributionsModel;
  data_file_document_topics: dataFileDocumentTopicsModel;
  data_file_id_profiles: dataFileIdProfilesModel;
  data_file_group_differences: dataFileGroupDifferencesModel;
  data_file_grouped_distributions: dataFileGroupedDistributionsModel;
  data_file_relationships: dataFileRelationshipsModel;
  data_file_compare_columns: dataFileCompareColumnsModel;
  data_file_hygiene: dataFileHygieneModel;
  data_file_column_types: dataFileColumnTypesModel;
  data_file_column_profiles: dataFileColumnProfilesModel;
  data_file_create_status: dataFileCreateStatus;
  data_file_create_upload: dataFileCreateUpload;
  data_file_create_filter: dataFileCreateFilter;
  data_file_create_join: dataFileCreateJoin;
  data_file_create_stack: dataFileCreateStack;
  data_file_create_aggregates: dataFileCreateAggregates;
  data_file_create_time_series: dataFileCreateTimeSeries;
  data_file_create_augment: dataFileCreateAugmentData;
  data_file_create_clean: dataFileCreateClean;
  auth: authStateModel;
  api_client: apiStateModel;
  reset: resetStateModel;
  crumbs: crumbsStateModel;
  tour_guide: tourGuideStateModel;
  downloads: downloadsStateModel;
  recipes: recipeStateModel;
  workspace_create_new_model: createNewModelState;
  workspace_create_iterate_model: createIterativeModelState;
  predictive_model_listing: predictiveModelListing;
  predictive_model_actions: predictiveModelActions;
  predictive_model_insights: predictiveModelInsights;
  predictive_model_exports: predictiveModelExports;
  data_files: dataFilesStateModel;
  data_files_user_settings: dataFilesUserSettingsModel;
  data_file_insights: dataFileInsightsStateModel;
  data_file_sample: dataFileSampleModel;
}
