import { Margin } from "Visualizations/types";
import { DEFAULT_MARGIN } from "Visualizations/default-style-settings";

export default (
  passedMargin: Partial<Margin> | null | undefined,
  defaultMargin: Margin = DEFAULT_MARGIN
): Margin => {
  if (passedMargin === null || passedMargin === undefined) {
    return defaultMargin;
  }

  let newM: Margin = { ...defaultMargin, ...passedMargin };

  newM["top"] =
    newM["top"] === null || newM["top"] === undefined
      ? defaultMargin.top
      : newM["top"];

  newM["bottom"] =
    newM["bottom"] === null || newM["bottom"] === undefined
      ? defaultMargin.bottom
      : newM["bottom"];

  newM["left"] =
    newM["left"] === null || newM["left"] === undefined
      ? defaultMargin.top
      : newM["left"];

  newM["right"] =
    newM["right"] === null || newM["right"] === undefined
      ? defaultMargin.top
      : newM["right"];
  return newM;
};
