import React from "react";

import {
  ViewPort,
  UnivariateDatum,
  VarType,
  ScaleType,
  FillProps,
  InteractionProps
} from "Visualizations/types";

import { DEFAULT_MARGIN } from "Visualizations/default-style-settings";
import { FILL_COLOR, HOVER_COLOR } from "Visualizations/default-style-settings";

import { useToolTip } from "../../components/Tool-Tip";
import { XAxis, YAxis, Brush, Bars, ToolTip } from "Visualizations/components";

import { generateDomains } from "Visualizations/data-utils";
import { getMargin } from "Visualizations/utils";

interface PropsInterface
  extends ViewPort,
    FillProps<UnivariateDatum>,
    InteractionProps<UnivariateDatum> {
  data: UnivariateDatum[];
  chartType: "vertical" | "horizontal";
  varType: VarType;
  valueDomain?: (string | number | Date)[];
  heightDomain?: [number, number];
  centerHeightAtZero?: boolean;
  valueScaleType?: ScaleType;
  heightScaleType?: "linear" | "sqrt";
  showBrush?: boolean;
  brushDomain?: (string | number | Date)[];
  showValueAxis?: boolean;
  showHeightAxis?: boolean;
  showValueAxisLabels?: boolean;
  showHeightAxisLabels?: boolean;
  startHeightAtZero?: boolean;
  showToolTip?: boolean;
  annotater?(d: UnivariateDatum): React.ReactNode;
  title?: React.ReactNode;
}

const INIT_VALUE_DOMAIN: (string | number | Date)[] = [];
const INIT_BRUSH_DOMAIN: (string | number | Date)[] = [];

const DEFAULT_ANNOTATER = (d: any) => d.toLocaleString();
const DEFAULT_INTERACTION = () => {};

const Barchart: React.FC<PropsInterface> = ({
  data,
  chartType = "vertical",
  varType,
  valueScaleType = "linear",
  heightScaleType = "linear",
  valueDomain = INIT_VALUE_DOMAIN,
  showBrush = false,
  brushDomain = INIT_BRUSH_DOMAIN,
  centerHeightAtZero = false,
  showValueAxis = true,
  showHeightAxis = true,
  showValueAxisLabels = true,
  showHeightAxisLabels = true,
  startHeightAtZero = false,
  fillColor = FILL_COLOR,
  hoverColor = HOVER_COLOR,
  showToolTip = true,
  annotater = DEFAULT_ANNOTATER,
  onClick = DEFAULT_INTERACTION,
  onMouseOver = DEFAULT_INTERACTION,
  onMouseOut = DEFAULT_INTERACTION,
  onZoomChange = DEFAULT_INTERACTION,
  width,
  height,
  margin = DEFAULT_MARGIN,
  title
}) => {
  const [hoverStatus, hoverD, onHover, offHover] = useToolTip(
    onMouseOver,
    onMouseOut
  );

  let heightDomain: [number, number];

  [valueDomain, heightDomain] = generateDomains(
    data,
    varType,
    valueDomain,
    undefined,
    startHeightAtZero
  );

  const ValueAxis = chartType === "vertical" ? XAxis : YAxis;
  const HeightAxis = chartType === "vertical" ? YAxis : XAxis;

  const new_margin = getMargin(margin, DEFAULT_MARGIN);

  return (
    <svg width={width} height={height}>
      {title && (
        <foreignObject
          x={0}
          y={0}
          width={width}
          height={margin.top}
          className="has-text-centered has-text-weight-bold"
          style={{ wordWrap: "normal", textDecoration: "underline" }}
        >
          {title}
        </foreignObject>
      )}
      <Bars
        data={data}
        barType={chartType}
        varType={varType}
        valueDomain={valueDomain}
        heightDomain={heightDomain}
        startHeightAtZero={startHeightAtZero}
        heightScaleType={heightScaleType}
        fillColor={fillColor}
        hoverColor={hoverColor}
        onClick={onClick}
        onMouseOver={onHover}
        onMouseOut={offHover}
        width={width}
        height={height}
        margin={new_margin}
        centerHeightAtZero={centerHeightAtZero}
      />
      {showValueAxis && (
        <ValueAxis
          varType={varType}
          scaleType={valueScaleType}
          domain={valueDomain}
          width={width}
          height={height}
          margin={new_margin}
          showText={showValueAxisLabels}
        />
      )}
      {showHeightAxis && (
        <HeightAxis
          varType="numeric"
          scaleType={heightScaleType}
          domain={heightDomain}
          width={width}
          height={height}
          margin={new_margin}
          showText={showHeightAxisLabels}
        />
      )}
      {showBrush && (
        <Brush
          domain={valueDomain}
          zoomedDomain={brushDomain}
          varType={varType}
          brushType={chartType === "horizontal" ? "vertical" : "horizontal"}
          height={height}
          width={width}
          margin={new_margin}
          onZoom={onZoomChange}
        />
      )}
      {showToolTip && hoverStatus && (
        <ToolTip
          d={annotater(hoverD.d)}
          offsetX={hoverD.offsetX}
          offsetY={hoverD.offsetY}
          width={width}
          margin={new_margin}
          position={chartType === "horizontal" ? "vertical" : "horizontal"}
        />
      )}
    </svg>
  );
};

export default Barchart;
