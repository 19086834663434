import { useState, useCallback } from "react";

function useSorter<T = string>(
  init_sorter?: T,
  init_order: "asc" | "dsc" = "dsc"
): ["asc" | "dsc", T | undefined, (l: T) => void] {
  const [sort_order, setSortOrder] = useState<"asc" | "dsc">(init_order);
  const [sort_column, setSortColumn] = useState<T | undefined>(init_sorter);

  const setSortCallback = useCallback(
    (l: T) => {
      if (sort_column === l) {
        setSortOrder(sort_order === "asc" ? "dsc" : "asc");
      } else {
        setSortColumn(l);
      }
    },
    [sort_order, sort_column]
  );

  return [sort_order, sort_column, setSortCallback];
}

export default useSorter;
