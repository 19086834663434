import React from "react";

import {
  ViewPort,
  ScatterPointDatum,
  VarType,
  ScaleType,
  Fill,
  FillProps
} from "Visualizations/types";

import {
  DEFAULT_MARGIN,
  HOVER_COLOR,
  MARKER_COLOR
} from "Visualizations/default-style-settings";

import { useToolTip } from "Visualizations/components/Tool-Tip";
import {
  XAxis,
  YAxis,
  Brush,
  StackedBars,
  LineScatter,
  ToolTip
} from "Visualizations/components";
import getStackSizes from "Visualizations/components/Stacked-Bars/stack-sizes";
import getDomains from "Visualizations/components/Stacked-Bars/domains";
import getGroups from "Visualizations/components/Stacked-Bars/groups";

import { getScale, getMargin } from "Visualizations/utils";
import {
  filterObjectsByValidity,
  getDomainFromArray
} from "Visualizations/data-utils";

interface GridCell {
  value: number;

  x?: string | number;
  y?: string | number;

  leftEdge?: Date | number;
  rightEdge?: Date | number;
  level?: string | number;

  xLeftEdge?: Date | number;
  yLeftEdge?: Date | number;
  xRightEdge?: Date | number;
  yRightEdge?: Date | number;

  group?: string | number | Date;
  [key: string]: any;
}

interface PropsInterface extends ViewPort, FillProps<GridCell> {
  data: { bars: GridCell[]; lines: ScatterPointDatum[] };
  chartType?: "horizontal" | "vertical";
  axisVariableType?: VarType;
  stackVariableType?: VarType;
  axisDomain?: (string | number | Date)[];
  stackDomain?: (string | number | Date)[];
  valueDomain?: [number, number];
  xMarkers?: (string | number | Date)[];
  yMarkers?: number[];
  xMarkerColor?: Fill<string | number | Date>;
  yMarkerColor?: Fill<number>;
  lineDomain?: number[];
  valueScaleType?: ScaleType;
  lineAxisType?: VarType;
  showBrush?: boolean;
  brushDomain?: (string | number | Date)[];
  showValueAxis?: boolean;
  showValueAxisLabels?: boolean;
  showAxisAxis?: boolean;
  showAxisAxisLabels?: boolean;
  startHeightAtZero?: boolean;
  showLineAxis?: boolean;
  pointColor?: Fill<ScatterPointDatum>;
  lineColor?: Fill<ScatterPointDatum>;
  showBarToolTip?: boolean;
  showPointToolTip?: boolean;
  showLineToolTip?: boolean;
  barAnnotater?(d: any): React.ReactNode;
  onBarClick?(d: any, e?: React.MouseEvent): any;
  onBarMouseOver?(d: any, e?: React.MouseEvent): any;
  onBarMouseOut?(): any;
  pointAnnotater?(d: ScatterPointDatum): React.ReactNode;
  onPointClick?: (p: ScatterPointDatum, e?: React.MouseEvent) => any;
  onPointMouseOver?: (p: ScatterPointDatum, e?: React.MouseEvent) => any;
  onPointMouseOut?: (p?: ScatterPointDatum, e?: React.MouseEvent) => any;
  lineAnnotater?: ((p: ScatterPointDatum) => React.ReactNode) | React.ReactNode;
  onLineClick?(e: React.MouseEvent): any;
  onLineMouseOver?: (e?: React.MouseEvent) => any;
  onLineMouseOut?: (e?: React.MouseEvent) => any;
  onZoomChange?(domain: (string | number | Date)[]): any;
}

// const INIT_VALUE_DOMAIN: (string | number | Date)[] = [];
// const INIT_BRUSH_DOMAIN: (string | number | Date)[] = [];

const DEFAULT_ANNOTATER = (d: any) => JSON.stringify(d);
const DEFAULT_POINT_ANNOTATER = (d: ScatterPointDatum) =>
  `X: ${d.x.toLocaleString()}; Y: ${d.y.toLocaleString()}`;
const DEFAULT_LINE_ANNOTATER = (d: ScatterPointDatum) =>
  d.group !== null && d.group !== undefined ? d.group.toLocaleString() : "";
const DEFAULT_INTERACTION = () => {};

const DEF_MARGIN = { ...DEFAULT_MARGIN, right: 50 };

const StackedBarChartWithLines: React.FC<PropsInterface> = ({
  data,
  chartType = "vertical",
  axisVariableType = "numeric",
  stackVariableType = "char",
  axisDomain,
  stackDomain,
  lineAxisType = "numeric",
  valueDomain,
  lineDomain,
  showBrush = false,
  brushDomain,
  xMarkers,
  yMarkers,
  xMarkerColor,
  yMarkerColor,
  showValueAxis = true,
  showValueAxisLabels = true,
  showAxisAxis = true,
  showAxisAxisLabels = true,
  showLineAxis = true,
  fillColor,
  hoverColor = HOVER_COLOR,
  pointColor,
  lineColor = MARKER_COLOR,
  showBarToolTip = true,
  showPointToolTip = true,
  showLineToolTip = true,
  barAnnotater: annotater = DEFAULT_ANNOTATER,
  pointAnnotater = DEFAULT_POINT_ANNOTATER,
  lineAnnotater = DEFAULT_LINE_ANNOTATER,
  onBarClick = DEFAULT_INTERACTION,
  onBarMouseOver = DEFAULT_INTERACTION,
  onBarMouseOut = DEFAULT_INTERACTION,
  onPointClick = DEFAULT_INTERACTION,
  onPointMouseOver = DEFAULT_INTERACTION,
  onPointMouseOut = DEFAULT_INTERACTION,
  onLineClick = DEFAULT_INTERACTION,
  onLineMouseOver = DEFAULT_INTERACTION,
  onLineMouseOut = DEFAULT_INTERACTION,
  onZoomChange = DEFAULT_INTERACTION,
  width,
  height,
  margin
}) => {
  const new_margin = getMargin(margin, DEF_MARGIN);
  const [barHoverStatus, barHoverD, onBarHover, offBarHover] = useToolTip(
    onBarMouseOver,
    onBarMouseOut
  );
  const [
    pointHoverStatus,
    pointHoverD,
    onPointHover,
    offPointHover
  ] = useToolTip(onPointMouseOver, onPointMouseOut);

  const [lineHoverStatus, lineHoverD, onLineHover, offLineHover] = useToolTip(
    (_, e) => onLineMouseOver(e),
    () => onLineMouseOut()
  );

  let { bars, lines } = data;

  if (!valueDomain) {
    const stackSizes = getStackSizes(bars, axisVariableType, stackVariableType);
    valueDomain = [0, Math.max(...stackSizes)];
  }

  if (!axisDomain || !stackDomain) {
    [axisDomain, stackDomain] = getDomains(
      bars,
      axisVariableType,
      stackVariableType,
      axisDomain,
      stackDomain
    );
  }

  if (!fillColor) {
    const [stackUniques] = getGroups(
      bars,
      axisVariableType,
      stackVariableType,
      "stack"
    );
    // @ts-ignore
    fillColor = getScale({ scaleType: "color", domain: stackUniques });
  }

  const AxisAxis = chartType === "vertical" ? XAxis : YAxis;
  const ValueAxis = chartType === "vertical" ? YAxis : XAxis;

  lines = filterObjectsByValidity(lines, "x", axisVariableType);
  lines = filterObjectsByValidity(lines, "y", "numeric");

  const groups = Array.from(
    new Set(
      lines.map(({ group }) =>
        group === undefined || group === null ? group : group.toString()
      )
    )
  );

  const pointGroups =
    groups.length === 1
      ? [lines]
      : groups.map(g =>
          lines.filter(({ group }) =>
            group === undefined || group === null
              ? g === undefined || g === null
              : g === group.toString()
          )
        );

  const yDomain = getDomainFromArray(
    lines.map(({ y }) => y),
    "numeric",
    lineDomain
  );

  return (
    <svg width={width} height={height}>
      <StackedBars
        data={bars}
        barType={chartType}
        stackVariableType={stackVariableType}
        axisVariableType={axisVariableType}
        axisDomain={axisDomain}
        stackDomain={stackDomain}
        valueDomain={valueDomain}
        colorFill={fillColor}
        onClick={onBarClick}
        onMouseOver={onBarHover}
        onMouseOut={offBarHover}
        height={height}
        width={width}
        margin={new_margin}
      />
      {showBarToolTip && barHoverStatus && (
        <ToolTip
          d={annotater(barHoverD.d)}
          offsetX={barHoverD.offsetX}
          offsetY={barHoverD.offsetY}
          width={width}
          margin={new_margin}
          position={chartType === "horizontal" ? "vertical" : "horizontal"}
        />
      )}
      {groups.length === 1 && (
        <LineScatter
          data={lines}
          xType={axisVariableType}
          yType={"numeric"}
          xDomain={axisDomain}
          yDomain={yDomain}
          xMarkers={xMarkers}
          yMarkers={yMarkers}
          xMarkerColor={xMarkerColor}
          // What's expected in string | number | Date. What goes in is number.
          // @ts-ignore
          yMarkerColor={yMarkerColor}
          showLine={true}
          showPoints={true}
          pointColor={pointColor}
          pointHoverColor={hoverColor}
          lineColor={
            typeof lineColor === "function"
              ? lineColor(lines[0])
              : lineColor || MARKER_COLOR
          }
          onPointClick={onPointClick}
          onPointMouseOver={onPointHover}
          onPointMouseOut={offPointHover}
          onLineClick={onLineClick}
          onLineMouseOver={e => onLineHover({}, e)}
          onLineMouseOut={e => offLineHover({}, e)}
          width={width}
          height={height}
          margin={new_margin}
        />
      )}
      {groups.length > 1 &&
        pointGroups.map((d, i) => (
          <LineScatter
            key={
              groups[i] === undefined || groups[i] === null
                ? "NULL--UND"
                : groups[i]
            }
            data={d}
            xType={axisVariableType}
            yType={"numeric"}
            xDomain={axisDomain}
            yDomain={yDomain}
            xMarkers={xMarkers}
            yMarkers={yMarkers}
            xMarkerColor={xMarkerColor}
            // What's expected in string | number | Date. What goes in is number.
            // @ts-ignore
            yMarkerColor={yMarkerColor}
            showLine={true}
            showPoints={true}
            pointColor={
              typeof lineColor === "function"
                ? lineColor(d[0])
                : lineColor || MARKER_COLOR
            }
            pointHoverColor={hoverColor}
            lineColor={
              typeof lineColor === "function"
                ? lineColor(d[0])
                : lineColor || MARKER_COLOR
            }
            onPointClick={onPointClick}
            onPointMouseOver={onPointHover}
            onPointMouseOut={offPointHover}
            onLineClick={onLineClick}
            onLineMouseOver={e => onLineHover(d[0].group, e)}
            onLineMouseOut={e => offLineHover(d[0].group, e)}
            width={width}
            height={height}
            margin={new_margin}
          />
        ))}
      {showBrush && (
        <Brush
          domain={axisDomain}
          zoomedDomain={brushDomain}
          varType={axisVariableType}
          brushType={chartType === "horizontal" ? "vertical" : "horizontal"}
          height={height}
          width={width}
          margin={new_margin}
          onZoom={onZoomChange}
        />
      )}
      {showLineAxis && (
        <YAxis
          varType={lineAxisType}
          scaleType="linear"
          orientation={showValueAxis ? "right" : "left"}
          domain={yDomain}
          width={width}
          height={height}
          margin={new_margin}
        />
      )}
      {showPointToolTip && pointHoverStatus && (
        <ToolTip
          d={pointAnnotater(pointHoverD.d)}
          offsetX={pointHoverD.offsetX}
          offsetY={pointHoverD.offsetY}
          width={width}
          margin={new_margin}
          position={chartType === "horizontal" ? "vertical" : "horizontal"}
        />
      )}
      {showLineToolTip && lineAnnotater && lineHoverStatus && (
        <ToolTip
          d={
            typeof lineAnnotater === "function"
              ? lineAnnotater(lineHoverD.d)
              : lineAnnotater
          }
          offsetX={pointHoverD.offsetX}
          offsetY={pointHoverD.offsetY}
          width={width}
          margin={new_margin}
          position={chartType === "horizontal" ? "vertical" : "horizontal"}
        />
      )}
      {showValueAxis && (
        <ValueAxis
          varType={"numeric"}
          scaleType="linear"
          domain={valueDomain}
          width={width}
          height={height}
          margin={new_margin}
          showText={showValueAxisLabels}
        />
      )}
      {showAxisAxis && (
        <AxisAxis
          varType={axisVariableType}
          scaleType="linear"
          domain={axisDomain}
          width={width}
          height={height}
          margin={new_margin}
          showText={showAxisAxisLabels}
        />
      )}
    </svg>
  );
};

export default StackedBarChartWithLines;
