import { GridCell } from "Visualizations";
import { TimeResolution } from "./types";

import { annotateTimeResolution } from "utils";

const annotater = (resolution: TimeResolution) => {
  return (p: GridCell) => {
    if (p.rightEdge === undefined || p.level === undefined) {
      return "";
    }

    const group =
      p.group === undefined ? "" : `Group: ${p.group.toLocaleString()}; `;

    const value = `${group}${p.size.toLocaleString()} rows for ${p.level.toLocaleString()}`;

    return `${annotateTimeResolution(p.rightEdge, resolution)}: ${value}`;
  };
};

export default annotater;
