export const ROUTER_BASE_URL = process.env.PUBLIC_URL;

export const IDENTITY_PROVIDER =
  process.env.REACT_APP_AUTH_PROVIDER || "Daisho-JWT";
export const SIGN_IN_ALLOWED = [true, "true"].includes(
  process.env.REACT_APP_ALLOW_SIGNIN || false
);
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DB_URL = process.env.REACT_APP_FIREBASE_DB_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const UPGRADE_URL = process.env.REACT_APP_UPGRADE_URL;

export const API_BASE_URL =
  process.env.REACT_APP_DAISHO_API_URL !== undefined &&
  process.env.REACT_APP_DAISHO_API_URL !== null
    ? process.env.REACT_APP_DAISHO_API_URL
    : "http://localhost:8000";

export const AUTH_API_URL =
  process.env.REACT_APP_AUTH_API_URL !== undefined &&
  process.env.REACT_APP_AUTH_API_URL !== null
    ? process.env.REACT_APP_AUTH_API_URL
    : "http://localhost:9000";
