import { SingleRule } from "./types";

const exportToCSV = (rules: SingleRule[]) => {
  let csvContent = "";
  const headers = [
    "Grouping",
    "Antecedent",
    "Consequent",
    "Ante Count",
    "Cons Count",
    "Joint Count",
    "Confidence",
    "Certainty"
  ];
  csvContent += headers.join(",") + "\r\n";

  rules.forEach(r => {
    const row = [
      r["grouping"].join("__"),
      r["antecedent"].join("__"),
      r["consequent"].join("__"),
      r["ante-count"],
      r["cons-count"],
      r["joint-count"],
      r["confidence"],
      r["certainty"]
    ];
    csvContent += row.join(",") + "\r\n";
  });

  const blob = new Blob([csvContent], { type: "text/csv" });
  const href = window.URL.createObjectURL(blob);
  window.location.assign(href);
};

export default exportToCSV;
