import jsPDF from "jspdf";
import html2canvas from "html2canvas";

//@ts-ignore
window.html2canvas = html2canvas;

const svgToCanvas = (targetElem: any) => {
  var svgElem = targetElem.getElementsByTagName("svg");
  for (const node of svgElem) {
    node.setAttribute(
      "font-family",
      window.getComputedStyle(node, null).getPropertyValue("font-family")
    );
    node.replaceWith(node);
  }
};

const convertToImage = (ref: React.RefObject<HTMLDivElement>) => {
  if (ref === null || ref.current === null) {
    return;
  }
  svgToCanvas(ref.current);

  const width = ref.current ? ref.current.offsetWidth : 0;
  const height = ref.current ? ref.current.offsetHeight : 0;

  return html2canvas(ref.current, {
    width,
    height,
    windowWidth: ref.current.scrollWidth,
    windowHeight: ref.current.scrollHeight
  });
};

function saveAs(uri: any, filename: string) {
  var link = document.createElement("a");

  if (typeof link.download === "string") {
    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
}

export const exportToImg = async (
  ref: React.RefObject<HTMLDivElement>,
  fileName: string
) => {
  if (ref === null || ref.current === null) {
    return;
  }
  const canvas = await convertToImage(ref);
  if (canvas) {
    saveAs(canvas.toDataURL(), fileName);
  }
};

/**
 * Converts element to PDF. Prints in A4 landscape format.
 *
 * @param ref Ref to element that should be exported to PDF.
 * @param pdfName File Name of the Export.
 */
export const exportToPDF = async (
  ref: React.RefObject<HTMLDivElement>,
  pdfName: string
) => {
  if (ref === null || ref.current === null) {
    return;
  }

  // @ts-ignore
  const pdf = new jsPDF({
    orientation: "l",
    compress: true,
    x: 10,
    y: 10,
    putOnlyUsedFonts: true
  });

  const width = ref.current ? ref.current.offsetWidth : 0;
  const height = ref.current ? ref.current.offsetHeight : 0;

  const aspect_ratio = height / width;

  const canvas = await convertToImage(ref);
  pdf.addImage(canvas, "JPEG", 10, 10, 300, aspect_ratio * 300);
  pdf.save(pdfName);
};

export default exportToPDF;
