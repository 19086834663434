import { extent } from "d3-array";

import { isCategorical, isNumber } from "../../utils";

import { VarType } from "../../types";

import { GridCell } from "./types";

const generateDomains = (
  data: GridCell[],
  xType: VarType,
  yType: VarType,
  xDomain: (string | number | Date | undefined)[],
  yDomain: (string | number | Date | undefined)[]
) => {
  if (isCategorical(xType) && isCategorical(yType)) {
    if (!(Array.isArray(xDomain) && xDomain.length >= 1)) {
      xDomain = Array.from(new Set(data.map(({ x }) => x)));
    }
    if (!(Array.isArray(yDomain) && yDomain.length >= 1)) {
      yDomain = Array.from(new Set(data.map(({ y }) => y)));
    }
  } else if (!isCategorical(xType) && !isCategorical(yType)) {
    if (!(Array.isArray(xDomain) && xDomain.filter(isNumber).length === 2)) {
      xDomain = [
        // @ts-ignore
        extent(data.map(({ xLeftEdge }) => xLeftEdge))[0] || 0,
        // @ts-ignore
        extent(data.map(({ xRightEdge }) => xRightEdge))[1] || 0
      ];
    }
    if (!(Array.isArray(yDomain) && yDomain.filter(isNumber).length === 2)) {
      yDomain = [
        // @ts-ignore
        extent(data.map(({ yLeftEdge }) => yLeftEdge))[0] || 0,
        // @ts-ignore
        extent(data.map(({ yRightEdge }) => yRightEdge))[1] || 0
      ];
    }
  } else {
    let numDomain = isCategorical(xType) ? yDomain : xDomain;
    let catDomain = isCategorical(xType) ? xDomain : yDomain;
    if (
      !(Array.isArray(numDomain) && numDomain.filter(isNumber).length === 2)
    ) {
      numDomain = [
        // @ts-ignore
        extent(data.map(({ leftEdge }) => leftEdge))[0] || 0,
        // @ts-ignore
        extent(data.map(({ rightEdge }) => rightEdge))[1] || 0
      ];
    }

    if (!(Array.isArray(catDomain) && catDomain.length >= 1)) {
      catDomain = Array.from(new Set(data.map(({ level }) => level)));
    }

    xDomain = isCategorical(xType) ? catDomain : numDomain;
    yDomain = isCategorical(yType) ? catDomain : numDomain;
  }

  return [xDomain, yDomain];
};

export default generateDomains;
