import { VarType } from "../types";

import { isCategorical, isValid, isNumber, isDate } from "../utils";
import filterObjectsByValidity from "./filter-objects-by-validity";

interface Point {
  [key: string]: any;
}

/** Filters a list of objects based on whether each object has a valid value for key. */
function filterObjects<T extends Point>(
  data: T[],
  key: string,
  valueType: VarType,
  range: (string | number | Date | null)[] = []
): T[] {
  if (!Array.isArray(range) || !Array.isArray(data)) {
    return data;
  }
  range = range.filter(isValid);
  if (range.length === 0) {
    return data;
  }
  if (isCategorical(valueType)) {
    data = filterObjectsByValidity(data, key, valueType);
    return data
      .filter(d => range.includes(d[key]))
      .sort((a, b) => range.indexOf(a[key]) - range.indexOf(b[key]));
  }

  if (valueType === "date" || valueType === "time") {
    // @ts-ignore
    range = range.filter(isDate).map(d => (isNumber(d) ? d : Date.parse(d)));
  } else {
    range = range.filter(isNumber);
  }
  if (range.length !== 2) {
    return data;
  }

  data = filterObjectsByValidity(data, key, valueType);

  if (valueType === "date" || valueType === "time") {
    return data.filter(
      // @ts-ignore
      d => Date.parse(d[key]) >= range[0] && Date.parse(d[key]) <= range[1]
    );
  }
  // @ts-ignore
  return data.filter(d => d[key] >= range[0] && d[key] <= range[1]);
}

interface Range {
  key: string;
  varType: VarType;
  range?: (string | number | Date | null)[];
}

export function filterObjectsByRanges<T extends Point>(
  data: T[],
  ranges: Range[] = []
): T[] {
  if (!Array.isArray(ranges)) {
    return data;
  }
  ranges = ranges.filter(
    ({ range }) => Array.isArray(range) && range.length >= 1
  );

  if (ranges.length === 0) {
    return data;
  }

  ranges.forEach(({ key, varType, range }) => {
    data = filterObjects(data, key, varType, range);
  });

  return data;
}

export default filterObjects;
