import { useState, useEffect } from "react";

import {
  filterObjectsByRange,
  filterObjectsByValidity,
  isCategorical,
  UnivariateDatum,
  VarType,
} from "Visualizations";

type Domain = (string | number | Date)[];

const INIT_DOMAIN: Domain = [];

const useDomain = (
  data: UnivariateDatum[],
  dense_domain: Domain,
  col_type: VarType,
  data_count: number
): [Domain, (d: Domain) => any, UnivariateDatum[], number, string] => {
  if (!(Array.isArray(dense_domain) && dense_domain.length >= 1)) {
    if (col_type === "char" || col_type === "categorical") {
      // @ts-ignore
      dense_domain = data.map((d) => d.level).filter((l) => l !== undefined);
    } else if (
      col_type === "numeric" ||
      col_type === "int" ||
      col_type === "float"
    ) {
      const min: number = Math.min(
        // @ts-ignore
        ...data.map((d) => d.leftEdge).filter((l) => !isNaN(l))
      );
      const max: number = Math.max(
        // @ts-ignore
        ...data.map((d) => d.rightEdge).filter((l) => !isNaN(l))
      );
      dense_domain = [min, max];
    } else if (col_type === "date" || col_type === "time") {
      const d_l: number = Math.min(
        // @ts-ignore
        ...data.map((d) => new Date(d.leftEdge).valueOf())
      );

      const d_r: number = Math.max(
        // @ts-ignore
        ...data.map((d) => new Date(d.rightEdge).valueOf())
      );
      dense_domain = [new Date(d_l), new Date(d_r)];
    }
  }

  const [domain, setDomain] = useState({
    char_domain: ["char", "categorical"].includes(col_type)
      ? dense_domain
      : INIT_DOMAIN,
    num_domain: ["numeric", "int", "float"].includes(col_type)
      ? dense_domain
      : INIT_DOMAIN,
    date_domain: ["date", "time"].includes(col_type)
      ? dense_domain
      : INIT_DOMAIN,
  });

  // useEffect(() => {
  //   setDomain({
  //     char_domain: ["char", "categorical"].includes(col_type)
  //       ? dense_domain
  //       : INIT_DOMAIN,
  //     num_domain: ["numeric", "int", "float"].includes(col_type)
  //       ? dense_domain
  //       : INIT_DOMAIN,
  //     date_domain: ["date", "time"].includes(col_type)
  //       ? dense_domain
  //       : INIT_DOMAIN,
  //   });
  // }, [data, dense_domain, setDomain, col_type]);

  const setTypedDomain = (d: Domain) => {
    if (col_type === "char" || col_type === "categorical") {
      setDomain({ ...domain, char_domain: d });
    } else if (
      col_type === "numeric" ||
      col_type === "int" ||
      col_type === "float"
    ) {
      setDomain({ ...domain, num_domain: d });
    } else if (col_type === "date" || col_type === "time") {
      setDomain({ ...domain, date_domain: d });
    }
  };

  let typed_domain: Domain = [];
  if (col_type === "char" || col_type === "categorical") {
    typed_domain = domain.char_domain;
  } else if (
    col_type === "numeric" ||
    col_type === "int" ||
    col_type === "float"
  ) {
    typed_domain = domain.num_domain;
  } else if (col_type === "date" || col_type === "time") {
    typed_domain = domain.date_domain;
  }

  data = filterObjectsByValidity(data, "height", "numeric");
  if (isCategorical(col_type)) {
    data = filterObjectsByRange(data, "level", col_type, typed_domain);
  } else {
    data = filterObjectsByRange(data, "leftEdge", col_type, typed_domain);
    data = filterObjectsByRange(data, "rightEdge", col_type, typed_domain);
  }

  const total_shown = data
    .map(({ count = 0 }) => count)
    .reduce((a, b) => a + b, 0);

  const p = ((total_shown / data_count) * 100).toLocaleString();

  let new_domain = [...typed_domain];

  return [new_domain, setTypedDomain, data, total_shown, p];
};

export default useDomain;
