import React from "react";

import "./Loading-Indicator.sass";

interface PropsInterface {
  /** Defaults to "Loading..." */
  message?: string;
}

const Loadingindicator: React.FunctionComponent<PropsInterface> = ({
  message
}) => (
  <div className="pageloader is-active">
    <span className="title">{message || "Loading..."}</span>
  </div>
);

export default Loadingindicator;
