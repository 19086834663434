import { VarType } from "Visualizations/types";

import { isCategorical } from "Visualizations/utils";
import {
  filterObjectsByValidity,
  filterObjectsByRange
} from "Visualizations/data-utils";

import { GridCell } from "./types";

const filterData = (
  data: GridCell[],
  axisVariableType: VarType,
  stackVariableType: VarType,
  axisDomain?: (string | number | Date)[],
  stackDomain?: (string | number | Date)[],
  valueDomain?: number[]
): GridCell[] => {
  data = filterObjectsByValidity(data, "value", "numeric");
  let axisKeys: string[] = [];
  let stackKeys: string[] = [];

  if (isCategorical(stackVariableType) && isCategorical(axisVariableType)) {
    axisKeys = ["x"];
    stackKeys = ["y"];
  } else if (isCategorical(stackVariableType)) {
    axisKeys = ["leftEdge", "rightEdge"];
    stackKeys = ["level"];
  } else if (isCategorical(axisVariableType)) {
    axisKeys = ["level"];
    stackKeys = ["leftEdge", "rightEdge"];
  } else {
    axisKeys = ["xLeftEdge", "xRightEdge"];
    stackKeys = ["yLeftEdge", "yRightEdge"];
  }

  data = filterObjectsByRange(data, "value", "numeric", valueDomain);

  axisKeys.forEach(k => {
    data = filterObjectsByValidity(data, k, axisVariableType);
    data = filterObjectsByRange(data, k, axisVariableType, axisDomain);
  });

  stackKeys.forEach(k => {
    data = filterObjectsByValidity(data, k, stackVariableType);
    data = filterObjectsByRange(data, k, stackVariableType, stackDomain);
  });

  return data;
};

export default filterData;
