import { useState } from "react";

import { SingleGroupItemSet } from "./types";
import generatePatterns from "./generate-listing";

const useSort = (data: SingleGroupItemSet[]) => {
  const [sorter, setSorter] = useState<"count" | "l">("count");
  const [sortD, setSortD] = useState<"asc" | "dsc">("dsc");

  let patterns = generatePatterns(data).sort((a, b) => a[sorter] - b[sorter]);

  if (sortD === "dsc") {
    patterns.reverse();
  }

  return { sorter, setSorter, sortD, setSortD, patterns };
};

export default useSort;
