import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import { useStoreState, useStoreActions } from "store";

import paths from "App/paths";
import logo from "./DAISHO FINAL LOGO FILES-02.png";
import { SIGN_IN_ALLOWED } from "App/constants";
import { LoadingIndicator } from "UI-Components";

const HamburgerLinks = () => {
  const loggedIn = useStoreState((store) => {
    return store.auth.logged_in;
  });
  const userDetails = useStoreState((store) => store.auth.user_details);

  const logout = useStoreActions((actions) => actions.auth.startLogout);

  if (loggedIn) {
    return (
      <div className="has-text-right" style={{ backgroundColor: "white" }}>
        <Link to={paths.uploadData} className="navbar-item" id="upload">
          Upload
        </Link>
        <Link
          to={paths.dataFiles}
          className="navbar-item"
          id="explore"
          target="_blank"
        >
          Explore
        </Link>
        <Link
          to={paths.createData}
          className="navbar-item"
          id="create"
          target="_blank"
        >
          Transform
        </Link>
        <Link
          to={paths.predictiveModels}
          className="navbar-item"
          id="models"
          target="_blank"
        >
          Predictive Models
        </Link>
        <span>
          {" "}
          <FontAwesomeIcon icon={faQuestionCircle} className="fas icon" />
        </span>
        {userDetails !== undefined && userDetails !== null && (
          <Link
            onClick={() => {
              logout();
            }}
            to={"/"}
            className="navbar-item"
          >
            Logout
          </Link>
        )}
      </div>
    );
  } else {
    return (
      <div className="navbar-item">
        <p className="has-text-right">
          <Link to={paths.login} key="login">
            Login
          </Link>
        </p>
      </div>
    );
  }
};

const MenuLinks = () => {
  const loggedIn = useStoreState((store) => {
    return store.auth.logged_in;
  });
  const userDetails = useStoreState((store) => store.auth.user_details);

  const logout = useStoreActions((actions) => actions.auth.startLogout);
  const showTourGuide = useStoreActions(
    (actions) => actions.tour_guide.showCurrent
  );

  if (!loggedIn) {
    return (
      <div className="navbar-item">
        <div className="buttons">
          <Link to={paths.login} key="login" className="button">
            LOGIN
          </Link>
          {SIGN_IN_ALLOWED && (
            <Link to={paths.signup} key="sign-up" className="button is-success">
              SIGN UP FREE
            </Link>
          )}
        </div>
      </div>
    );
  }

  if (userDetails === null || userDetails === undefined) {
    return <LoadingIndicator message="Loading your details..." />;
  }

  return (
    <Fragment>
      <div
        className="navbar-item has-dropdown is-hoverable"
        id="data-files-dropdown"
      >
        <div className="navbar-link has-pointer">Data Files</div>
        <div className="navbar-dropdown is-boxed">
          <Link to={paths.uploadData} className="navbar-item" id="upload">
            Upload
          </Link>
          <Link
            to={paths.dataFiles}
            className="navbar-item"
            id="explore"
            target="_blank"
          >
            Explore
          </Link>
          <Link
            to={paths.createData}
            className="navbar-item"
            id="create"
            target="_blank"
          >
            Transform
          </Link>
        </div>
      </div>
      <div className="navbar-item has-dropdown is-hoverable">
        <div className="navbar-link has-pointer">Predictive Models</div>
        <div className="navbar-dropdown is-boxed">
          <Link
            to={paths.recipes}
            className="navbar-item"
            id="create-model"
            target="_blank"
          >
            Recipes/Create
          </Link>
          <Link
            to={paths.predictiveModels}
            className="navbar-item"
            id="explore-models"
            target="_blank"
          >
            Explore Existing
          </Link>
        </div>
      </div>
      <div className="navbar-item is-hoverable" id="header-help">
        <div className="has-pointer">
          <span>
            {" "}
            <FontAwesomeIcon icon={faQuestionCircle} className="fas icon" />
          </span>
        </div>
        <div className="navbar-dropdown is-boxed">
          <span className="navbar-item is-link" onClick={() => showTourGuide()}>
            Show Tour
          </span>
        </div>
      </div>
      <div className="navbar-item has-dropdown is-hoverable">
        <div className="navbar-link has-pointer">{userDetails.email}</div>
        <div className="navbar-dropdown is-boxed">
          <Link
            to="/"
            className="navbar-item"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

const Header: React.FC = () => {
  const loggedIn = useStoreState((store) => {
    return store.auth.logged_in;
  });

  const fetchUserDetails = useStoreActions(
    (actions) => actions.auth.fetchUserDetails
  );

  const [menuActive, setMenuActive] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    fetchUserDetails();
  }, [loggedIn, fetchUserDetails]);

  const show_links = ![paths.login, paths.signup].includes(pathname);

  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label={"main navigation"}
      id="main-header"
    >
      <div className="navbar-brand">
        <Link to={"/"} className={"navbar-item"}>
          <img
            src={logo}
            style={{ maxHeight: "4rem" }}
            alt="Click for Homepage"
          />
        </Link>
        {show_links && (
          <p
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setMenuActive(!menuActive)}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </p>
        )}
      </div>
      {show_links && (
        <div
          className={`navbar-menu${
            menuActive ? " is-active has-background-primary" : ""
          }`}
        >
          <div className="navbar-end" id="header-links">
            {menuActive ? <HamburgerLinks /> : <MenuLinks />}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;
