import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { SelectField } from "UI-Components";
import { useStoreActions, useStoreState } from "store";

import paths from "App/paths";
import { SubscriptionRoute } from "App/Route-Utils";

interface Insight {
  title: string;
  subtitle: string;
  text: string;
  link: string;
}

const ChooseDataFile: React.FC<{ insight: Insight }> = ({ insight }) => {
  const fetchListing = useStoreActions(
    actions => actions.data_files.fetchListing
  );

  useEffect(() => {
    fetchListing(1);
  }, [fetchListing]);

  const fileListing = useStoreState(store => store.data_files.files);

  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const has_access_rights = useStoreState(
    state => state.data_files.has_access_rights
  );

  if (!has_access_rights) {
    return (
      <SubscriptionRoute message="You dont seem to have a valid subscription!!!" />
    );
  }

  return (
    <div className="content">
      <div className="modal-header">
        <b>{insight.subtitle}</b>
      </div>
      <div className="modal-content">
        <p>{insight.text}</p>
        <SelectField
          label="Select Data:"
          options={Object.values(fileListing).map(file => ({
            value: file.id,
            label: `${file.name} (${file.col_count} columns and ${file.row_count} rows)`
          }))}
          placeholder="Select Data"
          value={selectedFile || undefined}
          onValueChange={v => setSelectedFile(v === null ? v : v.toString())}
        />
        {selectedFile && (
          <>
            <p className="has-text-weight-bold">Columns:</p>
            <p className="tags">
              {(fileListing[selectedFile].columns || []).map(c => (
                <span className="tag is-success" key={c}>
                  {c}
                </span>
              ))}
            </p>
            <hr />
          </>
        )}
      </div>
      <div className="modal-actions">
        {selectedFile === null || selectedFile === undefined ? (
          <button className="button is-primary" disabled>
            GENERATE INSIGHT
          </button>
        ) : (
          <Link
            to={`${paths.dataFiles}/${selectedFile}/${insight.link}`}
            className="button is-primary"
          >
            GENERATE INSIGHT
          </Link>
        )}
      </div>
    </div>
  );
};

export default ChooseDataFile;
