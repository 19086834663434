import React from "react";

import InputField from "../Input-Field";

interface Treatment<T> {
  treatment?: "drop-rows" | "fill-value" | "substitute" | "ignore";
  value?: T;
}

interface Props<T> {
  colType: "numeric" | "date" | "char";
  treatment?: Treatment<T>;
  onChange: (treatment: Treatment<T>) => any;
  error?: string;
}

function FillValue<T extends string | number | Date>({
  colType,
  treatment,
  onChange,
  error
}: Props<T>) {
  return (
    <InputField
      label="Fill Value"
      type={
        colType === "char" ? "text" : colType === "date" ? "date" : "number"
      }
      value={(treatment || {}).value}
      help="ALL missing values will be replaced by this value."
      onValueChange={v => {
        if (v !== null && v !== undefined) {
          const newTreatment = { ...(treatment || {}), value: v };
          // @ts-ignore
          onChange(newTreatment);
        }
      }}
      error={error}
    />
  );
}

export default FillValue;
