import React from "react";

import SelectField from "../Select-Field";

type Treatment = "drop" | "clamp" | "ignore";

const TREATMENT_OPTIONS: { value: Treatment; label: string }[] = [
  {
    value: "drop",
    label: "Drop ALL outlier rows"
  },
  { value: "ignore", label: "Ignore ALL outlier rows." },
  {
    value: "clamp",
    label: "Clamp outlier-rows to floor/ceiling/replacement"
  }
];

interface Props {
  treatment?: Treatment;
  onChange: (t: "drop" | "clamp" | "ignore") => any;
}

const TreatmentOptions: React.FC<Props> = ({ treatment, onChange }) => {
  return (
    <SelectField
      label="Outlier Treatment:"
      placeholder="Select an Option"
      value={treatment}
      options={TREATMENT_OPTIONS}
      onValueChange={t => {
        t && onChange(t);
      }}
      help="Outlier Treatment"
    />
  );
};

export default TreatmentOptions;
