import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec } from "./types";

const stringifySpec = (spec: Spec): string => JSON.stringify(spec);

// Change key
const INSIGHT_TYPE = "data-file-association-rules";

const model: StateInterface = {
  // Add params. Each of them: param: {[file_id]: ...}
  confidence_threshold: {},
  certainty_threshold: {},

  // Set Params
  setParams: action((state, payload) => {
    const { file_id, params } = payload;
    // Add other params as required.
    if (
      params.confidence_threshold !== null &&
      params.confidence_threshold !== undefined
    ) {
      state.confidence_threshold[file_id] = params.confidence_threshold;
    }
    if (
      params.certainty_threshold !== null &&
      params.certainty_threshold !== undefined
    ) {
      state.certainty_threshold[file_id] = params.certainty_threshold;
    }
  }),

  // Create Request
  create: thunk(
    async (actions, file_id, { injections, getState, getStoreActions }) => {
      const state = getState();

      if (state.resource(file_id)) {
        return;
      }

      const spec = state.getSpec(file_id);
      if (!spec) {
        return;
      }

      const { urls } = injections;
      const spec_string = stringifySpec(spec);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_association_rules}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec,
        spec_string
      });
    }
  ),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [storeActions.reset.reset],
    state => {
      // Reset params
      state.certainty_threshold = {};
      state.confidence_threshold = {};
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getParams: computed(
    [
      state => state,
      (_, globalState) => globalState.data_file_frequent_patterns
    ],
    (state, fp_settings) => file_id => {
      const fp_params = fp_settings.getParams(file_id);
      return {
        ...fp_params,
        certainty_threshold: state.certainty_threshold[file_id],
        confidence_threshold: state.confidence_threshold[file_id]
      };
    }
  ),

  getSpec: computed(
    [
      state => state.getParams,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (getParams, settings) => file => {
      const params = getParams(file);
      if (
        !params.transaction_column ||
        !params.item_column ||
        !params.min_support ||
        !params.confidence_threshold ||
        !params.certainty_threshold
      ) {
        return null;
      }
      // Change spec as required.
      const filters = settings.getFiltersForFile(file);
      const grouping_columns = settings.getGroupingColumnsForFile(file);

      const spec: Spec = {
        filters,
        grouping_columns,
        transaction_column: params.transaction_column,
        item_column: params.item_column,
        min_support: params.min_support,
        certainty_threshold: params.certainty_threshold,
        confidence_threshold: params.confidence_threshold
      };
      return spec;
    }
  ),

  resource: computed(
    [
      state => state.getSpec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (getSpec, getInsight) => file_id => {
      const spec = getSpec(file_id);
      if (spec === null) {
        return;
      }
      const spec_string = stringifySpec(spec);
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getData: computed(state => file_id => state.resource(file_id)?.data),

  getComputeStatus: computed(state => file_id =>
    state.resource(file_id)?.compute_status
  )
};

export default model;
