import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";

type KeyType = string | number | boolean;

interface Option<T = KeyType> {
  label: React.ReactNode;
  key: T;
}

interface PropsInterface<T = KeyType> {
  options: Option<T>[];
  /** For inside the form */
  name?: string;
  /** Initial Value */
  initialValue?: T;
  /**Published ONLY if passed */
  label?: React.ReactNode;
  help?: React.ReactNode;
  error?: React.ReactNode;
  /** The active option is highlighted. Must be key of one of the options. */
  active?: T;
  /**Defaults to a blank function */
  onClick?(opt: Option<T>): any;
  /** Custom Validator function. Defaults to empty function. */
  validate?: (value: T) => any;
  tags?: React.ReactNode;
  className?: string;
  switchClassName?: string;
}

type Component<T = KeyType> = React.FC<PropsInterface<T>>;

const GroupedSwitch: Component = ({
  label,
  name = "grouped-switch",
  options,
  initialValue,
  help,
  error,
  active,
  onClick = () => {},
  validate = () => true,
  tags,
  className = "",
  switchClassName,
}) => {
  const { control, setValue, register, watch } = useFormContext() || {};

  useEffect(() => {
    if (setValue) {
      setValue(name, active);
    }
  }, [setValue, name, active]);

  if (control) {
    register(name);
    active = watch(name) || initialValue;
  }

  return (
    <div style={{ marginBottom: "0.5rem" }} className={className}>
      <div
        className={`field has-addons ${switchClassName ? switchClassName : ""}`}
      >
        {label !== undefined && label !== null && (
          <div className="field-label is-normal">
            <label className="label">
              {label}
              {tags && tags}
            </label>
          </div>
        )}
        <p className="buttons has-addons">
          {options.map((opt) => (
            <button
              className={active === opt.key ? "button is-primary" : "button"}
              onClick={() => {
                onClick(opt);
                if (control) {
                  setValue(name, opt.key);
                }
              }}
              key={opt.key.toString()}
              data-testid={`button-${opt.key}`}
              type="button"
            >
              {opt.label}
            </button>
          ))}
        </p>
      </div>
      {help && <div className="help">{help}</div>}
      {error && <div className={"help is-danger"}>{error}</div>}
    </div>
  );
};

export default GroupedSwitch;
