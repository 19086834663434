import {
  createStore,
  persist,
  EasyPeasyConfig,
  PersistConfig,
} from "easy-peasy";

import { ALLOWED_URLS, StoreModel } from "./types";

import model from "./model";

const rootPersistConfig: PersistConfig<StoreModel> = {
  storage: "localStorage",
  whitelist: [
    "tour_guide",
    "data_files_user_settings",
    "data_file_compare_columns",
    "data_file_grouped_distributions",
    "data_file_group_differences",
    "data_file_id_profiles",
    "data_file_document_topics",
    "data_file_time_series_distributions",
    "data_file_time_series_change_points",
    "data_file_time_series_anomalies",
    "data_file_frequent_patterns",
    "data_file_association_rules",
    // "data_file_create_upload",
    "data_file_create_filter",
    "data_file_create_join",
    "data_file_create_stack",
    "data_file_create_aggregates",
    "data_file_create_time_series",
    "data_file_create_augment",
    "data_file_create_clean",
    "workspace_create_new_model",
  ],
};

export default (
  urls: Record<ALLOWED_URLS, string>,
  reducerEnhancer?: (reducer: any) => any
) => {
  let store_config: EasyPeasyConfig = { injections: { urls } };
  if (reducerEnhancer) {
    store_config["reducerEnhancer"] = reducerEnhancer;
  }

  const store = createStore(persist(model, rootPersistConfig), store_config);

  if (process.env.NODE_ENV === "development") {
    // @ts-ignore
    if (module.hot) {
      // @ts-ignore
      module.hot.accept("./model", () => {
        store.reconfigure(model); // 👈 Here is the magic
      });
    }
  }

  return store;
};
