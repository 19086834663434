import { useState } from "react";

interface HoverPoint<T = any> {
  d: T;
  offsetX: number;
  offsetY: number;
}

const INIT_HOVER: HoverPoint = { d: {}, offsetX: 0, offsetY: 0 };

/**
 * Effect for using tool-tips.
 *
 * Returns:
 *  hoverStatus: boolean. Indicates whether the tool-tip is to be rendered.
 *  hoverD: {d: {}, offsetX: number, offsetY: number} - tool-tip render details.
 *  onHover: function. This updates hoverStatus, hoverD, and then calls passed onMouseOver.
 *  offHover: function. This updates hoverStatus, hoverD, and then calls passed onMouseOut.
 *
 * @param onMouseOver
 * @param onMouseOut
 */
function useToolTip<T>(
  onMouseOver: (d: T, e?: React.MouseEvent) => any,
  onMouseOut: (d?: T, e?: React.MouseEvent) => any
): [
  boolean,
  HoverPoint<T>,
  (d: T, e: React.MouseEvent) => any,
  (d?: T, e?: React.MouseEvent) => any
] {
  const [hoverStatus, setHoverStatus] = useState(false);

  const [hoverD, setHoverD] = useState(INIT_HOVER);

  const onHover = (d: any, e: React.MouseEvent) => {
    setHoverStatus(true);
    const { offsetX, offsetY } = e.nativeEvent;
    setHoverD({ d, offsetX, offsetY });
    return onMouseOver(d);
  };

  const offHover = () => {
    setHoverStatus(false);
    setHoverD(INIT_HOVER);
    return onMouseOut();
  };

  return [hoverStatus, hoverD, onHover, offHover];
}

export default useToolTip;
