import React from "react";

import { FillProps, InteractionProps } from "../../types";

import ToolTip, { useToolTip } from "../../components/Tool-Tip";

import Pie from "../../components/Pie";

type Datum = { level: string | number | Date; value: number };

interface PropsInterface extends FillProps<Datum>, InteractionProps<Datum> {
  data: Datum[];
  radius: number;
  levelDomain?: (string | number | Date)[];
  opacityDomain?: [number, number];
  showToolTip?: boolean;
  annotater?(d: any): React.ReactNode;
  onClick?(d: any, e?: React.MouseEvent): any;
  onMouseOver?(d: any, e?: React.MouseEvent): any;
  onMouseOut?(): any;
  onZoomChange?(domain: (string | number | Date)[]): any;
}

const DEFAULT_ANNOTATER = (d: Datum) => JSON.stringify(d);
const DEFAULT_INTERACTION = () => {};

const PieChart: React.FunctionComponent<PropsInterface> = ({
  data,
  radius,
  levelDomain,
  opacityDomain,
  fillColor,
  hoverColor,
  onClick = DEFAULT_INTERACTION,
  onMouseOver = DEFAULT_INTERACTION,
  onMouseOut = DEFAULT_INTERACTION,
  showToolTip = true,
  annotater = DEFAULT_ANNOTATER
}) => {
  const [hoverStatus, hoverD, onHover, offHover] = useToolTip(
    onMouseOver,
    onMouseOut
  );

  return (
    <svg width={radius * 2} height={radius * 2}>
      <Pie
        data={data}
        radius={radius}
        levelDomain={levelDomain}
        opacityDomain={opacityDomain}
        fillColor={fillColor}
        hoverColor={hoverColor}
        onClick={onClick}
        onMouseOver={onHover}
        onMouseOut={offHover}
      />
      {showToolTip && hoverStatus && (
        <ToolTip
          d={annotater(hoverD.d)}
          offsetX={hoverD.offsetX}
          offsetY={hoverD.offsetY}
          width={radius * 2}
          margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
        />
      )}
    </svg>
  );
};

export default PieChart;
