import { SingleGroupItemSet, Pattern } from "./types";

const generateListing = (data: SingleGroupItemSet[]): Pattern[] => {
  let patterns: Pattern[] = [];

  data.forEach(fp => {
    patterns.push(
      ...fp["counts"].map((c, i) => ({
        count: c,
        itemSet: fp["item-sets"][i],
        grouping: fp.grouping,
        p: c / fp["transaction-count"],
        l: fp["item-sets"][i].length
      }))
    );
  });

  return patterns;
};

export default generateListing;
