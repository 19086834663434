import React from "react";

interface PropsInterface {
  completed: boolean;
  error: boolean;
  /** MUST be between 0 and 1. If greater than 1, gets capped at 1. */
  progress: number;
  /** If passed, the status message gets prefixed by this. Use this for IDs */
  name?: string;
  /** If not passed, "Complete." or "Validating." based on other params. */
  status_message?: string;
  /** Defaults to "Error: Unable to complete." */
  error_message?: string | string[] | { [key: string]: string | string[] };
}

const ProgressBar: React.FC<PropsInterface> = ({
  completed,
  error,
  error_message,
  status_message,
  progress,
  name
}) => {
  let status: string;
  if (error) {
    status = (error_message || "Error: Unable to complete.").toString();
  } else if (progress < 1) {
    status = `${(progress * 100).toFixed(0)}% done...`;
  } else if (status_message) {
    status = status_message;
  } else if (completed) {
    status = "Complete.";
  } else {
    status = "Validating.";
  }

  let cls = "progress";
  if (error) {
    cls += " is-danger";
  } else if (completed || (progress >= 1 && !error)) {
    cls += " is-success";
  } else {
    cls += " is-info";
  }
  return (
    <div className="content">
      <b>{name ? `${name}: ${status}` : status}</b>
      <progress className={cls} value={progress} max={1} />
      {status_message && <p>{status_message}</p>}
    </div>
  );
};

export default ProgressBar;
