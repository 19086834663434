import React, { useState } from "react";

import { extent } from "d3-array";

import {
  ViewPort,
  InteractionProps,
  FillProps,
  Fill
} from "Visualizations/types";

import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR
} from "Visualizations/default-style-settings";

import { filterObjectsByRange } from "Visualizations/data-utils";
import { getMargin, getScale, getOpacityScale } from "Visualizations/utils";

interface NumNumGridCell {
  xLeftEdge: Date | number;
  yLeftEdge: Date | number;
  xRightEdge: Date | number;
  yRightEdge: Date | number;
  value: number;
}

const extract_domain = (
  data: NumNumGridCell[],
  passedDomain: (Date | number)[],
  domainAxis: "x" | "y"
): (Date | number)[] => {
  if (passedDomain.length >= 1) {
    return passedDomain;
  }

  const leftEdgeKey = domainAxis === "x" ? "xLeftEdge" : "yLeftEdge";
  const rightEdgeKey = domainAxis === "x" ? "xRightEdge" : "yRightEdge";

  const l = extent(data.map(d => d[leftEdgeKey]))[0];
  const r = extent(data.map(d => d[rightEdgeKey]))[1];
  return [l === undefined ? 0 : l, r === undefined ? 0 : r];
};

const Cell = ({
  item,
  yLength,
  xScale,
  yScale,
  opacityScale,
  fill,
  hover,
  onClick,
  onMouseOver,
  onMouseOut
}: {
  item: NumNumGridCell;
  yLength: number;
  xScale: any;
  yScale: any;
  opacityScale: any;
  fill: Fill<NumNumGridCell>;
  hover: Fill<NumNumGridCell>;
  onClick: (d: NumNumGridCell) => any;
  onMouseOver: (d: NumNumGridCell, e: React.MouseEvent) => any;
  onMouseOut: () => any;
}) => {
  const [hoverStatus, setHover] = useState(false);

  if (!(typeof fill === "string")) {
    fill = fill(item);
  }

  if (!(typeof hover === "string")) {
    hover = hover(item);
  }

  return (
    <rect
      x={xScale(item.xLeftEdge)}
      y={yLength - yScale(item.yRightEdge)}
      width={Math.max(xScale(item.xRightEdge) - xScale(item.xLeftEdge), 1)}
      height={Math.max(yScale(item.yRightEdge) - yScale(item.yLeftEdge), 1)}
      fillOpacity={opacityScale(item.value)}
      onClick={() => onClick(item)}
      onMouseOver={e => {
        setHover(true);
        return onMouseOver(item, e);
      }}
      onMouseMove={e => onMouseOver(item, e)}
      onMouseOut={() => {
        setHover(false);
        onMouseOut();
      }}
      fill={hoverStatus ? hover : fill}
    />
  );
};

interface PropsInterface
  extends ViewPort,
    InteractionProps<NumNumGridCell>,
    FillProps<NumNumGridCell> {
  data: NumNumGridCell[];
  xType?: "int" | "float" | "date" | "numeric";
  yType?: "int" | "float" | "date" | "numeric";
  /** If passed, filters data by this. Else uses the full data. */
  xDomain?: (Date | number)[];
  /** If passed, filters data by this. Else uses the full data. */
  yDomain?: (Date | number)[];
  /** Range for color/opacity */
  densityDomain?: number[];

  /** If true, shades the cell color using d.value */
  varyOpacity?: boolean;
}

const DEFAULT_DOMAIN: (Date | number)[] = [];
const DEFAULT_DENSITY_DOMAIN: number[] = [];
const DEFAULT_INTERACTION = () => {};

const NumNumGrid: React.FunctionComponent<PropsInterface> = ({
  data,
  xType = "float",
  yType = "float",
  xDomain = DEFAULT_DOMAIN,
  yDomain = DEFAULT_DOMAIN,
  densityDomain = DEFAULT_DENSITY_DOMAIN,
  varyOpacity = true,
  fillColor = FILL_COLOR,
  hoverColor = HOVER_COLOR,
  width,
  height,
  margin = DEFAULT_MARGIN,
  onClick = DEFAULT_INTERACTION,
  onMouseOver = DEFAULT_INTERACTION,
  onMouseOut = DEFAULT_INTERACTION
}) => {
  const new_margin = getMargin(margin, DEFAULT_MARGIN);

  xDomain = extract_domain(data, xDomain, "x");
  yDomain = extract_domain(data, yDomain, "y");

  data = filterObjectsByRange(data, "xLeftEdge", xType, xDomain);
  data = filterObjectsByRange(data, "xRightEdge", xType, xDomain);
  data = filterObjectsByRange(data, "yLeftEdge", yType, yDomain);
  data = filterObjectsByRange(data, "yRightEdge", yType, yDomain);

  const xLength = width - (new_margin.left + new_margin.right);
  const yLength = height - (new_margin.top + new_margin.bottom);

  const xScale = getScale({
    varType: xType,
    domain: xDomain,
    axisLength: xLength
  });
  const yScale = getScale({
    varType: yType,
    domain: yDomain,
    axisLength: yLength
  });

  const opacityScale = getOpacityScale(data, densityDomain, varyOpacity);
  return (
    <g transform={`translate(${new_margin.left}, ${new_margin.top})`}>
      {data.map((item, i) => (
        <Cell
          key={i}
          item={item}
          yLength={yLength}
          xScale={xScale}
          yScale={yScale}
          opacityScale={opacityScale}
          fill={fillColor}
          hover={hoverColor}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      ))}
    </g>
  );
};

export default NumNumGrid;
