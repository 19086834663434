import { isCategorical, UnivariateDatum, VarType } from "Visualizations";

export const catAnnotater = (p: UnivariateDatum) =>
  p.level !== undefined && p.level !== null && p.level !== ""
    ? `${p.level.toLocaleString()} has ${p.height.toLocaleString()} rows.`
    : "";

export const numAnnotater = (p: UnivariateDatum) => {
  if (p.value === null || p.value === undefined) {
    if (p.leftEdge !== undefined && p.rightEdge !== undefined) {
      return `${(
        p.count || 0
      ).toLocaleString()} rows between ${p.leftEdge.toLocaleString()} and ${p.rightEdge.toLocaleString()}`;
    } else {
      return "";
    }
  } else {
    return `${(
      p.count || 0
    ).toLocaleString()} rows are ${p.value.toLocaleString()}`;
  }
};

export default (colType: VarType) =>
  isCategorical(colType) ? catAnnotater : numAnnotater;
