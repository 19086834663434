// @ts-ignore
import _colors from "../styles/_colors.scss";

export const FILL_COLOR = _colors.lime7;
export const HOVER_COLOR = _colors.pink7;

export const GREEN = _colors.lime7;
export const RED = _colors.red6;
export const BLUE = _colors.blue5;
export const ORANGE = _colors.orange7;

export const MARKER_COLOR = _colors.red5;
export const MARKET_COLOR_ALT = _colors.orange6;

export const DEFAULT_MARGIN = { top: 10, right: 20, bottom: 40, left: 70 };

export const TOOL_TIP_HEIGHT = 150;
export const TOOL_TIP_WIDTH = 150;

export default {
  FILL_COLOR,
  HOVER_COLOR,
  MARKER_COLOR,
  DEFAULT_MARGIN,
  TOOL_TIP_HEIGHT,
  TOOL_TIP_WIDTH,
  GREEN,
  RED
};
