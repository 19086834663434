import React, { useState } from "react";

import { Margin } from "Visualizations/types";

import getSentences from "Visualizations/utils/sentences";

const lineHeight = 1.1; // ems

interface YAxisLabelProps {
  value: string;
  bandWidth: number;
  margin: Margin;
  orientation: "left" | "right";
}

export default ({ value, bandWidth, margin, orientation }: YAxisLabelProps) => {
  const [hover, setHover] = useState(false);

  const width = orientation === "left" ? margin.left - 10 : margin.right - 10;
  const dx = orientation === "left" ? -9 : 9;

  let labels;
  const characters = width / 10;
  if (value.length < characters) {
    labels = (
      <tspan x={dx} y={0} dy="0.375em">
        {value}
      </tspan>
    );
  } else {
    const maxLines = bandWidth / 20;
    let sentences = getSentences(value, characters);
    if (hover === false) {
      sentences = sentences.slice(0, maxLines);
    }

    labels = sentences.map((sentence, i) => (
      <tspan x={dx} y={"0.35em"} dy={`${i * lineHeight}em`} key={i}>
        {sentence.slice(0, characters)}
      </tspan>
    ));
  }

  return (
    <text
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onMouseLeave={() => setHover(false)}
    >
      {labels}
    </text>
  );
};
