import React, { Fragment, useState } from "react";

import { getMargin, isCategorical, isNumber } from "../../utils";

import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR
} from "../../default-style-settings";

import {
  ViewPort,
  VarType,
  FillProps,
  InteractionProps,
  UnivariateDatum
} from "../../types";

const MIN_BAR_WIDTH = 1;

interface SingleBarProps
  extends ViewPort,
    FillProps<UnivariateDatum>,
    InteractionProps<UnivariateDatum> {
  d: UnivariateDatum;
  barType: "vertical" | "horizontal";
  varType: VarType;
  valueScale: any;
  heightScale: any;
  centerHeightAtZero?: boolean;
}

const DummyFunction = () => {};

export default ({
  d,
  barType,
  varType,
  height,
  width,
  margin,
  valueScale,
  heightScale,
  centerHeightAtZero = false,
  onClick = DummyFunction,
  onMouseOver = DummyFunction,
  onMouseOut = DummyFunction,
  fillColor = FILL_COLOR,
  hoverColor = HOVER_COLOR
}: SingleBarProps) => {
  const [hoverStatus, setHover] = useState(false);

  const new_margin = getMargin(margin, DEFAULT_MARGIN);

  const xLength = width - (new_margin.left + new_margin.right);
  const yLength = height - (new_margin.top + new_margin.bottom);

  if (xLength <= 0 || yLength <= 0) {
    return null;
  }

  let x: number;
  let y: number;
  let barHeight: number;
  let barWidth: number;

  let transX: number;
  let transY: number;
  let transHeight: number;
  let transWidth: number;

  const cat = isCategorical(varType);

  let { level, leftEdge, rightEdge } = d;
  if (varType === "date" || varType === "time") {
    // @ts-ignore
    leftEdge = Date.parse(leftEdge);
    // @ts-ignore
    rightEdge = Date.parse(rightEdge);
  }
  if (barType === "vertical") {
    x = cat ? valueScale(level) : valueScale(leftEdge);
    y = yLength - heightScale(d.height);
    barWidth = cat ? valueScale.bandwidth() : valueScale(rightEdge) - x;
    barWidth = Math.max(barWidth, MIN_BAR_WIDTH);
    barHeight = heightScale(d.height);

    transX = x;
    transY = 0;
    transHeight = yLength;
    transWidth = barWidth;
  } else {
    if (centerHeightAtZero) {
      const x_0 = heightScale(0);
      const x_act = heightScale(d.height);
      x = Math.min(x_0, x_act);
      barWidth = Math.abs(x_0 - x_act);
    } else {
      x = 0;
      barWidth = heightScale(d.height);
    }
    y = cat ? valueScale(level) : valueScale(leftEdge);
    barHeight = cat ? valueScale.bandwidth() : valueScale(rightEdge) - y;
    barHeight = Math.max(barHeight, MIN_BAR_WIDTH);
    y = yLength - y - barHeight;

    transX = 0;
    transY = y;
    transHeight = barHeight;
    transWidth = xLength;
  }

  if (
    !(isNumber(x) && isNumber(y) && isNumber(barHeight) && isNumber(barWidth))
  ) {
    return null;
  }

  if (
    !(barWidth >= 0 && barHeight >= 0 && transHeight >= 0 && transWidth >= 0)
  ) {
    return null;
  }

  const barC: string =
    (typeof fillColor === "function" ? fillColor(d) : fillColor) || FILL_COLOR;
  const hovC: string =
    (typeof hoverColor === "function" ? hoverColor(d) : hoverColor) ||
    HOVER_COLOR;

  const interactions = {
    onClick: () => onClick(d),
    onMouseOver: (e: React.MouseEvent) => {
      setHover(true);
      return onMouseOver(d, e);
    },
    onMouseMove: (e: React.MouseEvent) => onMouseOver(d, e),
    onMouseOut: () => {
      setHover(false);
      return onMouseOut();
    }
  };

  return (
    <Fragment>
      <rect
        x={transX}
        y={transY}
        height={transHeight}
        width={transWidth}
        fill="transparent"
        {...interactions}
      />
      <rect
        x={x}
        y={y}
        height={barHeight}
        width={barWidth}
        fill={hoverStatus ? hovC : barC}
        {...interactions}
      />
    </Fragment>
  );
};
