import { action, thunk, computed, actionOn } from "easy-peasy";
import { delay } from "utils";

import StateInterface from "./types";

const model: StateInterface = {
  files: {},
  deleteStatus: {},
  exportStatus: {},
  accessFlags: {},
  listingFetchInProgress: false,

  // Store-Updaters
  updateListing: action((state, files) => {
    if (!state.accessFlags) {
      state.accessFlags = {};
    }
    files.forEach(dt => {
      state.files[dt.id] = { ...(state.files[dt.id] || {}), ...dt };
      state.accessFlags[dt.id] = true;
    });
  }),

  updateAccessFlag: action((state, { file_id, flag }) => {
    state.accessFlags[file_id] = flag;
  }),

  updateExportStatus: action((state, { file_id, status }) => {
    state.exportStatus[file_id] = status;
  }),

  updateDeleteStatus: action((state, { file_id, status }) => {
    if (status.completed && !status.error) {
      delete state.files[file_id];
    }
    state.deleteStatus[file_id] = status;
  }),

  updateListingFetchFlag: action((state, flag) => {
    state.listingFetchInProgress = flag;
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.files = {};
      state.exportStatus = {};
      state.deleteStatus = {};
      state.accessFlags = {};
      state.listingFetchInProgress = false;
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  fetchListing: thunk(
    async (
      actions,
      page_number,
      { injections, getStoreState, getStoreActions }
    ) => {
      if (page_number === undefined || page_number === null) {
        page_number = 1;
      }
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }
      const { urls } = injections;
      if (page_number === 1) {
        actions.updateListingFetchFlag(true);
      }

      const offset = 10 * (page_number - 1);
      const limit = 10;

      const url = `${urls.data}?offset=${offset}&limit=${limit}`;

      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: async ({ success, data }) => {
          if (success) {
            actions.updateListing(data.results);
            actions.updateListingFetchFlag(false);
            if (data.next) {
              actions.fetchListing((page_number || 1) + 1);
            }
          } else {
            await delay(5000);
            actions.fetchListing(page_number);
          }
        }
      });
    }
  ),

  fetchFileDetails: thunk(
    async (
      actions,
      file_id,
      { injections, getStoreState, getStoreActions }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const { urls } = injections;
      const url = `${urls.data}/${file_id}`;
      actions.updateListingFetchFlag(true);

      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: ({ success, data }) => {
          if (success) {
            actions.updateListing([data]);
          } else {
            actions.updateAccessFlag({ file_id, flag: false });
          }
          actions.updateListingFetchFlag(false);
        }
      });
    }
  ),

  exportAsCSV: thunk(
    async (
      actions,
      file_id,
      { injections, getStoreState, getStoreActions }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const { urls } = injections;
      actions.updateExportStatus({
        file_id,
        status: { completed: false, error: false }
      });
      const url = `${urls.data}/${file_id}/export-as-csv`;

      await getStoreActions().api_client.fetch({
        url,
        method: "POST",
        onComplete: ({ success, data }) =>
          actions.updateExportStatus({
            file_id,
            status: success ? { completed: true } : data
          })
      });
    }
  ),

  deleteFile: thunk(
    async (
      actions,
      file_id,
      { injections, getStoreState, getStoreActions, getState }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const user_details = globalState.auth.user_details;
      if (!user_details) {
        return;
      }

      const localState = getState();
      if (!localState.files[file_id]) {
        return;
      }
      if (localState.files[file_id].owner !== user_details.email) {
        return;
      }

      actions.updateDeleteStatus({
        file_id,
        status: { completed: false, error: false }
      });

      const { urls } = injections;
      const url = `${urls.data}/${file_id}`;

      await getStoreActions().api_client.fetch({
        url,
        method: "DELETE",
        onComplete: ({ success, data }) =>
          actions.updateDeleteStatus({
            file_id,
            status: success ? { completed: true } : data
          })
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  has_access_rights: computed([
    (_, globalState) => (globalState.auth.user_details ? true : false)
  ]),

  getFileDetails: computed(state => file_id => state.files[file_id])
};

export default model;
