import { VarType } from "Visualizations";
import { FlatUniVariateDistribution } from "./types";

const INIT_DOMAIN: (string | number | Date)[] = [];

export default (
  distribution: FlatUniVariateDistribution,
  colType: VarType,
  outliers: (string | number | Date)[] = INIT_DOMAIN
) => {
  outliers = outliers || INIT_DOMAIN;
  if (
    colType === "numeric" ||
    colType === "int" ||
    colType === "float" ||
    colType === "percent"
  ) {
    return outliers;
  }

  if (colType === "date" || colType === "time") {
    return outliers.map((d: number | string | Date) => new Date(d));
  }

  if (Array.isArray(outliers) && outliers.length >= 1) {
    return (distribution["level"] || []).filter(
      (l: any) => !outliers.includes(l)
    );
  }

  return outliers;
};
