const annotater = (value: any, resolution: string) => {
  const x = new Date(value);

  const time = x.toLocaleString();
  const month = x.getMonth() + 1;
  switch (resolution) {
    case "second":
      return `Second: ${time}`;
    case "minute":
      return `${time}`;
    case "hour":
      return `Hour ending with ${time}`;
    case "4-hours":
      return `4 Hours ending with ${time}`;
    case "day":
      return `${x.toDateString()}`;
    case "week":
      return `Week ending with ${x.toDateString()}`;
    case "month":
      return `${month}/${x.getFullYear()}`;
    case "quarter":
      return `Quarter ending with ${month}/${x.getFullYear()}`;
    case "year":
      return `${x.getFullYear()}`;
    default:
      return `${time}`;
  }
};

export default annotater;
