import React from "react";

import { Switch, Route } from "react-router-dom";

import paths from "./paths";

import { NotFound, AuthRoute } from "App/Route-Utils";
import Landing from "./Landing";

import { SIGN_IN_ALLOWED } from "App/constants";

const TOC = React.lazy(() => import("./TOC"));
const PrivacyPolicy = React.lazy(() => import("./Privacy-Policy"));

const Login = React.lazy(() => import("./Login"));
const SignUp = React.lazy(() => import("./Sign-Up"));
const ChangePassword = React.lazy(() => import("./Change-Password"));

const DataFiles = React.lazy(() => import("./Data-Files"));
const Recipes = React.lazy(() => import("./Recipes"));
const PredictiveModels = React.lazy(() => import("./Predictive-Models"));

const Downloads = React.lazy(() => import("./Downloadable-Files"));

const Body = () => {
  return (
    <Switch>
      <Route path={paths.login}>
        <Login />
      </Route>
      <Route path={paths.toc}>
        <TOC />
      </Route>
      <Route path={paths.privacy}>
        <PrivacyPolicy />
      </Route>
      {SIGN_IN_ALLOWED && (
        <Route path={paths.signup}>
          <SignUp />
        </Route>
      )}
      <AuthRoute path={paths.changePassword}>
        <ChangePassword />
      </AuthRoute>
      <AuthRoute path={paths.dataFiles}>
        <DataFiles />
      </AuthRoute>
      <AuthRoute path={paths.recipes}>
        <Recipes />
      </AuthRoute>
      <AuthRoute path={paths.predictiveModels}>
        <PredictiveModels />
      </AuthRoute>
      <AuthRoute path={paths.downloads}>
        <Downloads />
      </AuthRoute>
      <Route path="/">
        <Landing />
      </Route>
      <Route path="*" default={true}>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Body;
