import React, { useMemo } from "react";

import { ViewPort, InteractionProps, VarType, Fill } from "../../types";

import { DEFAULT_MARGIN } from "../../default-style-settings";

import ToolTip, { useToolTip } from "../../components/Tool-Tip";
import { XAxis, YAxis, Brush } from "../../components";
import { NestedXAxis, NestedYAxis } from "../../components";

import { getScale, getMargin } from "../../utils";

import getStackSizes from "../../components/Stacked-Bars/stack-sizes";
import filterData from "../../components/Stacked-Bars/filter-data";
import getDomains from "../../components/Stacked-Bars/domains";
import getGroups from "../../components/Stacked-Bars/groups";
import { StackedBars } from "../../components";

import Stacks from "./Stacks";
import { GridCell } from "./types";

interface PropsInterface extends ViewPort, InteractionProps<GridCell> {
  data: GridCell[];
  chartType?: "horizontal" | "vertical";
  axisVariableType?: VarType;
  stackVariableType?: VarType;
  valueAxisType?: "numeric" | "percent";
  axisDomain?: (string | number | Date)[];
  stackDomain?: (string | number | Date)[];
  groupDomain?: (string | number | Date)[];
  valueDomain?: [number, number];
  fillColor?: Fill<any>;
  showValueAxis?: boolean;
  showAxisAxis?: boolean;
  showNestedAxis?: boolean;
  showValueAxisLabels?: boolean;
  showAxisAxisLabels?: boolean;
  showBrush?: boolean;
  brushDomain?: (string | number | Date)[];
  setBrushDomain?: (domain: (string | number | Date)[]) => any;
  showToolTip?: boolean;
  annotater?(d: any): React.ReactNode;
  title?: React.ReactNode;
}

const DUMMY_FUNC = () => {};

const GroupedStackedBarChart: React.FC<PropsInterface> = ({
  data,
  chartType = "vertical",
  axisVariableType = "numeric",
  stackVariableType = "char",
  valueAxisType = "numeric",
  axisDomain,
  stackDomain,
  valueDomain,
  groupDomain,
  showValueAxis = true,
  showAxisAxis = true,
  showNestedAxis = true,
  showValueAxisLabels = true,
  showAxisAxisLabels = true,
  fillColor,
  showToolTip = true,
  showBrush,
  brushDomain,
  setBrushDomain = () => {},
  annotater = (d: GridCell) => JSON.stringify(d),
  onClick = DUMMY_FUNC,
  onMouseOver = DUMMY_FUNC,
  onMouseOut = DUMMY_FUNC,
  width,
  height,
  margin = DEFAULT_MARGIN,
  title
}) => {
  const new_margin = getMargin(margin, DEFAULT_MARGIN);
  data = filterData(
    data,
    axisVariableType,
    stackVariableType,
    axisDomain,
    stackDomain,
    valueDomain
  );

  const [hoverStatus, hoverD, onHover, offHover] = useToolTip(
    onMouseOver,
    onMouseOut
  );

  valueDomain = useMemo(() => {
    if (valueDomain) {
      return valueDomain;
    } else {
      return [
        0,
        Math.max(...getStackSizes(data, axisVariableType, stackVariableType))
      ];
    }
  }, [data, axisVariableType, stackVariableType, valueDomain]);

  [axisDomain, stackDomain] = useMemo(() => {
    if (!axisDomain || !stackDomain) {
      return getDomains(
        data,
        axisVariableType,
        stackVariableType,
        axisDomain,
        stackDomain
      );
    } else {
      return [axisDomain, stackDomain];
    }
  }, [axisDomain, stackDomain, data, axisVariableType, stackVariableType]);

  fillColor = useMemo(() => {
    if (fillColor) {
      return fillColor;
    }
    const [stackUniques] = getGroups(
      data,
      axisVariableType,
      stackVariableType,
      "stack"
    );
    // @ts-ignore
    return getScale({ scaleType: "color", domain: stackUniques });
  }, [fillColor, data, stackVariableType, axisVariableType]);

  const AxisAxis = chartType === "vertical" ? XAxis : YAxis;
  const ValueAxis = chartType === "vertical" ? YAxis : XAxis;

  const groups = useMemo(
    () =>
      Array.from(
        new Set(
          data.map(({ group }) =>
            group === undefined || group === null ? group : group.toString()
          )
        )
      ),
    [data]
  );

  if (data.length === 0) {
    return <div> No data found!!!</div>;
  }

  brushDomain = brushDomain || axisDomain;

  if (groups.length <= 1) {
    return (
      <svg width={width} height={height}>
        {title && (
          <foreignObject
            x={0}
            y={0}
            width={width}
            height={margin.top}
            className="has-text-centered has-text-weight-bold"
            style={{ wordWrap: "normal", textDecoration: "underline" }}
          >
            {title}
          </foreignObject>
        )}
        <StackedBars
          data={data}
          barType={chartType}
          stackVariableType={stackVariableType}
          axisVariableType={axisVariableType}
          axisDomain={axisDomain}
          stackDomain={stackDomain}
          valueDomain={valueDomain}
          colorFill={fillColor}
          onClick={onClick}
          onMouseOver={onHover}
          onMouseOut={offHover}
          height={height}
          width={width}
          margin={new_margin}
        />
        {showToolTip && hoverStatus && (
          <ToolTip
            d={annotater(hoverD.d)}
            offsetX={hoverD.offsetX}
            offsetY={hoverD.offsetY}
            width={width}
            margin={new_margin}
            position={chartType === "horizontal" ? "vertical" : "horizontal"}
          />
        )}
        {showValueAxis && (
          <ValueAxis
            varType={valueAxisType}
            scaleType="linear"
            domain={valueDomain}
            width={width}
            height={height}
            margin={new_margin}
            showText={showValueAxisLabels}
          />
        )}
        {showAxisAxis && (
          <AxisAxis
            varType={axisVariableType}
            scaleType="linear"
            domain={axisDomain}
            width={width}
            height={height}
            margin={new_margin}
            showText={showAxisAxisLabels}
          />
        )}
        {showBrush && (
          <Brush
            domain={axisDomain}
            zoomedDomain={brushDomain}
            varType={axisVariableType}
            brushType={chartType === "horizontal" ? "vertical" : "horizontal"}
            height={height}
            width={width}
            margin={new_margin}
            onZoom={setBrushDomain}
          />
        )}
      </svg>
    );
  } else {
    if (groupDomain === undefined || groupDomain === null) {
      // @ts-ignore
      groupDomain = groups.filter(g => g !== undefined && g !== null);
    }

    data = data.filter(d =>
      // @ts-ignore
      groupDomain.includes(d.group === undefined ? d.group : d.group.toString())
    );

    const axisLength =
      chartType === "vertical"
        ? width - (new_margin.left + new_margin.right)
        : height - (new_margin.top + new_margin.bottom);

    const groupScale = getScale({
      varType: "char",
      domain:
        chartType === "vertical"
          ? groupDomain || []
          : [...(groupDomain || [])].reverse(),
      scaleType: "linear",
      axisLength: axisLength,
      // @ts-ignore
      padding: 0.05
    });

    const innerMargin =
      chartType === "vertical"
        ? {
            ...new_margin,
            left: (groupScale.padding() * axisLength) / 2,
            right: (groupScale.padding() * axisLength) / 2
          }
        : {
            ...new_margin,
            top: (groupScale.padding() * axisLength) / 2,
            bottom: (groupScale.padding() * axisLength) / 2
          };

    return (
      <svg width={width} height={height}>
        {title && (
          <foreignObject
            x={0}
            y={0}
            width={width}
            height={margin.top}
            className="has-text-centered has-text-weight-bold"
            style={{ wordWrap: "normal", textDecoration: "underline" }}
          >
            {title}
          </foreignObject>
        )}
        {(groupDomain || []).map(g => (
          <Stacks
            data={data}
            key={g.toString()}
            g={g === undefined ? g : g.toString()}
            chartType={chartType}
            stackVariableType={stackVariableType}
            axisVariableType={axisVariableType}
            axisDomain={axisDomain || []}
            stackDomain={stackDomain || []}
            valueDomain={valueDomain || [0, 0]}
            fillColor={fillColor}
            onClick={onClick}
            onHover={onHover}
            offHover={offHover}
            width={width}
            height={height}
            margin={new_margin}
            innerMargin={innerMargin}
            groupScale={groupScale}
          />
        ))}
        )}
        {showToolTip && hoverStatus && (
          <ToolTip
            d={annotater(hoverD.d)}
            offsetX={hoverD.offsetX}
            offsetY={hoverD.offsetY}
            width={width}
            margin={new_margin}
            position={chartType === "horizontal" ? "vertical" : "horizontal"}
          />
        )}
        {showBrush && (
          <Brush
            domain={axisDomain}
            zoomedDomain={brushDomain}
            varType={axisVariableType}
            brushType={chartType === "horizontal" ? "vertical" : "horizontal"}
            height={height}
            width={width}
            margin={new_margin}
            onZoom={setBrushDomain}
          />
        )}
        {showValueAxis && (
          <ValueAxis
            varType={valueAxisType}
            scaleType="linear"
            domain={valueDomain}
            width={width}
            height={height}
            margin={new_margin}
            showText={showValueAxisLabels}
          />
        )}
        {showAxisAxis &&
          (showNestedAxis ? (
            chartType === "vertical" ? (
              <NestedXAxis
                varType={axisVariableType}
                scaleType="linear"
                outerDomain={groupDomain || []}
                innerDomain={axisDomain}
                width={width}
                height={height}
                margin={new_margin}
                showText={showAxisAxisLabels}
              />
            ) : (
              <NestedYAxis
                varType={axisVariableType}
                scaleType="linear"
                outerDomain={groupDomain || []}
                innerDomain={axisDomain}
                width={width}
                height={height}
                margin={new_margin}
                showText={showAxisAxisLabels}
              />
            )
          ) : chartType === "vertical" ? (
            <XAxis
              varType={stackVariableType}
              scaleType={"linear"}
              domain={groupDomain || []}
              width={width}
              height={height}
              margin={new_margin}
              showText={showAxisAxisLabels}
            />
          ) : (
            <YAxis
              varType={stackVariableType}
              scaleType={"linear"}
              domain={groupDomain || []}
              width={width}
              height={height}
              margin={new_margin}
              showText={showAxisAxisLabels}
            />
          ))}
      </svg>
    );
  }
};

export default GroupedStackedBarChart;
