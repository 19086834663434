import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec } from "./types";

const stringifySpec = (spec: Spec): string => JSON.stringify(spec);

// Change key
const INSIGHT_TYPE = "data-file-time-series-anomalies";

const model: StateInterface = {
  // Add params. Each of them: param: {[file_id]: ...}
  adjust_for_seasonality: {},
  max_lag: {},

  // Set Params
  setParams: action((state, payload) => {
    const { file_id, params } = payload;
    // Add other params as required.

    if (
      params.adjust_for_seasonality !== null &&
      params.adjust_for_seasonality !== undefined
    ) {
      state.adjust_for_seasonality[file_id] = params.adjust_for_seasonality;
    }
    if (params.max_lag !== undefined && params.max_lag !== null) {
      state.max_lag[file_id] = params.max_lag;
    }
  }),

  // Create Request
  create: thunk(
    async (
      _,
      { file_id, value_column },
      { injections, getState, getStoreActions }
    ) => {
      const state = getState();

      if (state.resource(file_id, value_column)) {
        return;
      }

      const spec = state.getSpec(file_id, value_column);
      if (!spec) {
        return;
      }

      const { urls } = injections;
      const spec_string = stringifySpec(spec);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_time_series_anomalies}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec,
        spec_string
      });
    }
  ),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [storeActions.reset.reset],
    state => {
      // Reset params
      state.adjust_for_seasonality = {};
      state.max_lag = {};
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getParams: computed(state => file_id => {
    return {
      adjust_for_seasonality: state.adjust_for_seasonality[file_id],
      max_lag: state.max_lag[file_id]
    };
  }),

  getSpec: computed(
    [
      state => state.getParams,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (getParams, settings) => (file, value_column) => {
      const params = getParams(file);

      if (
        params.adjust_for_seasonality === undefined ||
        params.max_lag === undefined
      ) {
        return null;
      }

      const { timeColumn, resolution } = settings.getTimeSeriesParamsForFile(
        file
      );
      if (!timeColumn || !resolution) {
        return null;
      }

      // Change spec as required.
      const filters = settings.getFiltersForFile(file);
      const grouping_columns = settings.getGroupingColumnsForFile(file);

      const spec: Spec = {
        time_column: timeColumn,
        resolution,
        grouping_columns,
        filters,
        value_column,
        adjust_for_seasonality:
          params.adjust_for_seasonality === undefined ||
          params.adjust_for_seasonality === null
            ? true
            : params.adjust_for_seasonality,
        max_lag: params.max_lag || 0
      };
      return spec;
    }
  ),

  resource: computed(
    [
      state => state.getSpec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (getSpec, getInsight) => (file_id, value_column) => {
      const spec = getSpec(file_id, value_column);
      if (spec === null) {
        return;
      }
      const spec_string = stringifySpec(spec);
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getData: computed(state => (file_id, value_column) =>
    state.resource(file_id, value_column)?.data
  ),

  getComputeStatus: computed(state => (file_id, value_column) =>
    state.resource(file_id, value_column)?.compute_status
  )
};

export default model;
