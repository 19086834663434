import { thunk, computed } from "easy-peasy";

import StateInterface, { Spec } from "./types";

const stringifySpec = (spec: Spec): string => JSON.stringify(spec);

// Change key
const INSIGHT_TYPE = "data-file-relationships";

const model: StateInterface = {
  // Create Request
  create: thunk(
    async (_, file_id, { injections, getState, getStoreActions }) => {
      const state = getState();

      if (state.resource(file_id)) {
        return;
      }

      const { urls } = injections;

      const spec = state.getSpec(file_id);
      const spec_string = stringifySpec(spec);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_relationship_matrix}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec,
        spec_string
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])

  getSpec: computed(
    [(_, globalState) => globalState.data_files_user_settings],
    settings => file => {
      // Change spec as required.
      const filters = settings.getFiltersForFile(file);
      const grouping_variables = settings.getGroupingColumnsForFile(file);

      const spec: Spec = { filters, grouping_variables };
      return spec;
    }
  ),

  resource: computed(
    [
      state => state.getSpec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (getSpec, getInsight) => file_id => {
      const spec = getSpec(file_id);
      const spec_string = stringifySpec(spec);
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getData: computed(state => file_id => state.resource(file_id)?.data),

  getComputeStatus: computed(state => file_id =>
    state.resource(file_id)?.compute_status
  )
};

export default model;
