import React from "react";
import ReactWordcloud from "react-wordcloud";

interface PropsInterface {
  words: { text: string; value: number }[];
  title?: string;
}

const WordCloud: React.FC<PropsInterface> = ({ words, title }) => {
  return (
    <div>
      {title && (
        <p className="has-text-centered">
          <b>
            <u>{title}</u>
          </b>
        </p>
      )}
      <ReactWordcloud
        words={words}
        callbacks={{
          getWordTooltip: ({ text, value }) =>
            `${text}: ${(value * 100).toLocaleString()}%`
        }}
      />
    </div>
  );
};

export default WordCloud;
