import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { ReactourStep } from "reactour";

import { useStoreState, useStoreActions } from "store";

import paths from "App/paths";
import TourGuide from "App/Tour-Guide";

import insights from "App/Data-Files/insight-listing";
import createOptions from "App/Data-Files/create-options";

import ChooseDataFile from "./Choose-Data-File";
import Login from "App/Login";

const TOUR_KEY_LOGGED_IN = "main-landing-logged-in";
const TOUR_STEPS_LOGGED_IN: ReactourStep[] = [
  {
    selector: "#data-files-history",
    content: (
      <div>
        <p>
          All your data files - uploaded or created here - are called{" "}
          <b>Data Files</b>, and are the foundation for all the analysis you
          will do here.
        </p>
        <p>
          This button will take you to a listing of ALL the data files you have.
        </p>
      </div>
    ),
  },
  {
    selector: "#create-options",
    content: (
      <div>
        <p>You have several ways of creating new data here.</p>
        <ul>
          <p>
            You could just upload an{" "}
            <i>
              <b>Excel or CSV</b>
            </i>{" "}
            file, or
          </p>
          <p>
            Take a file that's already here, and transform it whichever way you
            like.
          </p>
        </ul>
        <p>This button takes you to all the CREATE OPTIONS available.</p>
      </div>
    ),
  },
  {
    selector: "#models-history",
    content: (
      <div>
        <p>
          This button takes you to a listing of the predictive models that you
          have built.
        </p>
        <p>
          To build new models, click on the{" "}
          <span className="tag is-primary">recipe</span> below, and follow the
          flow.
        </p>
        <p>
          And all the links we explored earlier are all present from the
          drop-downs on the left too.
        </p>
      </div>
    ),
  },
  {
    selector: "#analysis",
    content: (
      <div>
        <p>All the things you can do are listed here.</p>
        <ul>
          <p>
            Things that look like <span className="tag is-primary">recipe</span>{" "}
            are complex recipes, with iterate, predictions and auto-learn
            possibilities.
          </p>
          <p>
            <span className="tag is-info">complex-insight</span> and{" "}
            <span className="tag is-warning">data-insight</span> are analysis
            you can do with <b>EXISTING</b> data.
          </p>
          <p>
            And finally <span className="tag is-danger">create options</span>{" "}
            are options for you to <b>CREATE NEW</b> data.
          </p>
        </ul>
      </div>
    ),
  },
  {
    selector: "#main-header",
    content: (
      <div>
        <p>
          And finally, this header is present for you whichever page you go to.
        </p>
        <ul>
          <p>Click on the logo on the left to come back to this page.</p>
        </ul>
      </div>
    ),
  },
  {
    selector: "#header-help",
    content: (
      <div>
        <p>And click here to see this tour again.</p>
      </div>
    ),
  },
];

const TOUR_KEY_NO_LOGIN = "main-landing-no-login";
const TOUR_STEPS_NO_LOGIN: ReactourStep[] = [
  {
    // selector: "#main",
    content: "This is your landing page! You can do several things from here.",
  },
  {
    selector: "#analysis",
    content: (
      <div>
        <p>All the things you can do are listed here.</p>
        <ul>
          <p>
            Things that look like <span className="tag is-primary">recipe</span>{" "}
            are complex recipes, with iterate, predictions and auto-learn
            possibilities.
          </p>
          <p>
            <span className="tag is-info">complex-insight</span> and{" "}
            <span className="tag is-warning">data-insight</span> are analysis
            you can do with <b>EXISTING</b> data.
          </p>
          <p>
            And finally <span className="tag is-danger">create options</span>{" "}
            are options for you to <b>CREATE NEW</b> data.
          </p>
        </ul>
      </div>
    ),
  },
  {
    selector: "#header-links",
    content: <div>Click here to login to your account</div>,
  },
];

const Landing = () => {
  const logged_in = useStoreState((store) => store.auth.logged_in);
  const fetchRecipes = useStoreActions(
    (actions) => actions.recipes.fetchRecipes
  );
  useEffect(() => {
    fetchRecipes(1);
  }, [fetchRecipes, logged_in]);

  const recipes = useStoreState((store) => store.recipes.recipes);

  return (
    <section className="hero is-primary is-fullheight-with-navbar">
      {logged_in ? (
        <TourGuide tourKey={TOUR_KEY_LOGGED_IN} steps={TOUR_STEPS_LOGGED_IN} />
      ) : (
        <TourGuide tourKey={TOUR_KEY_NO_LOGIN} steps={TOUR_STEPS_NO_LOGIN} />
      )}
      <div className="hero-body">
        <div className="container">
          <h1 className="title has-text-centered">
            What do you want to do today?
          </h1>
          <div className="box has-text-centered" id="analysis-history">
            <div className="is-shadowless">
              <Link to={paths.dataFiles} id="data-files-history">
                <p
                  className="tag is-large is-success has-text-centered"
                  style={{ margin: "1rem" }}
                >
                  Explore Your Data
                </p>
              </Link>
              <Link to={paths.predictiveModels} id="models-history">
                <p
                  className="tag is-large is-success has-text-centered"
                  style={{ margin: "1rem" }}
                >
                  Your Predictive Models
                </p>
              </Link>
              <Link to={paths.createData} id="create-options">
                <p
                  className="tag is-large is-success has-text-centered"
                  style={{ margin: "1rem" }}
                >
                  Upload/Transform Data
                </p>
              </Link>
            </div>
          </div>
          <div className="box has-text-centered" id="analysis">
            <div className="tags">
              {Object.keys(recipes).map((r) => (
                <div
                  key={r}
                  className="tooltip has-tooltip-multiline has-text-centered tag is-large is-primary"
                  data-tooltip={recipes[r].description}
                >
                  <Link
                    to={`${paths.recipes}/create/${r}`}
                    className="has-text-centered has-text-white"
                  >
                    {recipes[r].title}
                  </Link>
                </div>
              ))}
              {insights.map((insight) => (
                <Popup
                  key={insight.title}
                  modal={true}
                  trigger={
                    <div
                      className={[
                        "tooltip",
                        "has-tooltip-multiline",
                        "has-text-centered",
                        "tag",
                        "is-large",
                        insight.tags.includes("learning")
                          ? "is-info"
                          : "is-warning",
                        "is-link",
                      ].join(" ")}
                      data-tooltip={insight.text}
                    >
                      {insight.title}
                    </div>
                  }
                >
                  {logged_in ? <ChooseDataFile insight={insight} /> : <Login />}
                </Popup>
              ))}
              {createOptions.map((option) => (
                <div
                  key={option.text}
                  className="tooltip has-tooltip-multiline has-text-centered tag is-large is-danger"
                  data-tooltip={option.text}
                  style={{ opacity: "80%" }}
                >
                  <Link
                    to={`${paths.createData}/${option.link}`}
                    className="has-text-centered has-text-white"
                  >
                    {option.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
