import { VarType, UnivariateDatum } from "../types";

import { isCategorical } from "../utils";
import { filterObjectsByValidity } from "../data-utils";

/** Generates [value_domain, height_domain] for a uni-variate distribution */
export default (
  data: UnivariateDatum[],
  varType: VarType,
  valueDomain?: (string | number | Date)[],
  heightDomain?: [number, number],
  startHeightAtZero?: boolean
): [(string | number | Date)[], [number, number]] => {
  data = filterObjectsByValidity(data, "height", "numeric");
  if (!(Array.isArray(heightDomain) && heightDomain.length === 2)) {
    heightDomain = [
      startHeightAtZero ? 0 : Math.min(...data.map(({ height }) => height)),
      Math.max(...data.map(({ height }) => height))
    ];
  }

  if (Array.isArray(valueDomain) && valueDomain.length >= 1) {
    return [valueDomain, heightDomain];
  }

  if (isCategorical(varType)) {
    data = filterObjectsByValidity(data, "level", varType);
    valueDomain = Array.from(
      new Set(data.map(({ level = "" }) => level).filter(l => l !== ""))
    );
  } else if (varType === "date" || varType === "time") {
    data = filterObjectsByValidity(data, "leftEdge", varType);
    data = filterObjectsByValidity(data, "rightEdge", varType);
    const lefts = data
      .map(({ leftEdge }) => leftEdge)
      // @ts-ignore
      .map(v => Date.parse(v));
    const rights = data
      .map(({ rightEdge }) => rightEdge)
      // @ts-ignore
      .map(v => Date.parse(v));
    valueDomain = [new Date(Math.min(...lefts)), new Date(Math.max(...rights))];
  } else {
    data = filterObjectsByValidity(data, "leftEdge", varType);
    data = filterObjectsByValidity(data, "rightEdge", varType);
    const lefts = data.map(({ leftEdge }) => leftEdge);
    const rights = data.map(({ rightEdge }) => rightEdge);
    valueDomain = [
      // @ts-ignore
      Math.min(...lefts),
      // @ts-ignore
      Math.max(...rights)
    ];
  }
  return [valueDomain, heightDomain];
};
