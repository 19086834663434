import { action, thunk, computed, actionOn } from "easy-peasy";

import { delay } from "utils";
import StateInterface, { StringifySpec } from "./types";

const makeSpecString: StringifySpec = ({
  model_id,
  insight_name,
  insight_params,
}) => {
  return [model_id, insight_name, JSON.stringify(insight_params || {})].join(
    "__"
  );
};

const model: StateInterface = {
  // State
  insight_resources: {},
  insight_params: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})
  updateResource: action((state, payload) => {
    const {
      model_id,
      insight_name,
      insight_params,
      data,
      compute_status,
    } = payload;

    const key = makeSpecString({ model_id, insight_name, insight_params });
    if (!(state.insight_resources[key] || {}).data) {
      state.insight_resources[key] = { compute_status, data };
    }
  }),

  updateInsightParam: action((state, payload) => {
    const { model_id, insight_name, insight_param } = payload;
    if (!state.insight_params[model_id]) {
      state.insight_params[model_id] = {};
    }

    if (state.insight_params[model_id][insight_name]) {
      state.insight_params[model_id][insight_name] = {};
    }
    const { key, value } = insight_param;
    if (value === null || value === undefined || value === "") {
      delete state.insight_params[model_id][insight_name][key];
    } else {
      state.insight_params[model_id][insight_name][key] = value;
    }
  }),

  reset: actionOn(
    (_, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout,
    ],
    (state) => {
      state.insight_resources = {};
      state.insight_params = {};
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  createInsight: thunk(
    async (actions, payload, { getState, injections, getStoreActions }) => {
      const { insight_resources } = getState();
      if (insight_resources[makeSpecString(payload)]) {
        return;
      }

      const { urls } = injections;
      const url = `${urls.model_details}/${payload.model_id}/insights`;

      await getStoreActions().api_client.fetch({
        url,
        method: "POST",
        data: { name: payload.insight_name, params: payload.insight_params },
        onComplete: ({ success, data, error }) => {
          if (success) {
            const { request_id } = data;
            if (request_id) {
              actions.fetchTaskStatus({
                task_id: request_id,
                spec: payload,
              });
            } else {
              actions.updateResource({
                ...payload,
                data: data.data,
                compute_status: { completed: true, error: false },
              });
            }
          } else {
            actions.updateResource({
              ...payload,
              compute_status: {
                completed: true,
                error: true,
                error_message: error,
              },
            });
          }
        },
      });
    }
  ),

  createMultipleInsights: thunk(async (actions, payload) => {
    payload.forEach((spec) => actions.createInsight(spec));
  }),

  fetchTaskStatus: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const { urls } = injections;
      const { spec, task_id } = payload;
      const url = `${urls.task_status}/${task_id}`;
      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: async ({ success, data, error }) => {
          if (!success) {
            if (error === "Login Failed") {
              await delay(5000);
              actions.fetchTaskStatus(payload);
            } else {
              actions.updateResource({
                ...spec,
                compute_status: { error: true, error_message: error },
              });
            }

            return;
          }
          if (data.status === "FAILURE") {
            actions.updateResource({
              ...spec,
              compute_status: {
                error: true,
                error_message: "Unable to compute...",
              },
            });
          } else if (data.status === "SUCCESS") {
            if (data.result.error) {
              actions.updateResource({
                ...spec,
                compute_status: {
                  error: true,
                  error_message:
                    data.result.error_message || data.result.message,
                },
              });
            } else if (data.result.completed === true) {
              actions.createInsight(spec);
            }
          } else {
            await delay(2000);
            actions.fetchTaskStatus(payload);
          }
        },
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getInsightResource: computed(
    [(state) => state.insight_resources],
    (resources) => (model_id, insight_name, insight_params) => {
      const key = makeSpecString({ model_id, insight_name, insight_params });
      return resources[key];
    }
  ),

  getInsightStatus: computed(
    [(state) => state.getInsightResource],
    (getInsightResource) => (model_id, insight_name, insight_params) =>
      getInsightResource(model_id, insight_name, insight_params)?.compute_status
  ),

  getInsightData: computed(
    [(state) => state.getInsightResource],
    (getInsightResource) => (model_id, insight_name, insight_params) =>
      getInsightResource(model_id, insight_name, insight_params)?.data
  ),

  getMultipleInsightResources: computed(
    [(state) => state.getInsightResource],
    (getInsightResource) => (model_id, specs) =>
      specs.map(({ insight_name, insight_params }) =>
        getInsightResource(model_id, insight_name, insight_params)
      )
  ),

  getMultipleInsightData: computed(
    [(state) => state.getInsightData],
    (getInsightData) => (model_id, specs) =>
      specs.map(({ insight_name, insight_params }) =>
        getInsightData(model_id, insight_name, insight_params)
      )
  ),
};

export default model;
