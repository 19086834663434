import React from "react";

import { Redirect, Link } from "react-router-dom";

import { useStoreState } from "store";

import FirebaseAuth from "./Firebase";
import EmailPasswordAuth from "./Email-Password";

import paths from "App/paths";
import { IDENTITY_PROVIDER, SIGN_IN_ALLOWED } from "App/constants";

interface LoginProps {
  redirect?: boolean;
}

const Login: React.FC<LoginProps> = ({ redirect = true }) => {
  const loggedIn = useStoreState((store) => store.auth.logged_in);

  if (loggedIn) {
    if (redirect) {
      return <Redirect to="/" />;
    }
  }

  const AuthComp =
    IDENTITY_PROVIDER === "firebase" ? FirebaseAuth : EmailPasswordAuth;

  if (!SIGN_IN_ALLOWED) {
    return <AuthComp />;
  }

  return (
    <>
      <AuthComp />
      <hr />
      <div className="has-text-centered">
        <p>
          <Link to={paths.signup}>Sign Up</Link> for a Free Account
        </p>
      </div>
    </>
  );
};

export default Login;
