import { isCategorical } from "../../utils";
import { filterObjectsByValidity } from "../../data-utils";

import { VarType } from "../../types";

import { GridCell } from "./types";

export default (data: GridCell[], xType: VarType, yType: VarType) => {
  if (isCategorical(xType) && isCategorical(yType)) {
    data = filterObjectsByValidity(data, "x", xType);
    data = filterObjectsByValidity(data, "y", yType);
  } else if (!isCategorical(xType) && !isCategorical(yType)) {
    data = filterObjectsByValidity(data, "xLeftEdge", xType);
    data = filterObjectsByValidity(data, "yLeftEdge", yType);
    data = filterObjectsByValidity(data, "xRightEdge", xType);
    data = filterObjectsByValidity(data, "yRightEdge", yType);
  } else {
    data = filterObjectsByValidity(data, "level", "char");
    const numType = isCategorical(xType) ? yType : xType;
    data = filterObjectsByValidity(data, "leftEdge", numType);
    data = filterObjectsByValidity(data, "rightEdge", numType);
  }
  return data;
};
