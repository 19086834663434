import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Pagination from "../Pagination";

import { Data } from "./types";
import useSort from "./sort-effect";
import useFilters from "./filter-effects";

import createListing from "./create-listing";
import exportToCSV from "./export-to-csv";

import Filters from "./Filters";

const ARROWS = {
  asc: <span> &uarr;</span>,
  dsc: <span> &darr;</span>,
  null: <span> &harr;</span>,
};

interface PropsInterface {
  data: Data;
  transaction_column: string;
  item_column: string;
  grouping_columns?: string[];
  ante_item_class?: string | ((item: string) => string);
  cons_item_class?: string | ((item: string) => string);
}

const AssociationRules: React.FC<PropsInterface> = ({
  data,
  transaction_column,
  item_column,
  grouping_columns,
  ante_item_class = "tag is-medium",
  cons_item_class = "tag is-medium",
}) => {
  const { sorter, sortD, set_sorter } = useSort();
  const [page, setPage] = useState<number>(1);

  const listing = createListing(data).sort((a, b) =>
    sortD === "asc" ? a[sorter] - b[sorter] : b[sorter] - a[sorter]
  );

  const {
    ante_items,
    cons_items,
    anteDisplayItems,
    setAnteDisplayItems,
    consDisplayItems,
    setConsDisplayItems,
    minAnteLength,
    setMinAnteLength,
    minConsLength,
    setMinConsLength,
    filtered_rules,
  } = useFilters(listing);

  return (
    <>
      <div className="is-clearfix">
        <p className="is-pulled-right">
          <button
            id="export-rules"
            className="button is-primary"
            onClick={() => exportToCSV(listing)}
          >
            EXPORT TO CSV
          </button>
        </p>
      </div>
      <p className="subtitle has-text-weight-bold">Association Rule</p>
      <p className="notification is-success">
        Every rule has an <b>antecedent</b> and a <b>consequent</b>. Lets take a
        rule which goes:{" "}
        <i>
          when <b>(A,B)</b> occur, there's a 70% change of <b>(P,Q,R)</b>{" "}
          occuring.
        </i>
        . In this case{" "}
        <b>
          <i>(A,B)</i>
        </b>{" "}
        is the <b>antecedent</b>, and{" "}
        <b>
          <i>(P,Q,R)</i>
        </b>{" "}
        the <b>consequent</b>. The <i>pattern length</i> is 2 for the antecedent
        here, and 3 for the consequent.
      </p>
      <hr />
      <div id="display-filters">
        <p className="has-text-weight-bold subtitle">
          The current filters display{" "}
          <b>
            <i>{filtered_rules.length.toLocaleString()}</i>
          </b>{" "}
          rules out of a total {listing.length.toLocaleString()} rules. Set
          filters on the table below:
        </p>
        <Filters
          item_column={item_column}
          ante_items={ante_items}
          anteDisplayItems={anteDisplayItems}
          setAnteDisplayItems={setAnteDisplayItems}
          minAnteLength={minAnteLength}
          setMinAnteLength={setMinAnteLength}
          cons_items={cons_items}
          consDisplayItems={consDisplayItems}
          minConsLength={minConsLength}
          setMinConsLength={setMinConsLength}
          setConsDisplayItems={setConsDisplayItems}
        />
      </div>
      <hr />
      {filtered_rules.length === 0 ? (
        <p className="has-text-centered">No rules to show!!!</p>
      ) : (
        <>
          <Pagination
            currentPage={page}
            pageCount={Math.floor(filtered_rules.length / 100) + 1}
            onClick={setPage}
          />
          <table className="table">
            <thead>
              <tr>
                {Array.isArray(grouping_columns) &&
                  grouping_columns.length >= 1 && (
                    <>
                      {grouping_columns.map((g) => (
                        <th key={g}>{g}</th>
                      ))}
                    </>
                  )}
                <th>
                  Antecedent ({item_column})
                  <span
                    className=" is-small tooltip has-tooltip-multiline"
                    data-tooltip={`For the rule: (P,Q,R) has happened 70% of the time when (A,B) happened, (A,B) is the antecedent.`}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="fas icon" />
                  </span>{" "}
                </th>
                <th>
                  Consequent ({item_column})
                  <span
                    className=" is-small tooltip has-tooltip-multiline"
                    data-tooltip={`For the rule: (P,Q,R) has happened 70% of the time when (A,B) happened, (P,Q,R) is the consequent.`}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="fas icon" />
                  </span>{" "}
                </th>
                <th>
                  <p onClick={() => set_sorter("ante-count")}>
                    {transaction_column} with Antecedent{" "}
                    <span
                      className=" is-small tooltip has-tooltip-multiline"
                      data-tooltip={`Number of times the antecedent occurred - irrespective of whether the consequent occurred or not.`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="fas icon"
                      />
                    </span>{" "}
                    {sorter === "ante-count" ? ARROWS[sortD] : ARROWS["null"]}
                  </p>
                </th>
                <th>
                  <p onClick={() => set_sorter("cons-count")}>
                    {transaction_column} with Consequent{" "}
                    <span
                      className=" is-small tooltip has-tooltip-multiline"
                      data-tooltip={`Number of times the consequent occurred - irrespective of whether the antecedent occurred or not.`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="fas icon"
                      />
                    </span>{" "}
                    {sorter === "cons-count" ? ARROWS[sortD] : ARROWS["null"]}
                  </p>
                </th>
                <th>
                  <p onClick={() => set_sorter("joint-count")}>
                    Joint Count{" "}
                    <span
                      className=" is-small tooltip has-tooltip-multiline"
                      data-tooltip={`Number of times this RULE occurred, i.e., both antecedent and consequent occurred.`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="fas icon"
                      />
                    </span>{" "}
                    {sorter === "joint-count" ? ARROWS[sortD] : ARROWS["null"]}
                  </p>
                </th>
                <th>
                  <p onClick={() => set_sorter("confidence")}>
                    Rule Confidence{" "}
                    <span
                      className=" is-small tooltip has-tooltip-multiline"
                      data-tooltip={`Joint Count / Antecedent Count - how confident are we of the consequent happenning, when we know the antecedent occurred?`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="fas icon"
                      />
                    </span>{" "}
                    {sorter === "confidence" ? ARROWS[sortD] : ARROWS["null"]}
                  </p>
                </th>
                <th>
                  <p onClick={() => set_sorter("certainty")}>
                    Rule Certainty{" "}
                    <span
                      className=" is-small tooltip has-tooltip-multiline"
                      data-tooltip={`Confidence corrected for baseline - how much has our confidence in the consequent happening increased after knowing that the antecedent happened?`}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="fas icon"
                      />
                    </span>{" "}
                    {sorter === "certainty" ? ARROWS[sortD] : ARROWS["null"]}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered_rules.slice((page - 1) * 100, page * 100).map((r) => (
                <tr
                  key={`${r.antecedent}+${r.consequent}+${r.grouping}`}
                  id="association-rule"
                >
                  {r.grouping.map((g) => (
                    <td key={g}>{g}</td>
                  ))}
                  <td>
                    <p className="tags">
                      {r.antecedent.map((c) => (
                        <span
                          key={c}
                          className={
                            (typeof ante_item_class === "function"
                              ? ante_item_class(c)
                              : ante_item_class) || "tag"
                          }
                        >
                          {c}
                        </span>
                      ))}
                    </p>
                  </td>
                  <td>
                    <p className="tags">
                      {r.consequent.map((c) => (
                        <span
                          key={c}
                          className={
                            (typeof cons_item_class === "function"
                              ? cons_item_class(c)
                              : cons_item_class) || "tag"
                          }
                        >
                          {c}
                        </span>
                      ))}
                    </p>
                  </td>
                  <td>{r["ante-count"].toLocaleString()}</td>
                  <td>{r["cons-count"].toLocaleString()}</td>
                  <td>{r["joint-count"].toLocaleString()}</td>
                  <td>{r["confidence"].toLocaleString()}</td>
                  <td>{r["certainty"].toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
          <Pagination
            currentPage={page}
            pageCount={Math.floor(filtered_rules.length / 100) + 1}
            onClick={setPage}
          />
        </>
      )}
    </>
  );
};

export default AssociationRules;
