import React, { useEffect } from "react";

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

import { useStoreActions } from "store";
import {
  IDENTITY_PROVIDER,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DB_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_ID,
  FIREBASE_STORAGE_BUCKET,
} from "App/constants";

if (IDENTITY_PROVIDER === "firebase") {
  try {
    const firebase_config = {
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      databaseURL: FIREBASE_DB_URL,
      projectId: FIREBASE_PROJECT_ID,
      storageBucket: FIREBASE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_MESSAGING_ID,
      appId: FIREBASE_APP_ID,
      measurementId: FIREBASE_MEASUREMENT_ID,
    };

    firebase.initializeApp(firebase_config);
    firebase.analytics();
  } catch (error) {
    console.log(error);
    console.log("Unable to initialize firebase");
  }
}

const AuthWrapper: React.FC = ({ children }) => {
  const initAuthProvider = useStoreActions(
    (actions) => actions.api_client.initAuthProvider
  );
  useEffect(() => {
    initAuthProvider();
  }, [initAuthProvider]);

  return <>{children}</>;
};

export default AuthWrapper;
