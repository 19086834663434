import { Data, SingleRule } from "./types";

const create_listing = (data: Data): SingleRule[] => {
  let formatted_rules: SingleRule[] = [];
  const { rules, mapper } = data;

  rules.forEach(r => {
    const g_r = r["antecedent"].map((ant, ind) => ({
      antecedent: ant.map(c => mapper[c.toString()]),
      consequent: r["consequent"][ind].map(c => mapper[c.toString()]),
      "ante-count": r["ante-count"][ind],
      "cons-count": r["cons-count"][ind],
      "joint-count": r["joint-count"][ind],
      confidence: r["confidence"][ind],
      certainty: r["certainty"][ind],
      grouping: r["grouping"]
    }));
    formatted_rules.push(...g_r);
  });

  return formatted_rules;
};

export default create_listing;
