import React from "react";

import { ViewPort, VarType } from "Visualizations/types";
import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR,
  MARKET_COLOR_ALT,
} from "Visualizations/default-style-settings";

import { useToolTip } from "Visualizations/components/Tool-Tip";
import { XAxis, YAxis, ToolTip } from "Visualizations/components";

import { getScale, getMargin } from "Visualizations/utils";

interface Stats {
  min: number;
  "2.5%"?: number;
  "5%"?: number;
  "25%": number;
  median?: number;
  "50%"?: number;
  "75%": number;
  "95%"?: number;
  "97.5%"?: number;
  max: number;
}

const DEFAULT_ANNOTATER = (d: { key: string; dist: Stats }) =>
  `${d.key}: Min: ${d.dist["min"].toLocaleString()}; 25th Percentile: ${d.dist[
    "25%"
  ].toLocaleString()}; Median: ${(
    (d.dist["median"] === undefined ? d.dist["50%"] : d.dist["median"]) ||
    "Not Passed"
  ).toLocaleString()}; 75th Percentile: ${d.dist[
    "75%"
  ].toLocaleString()}; Max: ${d.dist["max"].toLocaleString()}`;
const DEFAULT_INTERACTION = () => {};

interface Props extends ViewPort {
  distribution: { [col: string]: Stats };
  /* If not passed, defaults to float */
  varType?: VarType;
  /* If not passed, defaults to Object.keys(distribution) */
  domain?: string[];
  showToolTip?: boolean;
  annotater?(d: any): React.ReactNode;
  onMouseOver?(d: any, e?: React.MouseEvent): any;
  onMouseOut?(): any;
}

const BoxPlot: React.FC<Props> = ({
  distribution,
  varType = "float",
  domain,
  height,
  width,
  margin = DEFAULT_MARGIN,
  showToolTip = true,
  annotater = DEFAULT_ANNOTATER,
  onMouseOver = DEFAULT_INTERACTION,
  onMouseOut = DEFAULT_INTERACTION,
}) => {
  const [hoverStatus, hoverD, onHover, offHover] = useToolTip(
    onMouseOver,
    onMouseOut
  );

  if (!domain) {
    domain = Object.keys(distribution);
  }

  domain = domain.filter((d) => distribution[d]);

  if (domain.length === 0) {
    return null;
  }

  const new_margin = getMargin(margin);

  let numbers: number[] = [];
  Object.values(distribution).forEach((v) => numbers.push(v.min, v.max));

  const xDomain =
    varType === "date"
      ? [
          new Date(Math.min(...numbers.map((n) => new Date(n).valueOf()))),
          new Date(Math.max(...numbers.map((n) => new Date(n).valueOf()))),
        ]
      : [Math.min(...numbers), Math.max(...numbers)];

  const heightScale = getScale({
    varType: "categorical",
    domain: [...domain].reverse(),
    axisLength: height - (new_margin.top + new_margin.bottom),
  });

  const xScale = getScale({
    varType: varType,
    domain: xDomain,
    scaleType: "linear",
    axisLength: width - (new_margin.left + new_margin.right),
  });

  return (
    <svg width={width} height={height}>
      <YAxis
        varType="char"
        scaleType="categorical"
        domain={domain}
        height={height}
        width={width}
        margin={{
          ...new_margin,
          left: new_margin.left - 5,
          right: new_margin.right + 5,
        }}
      />
      <XAxis
        varType={varType}
        scaleType="linear"
        domain={xDomain}
        height={height}
        width={width}
        margin={new_margin}
      />
      {domain.map((k) => {
        let rectangles = [];
        const d = distribution[k];

        const p_2_5 =
          varType === "date" && d["2.5%"] ? new Date(d["2.5%"]) : d["2.5%"];
        const p_05 =
          varType === "date" && d["5%"] ? new Date(d["5%"]) : d["5%"];
        const p_25 =
          varType === "date" && d["25%"] ? new Date(d["25%"]) : d["25%"];
        const p_75 =
          varType === "date" && d["75%"] ? new Date(d["75%"]) : d["75%"];
        const p_95 =
          varType === "date" && d["95%"] ? new Date(d["95%"]) : d["95%"];
        const p_97_5 =
          varType === "date" && d["97.5%"] ? new Date(d["97.5%"]) : d["97.5%"];
        const p_50 =
          varType === "date" && d["50%"] ? new Date(d["50%"]) : d["50%"];

        const min =
          varType === "date" && d["min"] ? new Date(d["min"]) : d["min"];
        const max =
          varType === "date" && d["max"] ? new Date(d["max"]) : d["max"];

        const median =
          d.median === undefined
            ? p_50
            : varType === "date" && d.median
            ? new Date(d.median)
            : d.median;

        if (min === null || max === null) {
          return null;
        }

        const onHoverBox = (e: React.MouseEvent) =>
          onHover({ key: k, dist: d }, e);

        // Min to Max horizontal line
        rectangles.push(
          <rect
            key={1}
            fill={hoverD.d.key === k ? HOVER_COLOR : FILL_COLOR}
            x={xScale(min)}
            width={Math.max(xScale(max) - xScale(min), 10)}
            y={heightScale.bandwidth() / 2 - 0.5}
            height={1}
            onMouseOver={onHoverBox}
            onMouseOut={offHover}
          />
        );

        if (p_2_5 !== undefined) {
          // Line between 2.5% and 25%. Only when passed.
          rectangles.push(
            <rect
              key={2}
              fill={hoverD.d.key === k ? HOVER_COLOR : FILL_COLOR}
              x={xScale(p_2_5)}
              y={heightScale.bandwidth() / 2 - heightScale.bandwidth() / 4}
              height={heightScale.bandwidth() / 2}
              width={Math.max(xScale(p_25) - xScale(p_2_5), 5)}
              onMouseOver={onHoverBox}
              onMouseOut={offHover}
            />
          );
        } else if (p_05 !== undefined) {
          // Line between 5% and 25%. Only when 5% is passed, and 2.5% is not passed.
          rectangles.push(
            <rect
              key={2}
              fill={hoverD.d.key === k ? HOVER_COLOR : FILL_COLOR}
              x={xScale(p_05)}
              y={heightScale.bandwidth() / 2 - heightScale.bandwidth() / 4}
              height={heightScale.bandwidth() / 2}
              width={Math.max(xScale(p_25) - xScale(p_05), 5)}
              onMouseOver={onHoverBox}
              onMouseOut={offHover}
            />
          );
        }
        // Central box: 25% to 75%
        rectangles.push(
          <rect
            key={3}
            fill={hoverD.d.key === k ? HOVER_COLOR : FILL_COLOR}
            x={xScale(p_25)}
            y={0}
            height={heightScale.bandwidth()}
            width={Math.max(xScale(p_75) - xScale(p_25), 5)}
            onMouseOver={onHoverBox}
            onMouseOut={offHover}
          />
        );
        if (p_97_5 !== undefined) {
          // Line between 75% and 97.5%. Only when passed.
          rectangles.push(
            <rect
              key={4}
              fill={hoverD.d.key === k ? HOVER_COLOR : FILL_COLOR}
              x={xScale(p_75)}
              y={heightScale.bandwidth() / 2 - heightScale.bandwidth() / 4}
              height={heightScale.bandwidth() / 2}
              width={Math.max(xScale(p_97_5) - xScale(p_75), 5)}
              onMouseOver={onHoverBox}
              onMouseOut={offHover}
            />
          );
        } else if (p_95 !== undefined) {
          // Line between 75% and 95%. Only when 90% is passed, and 97.5% is not passed.
          rectangles.push(
            <rect
              key={4}
              fill={hoverD.d.key === k ? HOVER_COLOR : FILL_COLOR}
              x={xScale(p_75)}
              y={heightScale.bandwidth() / 2 - heightScale.bandwidth() / 4}
              height={heightScale.bandwidth() / 2}
              width={Math.max(xScale(p_95) - xScale(p_75), 5)}
              onMouseOver={onHoverBox}
              onMouseOut={offHover}
            />
          );
        }

        // End-Lines and Median line
        rectangles.push(
          <rect
            key={5}
            fill={MARKET_COLOR_ALT}
            x={xScale(min) - 5}
            y={0}
            height={heightScale.bandwidth()}
            width={5}
            onMouseOver={onHoverBox}
            onMouseOut={offHover}
          />,
          <rect
            key={6}
            fill={MARKET_COLOR_ALT}
            x={xScale(max)}
            y={0}
            height={heightScale.bandwidth()}
            width={5}
            onMouseOver={onHoverBox}
            onMouseOut={offHover}
          />
        );

        if (median !== undefined) {
          rectangles.push(
            <rect
              key={7}
              fill={MARKET_COLOR_ALT}
              x={xScale(median)}
              y={0}
              height={heightScale.bandwidth()}
              width={2}
              onMouseOver={onHoverBox}
              onMouseOut={offHover}
            />
          );
        }
        return (
          <g
            transform={`translate(${new_margin.left}, ${new_margin.top +
              (heightScale(k) || 0)})`}
            key={k}
          >
            {rectangles}
          </g>
        );
      })}
      {showToolTip && hoverStatus && (
        <ToolTip
          d={
            annotater
              ? annotater(hoverD.d)
              : `${hoverD.d.key}: Min: ${hoverD.d.dist[
                  "min"
                ].toLocaleString()}; 25th Percentile: ${hoverD.d.dist[
                  "25%"
                ].toLocaleString()}; Median: ${(
                  (hoverD.d.dist["median"] === undefined
                    ? hoverD.d.dist["50%"]
                    : hoverD.d.dist["median"]) || "Not Passed"
                ).toLocaleString()}; 75th Percentile: ${hoverD.d.dist[
                  "75%"
                ].toLocaleString()}; Max: ${hoverD.d.dist[
                  "max"
                ].toLocaleString()}`
          }
          offsetX={hoverD.offsetX}
          offsetY={hoverD.offsetY}
          width={width}
          toolTipWidth={width - (new_margin.left + new_margin.right)}
          margin={margin}
          position={"vertical"}
        />
      )}
    </svg>
  );
};

export default BoxPlot;
