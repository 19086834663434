import React from "react";

import SelectField from "../Select-Field";

import { ColumnType, Stats } from "./types";

export const ARRAY_OPERATORS = [
  "between",
  "sw",
  "nsw",
  "ew",
  "new",
  "cn",
  "ncn",
  "in",
  "not in",
];

const get_operator_options = (
  type: ColumnType | undefined | null,
  stats: Stats
) => {
  if (!type) {
    return [];
  }
  if (type === "date") {
    return [
      { value: "between", label: "In selected range" },
      { value: "lt", label: "Before selected date" },
      { value: "le", label: "Before or ON selected date" },
      { value: "eq", label: "ON selected date" },
      { value: "ne", label: "NOT ON selected date" },
      { value: "gt", label: "After selected date" },
      { value: "ge", label: "After or ON selected date" },
    ];
  } else if (type === "char") {
    stats = stats || {};
    if ((stats["unique-values"] || []).length === stats["unique-count"]) {
      return [
        { value: "in", label: "One of selected values" },
        { value: "not in", label: "NOT one of selected values" },
      ];
    } else {
      return [
        { value: "sw", label: "Starts with any of the typed values" },
        { value: "nsw", label: "Does Not start with any of the typed values" },
        { value: "ew", label: "Ends with any of the typed values" },
        { value: "new", label: "Does Not end with any of the typed values" },
        { value: "cn", label: "Contains any of the typed values" },
        { value: "ncn", label: "Does Not contain any of the typed values" },
      ];
    }
  } else {
    return [
      { value: "between", label: "In selected range" },
      { value: "lt", label: "Less than input value" },
      { value: "le", label: "Less than or equal to input value" },
      { value: "eq", label: "Equals input value" },
      { value: "ne", label: "NOT Equals input value" },
      { value: "gt", label: "Greater than input value" },
      { value: "ge", label: "Greater than or equal to input value" },
    ];
  }
};

const Operator = ({
  colType,
  stats,
  onValueChange,
}: {
  colType: ColumnType;
  stats: Stats;
  onValueChange: (condition: string) => any;
}) =>
  colType ? (
    <SelectField
      name="operator"
      options={get_operator_options(colType, stats)}
      label="Operator Condition:"
      required={true}
      onValueChange={(v) => {
        if (v !== null) {
          onValueChange(v);
        }
      }}
    />
  ) : null;

export default Operator;
