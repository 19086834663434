import { getScale } from "Visualizations";

import { TimeSeriesDistributionType } from "./types";
import { parseGrouping } from "utils";

const groupScale = (distribution: TimeSeriesDistributionType) => {
  const grp = distribution["__group__"];
  if (!grp) {
    return;
  }
  // @ts-ignore
  const groups: string[] = Array.from(
    new Set(grp.map(parseGrouping).filter(g => g !== undefined))
  );

  if (groups.length <= 1) {
    return;
  }

  return getScale({ scaleType: "color", domain: groups });
};

export default groupScale;
