import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Value, OptionType } from "./types";
import Selected from "./Selected";
import Excluded from "./Excluded";

interface PropsInterface<T extends Value> {
  options: OptionType<T>[];
  name?: string;
  label?: React.ReactNode;
  selected?: T[];
  placeholder?: string;
  /** Icon to place along with the field. If passed, the icon is on the left. */
  icon?: IconProp;
  required?: boolean;
  minInputs?: number;
  maxInputs?: number;
  error?: string;
  help?: React.ReactNode;
  enableSelectAll?: boolean;
  showSelected?: boolean;
  showExcluded?: boolean;
  onValueChange?(values: T[]): any;
  tags?: React.ReactNode;
  id?: string;
}

const DEFAULT_SELECTED: Value[] = [];
const PLACEHOLDER_VALUE = "Select values";
const DEFAULT_CALLBACK = () => {};

function MultiSelect<T extends Value>({
  options,
  label,
  name = "multi-select",
  selected,
  placeholder = PLACEHOLDER_VALUE,
  icon,
  required,
  minInputs = 2,
  maxInputs = 10000000,
  error,
  help,
  enableSelectAll = false,
  showSelected = true,
  showExcluded = false,
  onValueChange = DEFAULT_CALLBACK,
  tags,
  id,
}: PropsInterface<T>): React.ReactElement | null {
  const { control, setValue, register, watch, errors } = useFormContext() || {};

  const invalid_options = selected
    ? selected.filter((s) => !options.find((opt) => opt.value === s))
    : DEFAULT_SELECTED;

  if (invalid_options.length > 0 && Array.isArray(selected)) {
    selected = [...selected.filter((s) => !invalid_options.includes(s))];
  }

  useEffect(() => {
    if (setValue) {
      setValue(name, selected);
      if (Array.isArray(selected)) {
        onValueChange(selected);
      }
    }
  }, [setValue, name, selected, onValueChange]);

  if (control) {
    register(name, {
      required: minInputs >= 1,
      validate: {
        min_length: (value) =>
          (value?.length || 0) >= minInputs ||
          `Must select AT LEAST ${minInputs}`,
        max_length: (value) =>
          (value?.length || 0) <= maxInputs ||
          `Can select AT MOST ${maxInputs}`,
      },
    });

    selected = selected || watch(name);
    // @ts-ignore
    error = errors[name]?.message || error;
  }

  const displayOptions = options.filter(
    ({ value }) => !(selected || []).map((v) => v).includes(value)
  );
  let className = "control";
  if (icon) {
    className += " has-icons-left";
  }

  return (
    <div className="field" id={id}>
      <label className="label">
        {label} {required && <span className="tag is-primary">MANDATORY</span>}
        {tags && tags}
      </label>
      <div className={className}>
        {icon && (
          <span className="icon is-small is-left has-text-info">
            <FontAwesomeIcon icon={icon} className="fas" />
          </span>
        )}
        {enableSelectAll && (
          <p className="has-text-right">
            <span
              className="is-link"
              onClick={() => {
                const new_values = options.map(({ value }) => value);
                if (control) {
                  setValue(name, new_values);
                }
                onValueChange(new_values);
              }}
            >
              SELECT ALL
            </span>{" "}
            |{" "}
            <span
              className="is-link"
              onClick={() => {
                if (control && setValue) {
                  setValue(name, []);
                }
                onValueChange([]);
              }}
            >
              DE-SELECT ALL
            </span>
          </p>
        )}
        <div className={`select is-fullwidth${error ? " is-danger" : ""}`}>
          <select
            name={name}
            ref={register}
            onChange={({ target }) => {
              const value = target.value;
              const option = options.find(
                (opt) => opt.value.toString() === value.toString()
              );
              if (option) {
                if (control && setValue) {
                  setValue(name, [...(selected || []), option.value]);
                }
                onValueChange([...(selected || []), option.value]);
              }
            }}
          >
            <option key={"__NONE__"} value={placeholder}>
              {placeholder}
            </option>
            {displayOptions.map(({ value, label }) => (
              <option key={value.toString()} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        {help && <div className="help">{help}</div>}
        {error && <p className={"help is-danger"}>{error}</p>}
        {showSelected && (
          <Selected
            selected={selected || []}
            options={options}
            onValueChange={(values) => {
              onValueChange(values);
              if (control && setValue) {
                setValue(name, values);
              }
            }}
          />
        )}
        {showExcluded && (
          <Excluded
            selected={selected || []}
            options={options}
            onValueChange={(values) => {
              onValueChange(values);
              if (control && setValue) {
                setValue(name, values);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
export default MultiSelect;
