export const isCategorical = (varType: string): boolean =>
  ["char", "categorical", "factor"].includes(varType);

export const isValid = (value: any): boolean =>
  value !== undefined && value !== null;

export const isNumber = (value: any): boolean =>
  !isNaN(value) && value !== null && value !== undefined;

export const isDate = (value: any): boolean =>
  value !== null && value !== undefined && !isNaN(Date.parse(value));
