import { VarType } from "Visualizations";
import { FlatBiVariateDistribution } from "./types";

const INIT_OUTLIERS: (string | number | Date)[] = [];

export default (
  distribution: FlatBiVariateDistribution,
  level_key: "level" | "level-1" | "level-2",
  colType: VarType,
  outliers: (string | number | Date)[] = INIT_OUTLIERS
) => {
  if (outliers === undefined || outliers === null) {
    outliers = INIT_OUTLIERS;
  }
  if (Array.isArray(outliers) && outliers.length === 0) {
    outliers = INIT_OUTLIERS;
  }
  if (
    colType === "numeric" ||
    colType === "int" ||
    colType === "float" ||
    colType === "percent"
  ) {
    return outliers;
  }

  if (colType === "date" || colType === "time") {
    return outliers.map((d: number | string | Date) => new Date(d));
  }

  if (Array.isArray(outliers) && outliers.length >= 1) {
    return Array.from(
      new Set(
        (distribution[level_key] || []).filter(
          (l: any) => !outliers.includes(l)
        )
      )
    );
  }

  return outliers;
};
