import React from "react";

import {
  VarType,
  Margin,
  ViewPort,
  ScaleType,
  Scale
} from "Visualizations/types";

import {
  isCategorical,
  getTickValues,
  getScale,
  getMargin
} from "Visualizations/utils";
import { DEFAULT_MARGIN } from "Visualizations/default-style-settings";

import YAxisLabel from "./Labels";

const AXIS_EXTENSION = 5;

const Label = (props: {
  showTicks: boolean;
  showText: boolean;
  scale: Scale;
  axisLength: number;
  value: any;
  formattedValue: string;
  varType: string;
  margin: Margin;
  orientation: "left" | "right";
  tickX: { x: number; x2: number };
  tickColor: string;
}) => {
  const { showTicks, showText } = props;
  if (!(showTicks || showText)) {
    return null;
  }

  const { axisLength, value, formattedValue, varType, scale } = props;
  const bandWidth = scale.bandwidth && scale.padding ? scale.bandwidth() : 0;

  const offsetY = isCategorical(varType)
    ? axisLength - bandWidth / 2
    : axisLength;
  const transform = `translate(0, ${offsetY - scale(value)})`;

  const { tickX, margin, orientation, tickColor } = props;
  const Text = isCategorical(varType) ? (
    <YAxisLabel
      value={formattedValue}
      bandWidth={bandWidth}
      margin={margin}
      orientation={orientation}
    />
  ) : (
    <text y="0.5" x={tickX.x} dy="0.32em">
      {formattedValue}
    </text>
  );

  return (
    <g opacity={1} transform={transform}>
      {showTicks && <line y1="0.5" y2="0.5" x2={tickX.x2} stroke={tickColor} />}
      {showText && Text}
    </g>
  );
};

interface PropsInterface extends ViewPort {
  varType: VarType;
  scaleType: ScaleType;
  axisColor?: string;
  tickColor?: string;
  domain: any[];
  /**Defaults to "left" */
  orientation?: "left" | "right";
  /** Defaults to true */
  showTicks?: boolean;
  /**Defaults to true */
  showText?: boolean;
}

const Yaxis: React.FC<PropsInterface> = ({
  varType,
  scaleType,
  domain,
  height,
  width,
  axisColor = "black",
  tickColor,
  margin = DEFAULT_MARGIN,
  orientation = "left",
  showText = true,
  showTicks = true
}) => {
  const new_margin = getMargin(margin);
  const axisLength = height - (new_margin.top + new_margin.bottom);

  const scale = getScale({ varType, domain, scaleType, axisLength });

  const [tickValues, formattedValues] = getTickValues(
    varType,
    scale,
    axisLength,
    axisLength / 50
  );

  const axisX =
    orientation === "left" ? new_margin.left : width - new_margin.right;
  const tickX =
    orientation === "left"
      ? { x: -9, x2: -6, anchor: "end" }
      : { x: 9, x2: 6, anchor: "start" };
  return (
    <g
      transform={`translate(${axisX}, ${new_margin.top})`}
      textAnchor={tickX.anchor}
      className="axis"
    >
      <line
        y2={-AXIS_EXTENSION}
        y1={axisLength + AXIS_EXTENSION}
        x1="0"
        stroke={axisColor}
      />
      {tickValues.map((value: any, ind: number) => (
        <Label
          key={ind}
          value={value}
          formattedValue={formattedValues[ind]}
          varType={varType}
          scale={scale}
          showTicks={showTicks}
          showText={showText}
          tickX={tickX}
          axisLength={axisLength}
          tickColor={tickColor || axisColor}
          margin={new_margin}
          orientation={orientation}
        />
      ))}
    </g>
  );
};

export default Yaxis;
