import React from "react";

import {
  VarType,
  Margin,
  ViewPort,
  ScaleType,
  Scale
} from "Visualizations/types";

import {
  isCategorical,
  getTickValues,
  getScale,
  getMargin
} from "Visualizations/utils";
import { DEFAULT_MARGIN } from "Visualizations/default-style-settings";

import XAxisLabel from "./Labels";

const AXIS_EXTENSION = 5;

const Label = (props: {
  showTicks: boolean;
  showText: boolean;
  scale: Scale;
  axisLength: number;
  value: any;
  formattedValue: string;
  varType: string;
  margin: Margin;
  orientation: "top" | "bottom";
  tickY: { y: number; dy: number; y2: number };
  tickColor: string;
}) => {
  const { showTicks, showText } = props;
  if (!(showTicks || showText)) {
    return null;
  }

  const { value, formattedValue, varType, scale } = props;
  const bandWidth = scale.bandwidth ? Math.max(scale.bandwidth(), 1) : 0;

  const is_categorical =
    varType === "categorical" || varType === "char" || varType === "factor";

  const transform = `translate(${bandWidth / 2 + scale(value)}, 0)`;

  const { tickY, margin, orientation, tickColor } = props;
  const Text = is_categorical ? (
    <XAxisLabel
      value={formattedValue}
      tickY={tickY}
      bandWidth={bandWidth}
      margin={margin}
      orientation={orientation}
    />
  ) : (
    <text y={tickY.y} x="0.5" dy={tickY.dy}>
      {formattedValue}
    </text>
  );

  return (
    <g opacity={1} transform={transform}>
      {showTicks && <line y2={tickY.y2} x1="0.5" x2="0.5" stroke={tickColor} />}
      {showText && Text}
    </g>
  );
};

interface PropsInterface extends ViewPort {
  varType: VarType;
  scaleType: ScaleType;
  axisColor?: string;
  tickColor?: string;
  domain: any[];
  /**Defaults to "bottom" */
  orientation?: "top" | "bottom";
  /** Defaults to true */
  showTicks?: boolean;
  /**Defaults to true */
  showText?: boolean;
}

const XAxis: React.FunctionComponent<PropsInterface> = ({
  varType,
  scaleType,
  domain,
  height,
  width,
  axisColor = "black",
  tickColor,
  margin = DEFAULT_MARGIN,
  orientation = "bottom",
  showText = true,
  showTicks = true
}) => {
  const new_margin = getMargin(margin);
  const axisLength = width - (new_margin.left + new_margin.right);
  const axisY =
    orientation === "bottom" ? height - new_margin.bottom : new_margin.top;
  const tickY =
    orientation === "bottom"
      ? { y: 10 * (isCategorical(varType) ? 1 : 2), y2: 6, dy: 0.71 }
      : { y: -9, y2: -6, dy: -0.2 };

  const scale = getScale({ varType, domain, scaleType, axisLength });

  const [tickValues, formattedValues] = getTickValues(
    varType,
    scale,
    axisLength
  );

  tickColor = tickColor || axisColor;

  return (
    <g
      transform={`translate(${new_margin.left}, ${axisY})`}
      textAnchor={"middle"}
      className="axis"
    >
      <line
        y="0"
        y2="0"
        x1={-AXIS_EXTENSION}
        x2={axisLength + AXIS_EXTENSION}
        stroke={axisColor}
      />
      {tickValues.map((value: any, ind: number) => (
        <Label
          key={ind}
          value={value}
          formattedValue={formattedValues[ind]}
          varType={varType}
          scale={scale}
          showTicks={showTicks}
          showText={showText}
          tickY={tickY}
          axisLength={axisLength}
          tickColor={tickColor || axisColor}
          margin={new_margin}
          orientation={orientation}
        />
      ))}
    </g>
  );
};

export default React.memo(XAxis);
