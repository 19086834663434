import React from "react";

import { Margin, Scale } from "../../types";

import get_boundaries from "./boundaries";

interface OverlayBoxProps {
  boxType: "horizontal" | "vertical";
  scale: Scale;
  domain?: (number | string | Date)[];
  height: number;
  width: number;
  margin: Margin;
}

const OverlayBox = ({
  boxType,
  scale,
  domain = [],
  height,
  width,
  margin
}: OverlayBoxProps) => {
  const [b0, b1] = get_boundaries(scale, domain);
  const h = height - (margin.top + margin.bottom);
  const w = width - (margin.left + margin.right);

  const x = boxType === "horizontal" ? b0 : 0;
  const y = boxType === "horizontal" ? 0 : h - b1;
  const b_w = boxType === "horizontal" ? b1 - b0 : w;
  const b_h = boxType === "horizontal" ? h : b1 - b0;

  return (
    <rect x={x} y={y} width={b_w} height={b_h} fill="#777" fillOpacity="0.3" />
  );
};

export default OverlayBox;
