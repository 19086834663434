import { format } from "d3-format";

import { VarType, Scale } from "../types";

const get_tick_values = (
  varType: VarType,
  scale: Scale,
  axisLength: number,
  ticks?: number
): [string[], string[]] => {
  if (scale.bandwidth && scale.padding) {
    const r: string[] = scale.domain().map(t => t.toString());
    return [r, r];
  }

  const formatter =
    varType === "percent"
      ? format(".0%")
      : varType === "float" || varType === "numeric"
      ? format("0.2f")
      : scale.tickFormat();

  const range: string[] = scale.domain().map(formatter);

  const s = Math.max(...range.map(d => d.length));

  if (!ticks) {
    ticks = Math.ceil(axisLength / s / 20);
  }

  let tickValues = scale.ticks(ticks);

  if (varType === "int") {
    tickValues = tickValues.filter(
      (d: string | number) => parseInt(d.toString(), 10) === d
    );
  }

  return [tickValues, tickValues.map(formatter)];
};

export default get_tick_values;
