import { State } from "easy-peasy";

import { StoreModel, UserDefinedTypes } from "../types";
import StateInterface, { Spec } from "./types";

export const make_spec = (
  recipe: string,
  localState: State<StateInterface>,
  globalState: State<StoreModel>
) => {
  const { input_data, variable_mapping, model_params } = localState;

  if (!input_data[recipe]) {
    return;
  }

  const recipe_details = globalState.recipes.recipes[recipe];
  if (recipe_details === undefined) {
    return;
  }

  let files: string[] = [];
  Object.values(input_data[recipe]).forEach(values => {
    files.push(...values);
  });

  let cleanup_spec = {};
  let types_by_file: { [file_id: string]: UserDefinedTypes } = {};
  let columns_by_file: { [file_id: string]: string[] } = {};

  const fileSettings = globalState.data_files_user_settings;

  files.forEach(f => {
    const c = globalState.data_file_create_clean.getSpec(f);
    if (c !== null) {
      // @ts-ignore
      cleanup_spec[f] = c;
      const file_details = globalState.data_files.getFileDetails(f);
      const dropped_columns = fileSettings.getDroppedColumnsForFile(f);
      types_by_file[f] = fileSettings.getTypesForFile(f);
      columns_by_file[f] =
        file_details?.columns?.filter(c => !dropped_columns.includes(c)) || [];
    }
  });

  let cleaned_mapping: { [key: string]: string[] } = {};
  Object.keys(variable_mapping[recipe] || {}).forEach(key => {
    const mapping_spec = recipe_details.variable_mapping.find(
      m => m.key === key
    );
    if (
      mapping_spec !== undefined &&
      input_data[recipe][mapping_spec.location]
    ) {
      const value = variable_mapping[recipe][key];
      let columns: string[] = [];
      let types: UserDefinedTypes = {};

      (input_data[recipe][mapping_spec.location] || []).forEach(f => {
        columns.push(...columns_by_file[f]);
        types = { ...types, ...types_by_file[f] };
      });
      let valid_columns = value.filter(c => columns.includes(c));
      const expected_type = mapping_spec.type;

      if (expected_type !== "any") {
        valid_columns = valid_columns.filter(c => types[c] === expected_type);
      }

      cleaned_mapping[key] = valid_columns;
    }
  });

  let cleaned_model_params = { ...(model_params[recipe] || {}) };
  Object.keys(cleaned_model_params).forEach(key => {
    const params_spec = recipe_details.model_params.find(m => m.key === key);
    if (params_spec === undefined || params_spec === null) {
      delete cleaned_model_params[key];
    }
  });

  const spec: Spec = {
    recipe,
    input_data: input_data[recipe],
    cleanup_spec,
    variable_mapping: cleaned_mapping,
    model_params: cleaned_model_params
  };

  return spec;
};

export const validate_spec = (
  recipe: string,
  localState: State<StateInterface>,
  globalState: State<StoreModel>
) => {
  const { input_data } = localState;

  if (!input_data[recipe]) {
    return { error: true, message: "Input Data Not Set", field: "input_data" };
  }

  const recipe_details = globalState.recipes.recipes[recipe];
  if (recipe_details === undefined) {
    return { error: true, message: "Unknown Recipe", field: "recipe" };
  }

  const invalid_locations: string[] = recipe_details.data_required
    .filter(r => r.mandatory && (input_data[recipe][r.key] || []).length === 0)
    .map(r => r.name);

  if (invalid_locations.length >= 1) {
    return {
      error: true,
      message: `Invalid Data for ${invalid_locations}`,
      field: "input_data"
    };
  }

  const spec = make_spec(recipe, localState, globalState);
  if (!spec) {
    return { error: true, message: "Invalid Settings", field: "recipe" };
  }
  const invalid_variable_mappings: string[] = recipe_details.variable_mapping
    .filter(r => {
      const l = (spec.variable_mapping[r.key] || []).length;

      if (l === 0) {
        return r.mandatory === true;
      } else {
        return !(l >= r.min_choice_count && l <= r.max_choice_count);
      }
    })
    .map(r => r.name);

  if (invalid_variable_mappings.length >= 1) {
    return {
      error: true,
      message: `Invalid Mapping for ${invalid_variable_mappings}`,
      field: "variable_mapping"
    };
  }

  const invalid_model_params: string[] = recipe_details.model_params
    .filter(r => {
      const value = spec.model_params[r.key];
      if (value === undefined) {
        return r.mandatory;
      } else if (r.value_type === "filters" && r.mandatory) {
        return !(Array.isArray(value) && value.length >= 1);
      } else if (
        r.value_type === "choice" &&
        r.value_choice === "range" &&
        r.mandatory
      ) {
        return !(Array.isArray(value) && value.length >= 1);
      } else {
        return false;
      }
    })
    .map(r => r.name);

  if (invalid_model_params.length >= 1) {
    return {
      error: true,
      message: `Invalid Model params for ${invalid_model_params}`,
      field: "model_params"
    };
  }

  return { error: false };
};
