import React, { Fragment } from "react";

import { Droppable, Draggable, DraggableProvided } from "react-beautiful-dnd";

import { Item } from "./types";

const draggableElem: (
  provided: DraggableProvided,
  label: string | React.ReactNode
) => React.ReactElement = (provided, label) => {
  if (typeof label === "string") {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {label}
      </div>
    );
  } else {
    // @ts-ignore
    return React.cloneElement(label, {
      ref: provided.innerRef,
      ...provided.draggableProps,
      ...provided.dragHandleProps
    });
  }
};

interface CategoryBoxProps {
  category: string;
  label: string;
  items: Item[];
  catItems: string[];
  categoryClassName?: string;
}

const CategoryBox: React.FC<CategoryBoxProps> = ({
  category,
  label,
  items,
  catItems,
  categoryClassName
}) => {
  const categoryItemList = items.filter(({ value }) =>
    catItems.includes(value)
  );

  const draggables = categoryItemList.filter(
    ({ draggable }) => draggable === true
  );
  const fixed = categoryItemList.filter(
    ({ draggable }) => (draggable || false) === false
  );

  return (
    <Droppable droppableId={category}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <p className="has-text-weight-bold">{label}</p>
          <div className="box">
            <div
              className={categoryClassName || ""}
              style={{ minHeight: "20px" }}
            >
              {draggables.map(({ value, label }, index) => (
                <Draggable key={value} draggableId={value} index={index}>
                  {provided => draggableElem(provided, label)}
                </Draggable>
              ))}
              {fixed.map(({ value, label }) => (
                <Fragment key={value}>{label}</Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default CategoryBox;
