import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { useFormContext } from "react-hook-form";

interface PropsInterface {
  label: React.ReactNode;
  name?: string;
  checked?: boolean;
  required?: boolean;
  error?: string;
  help?: string;
  onValueChange(value: boolean | null): any;
}

const CheckBox: React.FC<PropsInterface> = ({
  label,
  name,
  checked = false,
  required = false,
  error,
  help,
  onValueChange = () => {},
}) => {
  const { register, errors, control } = useFormContext() || {};
  if (control) {
    // @ts-ignore
    error = ((errors || {})[name || ""] || {})["message"] || error;
  }

  return (
    <div className="field">
      <label className="label">
        {label}
        {"  "}
        <input
          name={name}
          ref={register}
          type="checkbox"
          checked={checked}
          onChange={(v) => onValueChange(v.target.checked)}
        />
      </label>
      {required && (
        <span className="icon is-small is-right has-text-danger">
          <FontAwesomeIcon icon={faExclamationCircle} className="fas" />
        </span>
      )}
      {help && <div className="help">{help}</div>}
      {error && <p className={"help is-danger"}>{error}</p>}
    </div>
  );
};

export default CheckBox;
