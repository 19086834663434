import { action, thunk, actionOn } from "easy-peasy";

import StateInterface from "./types";

const model: StateInterface = {
  uploadStatus: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})
  updateStatus: action((state, { name, status }) => {
    state.uploadStatus[name] = {
      ...(state.uploadStatus[name] || {}),
      ...status
    };
  }),

  resetStatus: action(state => {
    state.uploadStatus = {};
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.uploadStatus = {};
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  uploadSingleFile: thunk(
    async (actions, file, { injections, getStoreActions }) => {
      const { urls } = injections;

      const name = file.name;
      const { fetchTaskStatus } = getStoreActions().data_file_create_status;

      await getStoreActions().api_client.uploadFile({
        url: urls.upload_data,
        file: file,
        params: {},
        onUploadProgress: progress =>
          actions.updateStatus({
            name,
            status: { progress, completed: false, error: false }
          }),
        onComplete: ({ success, data, error }) => {
          if (success) {
            const { request_id } = data;
            actions.updateStatus({
              name,
              status: {
                taskID: request_id,
                progress: 1,
                completed: false,
                error: false,
                status: "Processing Data."
              }
            });
            fetchTaskStatus({
              taskID: request_id,
              updateResource: status => {
                actions.updateStatus({
                  name,
                  status: status.compute_status
                });
              }
            });
          } else {
            actions.updateStatus({
              name,
              status: {
                progress: 1,
                completed: true,
                error: true,
                error_message: error
              }
            });
          }
        }
      });
    }
  ),

  uploadFiles: thunk(async (actions, payload) => {
    payload.forEach(file => actions.uploadSingleFile(file));
  })

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
};

export default model;
