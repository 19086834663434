import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec, StringifySpec } from "./types";

// Approx Equality Check between two specs.
const stringifySpec: StringifySpec = ({
  source_data,
  name,
  filters,
  columns_to_be_dropped,
  column_types
}) =>
  `${source_data}__${name}__${JSON.stringify(
    filters
  )}__${columns_to_be_dropped.sort().join("__$$__")}__${JSON.stringify(
    column_types
  )}`;

const model: StateInterface = {
  // State
  params: {},

  taskStatus: {},
  result: null,

  // Set Params
  setParams: action((state, payload) => {
    state.params = { ...state.params, ...payload };
  }),

  resetParams: action(state => {
    state.params = {};
    state.taskStatus = {};
    state.result = null;
  }),

  setResult: action((state, result) => {
    state.result = result;
  }),

  resetTaskStatus: action(state => {
    state.taskStatus = {};
  }),

  // Update Store
  updateTaskStatus: action((state, { spec, status, result }) => {
    state.taskStatus[stringifySpec(spec)] = status;
    if (result) {
      state.result = result;
    }
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.params = {};
      state.taskStatus = {};
      state.result = null;
    }
  ),

  // Create Request
  create: thunk(
    async (actions, _, { injections, getState, getStoreActions }) => {
      // Get Spec.
      const spec = getState().spec;

      // Spec is null: There was an error.
      if (!spec) {
        return;
      }

      // Return immediately if task already requested once.
      if (getState().taskStatus[stringifySpec(spec)]) {
        return;
      }

      const url = injections.urls.create_data_filter;
      await getStoreActions().data_file_create_status.createFile({
        url,
        spec,
        // @ts-ignore
        updateTaskStatus: actions.updateTaskStatus
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  spec: computed(
    [
      state => state.params,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (params, settings) => {
      const { source_data, name } = params;
      if (!settings.getFiltersForFile) {
        return null;
      }
      const filters = settings.getFiltersForFile(source_data || "");

      if (
        !source_data ||
        !name ||
        !(Array.isArray(filters) && filters.length >= 1)
      ) {
        return null;
      }

      const types = settings.getTypesForFile(source_data);
      const droppedColumns = settings.getDroppedColumnsForFile(source_data);

      const spec: Spec = {
        source_data,
        name,
        filters,
        column_types: types,
        columns_to_be_dropped: droppedColumns
      };

      return spec;
    }
  ),

  getComputeStatus: computed(state => () => {
    const spec = state.spec;
    if (!spec) {
      return;
    }
    return state.taskStatus[stringifySpec(spec)];
  })
};

export default model;
