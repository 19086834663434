const parseGrouping = (
  gv: string | number | (string | number)[] | undefined
): string | number | undefined => {
  if (typeof gv === "string" || typeof gv === "number") {
    return gv;
  } else if (gv === undefined) {
    return;
  } else {
    return gv.join("__");
  }
};

export default parseGrouping;
