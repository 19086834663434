import React from "react";

import RedirectToUpgradeURL from "./Redirect-to-Upgrade";

import { UPGRADE_URL } from "App/constants";

interface SubscriptionRouteProps {
  message?: string;
}

const SubscriptionRoute: React.FC<SubscriptionRouteProps> = ({
  message = "Your plan doesnt allow for this operation!!!",
}) => {
  if (UPGRADE_URL) {
    return (
      <RedirectToUpgradeURL
        redirect_element={
          <div className="has-text-centered">
            {message} Redirecting you to upgrade page...
          </div>
        }
      />
    );
  } else {
    return (
      <p className="has-text-centered">
        {message} Please contact your administrator.
      </p>
    );
  }
};

export default SubscriptionRoute;
