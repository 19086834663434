import { action, thunk, computed, actionOn } from "easy-peasy";
import { delay } from "utils";

import StateInterface from "./types";

const model: StateInterface = {
  // State
  models: {},
  workspaces: {},
  models_in_workspace: {},
  models_in_branch: {},
  access_flags: {},
  listing_fetch_in_progress: false,

  workspace_delete_status: {},
  model_delete_status: {},

  iterate_params: {},
  display_params: {},
  insight_params: {},

  workspace_access_flags: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})
  updateWorkSpaces: action((state, workspaces) => {
    workspaces.forEach(ws => {
      state.workspaces[ws.id] = ws;
      state.access_flags[ws.id] = true;
    });
  }),

  updateListing: action((state, models) => {
    if (!state.access_flags) {
      state.access_flags = {};
    }
    models.forEach(md => {
      state.models[md.id] = { ...(state.models[md.id] || {}), ...md };
      if (!state.models_in_workspace[md.workspace]) {
        state.models_in_workspace[md.workspace] = [];
      }
      if (!state.models_in_workspace[md.workspace].includes(md.id)) {
        state.models_in_workspace[md.workspace].push(md.id);
      }
      if (!state.models_in_branch[md.model_branch]) {
        state.models_in_branch[md.model_branch] = [];
      }
      if (!state.models_in_branch[md.model_branch].includes(md.id)) {
        state.models_in_branch[md.model_branch].push(md.id);
      }
      state.access_flags[md.id] = true;
    });
  }),

  updateWorkspaceDeleteStatus: action((state, { workspace, status }) => {
    if (status.completed && !status.error) {
      const models = state.models_in_workspace[workspace];
      if (models !== undefined) {
        models.forEach(m => {
          delete state.models[m];
          delete state.access_flags[m];
        });
      }
      delete state.workspaces[workspace];
      delete state.models_in_workspace[workspace];
      delete state.iterate_params[workspace];
      delete state.display_params[workspace];
      delete state.workspace_access_flags[workspace];
    }
    state.workspace_delete_status[workspace] = status;
  }),

  updateModelDeleteStatus: action((state, { model, status }) => {
    if (status.completed && !status.error) {
      const { workspace, model_branch } = state.models[model];

      state.models_in_workspace[workspace] = state.models_in_workspace[
        workspace
      ].filter(m => m !== model);

      state.models_in_branch[model_branch] = state.models_in_branch[
        model_branch
      ].filter(m => m !== model);

      delete state.models[model];
      delete state.access_flags[model];
      delete state.insight_params[model];
    }
    state.model_delete_status[model] = status;
  }),

  updateAccessFlag: action((state, { model_id, flag }) => {
    state.access_flags[model_id] = flag;
  }),

  updateWorkspaceAccessFlag: action((state, { workspace_id, flag }) => {
    state.workspace_access_flags[workspace_id] = flag;
  }),

  updateListingFetchFlag: action((state, flag) => {
    state.listing_fetch_in_progress = flag;
  }),

  updateDisplayParams: action((state, { workspace_id, params }) => {
    state.display_params[workspace_id] = params;
  }),

  updateIterateParams: action((state, { workspace_id, params }) => {
    state.iterate_params[workspace_id] = params;
  }),

  updateInsightParams: action((state, { model_id, params }) => {
    if (!state.insight_params) {
      state.insight_params = {};
    }

    state.insight_params[model_id] = params;
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.models = {};
      state.models_in_workspace = {};
      state.models_in_branch = {};
      state.access_flags = {};
      state.listing_fetch_in_progress = false;
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  fetchModels: thunk(
    async (
      actions,
      { workspace_id, branch_id, model_id },
      { injections, getStoreState, getStoreActions }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }
      let url = `${injections.urls.predictive_models}/models`;

      if (model_id) {
        actions.updateListingFetchFlag(true);
        await getStoreActions().api_client.fetch({
          url: `${url}/${model_id}`,
          method: "GET",
          onComplete: ({ success, data }) => {
            if (success) {
              actions.updateListing([data]);
              actions.fetchWorkspaces(data.workspace);
            } else {
              actions.updateAccessFlag({ model_id, flag: false });
            }
          }
        });
        actions.updateListingFetchFlag(false);
      } else {
        if (workspace_id) {
          if (url.includes("?")) {
            url += "&";
          } else {
            url += "?";
          }
          url += `workspace=${workspace_id}`;
        }
        if (branch_id) {
          if (url.includes("?")) {
            url += "&";
          } else {
            url += "?";
          }
          url += `model_branch=${branch_id}`;
        }
        actions.fetchModelListing(url);
      }
    }
  ),

  fetchModelListing: thunk(
    async (actions, url, { injections, getStoreActions }) => {
      url = url || `${injections.urls.predictive_models}/models`;

      getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: async ({ success, data }) => {
          if (success) {
            actions.updateListing(data.results);
            actions.updateListingFetchFlag(false);
            if (data.next) {
              actions.fetchModelListing(data.next);
            }
          } else {
            await delay(5000);
            actions.fetchModelListing(url);
          }
        }
      });
    }
  ),

  fetchWorkspaces: thunk(
    async (
      actions,
      workspace_id,
      { injections, getStoreState, getStoreActions }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }
      if (workspace_id) {
        getStoreActions().api_client.fetch({
          url: `${injections.urls.predictive_models}/workspaces/${workspace_id}`,
          method: "GET",
          onComplete: async ({ success, data, status }) => {
            if (success) {
              actions.updateWorkSpaces([data]);
            } else if (status === 404) {
              actions.updateWorkspaceAccessFlag({ workspace_id, flag: false });
            } else {
              await delay(5000);
              actions.fetchWorkspaces(workspace_id);
            }
          }
        });
      } else {
        actions.fetchWorkspaceListing();
      }
    }
  ),

  fetchWorkspaceListing: thunk(
    async (actions, url, { injections, getStoreActions }) => {
      url = url || `${injections.urls.predictive_models}/workspaces`;

      getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: async ({ success, data }) => {
          if (success) {
            actions.updateWorkSpaces(data.results);
            if (data.next) {
              actions.fetchWorkspaceListing(data.next);
            }
          } else {
            await delay(5000);
            actions.fetchWorkspaceListing(url);
          }
        }
      });
    }
  ),

  deleteModel: thunk(
    async (
      actions,
      model,
      { injections, getStoreState, getStoreActions, getState }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const user_details = globalState.auth.user_details;
      if (!user_details) {
        return;
      }

      const localState = getState();
      if (!localState.models[model]) {
        return;
      }
      if (localState.models[model].owner !== user_details.email) {
        return;
      }

      actions.updateModelDeleteStatus({
        model,
        status: { completed: false, error: false }
      });

      getStoreActions().api_client.fetch({
        url: `${injections.urls.predictive_models}/models/${model}`,
        method: "delete",
        onComplete: ({ success, data }) =>
          actions.updateModelDeleteStatus({
            model,
            status: success ? { completed: true } : data
          })
      });
    }
  ),

  deleteWorkspace: thunk(
    async (
      actions,
      workspace,
      { injections, getStoreState, getStoreActions, getState }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const user_details = globalState.auth.user_details;
      if (!user_details) {
        return;
      }

      const localState = getState();
      if (!localState.workspaces[workspace]) {
        return;
      }
      if (localState.workspaces[workspace].owner !== user_details.email) {
        return;
      }

      actions.updateWorkspaceDeleteStatus({
        workspace,
        status: { completed: false, error: false }
      });

      getStoreActions().api_client.fetch({
        url: `${injections.urls.predictive_models}/workspaces/${workspace}`,
        method: "DELETE",
        onComplete: ({ success, data }) =>
          actions.updateWorkspaceDeleteStatus({
            workspace,
            status: success ? { completed: true } : data
          })
      });
    }
  ),

  fetchDisplayParams: thunk(
    async (
      actions,
      payload,
      { injections, getState, getStoreState, getStoreActions }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }
      if (!payload.workspace_id && !payload.model_id) {
        return;
      }

      let workspace_id = payload.workspace_id
        ? payload.workspace_id
        : payload.model_id
        ? (getState()["models"][payload.model_id] || {})["workspace"]
        : "";

      while (!workspace_id) {
        await delay(1000);
        workspace_id = payload.workspace_id
          ? payload.workspace_id
          : payload.model_id
          ? (getState()["models"][payload.model_id] || {})["workspace"]
          : "";
      }

      if (getState().display_params[workspace_id]) {
        return;
      }

      const { urls } = injections;
      const url = `${urls.predictive_models}/workspaces/${workspace_id}/display-params`;
      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: ({ success, data }) =>
          success && actions.updateDisplayParams({ workspace_id, params: data })
      });
    }
  ),

  fetchIterateParams: thunk(
    async (
      actions,
      payload,
      { injections, getState, getStoreState, getStoreActions }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }
      if (!payload.workspace_id && !payload.model_id) {
        return;
      }

      let workspace_id = payload.workspace_id
        ? payload.workspace_id
        : payload.model_id
        ? (getState()["models"][payload.model_id] || {})["workspace"]
        : "";

      while (!workspace_id) {
        await delay(1000);
        workspace_id = payload.workspace_id
          ? payload.workspace_id
          : payload.model_id
          ? (getState()["models"][payload.model_id] || {})["workspace"]
          : "";
      }

      if (getState().iterate_params[workspace_id]) {
        return;
      }

      const { urls } = injections;
      const url = `${urls.predictive_models}/workspaces/${workspace_id}/iterate-params`;
      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: ({ success, data }) =>
          success && actions.updateIterateParams({ workspace_id, params: data })
      });
    }
  ),

  fetchInsightParams: thunk(
    async (
      actions,
      payload,
      { injections, getState, getStoreState, getStoreActions }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }
      if (!payload.model_id) {
        return;
      }

      const { model_id } = payload;

      if (getState().insight_params[model_id]) {
        return;
      }

      const { urls } = injections;
      const url = `${urls.predictive_models}/models/${model_id}/insight-params`;
      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: ({ success, data }) =>
          success && actions.updateInsightParams({ model_id, params: data })
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getModelsForWorkSpace: computed(
    [state => state.models_in_workspace, state => state.models],
    (models_in_ws, models) => ws_id => {
      return (models_in_ws[ws_id] || [])
        .map(m_id => models[m_id])
        .filter(m => m !== undefined);
    }
  ),

  getDisplayParamsForModel: computed(
    [state => state.display_params, state => state.models],
    (display_params, models) => model_id =>
      display_params[models[model_id]?.workspace]
  )
};

export default model;
