import React from "react";
import { ViewPort, VarType, ScaleType } from "../../types";

import { DEFAULT_MARGIN } from "../../default-style-settings";
import { getScale, getMargin } from "../../utils";

import XAxis from "../X-Axis";

interface PropsInterface extends ViewPort {
  /** Inner Var Type */
  varType: VarType;
  /** Inner Scale Type */
  scaleType: ScaleType;
  axisColor?: string;
  tickColor?: string;
  outerDomain: any[];
  innerDomain: any[];
  /**Defaults to "bottom" */
  orientation?: "top" | "bottom";
  /** Defaults to true */
  showTicks?: boolean;
  /**Defaults to true */
  showText?: boolean;
  /** Defaults to true */
  showInnerTicks?: boolean;
  /**Defaults to true */
  showInnerText?: boolean;
}

const NestedXAxis: React.FunctionComponent<PropsInterface> = ({
  varType,
  scaleType,
  axisColor = "black",
  tickColor,
  outerDomain,
  innerDomain,
  orientation = "bottom",
  showText = true,
  showTicks = true,
  showInnerText = true,
  showInnerTicks = true,
  width,
  height,
  margin = DEFAULT_MARGIN
}) => {
  const new_margin = getMargin(margin, DEFAULT_MARGIN);
  if (orientation === "bottom") {
    new_margin.bottom = new_margin.bottom / 2;
  } else {
    new_margin.top = new_margin.top / 2;
  }

  const axisLength = width - (new_margin.left + new_margin.right);

  const outerScale = getScale({
    varType: "char",
    domain: outerDomain,
    axisLength
  });

  const innerMargin = {
    ...new_margin,
    left: (outerScale.padding() * axisLength) / 2,
    right: (outerScale.padding() * axisLength) / 2
  };

  return (
    <>
      <XAxis
        varType="char"
        scaleType="categorical"
        domain={outerDomain}
        width={width}
        height={height}
        showText={showText}
        showTicks={showTicks}
        axisColor={axisColor}
        tickColor={tickColor}
        margin={new_margin}
        orientation={orientation}
      />

      {outerDomain.map(d => (
        <g
          key={d}
          // @ts-ignore
          transform={`translate(${outerScale(d) + new_margin.left}, ${
            orientation === "bottom" ? 0 : new_margin.top
          })`}
          textAnchor={"middle"}
          className="axis"
        >
          <XAxis
            varType={varType}
            scaleType={scaleType}
            domain={innerDomain}
            width={outerScale.bandwidth() - outerScale.padding()}
            height={
              orientation === "bottom"
                ? height - new_margin.bottom
                : new_margin.top
            }
            showText={showInnerText}
            showTicks={showInnerTicks}
            axisColor={axisColor}
            tickColor={tickColor}
            margin={innerMargin}
            orientation={orientation}
          />
        </g>
      ))}
    </>
  );
};

export default NestedXAxis;
