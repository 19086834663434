import { thunk, computed } from "easy-peasy";

import StateInterface from "./types";

const INSIGHT_TYPE = "data-sample";

const model: StateInterface = {
  // Create Request
  create: thunk(
    async (_, file_id, { injections, getState, getStoreActions }) => {
      const state = getState();
      if (state.resource(file_id)) {
        return;
      }

      const { urls } = injections;
      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_sample}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec_string: ""
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])

  resource: computed(
    [(_, storeState) => storeState.data_file_insights.getInsight],
    getInsight => file_id => getInsight(file_id, INSIGHT_TYPE, "")
  ),

  getComputeStatus: computed(state => file_id =>
    state.resource(file_id)?.compute_status
  ),

  getData: computed(state => file_id => state.resource(file_id)?.data)
};

export default model;
