import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import DatePicker from "../Date-Picker";

import { Controller, useFormContext } from "react-hook-form";

type ValueType = string | number | Date;

/** Generates an Input Field. Automatically determines if inside a form (FormContext), and behaves accordingly. */
interface PropsInterface {
  label: string | React.ReactNode;
  /** If inside a form, this must be set, and be unique across all form-fields. */
  name?: string;
  /** Defaults to "text" */
  type?: "email" | "password" | "number" | "text" | "date";
  /** Initial Value */
  value?: ValueType | null | undefined;
  showLabel?: boolean;
  /** Icon to place along with the field. If passed, the icon is on the left. */
  icon?: IconProp;
  required?: boolean;
  placeholder?: string;
  error?: string;
  help?: React.ReactNode;
  onValueChange?(value: ValueType | null): any;
  tags?: React.ReactNode;
  showToolTip?: boolean;
}

const InputField: React.FC<PropsInterface> = ({
  label,
  name = "input",
  type = "text",
  value,
  icon,
  showLabel = true,
  required = false,
  placeholder = "Enter Value",
  error,
  help,
  onValueChange = () => {},
  tags,
  showToolTip = false,
}) => {
  const { register, control, watch, errors } = useFormContext() || {};

  if (watch) {
    value =
      watch(name) === null || watch(name) === undefined || watch(name) === ""
        ? value
        : watch(name);
    // @ts-ignore
    error = ((errors || {})[name || ""] || {})["message"] || error;
  }

  let comp;
  if (type === "date") {
    if (control) {
      comp = (
        <Controller
          as={
            <DatePicker
              onChange={() => {}}
              selected={
                value === undefined || value === null ? value : new Date(value)
              }
              isClearable={true}
              placeholderText={placeholder}
              className={error ? "input is-danger" : "input"}
            />
          }
          name={name || "field"}
          rules={{ required: required }}
          onChange={(d) => {
            const v = d[0];
            if (onValueChange) {
              onValueChange(v);
            }
            return v;
          }}
          defaultValue={value}
        />
      );
    } else {
      comp = (
        <DatePicker
          selected={
            value === undefined || value === null ? value : new Date(value)
          }
          name={name}
          onChange={onValueChange}
          isClearable={true}
          placeholderText={placeholder}
          className={error ? "input is-danger" : "input"}
          required={required}
        />
      );
    }
  } else {
    if (value instanceof Date) {
      value = value.toLocaleString();
    }
    comp = (
      <input
        ref={register}
        name={name || "field"}
        type={type === "number" ? "text" : type}
        className={error ? "input is-danger" : "input"}
        value={value === null || value === undefined ? "" : value}
        placeholder={placeholder}
        onChange={(e) => {
          onValueChange(
            type === "number"
              ? isNaN(parseFloat(e.target.value))
                ? e.target.value
                : parseFloat(e.target.value)
              : e.target.value
          );
        }}
        required={required}
      />
    );
  }

  let className = "control";
  if (icon) {
    className += " has-icons-left";
  }
  if (required) {
    className += " has-icons-right";
  }

  return (
    <div className="field">
      {showLabel && (
        <label className="label">
          {label}{" "}
          {showToolTip && help && (
            <span className="tooltip has-tooltip-multiline" data-tooltip={help}>
              <FontAwesomeIcon icon={faInfoCircle} className="fas icon" />
            </span>
          )}{" "}
          {tags && tags}
        </label>
      )}
      <div className={className}>
        {icon && (
          <span className="icon is-small is-left has-text-info">
            <FontAwesomeIcon icon={icon} className="fas" />
          </span>
        )}
        {comp}
        {required && (
          <span className="icon is-small is-right has-text-danger">
            <FontAwesomeIcon icon={faExclamationCircle} className="fas" />
          </span>
        )}
        {help && <div className="help">{help}</div>}
        {error && <p className={"help is-danger"}>{error}</p>}
      </div>
    </div>
  );
};

export default InputField;
