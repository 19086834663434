import { VarType } from "../types";
import { isCategorical, isValid, isNumber, isDate } from "../utils";

const getDomains = (
  array: (string | number | Date)[],
  varType: VarType,
  domain?: (string | number | Date)[]
): (string | number | Date)[] => {
  if (Array.isArray(domain) && domain.length >= 1) {
    return domain;
  }

  if (isCategorical(varType)) {
    return array.filter(isValid);
  }

  if (varType === "time" || varType === "date") {
    const values = array
      .filter(isDate)
      // @ts-ignore
      .map(v => Date.parse(v));

    return [new Date(Math.min(...values)), new Date(Math.max(...values))];
  } else {
    // @ts-ignore
    const values = array.filter(isNumber).map(parseFloat);
    return [Math.min(...values), Math.max(...values)];
  }
};

export default getDomains;
