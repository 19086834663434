import React, { Suspense } from "react";

import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "easy-peasy";

import { Helmet } from "react-helmet";

import configureStore, { useStoreState } from "store";

import urls from "App/api-urls";
import { ROUTER_BASE_URL } from "App/constants";
import LoadingIndicator from "UI-Components/Loading-Indicator";

import ErrorBoundary from "App/Error-Boundary";
import AuthWrapper from "./Auth-Wrapper";
import HubSpot from "App/Hubspot";
import Header from "App/Header";
import Footer from "App/Footer";
import BreadCrumbs from "App/Bread-Crumbs";
import Main from "App/Main";

require("typeface-roboto");

const store = configureStore(urls);

const LicenseWrapper: React.FC = ({ children }) => {
  const license_is_valid = useStoreState(
    (state) => state.auth.license_is_valid
  );
  const license_error = useStoreState((state) => state.auth.license_error);

  if (license_is_valid) {
    return <>{children}</>;
  } else {
    return (
      <p className="has-text-centered">
        {license_error ||
          "Your license is invalid. Please contact your administrator."}
      </p>
    );
  }
};

const Body = () => (
  <div
    style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
    id="main"
  >
    <Helmet title="Daisho" />
    <Header />
    <main style={{ flex: 1 }}>
      <BreadCrumbs />
      <Suspense fallback={<LoadingIndicator />}>
        <LicenseWrapper>
          <Main />
        </LicenseWrapper>
      </Suspense>
    </main>
    <Footer />
  </div>
);

const App = () => {
  if (!store) {
    console.log("Store is not yet built");
    return <LoadingIndicator />;
  }
  return (
    <BrowserRouter basename={ROUTER_BASE_URL ? ROUTER_BASE_URL : ""}>
      <StoreProvider store={store}>
        <HubSpot>
          <ErrorBoundary>
            <AuthWrapper>
              <Body />
            </AuthWrapper>
          </ErrorBoundary>
        </HubSpot>
      </StoreProvider>
    </BrowserRouter>
  );
};

export default App;
