import { AxiosResponse } from "axios";

export default (response: AxiosResponse) => {
  if (response.config["responseType"] === "blob") {
    const file_name =
      (response.headers["content-disposition"] || "").split("filename=")[1] ||
      "file";
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");

    link.href = downloadUrl;

    link.setAttribute("download", file_name);

    document.body.appendChild(link);

    link.click();

    link.remove();

    window.URL.revokeObjectURL(downloadUrl);
  }
  return {
    success: true,
    data: response.data,
    status: response.status
  };
};
