import { isCategorical, UnivariateDatum, VarType } from "Visualizations";

import { FlatUniVariateDistribution } from "./types";

const m = (l: number | Date, r: number | Date, count: number, value: any) => {
  // @ts-ignore
  const interval = r - l;
  return {
    leftEdge: l,
    rightEdge: r,
    count: count,
    height: count / interval,
    value: value
  };
};

export default (
  distribution: FlatUniVariateDistribution | undefined | null,
  col_type: VarType
): UnivariateDatum[] => {
  if (!distribution) {
    return [];
  }
  if (isCategorical(col_type)) {
    if (!distribution["level"] || !distribution["points-in-level"]) {
      return [];
    }

    const points_in_level = distribution["points-in-level"];

    return distribution["level"].map((l, i) => ({
      level: l,
      height: points_in_level[i],
      count: points_in_level[i]
    }));
  }
  if (!distribution["left-edges"]) {
    return [];
  }

  const leftEdges = distribution["left-edges"];
  const rightEdges = distribution["right-edges"];
  const points_in_interval = distribution["points-in-interval"];

  if (!leftEdges || !rightEdges || !points_in_interval) {
    return [];
  }
  const uniqueValues = distribution["unique-value"] || [];

  if (col_type === "numeric" || col_type === "float" || col_type === "int") {
    return leftEdges.map((l, i) => {
      const r = rightEdges[i];
      return m(l, r, points_in_interval[i], uniqueValues[i]);
    });
  } else {
    return distribution["left-edges"].map((l: number | Date, i: number) => {
      l = new Date(l);
      const r = new Date(rightEdges[i]);
      return m(l, r, points_in_interval[i], uniqueValues[i]);
    });
  }
};
