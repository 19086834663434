import React from "react";
import { ViewPort, InteractionProps, VarType, Fill, Scale } from "../../types";

import { DEFAULT_MARGIN } from "../../default-style-settings";
import { getScale, getMargin, isCategorical } from "../../utils";

import { GridCell } from "./types";
import filterData from "./filter-data";
import getStackSizes from "./stack-sizes";
import getDomains from "./domains";
import getGroupsAndKey from "./groups";

interface PropsInterface extends ViewPort, InteractionProps<GridCell> {
  data: GridCell[];
  barType: "vertical" | "horizontal";
  stackVariableType?: VarType;
  axisVariableType?: VarType;
  axisDomain?: (string | number | Date)[];
  stackDomain?: (string | number | Date)[];
  /** Values outside this range are filtered */
  valueDomain?: [number, number];
  /** For a vertical chart, corresponds to Y-Axis */
  valueAxisDomain?: [number, number];
  colorFill?: Fill<GridCell>;
}

const StackedBars: React.FunctionComponent<PropsInterface> = ({
  data,
  barType,
  stackVariableType = "char",
  axisVariableType = "numeric",
  axisDomain,
  stackDomain,
  valueDomain,
  valueAxisDomain,
  colorFill,
  onClick = () => {},
  onMouseOver = () => {},
  onMouseOut = () => {},
  height,
  width,
  margin = DEFAULT_MARGIN
}) => {
  const new_margin = getMargin(margin, DEFAULT_MARGIN);
  data = filterData(
    data,
    axisVariableType,
    stackVariableType,
    axisDomain,
    stackDomain,
    valueDomain
  );

  if (data.length === 0) {
    return (
      <g transform={`translate(${new_margin.left}, ${new_margin.top})`}>
        <text>No data found!!!</text>
      </g>
    );
  }

  if (valueAxisDomain === undefined || valueAxisDomain === null) {
    const stackSizes = getStackSizes(data, axisVariableType, stackVariableType);
    valueAxisDomain = [0, Math.max(...stackSizes)];
  }

  [axisDomain, stackDomain] = getDomains(
    data,
    axisVariableType,
    stackVariableType,
    axisDomain,
    stackDomain
  );

  if (barType === "horizontal" && isCategorical(axisVariableType)) {
    axisDomain = [...axisDomain].reverse();
  }

  const stackScale = getScale({
    varType: "int",
    domain: valueAxisDomain,
    axisLength:
      barType === "vertical"
        ? height - (new_margin.top + new_margin.bottom)
        : width - (new_margin.left + new_margin.right)
  });

  const axisScale = getScale({
    varType: axisVariableType,
    domain: axisDomain,
    axisLength:
      barType === "horizontal"
        ? height - (new_margin.top + new_margin.bottom)
        : width - (new_margin.left + new_margin.right)
  });

  const xScale: Scale = barType === "vertical" ? axisScale : stackScale;
  const yScale: Scale = barType === "vertical" ? stackScale : axisScale;

  const [axisUniques, axisKey] = getGroupsAndKey(
    data,
    axisVariableType,
    stackVariableType,
    "axis"
  );

  const [stackUniques, stackKey] = getGroupsAndKey(
    data,
    axisVariableType,
    stackVariableType,
    "stack"
  );

  if (colorFill === undefined || colorFill === null) {
    // @ts-ignore
    colorFill = getScale({ scaleType: "color", domain: stackUniques });
  }

  let stacks: React.ReactNode[] = [];

  const valueAxisLength = stackScale.range()[1];

  let y0: number;

  axisUniques.forEach(l => {
    let stackData: GridCell[] = [];

    if (axisVariableType === "date" || axisVariableType === "time") {
      stackData = data.filter(d => d[axisKey].toString() === l.toString());
    } else {
      stackData = data.filter(d => d[axisKey] === l);
    }

    y0 = 0;
    stackUniques.forEach(g => {
      const b = stackData.find(d => d[stackKey].toString() === g.toString());

      if (b) {
        let x: number = 0,
          y: number = 0,
          bw: number = 0,
          bh: number = 0;

        if (isCategorical(axisVariableType)) {
          const px = isCategorical(stackVariableType) ? b["x"] : b["level"];
          if (px !== undefined) {
            if (barType === "vertical") {
              x = xScale(px);
              bw = xScale.bandwidth ? xScale.bandwidth() : 10;
              bh = yScale(b.value);
              y = valueAxisLength - (yScale(y0) + bh);
            } else {
              y = yScale(px);
              bh = yScale.bandwidth ? yScale.bandwidth() : 10;
              x = xScale(y0);
              bw = xScale(b.value);
            }
          }
        } else {
          if (barType === "vertical") {
            const leftKey = isCategorical(stackVariableType)
              ? "leftEdge"
              : "xLeftEdge";
            const rightKey = isCategorical(stackVariableType)
              ? "rightEdge"
              : "xRightEdge";
            x = xScale(b[leftKey]);
            bw = xScale(b[rightKey]) - x;
            y = valueAxisLength - yScale(y0 + b.value);
            bh = yScale(b.value);
          } else {
            x = xScale(y0);
            bw = xScale(b.value);
            if (isCategorical(stackVariableType)) {
              y = yScale(b.leftEdge);
              bh = yScale(b.rightEdge) - y;
            } else {
              y = yScale.range()[1] - yScale(b.xRightEdge);
              bh = yScale(b.xRightEdge) - yScale(b.xLeftEdge);
            }
          }
        }
        if (bw > 0 && bh > 0) {
          const bar = (
            <rect
              key={`${g.toString()}--${l.toString()}`}
              x={x}
              width={bw}
              y={y}
              height={bh}
              // @ts-ignore
              fill={colorFill(g)}
              onClick={e => onClick(b, e)}
              onMouseOver={e => onMouseOver(b, e)}
              onMouseOut={e => onMouseOut(b, e)}
            />
          );
          y0 = y0 + b.value;
          stacks.push(bar);
        }
      }
    });
  });

  return <g transform={`translate(${margin.left}, ${margin.top})`}>{stacks}</g>;
};

export default StackedBars;
