export default (
  event: React.MouseEvent,
  brushType: "horizontal" | "vertical"
): number => {
  // @ts-ignore
  const rect = event.target.parentNode.getBoundingClientRect();

  const v =
    brushType === "vertical"
      ? rect.bottom - event.clientY
      : event.clientX - rect.left;
  return v;
};
