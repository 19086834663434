import React, { useState } from "react";

import { pie, arc } from "d3-shape";

import { FillProps, InteractionProps } from "Visualizations/types";
import { FILL_COLOR, HOVER_COLOR } from "Visualizations/default-style-settings";

import { filterObjectsByValidity } from "Visualizations/data-utils";
import { getScale } from "Visualizations/utils";

type Datum = { level: string | number | Date; value: number };

interface PropsInterface extends FillProps<Datum>, InteractionProps<Datum> {
  data: Datum[];
  radius: number;
  levelDomain?: (string | number | Date)[];
  opacityDomain?: [number, number];
}

const DEFAULT_FUNC = () => {};

const Pie: React.FunctionComponent<PropsInterface> = ({
  data,
  radius,
  levelDomain,
  opacityDomain,
  fillColor,
  hoverColor = HOVER_COLOR,
  onClick = DEFAULT_FUNC,
  onMouseOver = DEFAULT_FUNC,
  onMouseOut = DEFAULT_FUNC
}) => {
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  data = filterObjectsByValidity(data, "value", "numeric");
  if (data.length === 0) {
    return null;
  }

  const domain = (levelDomain ? levelDomain : data.map(d => d.level)).map(d =>
    d.toString()
  );
  if (fillColor === undefined) {
    const scale = getScale({ scaleType: "color", domain: domain });
    // @ts-ignore
    fillColor = (d: Datum) => scale(d.level.toString());
  }

  const opacityScale = opacityDomain
    ? getScale({
        scaleType: "linear",
        varType: "numeric",
        axisLength: opacityDomain,
        domain: [
          Math.min(...data.map(d => d.value)),
          Math.max(...data.map(d => d.value))
        ]
      })
    : () => 1;

  const arcPath = arc()
    .innerRadius(0)
    .outerRadius(radius);

  const pieGen = pie().sort(null)(data.map(d => d.value));

  return (
    <g transform={`translate(${radius}, ${radius})`}>
      {pieGen.map((d, ind) => (
        <path
          key={ind}
          // @ts-ignore
          d={arcPath({ startAngle: d.startAngle, endAngle: d.endAngle })}
          fill={
            hoverIndex === ind
              ? hoverColor
              : typeof fillColor === "string"
              ? fillColor
              : fillColor
              ? fillColor(data[ind])
              : FILL_COLOR
          }
          fillOpacity={opacityScale(data[ind].value)}
          onClick={() => onClick(data[ind])}
          onMouseOver={e => {
            setHoverIndex(ind);
            return onMouseOver(data[ind], e);
          }}
          onMouseMove={e => onMouseOver(data[ind], e)}
          onMouseOut={e => {
            setHoverIndex(-1);
            return onMouseOut(data[ind], e);
          }}
        />
      ))}
    </g>
  );
};

export default Pie;
