import React from "react";

import TreatmentOptions from "./treatment-options";
import FillValue from "./fill-value";

interface MissingPatterns {
  "present::present": number;
  "present::missing": number;
  "missing::present": number;
  "missing::missing": number;
}

interface Treatment<T> {
  treatment?: "drop-rows" | "fill-value" | "ignore";
  value?: T;
}

interface Props<T> {
  missingPatterns: MissingPatterns;
  colType: "numeric" | "char" | "date";
  treatment?: Treatment<T>;
  onChange: (t: Treatment<T>) => any;
  treatmentError?: string;
  valueError?: string;
}

function SubstituteTreatment<T extends string | number | Date>({
  missingPatterns,
  colType,
  treatment,
  onChange,
  treatmentError,
  valueError
}: Props<T>) {
  const jointMissing = missingPatterns["missing::missing"];
  if (jointMissing === 0) {
    return <p className="help is-info">All rows can be safely filled.</p>;
  }

  return (
    <div>
      <p>
        {jointMissing.toLocaleString()} rows are MISSING in both columns. How
        should these rows be treated?
      </p>
      <TreatmentOptions
        label="Substitute Treatment"
        treatment={(treatment || {})["treatment"]}
        showSubstituteOption={false}
        onChange={t => {
          if (t !== undefined) {
            // @ts-ignore
            onChange({ ...treatment, treatment: t });
          }
        }}
        error={treatmentError}
      />
      {(treatment || {})["treatment"] === "fill-value" && (
        <FillValue
          colType={colType}
          treatment={treatment}
          // @ts-ignore
          onChange={onChange}
          error={valueError}
        />
      )}
    </div>
  );
}

export default SubstituteTreatment;
