import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface from "./types";
import { delay } from "utils";

const model: StateInterface = {
  // State

  ignored_features: {},
  forced_features: {},
  filters: {},
  model_params: {},
  hyper_params: {},
  algorithms: {},

  submit_error: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})

  updateIgnoredFeatures: action((state, { workspace, features }) => {
    state.ignored_features[workspace] = features;
    state.forced_features[workspace] = (
      state.forced_features[workspace] || []
    ).filter(f => !features.includes(f));
  }),

  updateForcedFeatures: action((state, { workspace, features }) => {
    state.forced_features[workspace] = features;
    state.ignored_features[workspace] = (
      state.ignored_features[workspace] || []
    ).filter(f => !features.includes(f));
  }),

  updateFilters: action((state, { workspace, filters }) => {
    state.filters[workspace] = filters;
  }),

  updateModelParam: action((state, { workspace, param }) => {
    if (!state.model_params[workspace]) {
      state.model_params[workspace] = {};
    }
    const { key, value } = param;
    if (value !== null && value !== undefined && value !== "") {
      state.model_params[workspace][key] = value;
    } else {
      delete state.model_params[workspace][key];
    }
  }),

  updateHyperParam: action((state, { workspace, param }) => {
    if (!state.hyper_params[workspace]) {
      state.hyper_params[workspace] = {};
    }
    const { key, value } = param;
    if (value === "" || value === undefined || value === null) {
      delete state.hyper_params[workspace][key];
    } else {
      state.hyper_params[workspace][key] = value;
    }
  }),

  updateAlgorithms: action((state, { workspace, algorithms }) => {
    state.algorithms[workspace] = algorithms;
  }),

  setSubmitError: action((state, error) => {
    state.submit_error = error || {};
  }),

  setResult: action((state, result) => {
    if (result === null || result === undefined) {
      delete state.result;
    } else {
      state.result = result;
    }
  }),

  resetTaskStatus: actionOn(
    actions => [
      actions.updateIgnoredFeatures,
      actions.updateForcedFeatures,
      actions.updateFilters,
      actions.updateModelParam,
      actions.updateHyperParam
    ],
    state => {
      state.submit_error = {};
      delete state.task_status;
      delete state.result;
    }
  ),

  updateTaskStatus: action((state, { status, result }) => {
    state.task_status = status;
    if (result) {
      state.result = result;
    }
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.ignored_features = {};
      state.forced_features = {};
      state.filters = {};
      state.model_params = {};
      state.hyper_params = {};
      state.algorithms = {};
      state.submit_error = {};
      delete state.task_status;
      delete state.result;
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  create: thunk(
    async (
      actions,
      workspace,
      { injections, getState, getStoreState, getStoreActions }
    ) => {
      const localState = getState();
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const ignored_features = (
        localState.ignored_features[workspace] || []
      ).filter(c => c);
      const forced_features = (
        localState.forced_features[workspace] || []
      ).filter(c => c);
      const filters = (localState.filters[workspace] || []).filter(c => c);

      let errors: { [key: string]: string } = {};

      const recipe =
        globalState.predictive_model_listing.workspaces[workspace].recipe;

      const mandatory_model_params = recipe.model_params.filter(
        p => p.iterable && p.mandatory
      );
      const w_model_params = localState.model_params[workspace] || {};
      const missing_model_params = mandatory_model_params
        .filter(
          p =>
            w_model_params[p.key] === undefined ||
            w_model_params[p.key] === null
        )
        .map(p => p.name);
      if (missing_model_params.length > 0) {
        errors.model_params = `Mandatory Model Params misssing: [${missing_model_params.join(
          ", "
        )}]`;
      }

      let model_params: { [key: string]: any } = {};
      Object.keys(w_model_params).forEach(k => {
        const v = w_model_params[k];
        if (v !== null && v !== undefined) {
          model_params[k] = v;
        }
      });

      const algorithms = localState.algorithms[workspace] || [];
      if (recipe.algorithm_choices.length > 0 && algorithms.length === 0) {
        errors.algorithms = "No algorithms selected!!!";
      }

      if (Object.keys(errors).length > 0) {
        actions.setSubmitError(errors);
        return;
      }

      const w_hyper_params = localState.hyper_params[workspace] || {};
      let hyper_params: { [key: string]: any } = {};
      Object.keys(w_hyper_params).forEach(k => {
        const v = w_hyper_params[k];
        if (v !== null && v !== undefined) {
          hyper_params[k] = v;
        }
      });

      if (
        ignored_features.length === 0 &&
        forced_features.length === 0 &&
        filters.length === 0 &&
        Object.keys(model_params).length === 0 &&
        Object.keys(hyper_params).length === 0
      ) {
        actions.setSubmitError({
          ignored_features: "No columns ignored!!!",
          forced_features: "No columns forced !!!",
          filters: "No filters set!!!",
          model_params: "No Model Params Set!!!",
          hyper_params: "No Hyper Params Set!!!"
        });
        return;
      }

      await actions.updateTaskStatus({
        status: { completed: false, error: false }
      });

      const spec = {
        ignored_features,
        forced_features,
        filters,
        model_params,
        hyper_params,
        algorithms: localState.getAlgorithms(workspace)
      };

      await getStoreActions().api_client.fetch({
        url: `${injections.urls.predictive_models}/workspaces/${workspace}/iterate`,
        method: "post",
        data: spec,
        onComplete: async ({ success, data, error }) => {
          if (success) {
            if (data.request_id) {
              // Fetch Task Status
              actions.fetchTaskStatus(data.request_id);
            } else {
              actions.updateTaskStatus({
                status: data.compute_status,
                result:
                  data.data && Array.isArray(data.data) ? data.data : undefined
              });
            }
          } else if (error === "Login Failed") {
            actions.updateTaskStatus({
              status: { error: true, error_message: error }
            });
          } else {
            await delay(5000);
            actions.create(workspace);
          }
        }
      });
    }
  ),

  fetchTaskStatus: thunk(
    async (actions, task_id, { getStoreActions, injections }) => {
      const url = `${injections.urls.task_status}/${task_id}`;
      await getStoreActions().api_client.fetch({
        url: url,
        method: "GET",
        onComplete: async ({ success, error, data }) => {
          if (!success) {
            if (error === "Login Failed") {
              await delay(5000);
              actions.fetchTaskStatus(task_id);
            } else {
              actions.updateTaskStatus({
                status: { error: true, error_message: error }
              });
            }
            return;
          }

          if (data.status === "FAILURE") {
            actions.updateTaskStatus({
              status: { error: true, error_message: "Unable to compute..." }
            });
          } else if (data.status === "SUCCESS") {
            if (data.result.error) {
              actions.updateTaskStatus({
                status: {
                  error: true,
                  error_message:
                    data.result.error_message || data.result.message
                }
              });
            } else if (data.result.completed === true) {
              actions.updateTaskStatus({
                status: { error: false, completed: true },
                result: data.result.data
              });
            }
          } else {
            await delay(2000);
            actions.fetchTaskStatus(task_id);
          }
        }
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getIgnoredFeatures: computed(state => workspace =>
    state.ignored_features[workspace]
  ),

  getForcedFeatures: computed(state => workspace =>
    state.forced_features[workspace]
  ),

  getFilters: computed(state => workspace => state.filters[workspace]),

  getModelParams: computed(state => workspace => state.model_params[workspace]),

  getHyperParams: computed(state => workspace => state.hyper_params[workspace]),

  getAlgorithms: computed(state => workspace =>
    state.algorithms[workspace] || []
  ),

  getComputeStatus: computed(state => () => {
    return state.task_status;
  })
};

export default model;
