import { SingleGroupItemSet } from "./types";

import generatePatterns from "./generate-listing";

export default (
  data: SingleGroupItemSet[],
  transaction_column: string,
  item_column: string,
  grouping_columns?: string[]
) => {
  const patterns = generatePatterns(data);
  let csvContent = "";

  const headers =
    [
      `Pattern (${item_column})`,
      ...(grouping_columns || []),
      `${transaction_column} Count`,
      "Pattern Length"
    ].join(",") + "\r\n";

  csvContent += headers;
  patterns.forEach(p => {
    csvContent +=
      [p.itemSet.join("__$$__"), ...(p.grouping || []), p.count, p.l].join(
        ","
      ) + "\r\n";
  });

  const blob = new Blob([csvContent], { type: "text/csv" });
  const href = window.URL.createObjectURL(blob);
  window.location.assign(href);
};
