import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { useFormContext } from "react-hook-form";

type Value = string | number;

type OptionType<T extends Value> = { value: T; label: string };

const PLACEHOLDER_VALUE = "__NONE__@@$$@@__NONE__123";

interface PropsInterface<T extends Value> {
  options: OptionType<T>[];
  label: React.ReactNode;
  /** MANDATORY if inside a form. */
  name?: string;
  /** Selected Value */
  value?: T;
  /** Icon to place along with the field. If passed, the icon is on the left. */
  icon?: IconProp;
  required?: boolean;
  placeholder?: string;
  error?: string;
  help?: React.ReactNode;
  onValueChange?: (value: T | null) => any;
  tags?: React.ReactNode;
  id?: string;
}

function SelectField<T extends Value>({
  label,
  name = "select-field",
  options,
  value,
  icon,
  required,
  placeholder = "Select a Value",
  error,
  help,
  onValueChange = () => {},
  tags,
  id,
}: PropsInterface<T>): React.ReactElement {
  const { register, watch, control, errors, setValue } = useFormContext() || {};

  useEffect(() => {
    if (control) {
      if (value !== watch(name) && watch(name) !== placeholder) {
        setValue(name, value);
      }
    }
  });

  if (control) {
    value = value || watch(name);
    if (required) {
      register(name, {
        validate: {
          required: (value) =>
            (value !== PLACEHOLDER_VALUE &&
              value !== undefined &&
              value !== null &&
              value !== "") ||
            "Required",
        },
      });
    }
    // @ts-ignore
    error = errors[name]?.message || error;
  }

  let className = "control";
  if (icon) {
    className += " has-icons-left";
  }

  return (
    <div className="field" id={id}>
      <label className="label">
        {label} {required && <span className="tag is-primary">MANDATORY</span>}
        {tags && tags}
      </label>
      <div className={className}>
        {icon && (
          <span className="icon is-small is-left has-text-info">
            <FontAwesomeIcon icon={icon} className="fas" />
          </span>
        )}
        <div className={`select is-fullwidth${error ? " is-danger" : ""}`}>
          <select
            ref={register}
            name={name}
            required={required}
            value={
              value === undefined || value === null ? PLACEHOLDER_VALUE : value
            }
            onChange={({ target }) => {
              const value = target.value;
              if (
                target.value === undefined ||
                target.value === PLACEHOLDER_VALUE
              ) {
                onValueChange(null);
                if (control) {
                  setValue(name, null);
                }
              } else {
                const option = options.find((opt) => opt.value === value);
                const s = option?.value === undefined ? null : option?.value;
                onValueChange(s);
                if (control) {
                  setValue(name, s);
                }
              }
            }}
          >
            <option key={PLACEHOLDER_VALUE}>{placeholder}</option>
            {options.map((opt) => (
              <option key={opt.value.toString()} value={opt.value}>
                {opt.label}
              </option>
            ))}
            placeholder={placeholder}
          </select>
        </div>
        {help && <div className="help">{help}</div>}
        {error && <p className={"help is-danger"}>{error}</p>}
      </div>
    </div>
  );
}

export default SelectField;
