import { action, thunk, computed, actionOn } from "easy-peasy";
import { UserDefinedTypes } from "../types";

import StateInterface, { Spec, StringifySpec } from "./types";

const stringifySpec: StringifySpec = spec => JSON.stringify(spec);

const model: StateInterface = {
  params: { source_data: [], indexers: {} },
  taskStatus: {},
  result: null,

  // Store-Updaters
  // For e.g., an action that has params as a parameter type.
  // update_hygiene: action((state, payload) => {}),

  setParams: action((state, params) => {
    state.params = { ...state.params, ...params };
  }),

  setIndexers: action((state, { file, indexers }) => {
    if (!state.params.indexers) {
      state.params.indexers = {};
    }
    state.params.indexers[file] = indexers;
  }),

  resetParams: action(state => {
    state.params = {};
    state.taskStatus = {};
    state.result = null;
  }),

  setResult: action((state, result) => {
    state.result = result;
  }),

  resetTaskStatus: action(state => {
    state.taskStatus = {};
  }),

  // Update Store
  updateTaskStatus: action((state, { spec, status, result }) => {
    state.taskStatus[stringifySpec(spec)] = status;
    if (result) {
      state.result = result;
    }
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.params = {};
      state.taskStatus = {};
      state.result = null;
    }
  ),

  // Create Request
  create: thunk(
    async (actions, _, { injections, getState, getStoreActions }) => {
      // Get Spec.
      const spec = getState().spec;

      // Spec is null: There was an error.
      if (!spec) {
        return;
      }

      // Return immediately if task already requested once.
      if (getState().taskStatus[stringifySpec(spec)]) {
        return;
      }

      const url = injections.urls.create_data_join;
      await getStoreActions().data_file_create_status.createFile({
        url,
        spec,
        // @ts-ignore
        updateTaskStatus: actions.updateTaskStatus
      });
    }
  ),

  // Selectors
  // For e.g.:
  // get_data_quality_for_file: select(state => file_id => state.resources[file_id])
  spec: computed(
    [
      state => state.params,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (params, settings) => {
      const { source_data, name, indexers } = params;
      if (!name) {
        return null;
      }
      if (!source_data || source_data.length === 0) {
        return null;
      }

      let column_types: { [source: string]: UserDefinedTypes } = {};
      let columns_to_drop: { [source: string]: string[] } = {};
      source_data.forEach(f => {
        column_types[f] = settings.getTypesForFile(f);
        columns_to_drop[f] = settings.getDroppedColumnsForFile(f);
      });

      const spec: Spec = {
        name,
        source_data,
        indexers: indexers || {},
        column_types,
        columns_to_drop
      };

      return spec;
    }
  ),

  getComputeStatus: computed(state => () => {
    const spec = state.spec;
    if (!spec) {
      return;
    }
    return state.taskStatus[stringifySpec(spec)];
  })
};

export default model;
