import { UserDefinedTypes } from "../types";
import { OutlierTreatment, MissingTreatment } from "./prepare-spec";

import { Spec } from "./types";

const validateOutlierTreatment = (
  outlier_treatment: OutlierTreatment[],
  column_types: UserDefinedTypes
) => {
  let errors: { [col: string]: { [field: string]: string } } = {};

  outlier_treatment.forEach(
    ({ column, treatment, range, replacement, floor, ceiling }) => {
      if (treatment === "ignore") {
        return;
      }
      if (!Array.isArray(range)) {
        errors[column] = {
          range: "MUST enter range when treatment is NOT IGNORE"
        };
        return;
      }
      range = range.filter(r => r !== undefined && r !== null);
      const colType = column_types[column];
      if (colType === "numeric") {
        range = range.filter(r => !isNaN(parseFloat(r.toString())));
      } else if (colType === "date") {
        range = range.filter(
          r => new Date(r) !== null && new Date(r) !== undefined
        );
      }
      if (colType === "char") {
        if (range.length <= 0) {
          errors[column] = {
            range: "MUST enter range when treatment is NOT IGNORE"
          };
          return;
        }
      } else if (range.length !== 2) {
        errors[column] = {
          range: "MUST enter range when treatment is NOT IGNORE"
        };
        return;
      }

      if (colType === "char" && treatment === "clamp" && !replacement) {
        errors[column] = { replacement: "MUST be a valid string!!!" };
        return;
      }
      if (colType === "numeric" && treatment === "clamp") {
        if (
          floor === null &&
          floor === undefined &&
          ceiling === null &&
          ceiling === undefined
        ) {
          errors[column] = {
            floor:
              "At least ONE of floor and ceiling MUST be a valid number!!!",
            ceiling:
              "At least ONE of floor and ceiling MUST be a valid number!!!"
          };
          return;
        }
        if (
          isNaN(parseFloat((floor || "").toString())) &&
          isNaN(parseFloat((ceiling || "").toString()))
        ) {
          errors[column] = {
            floor:
              "At least ONE of floor and ceiling MUST be a valid number!!!",
            ceiling:
              "At least ONE of floor and ceiling MUST be a valid number!!!"
          };
          return;
        }
      } else if (colType === "date" && treatment === "clamp") {
        if (
          floor === null &&
          floor === undefined &&
          ceiling === null &&
          ceiling === undefined
        ) {
          errors[column] = {
            floor: "At least ONE of floor and ceiling MUST be a valid date!!!",
            ceiling: "At least ONE of floor and ceiling MUST be a valid date!!!"
          };
          return;
        }
        if (
          !(
            new Date(parseFloat((floor || "").toString())) ||
            new Date(parseFloat((ceiling || "").toString()))
          )
        ) {
          errors[column] = {
            floor: "At least ONE of floor and ceiling MUST be a valid date!!!",
            ceiling: "At least ONE of floor and ceiling MUST be a valid date!!!"
          };
          return;
        }
      }
    }
  );

  return errors;
};

const validateMissingTreatment = (
  missing_treatment: MissingTreatment[],
  column_types: UserDefinedTypes,
  columns_to_drop: string[]
) => {
  let errors: { [col: string]: { [field: string]: string } } = {};

  missing_treatment
    .filter(t => !columns_to_drop.includes(t.column))
    .forEach(t => {
      const { column, treatment, value } = t;
      if (treatment === "ignore" || treatment === "drop-rows") {
        return;
      }
      const colType = column_types[column];
      if (treatment === "fill-value") {
        if (value === null || value === undefined) {
          errors[column] = {
            value: "Value is MANDATORY when treatment is fill-value!!!"
          };
          return;
        }
        if (colType === "char") {
          if (!value) {
            errors[column] = { value: "Value MUST be a VALID string!!!" };
            return;
          }
        } else if (colType === "numeric") {
          if (isNaN(parseFloat(value.toString()))) {
            errors[column] = { value: "Value MUST be a VALID number!!!" };
            return;
          }
        } else if (colType === "date") {
          if (new Date(value) === undefined || new Date(value) === null) {
            errors[column] = { value: "Value MUST be a VALID date!!!" };
            return;
          }
        }
        return;
      }
      if (treatment === "substitute") {
        if (t["substitute-params"] === undefined) {
          errors[column] = {
            substitute: "Substitute column MUST be set!!!",
            "substitute-treatment": "MUST be set!!!"
          };
          return;
        }
        const { substitute, treatment } = t["substitute-params"];
        if (!substitute) {
          errors[column] = { substitute: "Substitute column MUST be set!!!" };
          return;
        }
        if (!treatment) {
          errors[column] = { "substitute-treatment": "MUST be set!!!" };
          return;
        }
        const option = treatment.treatment;
        if (!option) {
          errors[column] = { "substitute-treatment": "MUST be set!!!" };
          return;
        }
        if (
          option === "fill-value" &&
          (treatment.value === null || treatment.value === undefined)
        ) {
          errors[column] = { "substitute-fill": "MUST be set!!!" };
        }
      }
    });

  return errors;
};

const validateSpec = (spec: Spec) => {
  const {
    outlier_treatment,
    missing_treatment,
    column_types,
    columns_to_drop
  } = spec;

  return {
    outlierErrors: validateOutlierTreatment(outlier_treatment, column_types),
    missingErrors: validateMissingTreatment(
      missing_treatment,
      column_types,
      columns_to_drop
    )
  };
};

export default validateSpec;
