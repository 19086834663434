import React from "react";

import InputField from "../Input-Field";
import MultiSelect from "../Multi-Select";

interface Props {
  item_column: string;
  ante_items: string[];
  anteDisplayItems: string[];
  setAnteDisplayItems: (items: string[]) => any;
  minAnteLength: number;
  setMinAnteLength: (minLength: number) => any;
  cons_items: string[];
  consDisplayItems: string[];
  setConsDisplayItems: (items: string[]) => any;
  minConsLength: number;
  setMinConsLength: (minLength: number) => any;
}

const Filters: React.FC<Props> = props => {
  const {
    item_column,
    ante_items,
    anteDisplayItems,
    setAnteDisplayItems,
    minAnteLength,
    setMinAnteLength,
    cons_items,
    consDisplayItems,
    minConsLength,
    setMinConsLength,
    setConsDisplayItems
  } = props;
  return (
    <div className="columns">
      <div className="column is-half-desktop">
        <InputField
          type="number"
          label="Min Pattern Length for ANTECEDENT"
          onValueChange={setMinAnteLength}
          value={minAnteLength}
        />
        <MultiSelect
          label={`${item_column} to show in ANTECEDENT`}
          options={ante_items.map(value => ({ value, label: value }))}
          selected={anteDisplayItems}
          onValueChange={setAnteDisplayItems}
          enableSelectAll={true}
          showSelected={true}
          showExcluded={true}
        />
      </div>
      <div className="column is-half-desktop">
        <InputField
          type="number"
          label="Min Pattern Length for CONSEQUENT"
          onValueChange={setMinConsLength}
          value={minConsLength}
        />
        <MultiSelect
          label={`${item_column} to show in CONSEQUENT`}
          options={cons_items.map(value => ({ value, label: value }))}
          selected={consDisplayItems}
          onValueChange={setConsDisplayItems}
          enableSelectAll={true}
          showSelected={true}
          showExcluded={true}
        />
      </div>
    </div>
  );
};

export default Filters;
