import { useEffect } from "react";
import { createTypedHooks } from "easy-peasy";

import { StoreModel } from "./types";

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export const useCrumbs = (link: string, text: string) => {
  const push = useStoreActions(store => store.crumbs.push_crumb);
  const pop = useStoreActions(store => store.crumbs.pop_crumb);

  useEffect(() => {
    push({ link, text });
    return () => pop();
  }, [push, pop, link, text]);
};
