import React, { Fragment } from "react";

import { Scale, Margin } from "../../types";

import get_boundaries from "./boundaries";

const Limit = ({
  type,
  x,
  y,
  width,
  height,
  updateDragStatus,
  changeZoom
}: {
  type: "vertical" | "horizontal";
  x: number;
  y: number;
  width: number;
  height: number;
  updateDragStatus(status: boolean): any;
  changeZoom(d: React.MouseEvent): any;
}) => (
  <rect
    cursor={type === "vertical" ? "ns-resize" : "ew-resize"}
    className="drag"
    x={x}
    y={y}
    width={width}
    height={height}
    fill="#777"
    fillOpacity={1}
    onMouseDown={() => updateDragStatus(true)}
    onMouseUp={() => updateDragStatus(false)}
    onMouseMove={changeZoom}
  />
);

interface LimitLinesProps {
  scale: Scale;
  domain?: (string | number | Date)[];
  height: number;
  width: number;
  margin: Margin;
  updateTopDragStatus(status: boolean): any;
  updateBottomDragStatus(status: boolean): any;
  updateLeftDragStatus(status: boolean): any;
  updateRightDragStatus(status: boolean): any;
  changeZoom(d: React.MouseEvent): void;
}

const VerticalLimits = (props: LimitLinesProps) => {
  const [y0, y1] = get_boundaries(props.scale, props.domain);

  const { height, width, margin } = props;
  const h = height - (margin.top + margin.bottom);
  const w = width - (margin.left + margin.right);

  const { updateTopDragStatus, updateBottomDragStatus } = props;

  const keys = [
    { key: "bottomDrag", value: y0, func: updateBottomDragStatus },
    { key: "topDrag", value: y1, func: updateTopDragStatus }
  ];

  return (
    <Fragment>
      {keys.map(({ key, value, func }) => (
        <Limit
          key={key}
          type="vertical"
          x={0}
          y={h - value - 1}
          width={w}
          height={2}
          updateDragStatus={func}
          changeZoom={props.changeZoom}
        />
      ))}
    </Fragment>
  );
};

const HorizontalLimits = (props: LimitLinesProps) => {
  const [x0, x1] = get_boundaries(props.scale, props.domain);

  const { height, margin } = props;
  const h = height - (margin.top + margin.bottom);

  const { updateLeftDragStatus, updateRightDragStatus } = props;

  const keys = [
    { key: "leftDrag", value: x0, func: updateLeftDragStatus },
    { key: "rightDrag", value: x1, func: updateRightDragStatus }
  ];

  return (
    <Fragment>
      {keys.map(({ key, value, func }) => (
        <Limit
          key={key}
          type="horizontal"
          x={value - 1}
          y={0}
          width={2}
          height={h}
          updateDragStatus={func}
          changeZoom={props.changeZoom}
        />
      ))}
    </Fragment>
  );
};

interface FinalProps extends LimitLinesProps {
  type: "horizontal" | "vertical";
}

export default (props: FinalProps) => {
  const { type, ...rest } = props;
  return type === "horizontal" ? (
    <HorizontalLimits {...rest} />
  ) : (
    <VerticalLimits {...rest} />
  );
};
