import { SingleRule } from "./types";

export default (data: SingleRule[]) => {
  let ante_items: string[] = [];
  let cons_items: string[] = [];

  data.forEach(r => {
    ante_items.push(...r["antecedent"]);
    cons_items.push(...r["consequent"]);
  });

  return {
    ante_items: Array.from(new Set(ante_items)),
    cons_items: Array.from(new Set(cons_items))
  };
};
