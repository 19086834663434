import { thunk, computed } from "easy-peasy";

import StateInterface, { Spec, ColumnStatsInterface } from "./types";

const stringifySpec = (spec: Spec): string => JSON.stringify(spec);

// Change key
const INSIGHT_TYPE = "data-file-column-profiles";

const model: StateInterface = {
  // Create Request
  create: thunk(
    async (
      _,
      payload,
      { injections, getState, getStoreActions, getStoreState }
    ) => {
      const state = getState();
      let { file_id, column, col_type } = payload;

      if (!col_type) {
        col_type = getStoreState().data_files_user_settings.getTypesForFile(
          file_id
        )[column];
        payload.col_type = col_type;
      }

      if (state.resource(file_id, column, col_type)) {
        return;
      }

      const { urls } = injections;

      const spec_string = stringifySpec(payload);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_column_profile}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec: payload,
        spec_string
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])

  resource: computed(
    [
      (_, storeState) => storeState.data_file_insights.getInsight,
      (_, storeState) => storeState.data_files_user_settings.getTypesForFile
    ],
    (getInsight, getTypes) => (file_id, column, col_type) => {
      if (!col_type) {
        col_type = getTypes(file_id)[column];
      }
      const spec_string = stringifySpec({ file_id, column, col_type });
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getData: computed(state => (file_id, column) =>
    state.resource(file_id, column)?.data
  ),

  getComputeStatus: computed(state => (file_id, column) =>
    state.resource(file_id, column)?.compute_status
  ),

  getStatsForFile: computed(
    [
      state => state.getData,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (getProfiles, userSettings) => file_id => {
      const types = userSettings.getTypesForFile(file_id);
      let stats: { [col: string]: ColumnStatsInterface } = {};
      Object.keys(types).forEach(column => {
        const profile = getProfiles(file_id, column);
        if (profile !== null && profile !== undefined) {
          stats[column] = profile.stats;
        }
      });

      return stats;
    }
  )
};

export default model;
