import React from "react";

import { Margin, VarType, Fill, Scale } from "Visualizations/types";
import { StackedBars } from "Visualizations/components";

import { GridCell } from "./types";

const getTransform = (
  g: string | number | Date | undefined,
  chartType: "vertical" | "horizontal",
  groupScale: (g: any) => number,
  margin: Margin
): string => {
  if (chartType === "vertical") {
    return `translate(${groupScale(g) + margin.left}, 0)`;
  } else {
    return `translate(0, ${groupScale(g) + margin.top})`;
  }
};

interface StackProps {
  data: GridCell[];
  g: string | undefined;
  chartType: "vertical" | "horizontal";
  axisVariableType: VarType;
  stackVariableType: VarType;
  axisDomain: (string | number | Date)[];
  stackDomain: (string | number | Date)[];
  valueDomain: [number, number];
  fillColor?: Fill<GridCell>;

  onClick: (p: GridCell, e: React.MouseEvent) => any;
  onHover: (p: GridCell, e: React.MouseEvent) => any;
  offHover: (p: GridCell, e: React.MouseEvent) => any;

  width: number;
  height: number;
  margin: Margin;
  innerMargin: Margin;
  groupScale: Scale;
}

const Stacks: React.FC<StackProps> = ({
  data,
  g,
  chartType,
  stackVariableType,
  axisVariableType,
  axisDomain,
  stackDomain,
  valueDomain,
  fillColor,
  onClick,
  onHover,
  offHover,
  width,
  height,
  margin,
  innerMargin,
  groupScale
}) => {
  if (!groupScale.bandwidth) {
    return null;
  }
  const filteredData = data.filter(d =>
    d.group === undefined || d.group === null
      ? g === undefined || g === null
      : d.group.toString() === g
  );
  return (
    <g
      key={g === undefined ? "UNDE" : g.toString()}
      transform={getTransform(g, chartType, groupScale, margin)}
    >
      <StackedBars
        data={filteredData}
        barType={chartType}
        stackVariableType={stackVariableType}
        axisVariableType={axisVariableType}
        axisDomain={axisDomain}
        stackDomain={stackDomain}
        valueAxisDomain={valueDomain}
        colorFill={fillColor}
        onClick={onClick}
        onMouseOver={onHover}
        onMouseOut={offHover}
        height={chartType === "vertical" ? height : groupScale.bandwidth()}
        width={chartType === "horizontal" ? width : groupScale.bandwidth()}
        margin={innerMargin}
      />
    </g>
  );
};

export default Stacks;
