import { API_BASE_URL, AUTH_API_URL } from "App/constants";

export default {
  login: `${AUTH_API_URL}/jwt/create/`,
  refresh: `${AUTH_API_URL}/jwt/refresh/`,
  logout: `${API_BASE_URL}/logout/`,

  create_jwt: `${AUTH_API_URL}/jwt/create/`,
  refresh_jwt: `${AUTH_API_URL}/jwt/refresh/`,
  destroy_jwt: `${AUTH_API_URL}/jwt/destroy/`,

  me: `${API_BASE_URL}/me`,

  change_password: `${AUTH_API_URL}/auth/password/`,

  task_status: `${API_BASE_URL}/task-status`,
  data_task_status: `${API_BASE_URL}/task-status`,

  downloads: `${API_BASE_URL}/exports`,

  data: `${API_BASE_URL}/data`,
  data_hygiene: "data-hygiene",
  data_sample: "data_file_sample",
  data_column_types: "column-types",
  data_column_profile: "column-profile",
  data_compare_columns: "compare-columns",
  data_relationship_matrix: "relationship_matrix",
  data_time_series_distributions: "time_series_distributions",
  data_time_series_change_points: "time_series_change_points",
  data_time_series_anomalies: "time_series_anomalies",
  data_id_profiles: "id_profiles",
  data_export_id_profiles: "export_tagged_id_profiles",
  data_document_topics: "document_topics",
  data_export_document_topics: "export_tagged_document_topics",
  data_frequent_itemsets: "frequent_itemsets",
  data_association_rules: "association_rules",
  data_group_differences_summary: "group_differences_summary",
  data_group_differences_column_details: "group_differences_column_details",
  data_grouped_distributions: "grouped_distributions",

  upload_data: `${API_BASE_URL}/data/upload-file`,

  create_data_clean: `${API_BASE_URL}/data/clean-data`,
  create_data_augment: `${API_BASE_URL}/data/augment-data`,

  create_data_filter: `${API_BASE_URL}/data/filter-data`,
  create_data_aggregates: `${API_BASE_URL}/data/grouped-aggregates`,
  create_data_time_series: `${API_BASE_URL}/data/time-series`,

  create_data_join: `${API_BASE_URL}/data/join-data`,
  create_data_stack: `${API_BASE_URL}/data/stack-data`,

  recipes: `${API_BASE_URL}/recipes`,
  create_new_model: `${API_BASE_URL}/predictive-models/models`,
  model_details: `${API_BASE_URL}/predictive-models/models`,
  predictive_models: `${API_BASE_URL}/predictive-models`,
};
