import { VarType } from "Visualizations/types";
import { isCategorical } from "Visualizations/utils";

import { GridCell } from "./types";

const getDomains = (
  data: GridCell[],
  axisVariableType: VarType,
  stackVariableType: VarType,
  axisDomain?: (string | number | Date)[],
  stackDomain?: (string | number | Date)[]
): [(string | number | Date)[], (string | number | Date)[]] => {
  if (axisDomain && stackDomain) {
    return [axisDomain, stackDomain];
  }

  if (!axisDomain) {
    if (isCategorical(axisVariableType)) {
      const levelKey = isCategorical(stackVariableType) ? "x" : "level";

      // TS not picking up undefined being filtered
      // @ts-ignore
      axisDomain = Array.from(new Set(data.map(d => d[levelKey]))).filter(
        d => d !== undefined
      );
    } else {
      let leftEdgeKey: string;
      let rightEdgeKey: string;
      if (isCategorical(stackVariableType)) {
        leftEdgeKey = "leftEdge";
        rightEdgeKey = "rightEdge";
      } else {
        leftEdgeKey = "xLeftEdge";
        rightEdgeKey = "xRightEdge";
      }
      const min = Math.min(...data.map(d => d[leftEdgeKey]));
      const max = Math.max(...data.map(d => d[rightEdgeKey]));
      axisDomain = [min, max];
    }
  }

  if (!stackDomain) {
    if (isCategorical(stackVariableType)) {
      const levelKey = isCategorical(axisVariableType) ? "y" : "level";
      // TS not picking up undefined being filtered
      // @ts-ignore
      stackDomain = Array.from(new Set(data.map(d => d[levelKey]))).filter(
        d => d !== undefined
      );
    } else {
      let leftEdgeKey: string;
      let rightEdgeKey: string;
      if (isCategorical(axisVariableType)) {
        leftEdgeKey = "leftEdge";
        rightEdgeKey = "rightEdge";
      } else {
        leftEdgeKey = "yLeftEdge";
        rightEdgeKey = "yRightEdge";
      }
      const min = Math.min(...data.map(d => d[leftEdgeKey]));
      const max = Math.max(...data.map(d => d[rightEdgeKey]));
      stackDomain = [min, max];
    }
  }
  return [axisDomain || [], stackDomain || []];
};

export default getDomains;
