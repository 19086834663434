import {
  MissingTreatmentOptions,
  OutlierTreatmentOptions
} from "./prepare-spec";

interface Stats {
  count: number;
  size: number;
  "outlier-ranges": (number | string | Date)[];
  "default-values": number | string | Date;
  "unique-count": number;
  min?: number | string;
  max?: number | string;
}

export const getInitMissingTreatment = (
  colType: "char" | "numeric" | "date",
  stats: Stats
): { option: MissingTreatmentOptions; value?: number | string | Date } => {
  if (stats.count === stats.size) {
    return { option: "ignore" };
  }
  if (stats.count / stats.size <= 0.01) {
    return { option: "drop-rows" };
  }

  return {
    option: "fill-value",
    value:
      colType === "char"
        ? "UNKNOWN/MISSING"
        : colType === "numeric"
        ? stats["default-values"]
        : new Date(stats["default-values"])
  };
};

export const getInitOutlierTreatment = (
  colType: "char" | "numeric" | "date",
  stats: Stats
): { option: OutlierTreatmentOptions; range: (number | string | Date)[] } => {
  const range =
    colType === "char"
      ? (stats["outlier-ranges"] || []).map(v => v.toString())
      : colType === "date"
      ? (stats["outlier-ranges"] || []).map(v => new Date(v))
      : stats["outlier-ranges"] || [];

  if (stats["unique-count"] <= 1 || range.length === 0) {
    return { option: "ignore", range };
  }

  // CHECK IF OUTLIER-RANGES ARE INSIDE MIN AND MAX.
  if (colType === "numeric") {
    const { min, max } = stats;
    if (min !== undefined && max !== undefined) {
      const range = stats["outlier-ranges"];
      if (range.length === 2) {
        if (min >= range[0] && max <= range[1]) {
          return { option: "ignore", range };
        }
      }
    }
  } else if (colType === "char") {
    const range = stats["outlier-ranges"];
    if (!Array.isArray(range)) {
      return { option: "ignore", range };
    }
    if (range.length === 0) {
      return { option: "ignore", range };
    }
  } else if (colType === "date") {
    const { min, max } = stats;
    if (min !== undefined && max !== undefined) {
      // @ts-ignore
      const range = stats["outlier-ranges"].map(d => Date.parse(d));
      if (range.length === 2) {
        // @ts-ignore
        if (Date.parse(min) >= range[0] && Date.parse(max) <= range[1]) {
          return { option: "ignore", range };
        }
      }
    }
  }

  return { option: "drop", range };
};

export const getInitDropStatus = (stats: Stats) => {
  if (stats.count <= 1) {
    return "drop";
  }
  if (stats["unique-count"] <= 1) {
    return "drop";
  }
  return "keep";
};
