import { VarType } from "Visualizations/types";

import { isCategorical } from "Visualizations/utils";

import { GridCell } from "./types";

const getGroups = (
  data: GridCell[],
  axisVariableType: VarType,
  stackVariableType: VarType,
  groupType: "axis" | "stack" = "axis"
): [(string | number | Date)[], string] => {
  let key: string;
  if (isCategorical(axisVariableType)) {
    if (isCategorical(stackVariableType)) {
      key = groupType === "axis" ? "x" : "y";
    } else {
      key = groupType === "axis" ? "level" : "leftEdge";
    }
  } else {
    if (isCategorical(stackVariableType)) {
      key = groupType === "axis" ? "leftEdge" : "level";
    } else {
      key = groupType === "axis" ? "xLeftEdge" : "yLeftEdge";
    }
  }

  const dType = groupType === "axis" ? axisVariableType : stackVariableType;
  let values = data.map(d => d[key]);

  if (dType !== "date" && dType !== "time") {
    return [Array.from(new Set(values)), key];
  } else {
    values = Array.from(new Set(values.map(d => d.toString()))).map(
      d => new Date(d)
    );
    return [values, key];
  }
};

export default getGroups;
