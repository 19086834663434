import React, { useState, useEffect } from "react";

import { UPGRADE_URL } from "App/constants";

interface NotFoundProps {
  redirect_element?: any;
  time_to_redirect?: number;
}

const RedirectToUpgradeURL: React.FC<NotFoundProps> = ({
  redirect_element = (
    <div className="has-text-centered">Your Subscription has Expired!!!</div>
  ),
  time_to_redirect = 5,
}) => {
  const [time_since_load, updateTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => incrementTimer(), 1000);

    return () => clearInterval(timer);
  });

  if (!UPGRADE_URL) {
    return null;
  }

  const incrementTimer = () => updateTime(time_since_load + 1);

  if (time_since_load >= time_to_redirect) {
    window.location.href = UPGRADE_URL;
    return null;
  }

  return (
    <div className="container is-fluid">
      <div className="section">
        {redirect_element}
        <hr />
        <p className="has-text-centered">
          Redirecting in {time_to_redirect - time_since_load} seconds...
        </p>
      </div>
    </div>
  );
};

export default RedirectToUpgradeURL;
