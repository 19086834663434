import { action, thunk, computed } from "easy-peasy";

import StateInterface, { StringifySpec } from "./types";

const makeSpecString: StringifySpec = ({ model_id, type, params }) => {
  return [model_id, type, JSON.stringify(params || {})].join("__");
};

const model: StateInterface = {
  // State
  export_params: {},
  export_status: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})
  updateExportParams: action((state, payload) => {
    const { model_id, type, params } = payload;
    if (!state.export_params[model_id]) {
      state.export_params[model_id] = {};
    }
    const current_params = state.export_params[model_id][type] || {};
    state.export_params[model_id][type] = {
      ...current_params,
      ...params
    };
  }),

  updateExportStatus: action((state, payload) => {
    const { spec, status } = payload;
    state.export_status[makeSpecString(spec)] = status;
  }),

  // Reset on a Global reset signal

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  generateExport: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const { model_id, type, params } = payload;
      actions.updateExportParams(payload);

      const { urls } = injections;
      const url = `${urls.model_details}/${model_id}/exports`;

      await getStoreActions().api_client.fetch({
        url,
        method: "POST",
        data: { type, params },
        onComplete: ({ success, error }) => {
          if (success) {
            actions.updateExportStatus({
              spec: payload,
              status: { completed: true, error: false }
            });
          } else {
            actions.updateExportStatus({
              spec: payload,
              status: { completed: true, error: true, error_message: error }
            });
          }
        }
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getExportStatus: computed([state => state.export_status], status => spec => {
    return status[makeSpecString(spec)];
  }),

  getExportParams: computed(
    [state => state.export_params],
    params => (model_id, type) => {
      if (!params[model_id]) {
        return {};
      }
      if (!params[model_id][type]) {
        return {};
      }
      return params[model_id][type];
    }
  )
};

export default model;
