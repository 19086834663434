import React from "react";

import {
  ViewPort,
  InteractionProps,
  VarType,
  Fill
} from "Visualizations/types";

import { DEFAULT_MARGIN } from "Visualizations/default-style-settings";

import ToolTip, { useToolTip } from "Visualizations/components/Tool-Tip";
import { XAxis, YAxis } from "Visualizations/components";

import { getScale, getMargin } from "Visualizations/utils";

import getStackSizes from "Visualizations/components/Stacked-Bars/stack-sizes";
import filterData from "Visualizations/components/Stacked-Bars/filter-data";
import getDomains from "Visualizations/components/Stacked-Bars/domains";
import getGroups from "Visualizations/components/Stacked-Bars/groups";
import { StackedBars } from "Visualizations/components";

interface GridCell {
  value: number;

  x?: string | number;
  y?: string | number;

  leftEdge?: Date | number;
  rightEdge?: Date | number;
  level?: string | number;

  xLeftEdge?: Date | number;
  yLeftEdge?: Date | number;
  xRightEdge?: Date | number;
  yRightEdge?: Date | number;

  group?: string | number | Date;
  [key: string]: any;
}

interface PropsInterface extends ViewPort, InteractionProps<GridCell> {
  data: GridCell[];
  chartType?: "horizontal" | "vertical";
  axisVariableType?: VarType;
  stackVariableType?: VarType;
  axisDomain?: (string | number | Date)[];
  stackDomain?: (string | number | Date)[];
  valueDomain?: [number, number];
  fillColor?: Fill<GridCell>;
  showValueAxis?: boolean;
  showAxisAxis?: boolean;
  showValueAxisLabels?: boolean;
  showAxisAxisLabels?: boolean;
  showToolTip?: boolean;
  annotater?(d: any): React.ReactNode;
}

const DUMMY_FUNC = () => {};

const StackedBarChart: React.FunctionComponent<PropsInterface> = ({
  data,
  chartType = "vertical",
  axisVariableType = "numeric",
  stackVariableType = "char",
  axisDomain,
  stackDomain,
  valueDomain,
  showValueAxis = true,
  showAxisAxis = true,
  showValueAxisLabels = true,
  showAxisAxisLabels = true,
  fillColor,
  showToolTip = true,
  annotater = (d: GridCell) => JSON.stringify(d),
  onClick = DUMMY_FUNC,
  onMouseOver = DUMMY_FUNC,
  onMouseOut = DUMMY_FUNC,
  width,
  height,
  margin = DEFAULT_MARGIN
}) => {
  const new_margin = getMargin(margin, DEFAULT_MARGIN);
  data = filterData(
    data,
    axisVariableType,
    stackVariableType,
    axisDomain,
    stackDomain,
    valueDomain
  );

  const [hoverStatus, hoverD, onHover, offHover] = useToolTip(
    onMouseOver,
    onMouseOut
  );

  if (data.length === 0) {
    return <div> No data found!!!</div>;
  }

  if (!valueDomain) {
    const stackSizes = getStackSizes(data, axisVariableType, stackVariableType);
    valueDomain = [0, Math.max(...stackSizes)];
  }

  if (!axisDomain || !stackDomain) {
    [axisDomain, stackDomain] = getDomains(
      data,
      axisVariableType,
      stackVariableType,
      axisDomain,
      stackDomain
    );
  }

  if (!fillColor) {
    const [stackUniques] = getGroups(
      data,
      axisVariableType,
      stackVariableType,
      "stack"
    );
    // @ts-ignore
    fillColor = getScale({ scaleType: "color", domain: stackUniques });
  }

  const AxisAxis = chartType === "vertical" ? XAxis : YAxis;
  const ValueAxis = chartType === "vertical" ? YAxis : XAxis;

  return (
    <svg width={width} height={height}>
      <StackedBars
        data={data}
        barType={chartType}
        stackVariableType={stackVariableType}
        axisVariableType={axisVariableType}
        axisDomain={axisDomain}
        stackDomain={stackDomain}
        valueDomain={valueDomain}
        colorFill={fillColor}
        onClick={onClick}
        onMouseOver={onHover}
        onMouseOut={offHover}
        height={height}
        width={width}
        margin={new_margin}
      />
      {showToolTip && hoverStatus && (
        <ToolTip
          d={annotater(hoverD.d)}
          offsetX={hoverD.offsetX}
          offsetY={hoverD.offsetY}
          width={width}
          margin={new_margin}
          position={chartType === "horizontal" ? "vertical" : "horizontal"}
        />
      )}
      {showValueAxis && (
        <ValueAxis
          varType={"numeric"}
          scaleType="linear"
          domain={valueDomain}
          width={width}
          height={height}
          margin={new_margin}
          showText={showValueAxisLabels}
        />
      )}
      {showAxisAxis && (
        <AxisAxis
          varType={axisVariableType}
          scaleType="linear"
          domain={axisDomain}
          width={width}
          height={height}
          margin={new_margin}
          showText={showAxisAxisLabels}
        />
      )}
    </svg>
  );
};

export default StackedBarChart;
