const format_value = (
  value: any,
  varType:
    | "int"
    | "float"
    | "numeric"
    | "date"
    | "time"
    | "char"
    | ""
    | undefined
): string => {
  if (value === undefined || value === null) {
    return "";
  }

  switch (varType) {
    case "int":
    case "float":
    case "numeric":
      const num_value =
        varType === "int" ? parseInt(value, 10) : parseFloat(value);
      return isNaN(num_value) ? "" : num_value.toLocaleString();
    case "date":
    case "time":
      const d = new Date(value);
      return !isNaN(d.valueOf())
        ? `${d.toDateString()}, ${d.toLocaleTimeString()}`
        : "";
    case "":
    case undefined:
      const v = new Date(value);
      return !isNaN(v.valueOf())
        ? `${v.toDateString()}, ${v.toLocaleTimeString()}`
        : value.toLocaleString();
    default:
      return value.toLocaleString();
  }
};

export default format_value;
