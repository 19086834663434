import { action } from "easy-peasy";

import StateInterface from "./types";

const model: StateInterface = {
  count: 0,
  reset: action(state => {
    state.count += 1;
  })
};

export default model;
