import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec, StringifySpec } from "./types";

const stringifySpec: StringifySpec = ({
  source_data,
  name,
  grouping_columns,
  aggregators,
  sorting_column,
  filters,
  column_types
}) =>
  `${source_data}__${name}__${grouping_columns.join(
    "__$$__"
  )}__${JSON.stringify(aggregators)}__${sorting_column || ""}__${JSON.stringify(
    filters
  )}__${JSON.stringify(column_types)}`;

const model: StateInterface = {
  params: {},
  taskStatus: {},
  result: null,

  // Store-Updaters
  // For e.g., an action that has params as a parameter type.
  // update_hygiene: action((state, payload) => {}),

  setParams: action((state, { file_id, params }) => {
    if (file_id !== null) {
      state.params[file_id] = { ...(state.params[file_id] || {}), ...params };
    }
  }),

  resetParams: action(state => {
    state.params = {};
    state.taskStatus = {};
    state.result = null;
  }),

  setResult: action((state, result) => {
    state.result = result;
  }),

  resetTaskStatus: action(state => {
    state.taskStatus = {};
  }),

  // Update Store
  updateTaskStatus: action((state, { spec, status, result }) => {
    state.taskStatus[stringifySpec(spec)] = status;
    if (result) {
      state.result = result;
    }
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.params = {};
      state.taskStatus = {};
    }
  ),

  // Create Request
  create: thunk(
    async (actions, source_data, { injections, getState, getStoreActions }) => {
      // Get Spec.
      const spec = getState().getSpec(source_data);

      // Spec is null: There was an error.
      if (!spec) {
        return;
      }

      // Return immediately if task already requested once.
      if (getState().taskStatus[stringifySpec(spec)]) {
        return;
      }

      const url = injections.urls.create_data_aggregates;
      await getStoreActions().data_file_create_status.createFile({
        url,
        spec,
        // @ts-ignore
        updateTaskStatus: actions.updateTaskStatus
      });
    }
  ),

  // Selectors
  // For e.g.:
  // get_data_quality_for_file: select(state => file_id => state.resources[file_id])
  getSpec: computed(
    [
      state => state.params,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (params, settings) => source_data => {
      if (!source_data) {
        return null;
      }
      if (!params[source_data]) {
        return null;
      }
      const { name, aggregators, sorting_column } = params[source_data];
      if (!name || Object.keys(aggregators || {}).length === 0) {
        return null;
      }

      const grouping_columns = settings.getGroupingColumnsForFile(source_data);
      const column_types = settings.getTypesForFile(source_data);
      const filters = settings.getFiltersForFile(source_data);

      if (grouping_columns.length === 0) {
        return null;
      }

      let spec: Spec = {
        name,
        source_data,
        aggregators: aggregators || {},
        grouping_columns,
        column_types,
        filters
      };
      if (sorting_column) {
        spec.sorting_column = sorting_column;
      }

      return spec;
    }
  ),

  getComputeStatus: computed(state => source_data => {
    const spec = state.getSpec(source_data);
    if (!spec) {
      return undefined;
    }
    return state.taskStatus[stringifySpec(spec)];
  })
};

export default model;
