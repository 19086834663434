import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec, StringifySpec } from "./types";

const stringifySpec: StringifySpec = ({
  source_data,
  name,
  time_column,
  time_resolution,
  aggregators,
  zero_size_interval_treatment,
  grouping_columns,
  filters,
  column_types
}) =>
  `${source_data}__${name}__${time_column}__${time_resolution}__${JSON.stringify(
    aggregators
  )}__${zero_size_interval_treatment}__${grouping_columns.join(
    "__$$__"
  )}__${JSON.stringify(filters)}__${JSON.stringify(column_types)}`;

const model: StateInterface = {
  params: {},
  taskStatus: {},
  result: null,

  // Store-Updaters
  // For e.g., an action that has params as a parameter type.
  // update_hygiene: action((state, payload) => {}),

  setParams: action((state, { file_id, params }) => {
    if (file_id !== null) {
      state.params[file_id] = { ...(state.params[file_id] || {}), ...params };
    }
  }),

  resetParams: action(state => {
    state.params = {};
    state.taskStatus = {};
    state.result = null;
  }),

  setResult: action((state, result) => {
    state.result = result;
  }),

  resetTaskStatus: action(state => {
    state.taskStatus = {};
  }),

  // Update Store
  updateTaskStatus: action((state, { spec, status, result }) => {
    state.taskStatus[stringifySpec(spec)] = status;
    if (result) {
      state.result = result;
    }
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.params = {};
      state.taskStatus = {};
    }
  ),

  // Create Request
  create: thunk(
    async (actions, source_data, { injections, getState, getStoreActions }) => {
      // Get Spec.
      const spec = getState().getSpec(source_data);

      // Spec is null: There was an error.
      if (!spec) {
        return;
      }

      // Return immediately if task already requested once.
      if (getState().taskStatus[stringifySpec(spec)]) {
        return;
      }

      const url = injections.urls.create_data_time_series;
      await getStoreActions().data_file_create_status.createFile({
        url,
        spec: spec,
        // @ts-ignore
        updateTaskStatus: actions.updateTaskStatus
      });
    }
  ),

  // Selectors
  // For e.g.:
  // get_data_quality_for_file: select(state => file_id => state.resources[file_id])
  getSpec: computed(
    [
      state => state.params,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (params, settings) => source_data => {
      if (!params[source_data || ""]) {
        return null;
      }
      const { name, aggregators, zero_size_interval_treatment } =
        params[source_data || ""] || {};
      if (
        !source_data ||
        !name ||
        !aggregators ||
        !zero_size_interval_treatment
      ) {
        return null;
      }
      if (Object.keys(aggregators || {}).length === 0) {
        return null;
      }

      const timeSeriesParams = settings.getTimeSeriesParamsForFile(source_data);
      const { timeColumn, resolution } = timeSeriesParams;
      if (!timeColumn || !resolution) {
        return null;
      }

      const types = settings.getTypesForFile(source_data);
      const grouping_columns = settings.getGroupingColumnsForFile(source_data);
      const filters = settings.getFiltersForFile(source_data);

      const spec: Spec = {
        source_data,
        name,
        aggregators,
        zero_size_interval_treatment,
        time_column: timeColumn,
        time_resolution: resolution,
        column_types: types,
        grouping_columns,
        filters
      };

      return spec;
    }
  ),

  getComputeStatus: computed(state => source_data => {
    const spec = state.getSpec(source_data);
    if (!spec) {
      return undefined;
    }
    return state.taskStatus[stringifySpec(spec)];
  })
};

export default model;
