import { action, thunk, thunkOn, computed } from "easy-peasy";

import axios, { AxiosRequestConfig, AxiosError, AxiosPromise } from "axios";
import * as firebase from "firebase/app";

import { delay } from "utils";

import { IDENTITY_PROVIDER } from "store/env-constants";

import StateInterface from "./types";
import { getAuthToken, setAuthToken, removeToken } from "./auth-token";
import processResponse from "./process-response";
import { LOGIN_FAILURE_MESSAGES, INVALID_LICENSE_MESSAGES } from "./constants";

const getHost = (url: string): string => {
  try {
    const host = new URL(url).host;
    return host;
  } catch {
    return "";
  }
};

const model: StateInterface = {
  auth_provider: IDENTITY_PROVIDER === "firebase" ? "firebase" : "Daisho-JWT",
  requests_in_flight: [],
  last_429: {},

  initAuthProvider: thunk(async (actions, _, { getStoreActions, getState }) => {
    if (getState().auth_provider === "firebase") {
      firebase.auth().onAuthStateChanged(() => {
        getStoreActions().auth.fetchUserDetails();
      });
    }
  }),

  setAuthToken: thunk(async (_, token) => {
    await setAuthToken(token);
  }),

  refreshAuthToken: thunkOn(
    (actions, storeActions) => [
      actions.setAuthToken,
      storeActions.auth.updateUserDetails,
    ],
    async (actions, target, { injections, getState }) => {
      const auth_provider = getState().auth_provider;
      if (auth_provider !== "Daisho-JWT") {
        return;
      }
      const setAuthToken = target.resolvedTargets[0];
      if (target.type === setAuthToken) {
        await delay(8 * 3600 * 1000);
      }
      const url = injections.urls.refresh_jwt;

      const token = await getAuthToken(auth_provider);

      actions.fetch({
        url,
        method: "POST",
        data: { token },
        onComplete: ({ success, data }) => {
          if (success && data.token) {
            actions.setAuthToken(data.token);
          }
        },
      });
    }
  ),

  update_429: action((state, url) => {
    const host = getHost(url);
    state.last_429[host] = new Date();
  }),

  addRequest: action((state, { url, method, data }) => {
    if (data instanceof FormData) {
      return;
    }
    state.requests_in_flight.push({
      url,
      data: JSON.stringify({ method, data }),
    });
  }),

  removeRequest: action((state, { url, method, data }) => {
    if (data instanceof FormData) {
      return;
    }
    const spec = JSON.stringify({ method, data });

    if (
      state.requests_in_flight.filter((s) => spec === s.data && url === s.url)
        .length >= 1
    ) {
      state.requests_in_flight = [
        ...state.requests_in_flight.filter(
          (s) => spec !== s.data || url !== s.url
        ),
      ];
    }
  }),

  removeAuthCredentials: thunk(async (actions, payload, { getState }) => {
    await removeToken(getState().auth_provider);
  }),

  fetch: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const {
      url,
      method,
      data = {},
      onComplete,
      timeout = 1000 * 60 * 5,
      onUploadProgress,
      onDownloadProgress,
      responseType,
    } = payload;

    const state = getState();
    if (state.requestInFlight({ url, method, data })) {
      return;
    }

    let back_off_time = getState().getBackOffTime(url);
    let curr_request_count = getState().getCurrentRequestCount(url);
    while (back_off_time > 0 || curr_request_count >= 10) {
      await delay(back_off_time || 100);
      back_off_time = getState().getBackOffTime(url);
      curr_request_count = getState().getCurrentRequestCount(url);
    }

    actions.addRequest({ url, method, data });

    let config: AxiosRequestConfig = { timeout };
    const { auth_provider } = getState();
    const token = auth_provider ? await getAuthToken(auth_provider) : undefined;

    const { auth } = getStoreActions();

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        // "X-Frame-Options": "Deny",
        // "X-XSS-Protection": 1,
        // "X-Content-Type-Options": "nosniff",
        // "Referrer-Policy": "same-origin"
      };
    } else {
      auth.updateLoginStatus({ success: false, error: "Login Failed" });
    }

    if (onUploadProgress) {
      config.onUploadProgress = (progressEvent) => {
        const completed = progressEvent.loaded / progressEvent.total;
        onUploadProgress(completed);
      };
    }

    if (onDownloadProgress) {
      config.onDownloadProgress = (progressEvent) => {
        onDownloadProgress(progressEvent.loaded, progressEvent.total);
      };
    }

    if (responseType) {
      config.responseType = responseType;
    }

    let request: AxiosPromise;

    switch (method) {
      case "post":
      case "POST":
        request = axios.post(url, data, config);
        break;
      case "put":
      case "patch":
      case "PUT":
      case "PATCH":
        request = axios.put(url, data, config);
        break;
      case "delete":
      case "DELETE":
        request = axios.delete(url, { ...config, data });
        break;
      default:
        request = axios.get(url, { ...config, data });
    }

    const status = await request
      .then((response) => {
        auth.updateLicenseValidity({ status: true });
        const result = processResponse(response);
        if (onComplete) {
          onComplete(result);
        }
        return response.status;
      })
      .catch(({ response }: AxiosError) => {
        if (!response) {
          // Network Error.
          if (onComplete) {
            onComplete({
              success: false,
              error: "Network Issue: Unable to complete request",
              status: 999,
            });
          }
          return 999;
        }

        const { data, status } = response;

        let errorMessage = data.detail ? data.detail : data;

        if (status === 403 && INVALID_LICENSE_MESSAGES.includes(data)) {
          auth.updateLicenseValidity({ status: false, error: data });
        }

        if (LOGIN_FAILURE_MESSAGES.includes(errorMessage)) {
          errorMessage = "Login Failed";
          auth.updateLoginStatus({ success: false, error: errorMessage });
        }

        if (status === 429) {
          actions.update_429(url);
        }

        // if (onComplete) {
        //   onComplete({
        //     success: false,
        //     error: errorMessage,
        //     status: status,
        //   });
        // }

        // if (status === 429) {
        //   actions.update_429(url);
        // } else if (onComplete) {
        //   onComplete({
        //     success: false,
        //     error: errorMessage,
        //     status: status,
        //   });
        // }

        return status;
      });

    actions.removeRequest({ url, method, data });

    if (status === 429 || status === 999 || status === 502 || status === 503) {
      await delay(5000);
      actions.fetch(payload);
    }

    if (status >= 500 && status !== 502 && status !== 503 && status !== 999) {
      onComplete &&
        onComplete({
          success: false,
          error:
            "Something went wrong on the server. We are very sorry for this. Maybe you want to try again later?",
          status: status,
        });
      return status;
    }
  }),

  uploadFile: thunk(async (actions, payload) => {
    const { url, file, params = {}, onUploadProgress, onComplete } = payload;
    let data = new FormData();
    data.append("file", file);
    Object.keys(params).forEach((key) =>
      data.append(key, JSON.stringify(params[key]))
    );

    actions.fetch({
      url,
      method: "POST",
      data: data,
      timeout: 1000 * 60 * 60,
      onComplete,
      onUploadProgress,
    });
  }),

  uploadMultipleFiles: thunk(async (actions, payload) => {
    const { url, files, params = {}, onUploadProgress, onComplete } = payload;
    let data = new FormData();
    files.forEach((f) => {
      data.append("files", f, f.name);
    });
    Object.keys(params).forEach((key) =>
      data.append(key, JSON.stringify(params[key]))
    );

    actions.fetch({
      url,
      method: "POST",
      data: data,
      timeout: 1000 * 60 * 60,
      onComplete,
      onUploadProgress,
    });
  }),

  getBackOffTime: computed((state) => (url) => {
    const current_host = getHost(url);

    const last_429 = state.last_429[current_host];
    if (last_429 === undefined) {
      return 0;
    }

    const time_since_last_429 = new Date().valueOf() - last_429.valueOf();

    return time_since_last_429 < 1000 ? 1000 - time_since_last_429 : 0;
  }),

  requestInFlight: computed((state) => ({ url, method, data }) => {
    const { requests_in_flight } = state;
    const url_requests = requests_in_flight.filter((s) => s.url === url);
    if (url_requests.length === 0) {
      return false;
    }
    if (data instanceof FormData) {
      return false;
    }
    const spec = JSON.stringify({ method, data });
    return url_requests.filter((r) => r.data === spec).length >= 1;
  }),

  getCurrentRequestCount: computed((state) => (url) => {
    const { requests_in_flight } = state;
    const current_host: string = getHost(url);

    return requests_in_flight.filter((s) => getHost(s.url) === current_host)
      .length;
  }),
};

export default model;
