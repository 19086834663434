import React, { useState, useRef } from "react";

type ValueType = string | number | Date | undefined | null;

interface Props {
  value: ValueType[];
  onChange: (tags: ValueType[]) => any;
  className?: string;
}

const ADD_KEYS = [9, 13];
const DEL_KEYS = [8];

const TagsInput: React.FC<Props> = ({ value, className = "", onChange }) => {
  const [is_focused, setIsFocused] = useState<boolean>(false);
  const [tag, setTag] = useState<string>("");
  const input = useRef<HTMLInputElement>(null);

  return (
    <div
      className={`react-tagsinput input${
        is_focused ? " react-tagsinput--focused" : ""
      } ${className}`}
    >
      <span>
        {value.map((tag, index) => (
          <span key={index} className="react-tagsinput-tag">
            {tag}
            <button
              className="delete is-small"
              onClick={() => onChange(value.filter((v) => v !== tag))}
            />
          </span>
        ))}
      </span>
      <input
        ref={input}
        type="text"
        onClick={() => input?.current?.focus()}
        value={tag}
        className="react-tagsinput-input"
        placeholder="Add a tag"
        onFocus={() => setIsFocused(true)}
        onPaste={(e) => {
          e.preventDefault();
          const data = e.clipboardData
            ? e.clipboardData.getData("text/plain")
            : "";

          const tags = data.split(" ").map((d) => d.trim());
          onChange([...value, ...tags]);
        }}
        onChange={(e) => {
          setTag(e.target.value);
          e.preventDefault();
        }}
        onKeyDown={(e) => {
          if (e.defaultPrevented) {
            return;
          }

          let empty = tag === "";
          const { key, keyCode } = e;

          const add =
            ADD_KEYS.includes(keyCode) || ADD_KEYS.includes(parseInt(key));

          const remove =
            DEL_KEYS.includes(keyCode) || DEL_KEYS.includes(parseInt(key));

          if (add && !empty) {
            onChange([...value, tag]);
            setTag("");
            e.preventDefault();
          }

          if (remove && value.length > 0 && empty) {
            e.preventDefault();
            onChange(value.filter((_, ind) => ind !== value.length - 1));
          }
        }}
      />
    </div>
  );
};

export default TagsInput;
