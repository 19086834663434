import { action, computed, actionOn } from "easy-peasy";

import StateInterface, { DataFilter } from "./types";

const DEFAULT_STRING_LIST: string[] = [];
const DEFAULT_STRING_MAP: { [key: string]: string } = {};
const DEFAULT_FILTERS: DataFilter[] = [];

const model: StateInterface = {
  types: {},
  columnsToDrop: {},
  columnRenamer: {},
  filters: {},
  timeSeriesParams: {},
  groupingColumns: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})
  setColumnType: action((state, { file_id, column, col_type }) => {
    if (!state.types[file_id]) {
      state.types[file_id] = { [column]: col_type };
    } else {
      const curr_col_type = state.types[file_id][column];
      if (curr_col_type !== col_type) {
        state.types[file_id][column] = col_type;
        if (state.filters[file_id]) {
          state.filters[file_id] = state.filters[file_id].filter(
            ({ column: c }) => column !== c
          );
        }
      }
    }
  }),

  setColumnDropStatus: action((state, { file_id, column, status }) => {
    if (!state.columnsToDrop[file_id]) {
      if (status === "drop") {
        state.columnsToDrop[file_id] = [column];
      }
    } else {
      const droppedColumns = state.columnsToDrop[file_id];
      if (status === "drop" && !droppedColumns.includes(column)) {
        state.columnsToDrop[file_id].push(column);
      } else if (status === "keep" && droppedColumns.includes(column)) {
        state.columnsToDrop[file_id] = droppedColumns.filter(c => c !== column);
      }
    }
  }),

  setColumnName: action((state, { file_id, column, new_name }) => {
    if (!state.columnRenamer[file_id]) {
      state.columnRenamer[file_id] = { [column]: new_name };
    } else {
      if (new_name === column) {
        delete state.columnRenamer[file_id][column];
      } else {
        state.columnRenamer[file_id][column] = new_name;
      }
    }
  }),

  setFilters: action((state, { file_id, filters }) => {
    state.filters[file_id] = filters;
  }),

  setTimeSeriesParams: action((state, payload) => {
    const { file_id, ...rest } = payload;
    if (state.timeSeriesParams[file_id]) {
      state.timeSeriesParams[file_id] = {
        ...state.timeSeriesParams[file_id],
        ...rest
      };
    } else {
      state.timeSeriesParams[file_id] = rest;
    }
  }),

  setGroupingColumns: action((state, payload) => {
    const { file_id, groupingColumns } = payload;
    state.groupingColumns[file_id] = groupingColumns;
  }),

  listenToTypeFetch: actionOn(
    (_, storeActions) => storeActions.data_file_insights.updateInsight,
    (state, { payload }) => {
      const { file_id, insight_type, insight } = payload;
      if (insight_type !== "data-file-column-types") {
        return;
      }
      const types = insight.data;
      if (types) {
        if (!state.types[file_id]) {
          state.types[file_id] = {};
        }
        Object.keys(types).forEach(column => {
          if (!state.types[file_id][column]) {
            const deducedType = types[column];
            const inferredType =
              deducedType === "int" || deducedType === "float"
                ? "numeric"
                : deducedType;
            state.types[file_id][column] = inferredType;
          }
        });
      }
    }
  ),

  listenToSettingsChange: actionOn(
    actions => [actions.setColumnType, actions.setColumnDropStatus],
    (state, target) => {
      const [setColumnType, setColumnDropStatus] = target.resolvedTargets;
      const { file_id, column } = target.payload;

      switch (target.type) {
        case setColumnType:
          // @ts-ignore
          const { col_type } = target.payload;
          if ((state.types[file_id] || {})[column] !== col_type) {
            const filters = state.filters[file_id] || [];
            const new_filters = filters.filter(f => f.column !== column);
            if (new_filters.length < filters.length) {
              state.filters[file_id] = [...new_filters];
            }
            const grouping_columns = state.groupingColumns[file_id] || [];
            if (grouping_columns.includes(column)) {
              state.groupingColumns[file_id] = [
                ...grouping_columns.filter(g => g !== column)
              ];
            }
          }
          break;
        case setColumnDropStatus:
          const filters = state.filters[file_id] || [];
          const new_filters = filters.filter(f => f.column !== column);
          if (new_filters.length < filters.length) {
            state.filters[file_id] = [...new_filters];
          }
          const grouping_columns = state.groupingColumns[file_id] || [];
          if (grouping_columns.includes(column)) {
            state.groupingColumns[file_id] = [
              ...grouping_columns.filter(g => g !== column)
            ];
          }
          const { timeColumn } = state.timeSeriesParams[file_id] || {};
          if (timeColumn === column) {
            delete state.timeSeriesParams[file_id]["timeColumn"];
          }
          break;
      }
    }
  ),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.types = {};
      state.columnsToDrop = {};
      state.columnRenamer = {};
      state.filters = {};
      state.timeSeriesParams = {};
      state.groupingColumns = {};
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getTypesForFile: computed(
    [
      state => state.types,
      (_, storeState) => storeState.data_file_column_types.getData
    ],
    (types, getTypes) => file_id => {
      let userDefinedTypes = types[file_id] || {};
      const deducedTypes = getTypes(file_id) || {};
      const columnsWithInferredTypes = Object.keys(deducedTypes).filter(
        c => !userDefinedTypes[c]
      );
      columnsWithInferredTypes.forEach(c => {
        const deducedType = deducedTypes[c];
        if (deducedType === "int" || deducedType === "float") {
          userDefinedTypes[c] = "numeric";
        } else {
          userDefinedTypes[c] = deducedType;
        }
      });

      return userDefinedTypes;
    }
  ),
  getColumnType: computed(state => (file_id, column) => {
    const types = state.getTypesForFile(file_id);
    return types[column];
  }),
  getDroppedColumnsForFile: computed(state => file_id =>
    state.columnsToDrop[file_id] || DEFAULT_STRING_LIST
  ),
  getColumnRenamerForFile: computed(state => file_id =>
    state.columnRenamer[file_id] || DEFAULT_STRING_MAP
  ),
  getFiltersForFile: computed(state => file_id =>
    state.filters[file_id] || DEFAULT_FILTERS
  ),
  getTimeSeriesParamsForFile: computed(state => file_id =>
    (state.timeSeriesParams || {})[file_id] || DEFAULT_STRING_MAP
  ),
  getGroupingColumnsForFile: computed(state => file_id =>
    (state.groupingColumns || {})[file_id] || DEFAULT_STRING_LIST
  )
};

export default model;
