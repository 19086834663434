import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec, StringifySpec } from "./types";

const stringifySpec: StringifySpec = spec => JSON.stringify(spec);

const model: StateInterface = {
  params: {},
  taskStatus: {},
  result: null,

  // Store-Updaters
  // For e.g., an action that has params as a parameter type.
  // update_hygiene: action((state, payload) => {}),

  setParams: action((state, { file_id, params }) => {
    if (file_id !== null) {
      state.params[file_id] = { ...(state.params[file_id] || {}), ...params };
    }
  }),

  addColumn: action((state, { file_id, column_spec }) => {
    if (!state.params[file_id]) {
      state.params[file_id] = {};
    }
    state.params[file_id]["new_columns"] = [
      ...(state.params[file_id]["new_columns"] || []),
      column_spec
    ];
  }),

  removeColumn: action((state, { file_id, index }) => {
    if (state.params[file_id]) {
      state.params[file_id]["new_columns"] = (
        state.params[file_id]["new_columns"] || []
      ).filter((_, ind) => ind !== index);
    }
  }),

  resetParams: action(state => {
    state.params = {};
    state.taskStatus = {};
    state.result = null;
  }),

  setResult: action((state, result) => {
    state.result = result;
  }),

  resetTaskStatus: action(state => {
    state.taskStatus = {};
  }),

  // Update Store
  updateTaskStatus: action((state, { spec, status, result }) => {
    state.taskStatus[stringifySpec(spec)] = status;
    if (result) {
      state.result = result;
    }
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.params = {};
      state.taskStatus = {};
    }
  ),

  // Create Request
  create: thunk(
    async (actions, source_data, { injections, getState, getStoreActions }) => {
      // Get Spec.
      const spec = getState().getSpec(source_data);

      // Spec is null: There was an error.
      if (!spec) {
        return;
      }

      // Return immediately if task already requested once.
      if (getState().taskStatus[stringifySpec(spec)]) {
        return;
      }

      const url = injections.urls.create_data_augment;
      await getStoreActions().data_file_create_status.createFile({
        url,
        spec,
        // @ts-ignore
        updateTaskStatus: actions.updateTaskStatus
      });
    }
  ),

  // Selectors
  // For e.g.:
  // get_data_quality_for_file: select(state => file_id => state.resources[file_id])
  getSpec: computed(
    [
      state => state.params,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (params, settings) => source_data => {
      if (!params[source_data]) {
        return null;
      }
      const { name, new_columns } = params[source_data];
      if (new_columns === undefined || new_columns === null) {
        return null;
      }
      if (!name || !new_columns || (new_columns || []).length === 0) {
        return null;
      }
      const column_types = settings.getTypesForFile(source_data);
      const filters = settings.getFiltersForFile(source_data);

      const spec: Spec = {
        name,
        source_data,
        new_columns,
        filters,
        column_types
      };

      return spec;
    }
  ),

  getComputeStatus: computed(state => source_data => {
    const spec = state.getSpec(source_data);
    if (!spec) {
      return undefined;
    }
    return state.taskStatus[stringifySpec(spec)];
  })
};

export default model;
