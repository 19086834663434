import {
  faStethoscope,
  faBarcode,
  faTablets,
  faChartLine,
  faChevronDown,
  faDiceD20,
  faIdBadge,
  faNotEqual,
  faShoppingBasket,
  faObjectUngroup,
  faComments
} from "@fortawesome/free-solid-svg-icons";

export const paths = {
  hygiene: "quality",
  quality: "quality",
  columnProfiles: "column-profiles",
  compareColumns: "compare-columns",
  groupDifferences: "differences-between-groups",
  groupedDistributions: "grouped-distributions",
  timeSeriesDistributions: "time-series-distributions",
  timeSeriesChangePoints: "time-series-change-points",
  timeSeriesAnomalies: "time-series-anomalies",
  idProfiles: "id-profiles",
  documentTopics: "document-topics",
  relationships: "relationships",
  frequentPatterns: "basket-analysis"
};

export default [
  {
    title: "Data Quality",
    subtitle: "Get a summary reading of your data quality",
    text:
      "Constants, Low-information columns, Potential duplication, Missing Values, and Missing Patterns",
    link: paths.quality,
    icon: faStethoscope,
    group: "basic",
    tags: ["basic", "hygiene"]
  },
  {
    title: "Column Profiles",
    subtitle: "See Profiles of Columns in your data",
    text:
      "Distributions, Missing values, Relationships with other Columns, Deep-dive if you want to.",
    link: paths.columnProfiles,
    icon: faBarcode,
    group: "basic",
    tags: ["basic", "data-profile"]
  },
  {
    title: "Compare Columns",
    subtitle: "Compare two columns",
    text:
      "See how two columns are related to each other. Relationships, Bi-variate distributions, co-missing, etc.",
    link: paths.compareColumns,
    icon: faTablets,
    group: "basic",
    tags: ["basic", "data-profile"]
  },
  {
    title: "Relationships",
    subtitle: "Overall Relationship Structure in the data",
    text:
      "See how columns are related to each other. You can also make groups of data, and explore relationship structures inside those groups.",
    link: paths.relationships,
    icon: faDiceD20,
    group: "basic",
    tags: ["basic", "data-profile"]
  },
  {
    title: "Pivot / Grouped Distributions",
    subtitle: "See how data varies by pivot variables",
    text:
      "Select pivot/grouping variables, and see how data is spread for each group.",
    link: paths.groupedDistributions,
    icon: faObjectUngroup,
    group: "basic",
    tags: ["grouped", "data-profile"]
  },
  {
    title: "Differences Between Groups",
    subtitle: "Is data the same across groups? e.g., Male/Female, Q1/Q2/Q3/Q4",
    text:
      "Make arbitrary groups in data, and see how data differs between the two groups.",
    link: paths.groupDifferences,
    icon: faNotEqual,
    group: "basic",
    tags: ["grouped", "data-profile"]
  },
  {
    title: "Build ID Profiles",
    subtitle: "Generate profiles based on events or characteristics",
    text:
      "Select a ID column (e.g., User), attribute column (e.g., Things Liked), and generate profiles.",
    link: paths.idProfiles,
    icon: faIdBadge,
    group: "misc",
    tags: ["learning", "profiling"]
  },
  {
    title: "Uncover Themes in Documents and Text",
    subtitle: "Discover themes in what people are talking about",
    text: "Select a Text column (e.g., Comments), and generate themese.",
    link: paths.documentTopics,
    icon: faComments,
    group: "misc",
    tags: ["learning", "topics"]
  },
  {
    title: "Time Series Distributions",
    subtitle: "See Data plotted as Time-Series",
    text:
      "Select a time-column, and see how data changes over time. You can also group data, and see how each group changes over time.",
    link: paths.timeSeriesDistributions,
    icon: faChartLine,
    group: "time-series",
    tags: ["time-series"]
  },
  {
    title: "Time Series Change-Points",
    subtitle: "Change/Inflextion Points",
    text:
      "See if your data has DIFFERENT patterns over time. Automatically generate change points.",
    link: paths.timeSeriesChangePoints,
    icon: faChevronDown,
    group: "time-series",
    tags: ["time-series", "learning"]
  },
  {
    title: "Time Series Anomalies",
    subtitle: "Anomaly detection on a time-series",
    text:
      "Select a time-column, and see if there are anomalies in data when data is treated as a time-series.",
    link: paths.timeSeriesAnomalies,
    icon: faChartLine,
    group: "time-series",
    tags: ["time-series", "learning"]
  },

  {
    title: "Frequent Patterns and Associations",
    subtitle: "Explore events happening together, and their likelihoods",
    text:
      "Select a ID column (e.g., TransactionID), an event column (e.g., Product Bought), and generate lists of patterns where events happen together.",
    link: paths.frequentPatterns,
    icon: faShoppingBasket,
    group: "misc",
    tags: ["learning", "event-association"]
  }
];
