import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";

import "./index.scss";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

const SENTRY_DSN = process.env.REACT_APP_DAISHO_SENTRY_DSN;

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE &&
  SENTRY_DSN !== undefined &&
  SENTRY_DSN !== null
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
