import { GridCell, ScatterPointDatum } from "Visualizations";
import { filterObjectsByRange } from "Visualizations/data-utils";

import { TimeResolution, NumTSD, CatTSD } from "./types";
import { timeResolutionDelta, parseGrouping } from "utils";

interface NumericDatum extends ScatterPointDatum {
  size: number;
  group: string | number | undefined;
}

export const generateNumTS = (
  distribution: NumTSD,
  timeDomain: [Date, Date],
  groupsToShow?: (string | number)[]
) => {
  const time = distribution["__time__"];
  const grouping = distribution["__group__"] || [];
  const median = distribution["median"] || [];
  const size = distribution["size"] || [];
  let chartData: NumericDatum[] = time.map((t: string, i: number) => {
    return {
      x: new Date(t),
      y: median[i],
      size: size[i],
      group: parseGrouping(grouping[i])
    };
  });
  chartData = filterObjectsByRange(chartData, "x", "date", timeDomain);
  chartData = filterObjectsByRange(chartData, "group", "char", groupsToShow);

  // @ts-ignore
  return chartData.sort((a, b) => a.x.getTime() - b.x.getTime());
};

export const generateCatTS = (
  distribution: CatTSD,
  timeDomain: [Date, Date],
  resolution: TimeResolution,
  groupsToShow?: (string | number)[],
  normalized: boolean = false
) => {
  const time = distribution["__time__"];
  const grouping = distribution["__group__"] || [];

  const levels = Object.keys(distribution).filter(
    k => k !== "__time__" && k !== "__group__"
  );
  let chartData: GridCell[] = [];
  time.forEach((t: string, i: number) => {
    const rightEdge = new Date(t);
    const leftEdge = timeResolutionDelta(t, resolution, -1);

    let d = levels.map((l: string) => {
      return {
        leftEdge,
        rightEdge,
        level: l,
        value: distribution[l][i],
        group: parseGrouping(grouping[i]),
        size: distribution[l][i]
      };
    });
    if (normalized) {
      const size = d.reduce((a, b) => a + b.size, 0);
      d = d.map(p => ({ ...p, value: p.value / size }));
    }
    chartData.push(...d);
  });

  chartData = filterObjectsByRange(chartData, "leftEdge", "date", timeDomain);
  chartData = filterObjectsByRange(chartData, "rightEdge", "date", timeDomain);
  chartData = filterObjectsByRange(chartData, "group", "char", groupsToShow);

  return chartData;
};
