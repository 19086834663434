import React, { useEffect } from "react";

import { useForm, FormContext, useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import SelectField from "../Select-Field";

import { Filter, ColumnType, Stats } from "./types";
import FilterSummary from "./Filter-Summary";
import Operator, { ARRAY_OPERATORS } from "./Operator";
import Condition from "./Condition";

import InputRange from "./Input-Range";

const Fields: React.FC<{
  columns: string[];
  columnTypes: { [col: string]: ColumnType };
  stats: { [col: string]: Stats };
  onColumnSelect?: (column: string) => any;
  showFilterHelp?: boolean;
}> = ({
  columns,
  columnTypes,
  stats,
  onColumnSelect,
  showFilterHelp = true,
}) => {
  const { setValue, watch } = useFormContext() || {};
  const operator = watch("operator");
  const condition = watch("condition");
  const column = watch("column");
  const range = watch("range");

  useEffect(() => {
    if (operator) {
      if (!ARRAY_OPERATORS.includes(operator)) {
        if (Array.isArray(range)) {
          setValue("range", range[0]);
        }
      } else {
        if (!Array.isArray(range)) {
          if (range === undefined || range === null) {
            setValue("range", []);
          } else {
            setValue("range", [range]);
          }
        }
      }
    }
  }, [operator, range, setValue]);

  const options = columns.map((c) => ({ value: c, label: c }));

  return (
    <>
      <SelectField
        name="column"
        label="Column to Filter On:"
        options={options}
        required={true}
        value={column}
        onValueChange={(value) => {
          setValue("column", value);
          if (operator) {
            setValue("operator", "");
          }
          if (range) {
            setValue("range", []);
          }
          if (onColumnSelect) {
            onColumnSelect(value?.toString() || "");
          }
        }}
      />
      {column && (
        <Condition onValueChange={(value) => setValue("condition", value)} />
      )}
      {condition && (
        <Operator
          colType={columnTypes[column]}
          stats={stats[column]}
          onValueChange={(value) => setValue("operator", value)}
        />
      )}
      {column && condition && operator && (
        <InputRange
          columnType={columnTypes[column]}
          operator={operator}
          stats={stats[column]}
        />
      )}
      <div className="field">
        <p className="control">
          <button
            className="button is-success tooltip has-tooltip-multiline has-tooltip-right"
            data-tooltip="A filter is a set of conditions. Each condition has a VARIABLE, a CONDITION (include/exclude), an OPERATOR (in, >=, etc.), and value range. ALL conditions must be met for a filter to be applied"
            type="submit"
          >
            <span>Add Filter </span>
            {showFilterHelp && (
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="fas icon is-small"
              />
            )}
          </button>
        </p>
      </div>
    </>
  );
};

interface PropsInterface {
  columns: string[];
  column_types: { [col: string]: ColumnType };
  stats: { [col: string]: Stats };
  onColumnSelect?: (column: string) => any;
  show_existing_filters?: boolean;
  existing_filters?: Filter[];
  add_filter: (filter: Filter) => any;
  remove_filter?: (index: number, filter: Filter) => any;
  showFilterHelp?: boolean;
}

const INIT_FILTERS: Filter[] = [];

const DataFilters: React.FC<PropsInterface> = ({
  columns,
  column_types,
  stats,
  onColumnSelect,
  show_existing_filters = true,
  existing_filters = INIT_FILTERS,
  add_filter,
  remove_filter = () => {},
  showFilterHelp = true,
}) => {
  const methods = useForm<Filter>();
  const onSubmit = (filter: Filter) => {
    if (["int", "float", "numeric"].includes(column_types[filter.column])) {
      if (Array.isArray(filter.range)) {
        filter.range = filter.range.map((v) => parseFloat(v?.toString()));
        // @ts-ignore
        if (filter.range.filter((v) => !isNaN(v)).length < 2) {
          methods.setError("range", "required", "Required");
          return;
        }
        if (filter.range[1] < filter.range[0]) {
          methods.setError(
            "range",
            "invalid",
            "Upper Limit is SMALLER than lower limit!!!"
          );
        }
      } else {
        // @ts-ignore
        const value: number = filter.range?.toString();
        if (isNaN(value)) {
          methods.setError("range", "required", "Required");
          return;
        }
        filter.range = [value];
      }
    } else if (["date", "time"].includes(column_types[filter.column])) {
      if (Array.isArray(filter.range)) {
        filter.range = filter.range.map((v) => new Date(v));
        // @ts-ignore
        if (filter.range.filter((v) => !isNaN(v.valueOf())).length < 2) {
          methods.setError("range", "required", "Required");
          return;
        }
        if (filter.range[1].valueOf() < filter.range[0].valueOf()) {
          methods.setError(
            "range",
            "invalid",
            "Upper Limit is BEFORE than lower limit!!!"
          );
        }
      } else {
        const value: Date = new Date(filter.range);
        if (isNaN(value.valueOf())) {
          methods.setError("range", "required", "Required");
          return;
        }
        filter.range = [value];
      }
    } else {
      if (!Array.isArray(filter.range)) {
        methods.setError("range", "required", "Required");
        return;
      }
      if (filter.range.length === 0) {
        methods.setError("range", "required", "Required");
        return;
      }
    }

    add_filter({ ...filter });
    methods.reset();
  };

  return (
    <div>
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Fields
            columns={columns}
            columnTypes={column_types}
            stats={stats}
            onColumnSelect={onColumnSelect}
            showFilterHelp={showFilterHelp}
          />
        </form>
      </FormContext>
      {show_existing_filters && (
        <div style={{ marginTop: "1rem" }}>
          {existing_filters.map((filter, i) => (
            <p key={i}>
              <FilterSummary
                filter={filter}
                type={column_types[filter.column]}
                index={i + 1}
                remove_filter={() => remove_filter(i, filter)}
              />
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default DataFilters;
