import React from "react";

import { Filter, ColumnType } from "./types";
import make_range_text from "./range-text";

interface PropsInterface {
  type?: ColumnType | "time";
  filter: Filter;
  index?: number;
  remove_filter?: () => any;
}

const DataFilterSummary: React.FunctionComponent<PropsInterface> = ({
  type,
  filter,
  index = 0,
  remove_filter
}) => {
  index = index || 0;

  const { column, operator, range, condition } = filter;

  const conditionText =
    condition === "include" ? "Include only" : "Exclude all";

  const rangeText = make_range_text(operator, type || "", range);

  return (
    <span>
      {index >= 1 && `${index}. `}
      {conditionText} rows where <i>{column}</i> {rangeText}{" "}
      {remove_filter && (
        <span onClick={remove_filter} className="is-link">
          Remove
        </span>
      )}
    </span>
  );
};

export default DataFilterSummary;
