import React, { useMemo } from "react";

import { GridChart, Barchart, isCategorical } from "Visualizations";
import { VarType, ViewPort, Fill } from "Visualizations";

import { FlatBiVariateDistribution, Cell } from "./types";
import { FlatUniVariateDistribution } from "../Univariate-Histogram/types";

import generateUniDist from "../Univariate-Histogram/generate-distribution";
import InputRange from "../Input-Range";

import generateDistribution from "./generate-distributions";
import getDenseDomain from "./dense-domain";
import makeAnnotater from "./annotaters";
import useDomain from "./domain-hook";

interface PropsInterface extends ViewPort {
  distribution: FlatBiVariateDistribution;
  column1: string;
  column2: string;
  column1_type: VarType;
  column2_type: VarType;
  column1_dist?: FlatUniVariateDistribution;
  column2_dist?: FlatUniVariateDistribution;
  outliers1?: (string | number | Date)[];
  outliers2?: (string | number | Date)[];
  fillColor?: Fill<Cell>;
  hoverColor?: Fill<Cell>;
  showZoom?: boolean;
}

const BivariateDistribution: React.FC<PropsInterface> = ({
  distribution,
  column1,
  column2,
  column1_type,
  column2_type,
  column1_dist,
  column2_dist,
  outliers1,
  outliers2,
  fillColor,
  hoverColor,
  showZoom = true,
  width,
  height,
  margin
}) => {
  const denseDomain1 = useMemo(
    () =>
      getDenseDomain(
        distribution,
        isCategorical(column1_type) === isCategorical(column2_type)
          ? "level-1"
          : "level",
        column1_type,
        outliers1
      ),
    [distribution, column1_type, column2_type, outliers1]
  );
  const denseDomain2 = useMemo(
    () =>
      getDenseDomain(
        distribution,
        isCategorical(column1_type) === isCategorical(column2_type)
          ? "level-2"
          : "level",
        column2_type,
        outliers2
      ),
    [distribution, column1_type, column2_type, outliers2]
  );

  const d = useMemo(
    () => generateDistribution(distribution, column1_type, column2_type),
    [distribution, column1_type, column2_type]
  );

  const data_count = distribution["data-count"];

  const data1 = useMemo(() => generateUniDist(column1_dist, column1_type), [
    column1_dist,
    column1_type
  ]);
  const data2 = useMemo(() => generateUniDist(column2_dist, column2_type), [
    column2_dist,
    column2_type
  ]);

  const [
    data,
    xDomain,
    yDomain,
    setXDomain,
    setYDomain,
    total_shown,
    p
  ] = useDomain(
    d,
    denseDomain1,
    denseDomain2,
    column1_type,
    column2_type,
    data_count
  );

  const annotater = makeAnnotater(column1, column1_type, column2, column2_type);

  let text: string;
  if (total_shown === data_count) {
    text = "Showing ALL values.";
  } else {
    text = `Showing ${total_shown.toLocaleString()} rows (${p}% of total data).`;
  }

  const rangeType1 = ["date", "time"].includes(column1_type)
    ? "date"
    : ["int", "float", "numeric"].includes(column1_type)
    ? "number"
    : "text";

  const rangeType2 = ["date", "time"].includes(column2_type)
    ? "date"
    : ["int", "float", "numeric"].includes(column2_type)
    ? "number"
    : "text";

  return (
    <div>
      <p className="has-text-right help is-danger has-text-weight-bold">
        {text}
      </p>
      <p className="has-text-right help is-danger has-text-weight-bold">
        {xDomain &&
          xDomain.length >= 1 &&
          (isCategorical(column1_type)
            ? `${column1} (X-Axis): Showing ${xDomain.length} levels`
            : `${column1} (X-Axis): Showing data between ${xDomain[0].toLocaleString()} and ${xDomain[1].toLocaleString()}`)}
      </p>
      <p className="has-text-right help is-danger has-text-weight-bold">
        {yDomain &&
          yDomain.length >= 1 &&
          (isCategorical(column2_type)
            ? `${column2} (Y-Axis): Showing ${yDomain.length} levels`
            : `${column2} (Y-Axis): Showing data between ${yDomain[0].toLocaleString()} and ${yDomain[1].toLocaleString()}`)}
      </p>
      <GridChart
        data={data}
        xType={column1_type}
        yType={column2_type}
        xDomain={xDomain}
        yDomain={yDomain}
        width={width}
        height={height}
        margin={margin}
        // @ts-ignore
        fillColor={fillColor}
        // @ts-ignore
        hoverColor={hoverColor}
        annotater={annotater}
      />
      {showZoom && data1.length > 1 && data2.length > 1 && (
        <div>
          <hr />
          <p className="has-text-centered">
            <b>{column1}</b>
          </p>
          <InputRange
            label="Filter Data to Zoom"
            onValueChange={setXDomain}
            type={rangeType1}
            range={xDomain}
          />
          <Barchart
            data={data1}
            chartType="vertical"
            varType={column1_type}
            showHeightAxis={false}
            startHeightAtZero={true}
            showBrush={true}
            brushDomain={xDomain}
            onZoomChange={setXDomain}
            // @ts-ignore
            fillColor={fillColor}
            // @ts-ignore
            hoverColor={fillColor}
            width={width}
            height={100}
            margin={margin}
          />
          <p className="has-text-centered">
            <b>{column2}</b>
          </p>
          <InputRange
            label="Filter Data to Zoom"
            onValueChange={setYDomain}
            type={rangeType2}
            range={yDomain}
          />
          <Barchart
            data={data2}
            chartType="vertical"
            varType={column2_type}
            showHeightAxis={false}
            startHeightAtZero={true}
            showBrush={true}
            brushDomain={yDomain}
            onZoomChange={setYDomain}
            // @ts-ignore
            fillColor={fillColor}
            // @ts-ignore
            hoverColor={fillColor}
            width={width}
            height={100}
            margin={margin}
          />
        </div>
      )}
    </div>
  );
};

export default BivariateDistribution;
