import * as firebase from "firebase/app";
import "firebase/auth";

export const getAuthToken = async (auth_provider: string) => {
  switch (auth_provider) {
    case "Daisho-JWT":
      const jwt_token: string | undefined | null = await localStorage.getItem(
        "apiToken"
      );
      return jwt_token;
    case "firebase":
      const { currentUser } = firebase.auth();
      const firebase_token = await currentUser?.getIdToken();
      return firebase_token;
  }
};

export const setAuthToken = async (token: string) => {
  await localStorage.setItem("apiToken", token);
};

export const removeToken = async (auth_provider: string) => {
  switch (auth_provider) {
    case "Daisho-JWT":
      await localStorage.removeItem("apiToken");
      return;
    case "firebase":
      await firebase.auth().signOut();
      return;
  }
};
