import { StoreModel } from "./types";

import auth from "./auth";
import api_client from "./api";
import reset from "./reset";
import crumbs from "./bread-crumbs";
import tour_guide from "./tour-guide";
import downloads from "./downloads-exports";
import recipes from "./recipes";

import workspace_create_new_model from "./workspace-create-new-model";
import workspace_create_iterate_model from "./workspace-create-iterate-model";

import predictive_model_listing from "./predictive-model-listing";
import predictive_model_actions from "./predictive-model-actions";
import predictive_model_insights from "./predictive-model-insights";
import predictive_model_exports from "./predictive-model-exports";

import data_files from "./data-files";
import data_file_insights from "./data-file-insights";
import data_file_sample from "./data-file-sample";
import data_files_user_settings from "./data-file-user-settings";

import data_file_column_profiles from "./data-file-column-profiles";
import data_file_column_types from "./data-file-column-types";

import data_file_hygiene from "./data-file-hygiene";
import data_file_compare_columns from "./data-file-compare-columns";
import data_file_relationships from "./data-file-relationships";
import data_file_grouped_distributions from "./data-file-grouped-distributions";
import data_file_group_differences from "./data-file-group-differences";
import data_file_id_profiles from "./data-file-id-profiles";
import data_file_document_topics from "./data-file-document-topics";
import data_file_time_series_distributions from "./data-file-time-series-distributions";
import data_file_time_series_change_points from "./data-file-time-series-change-points";
import data_file_time_series_anomalies from "./data-file-time-series-anomalies";
import data_file_frequent_patterns from "./data-file-frequent-patterns";
import data_file_association_rules from "./data-file-association-rules";

import data_file_create_status from "./data-file-create-status";
import data_file_create_upload from "./data-file-create-upload";
import data_file_create_filter from "./data-file-create-filter";
import data_file_create_join from "./data-file-create-join";
import data_file_create_stack from "./data-file-create-stack";
import data_file_create_aggregates from "./data-file-create-aggregates";
import data_file_create_time_series from "./data-file-create-time-series";
import data_file_create_augment from "./data-file-create-augmented-data";
import data_file_create_clean from "./data-file-create-clean";

const model: StoreModel = {
  data_file_create_clean,
  data_file_create_augment,
  data_file_create_time_series,
  data_file_create_aggregates,
  data_file_create_stack,
  data_file_create_join,
  data_file_create_filter,
  data_file_create_upload,
  data_file_create_status,
  data_file_association_rules,
  data_file_frequent_patterns,
  data_file_time_series_anomalies,
  data_file_time_series_change_points,
  data_file_time_series_distributions,
  data_file_document_topics,
  data_file_id_profiles,
  data_file_group_differences,
  data_file_grouped_distributions,
  data_file_relationships,
  data_file_compare_columns,
  data_file_hygiene,
  data_file_column_profiles,
  data_file_column_types,
  data_file_sample,
  data_file_insights,
  data_files_user_settings,
  data_files,
  workspace_create_new_model,
  workspace_create_iterate_model,
  predictive_model_listing,
  predictive_model_actions,
  predictive_model_insights,
  predictive_model_exports,
  recipes,
  downloads,
  auth,
  reset,
  crumbs,
  tour_guide,
  api_client
};

export default model;
