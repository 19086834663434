import React, { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { isCategorical, Barchart, UnivariateDatum } from "Visualizations";
import { VarType, ViewPort, Fill } from "Visualizations/types";

import InputRange from "UI-Components/Input-Range";

import { FlatUniVariateDistribution } from "./types";
import useDomain from "./use-domain";
import generate_distribution from "./generate-distribution";
import generate_dense_domain from "./dense-domain";
import getAnnotater from "./annotaters";

interface PropsInterface extends ViewPort {
  distribution: FlatUniVariateDistribution;
  col_type: VarType;
  outlier_ranges?: (string | number | Date)[];
  /** Count of valid values */
  data_count: number;
  showZoom?: boolean;
  barColor?: Fill<UnivariateDatum>;
  hoverColor?: Fill<UnivariateDatum>;
}
const UnivariateHistogram: React.FC<PropsInterface> = ({
  distribution,
  col_type,
  outlier_ranges = [],
  data_count,
  showZoom = true,
  barColor,
  hoverColor,
  height,
  width,
  margin,
}) => {
  const data = useMemo(() => generate_distribution(distribution, col_type), [
    distribution,
    col_type,
  ]);
  let dense_domain = useMemo(
    () => generate_dense_domain(distribution, col_type, outlier_ranges),
    [distribution, col_type, outlier_ranges]
  );
  dense_domain = showZoom ? dense_domain : [];
  const [domain, setDomain, data_shown, total_shown, p] = useDomain(
    data,
    dense_domain,
    col_type,
    data_count
  );
  if (data_count === 0) {
    return <div>No valid values!!!</div>;
  }

  if (data.length <= 1) {
    return null;
  }
  if (
    isCategorical(col_type) &&
    Math.max(...(distribution["points-in-level"] || [])) === 1
  ) {
    return <div>Every row is unique!!!</div>;
  }

  let text: string;
  if (total_shown === data_count) {
    text = `Showing ALL ${data_count} values.`;
  } else if (isCategorical(col_type)) {
    text = `Showing ${
      data_shown.length
    } values and ${total_shown.toLocaleString()} rows (${p} % of total data.)`;
  } else if (domain.length === 0) {
    text = `Showing ALL ${data_count} values.`;
  } else {
    text = `Showing ${total_shown.toLocaleString()} rows between ${domain[0].toLocaleString()} and ${domain[1].toLocaleString()} (${p}% of total data).`;
  }

  const rangeType = ["date", "time"].includes(col_type)
    ? "date"
    : ["int", "float", "numeric"].includes(col_type)
    ? "number"
    : "text";

  return (
    <div>
      <p className="has-text-right help is-danger has-text-weight-bold">
        {text}
      </p>
      <div>
        <Barchart
          data={data}
          chartType="vertical"
          varType={col_type}
          valueDomain={domain}
          showToolTip={true}
          annotater={getAnnotater(col_type)}
          showHeightAxis={false}
          startHeightAtZero={true}
          width={width}
          height={height}
          margin={margin}
          fillColor={barColor}
          hoverColor={hoverColor}
        />
      </div>
      {showZoom && (
        <div>
          <p className="has-text-centered">
            <span
              className="tooltip has-tooltip-multiline has-tooltip-right"
              data-tooltip="This chart always shows ALL the data, with the shaded regions being the data displayed above."
            >
              Full Chart{" "}
              <span>
                <FontAwesomeIcon icon={faInfoCircle} className="fas" />
              </span>
            </span>
          </p>
          <Barchart
            data={data}
            chartType="vertical"
            varType={col_type}
            showHeightAxis={false}
            startHeightAtZero={true}
            showBrush={true}
            brushDomain={domain}
            onZoomChange={setDomain}
            fillColor={barColor}
            hoverColor={barColor}
            width={width}
            height={height / 2}
            margin={margin}
          />
          <InputRange
            label={
              <span
                className="tooltip has-tooltip-multiline has-tooltip-right"
                data-tooltip="You can filter the top chart by changing the controls here."
              >
                Filter Data to Zoom{" "}
                <FontAwesomeIcon icon={faInfoCircle} className="fas" />
              </span>
            }
            onValueChange={setDomain}
            type={rangeType}
            range={domain}
          />
        </div>
      )}
    </div>
  );
};

export default UnivariateHistogram;
