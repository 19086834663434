import { action, thunk, computed, actionOn, thunkOn } from "easy-peasy";

import StateInterface from "./types";

const model: StateInterface = {
  // State
  login_status: { started: false, success: false, error: null },
  user_details: undefined,
  password_change_status: { started: false, success: false, error: null },
  license_status: { status: true, error: null },

  // Store-Updaters
  updateLoginStatus: action((state, { started, success, error }) => {
    if (typeof started === "boolean") {
      state.login_status = { started: started, success: false, error: null };
    }
    if (typeof success === "boolean") {
      state.login_status.started = started === undefined ? false : started;
      state.login_status.success = success;
      state.login_status.error = error || null;
    }
  }),

  updatePasswordChangeStatus: action((state, status) => {
    state.password_change_status = {
      ...state.password_change_status,
      ...status
    };
  }),

  updateUserDetails: action((state, details) => {
    state.user_details = { ...(state.user_details || {}), ...details };
  }),

  updateLicenseValidity: action((state, details) => {
    if (state.license_status.status !== true && details.status !== true) {
      state.license_status = { ...state.license_status, ...details };
    }
  }),

  resetAuth: actionOn(
    (_, storeActions) => storeActions.reset.reset,
    state => {
      state.login_status = { started: false, success: false, error: null };
      delete state.user_details;
      state.password_change_status = {
        started: false,
        success: false,
        error: null
      };
      state.license_status = { status: true, error: null };
    }
  ),

  startLogin: thunk(
    async (
      actions,
      payload,
      { injections, getState, getStoreActions, getStoreState }
    ) => {
      if (getState().logged_in || getState().login_started) {
        return;
      }

      const { auth_provider } = getStoreState().api_client;

      const { urls } = injections;
      if (auth_provider !== "Daisho-JWT") {
        return;
      }

      if (!payload) {
        console.log("Identity Provider is Daisho-JWT, but payload is null");
        actions.updateLoginStatus({
          success: false,
          error: "Username and password are mandatory"
        });
        return;
      }

      const { email, password } = payload;
      if (!email || !password) {
        actions.updateLoginStatus({
          success: false,
          error: "Username and password are mandatory"
        });
        return;
      }

      actions.updateLoginStatus({ started: true });

      await getStoreActions().api_client.fetch({
        url: urls.create_jwt,
        method: "POST",
        data: {
          email,
          password
        },
        onComplete: async ({ success, data, error }) => {
          if (success) {
            await getStoreActions().api_client.setAuthToken(data.token);
            actions.updateLoginStatus({ success: true });
          } else {
            error = Array.isArray(error)
              ? error[0]
              : typeof error === "string"
              ? error
              : "Invalid Username or Password";
            actions.updateLoginStatus({ success: false, error: error });
          }
        }
      });
    }
  ),

  startLogout: thunk(async (actions, payload, { getStoreActions }) => {
    getStoreActions().reset.reset();
    getStoreActions().api_client.removeAuthCredentials();
  }),

  changePassword: thunk(
    async (
      actions,
      payload,
      { injections, getState, getStoreState, getStoreActions }
    ) => {
      if (!getState().logged_in) {
        return;
      }

      const { auth_provider } = getStoreState().api_client;

      const { urls } = injections;

      if (auth_provider !== "Daisho-JWT") {
        return;
      }

      const { currentPassword, newPassword, newPassword2 } = payload;
      if (currentPassword === newPassword) {
        actions.updatePasswordChangeStatus({
          error: "Invalid Password. MUST be different from old password."
        });
      }
      if (newPassword !== newPassword2) {
        actions.updatePasswordChangeStatus({
          error: "Invalid Password. New passwords are DIFFERENT."
        });
      }
      actions.updatePasswordChangeStatus({ started: true });
      await getStoreActions().api_client.fetch({
        url: urls.change_password,
        method: "POST",
        data: {
          current_password: currentPassword,
          new_password: newPassword,
          new_password_2: newPassword2
        },
        onComplete: ({ success, error }) => {
          actions.updatePasswordChangeStatus({
            started: false,
            success,
            error: Array.isArray(error)
              ? error[0]
              : typeof error === "string"
              ? error
              : "Unable to change password!!!"
          });
        }
      });
    }
  ),

  fetchUserDetails: thunk(
    async (actions, _, { injections, getState, getStoreActions }) => {
      const { urls } = injections;

      await getStoreActions().api_client.fetch({
        url: urls.me,
        method: "GET",
        onComplete: async ({ success, data }) => {
          if (success) {
            const { user_details } = getState();
            if (user_details && user_details.email !== data.email) {
              // User has changed. Got to reset all the saved information. Everywhere.
              getStoreActions().reset.reset();
            }
            actions.updateUserDetails(data);
            actions.updateLoginStatus({ success: true });
          }
        }
      });
    }
  ),

  // Listeners
  listenToAuthTokenChange: thunkOn(
    (_, storeActions) => storeActions.api_client.setAuthToken.successType,
    async actions => {
      // actions.fetchUserDetails();
    }
  ),

  // Computed Properties
  logged_in: computed(state => state.login_status.success),

  login_started: computed(state => state.login_status.started),

  login_error: computed(state => state.login_status.error),

  license_is_valid: computed(state => state.license_status.status),

  license_error: computed(state => state.license_status.error)
};

export default model;
