import { VarType } from "../types";

import { isCategorical, isValid, isNumber, isDate } from "../utils";

interface Point {
  [key: string]: any;
}

/** Filters a list of objects based on whether each object has a valid value for key. */
function filterObjects<T extends Point>(
  data: T[],
  key: string,
  valueType: VarType
): T[] {
  if (!Array.isArray(data)) {
    console.log("Invalid data passed. Expected an array.");
    return data;
  }
  if (isCategorical(valueType)) {
    return data.filter(d => isValid(d[key]));
  } else if (valueType === "date" || valueType === "time") {
    return data.filter(d => isDate(d[key]));
  } else {
    return data.filter(d => isNumber(d[key]));
  }
}

export default filterObjects;
