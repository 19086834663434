import {
  scaleTime,
  scaleLinear,
  scaleSqrt,
  scaleBand,
  scaleOrdinal,
  ScaleOrdinal,
  ScaleTime,
  ScaleBand,
  ScaleContinuousNumeric
} from "d3-scale";

import { schemeCategory10, schemePaired } from "d3-scale-chromatic";

import { Scale, VarType, ScaleType } from "Visualizations/types";

interface ColorScaleProps {
  domain: ReadonlyArray<string>;
  scaleType: "color";
}

interface DateScaleProps {
  domain: Array<number | string | Date>;
  varType: "date" | "time";
  axisLength: number | [number, number];
}

interface CharScaleProps {
  domain: Array<string | number>;
  varType: "categorical" | "char" | "factor";
  axisLength: number;
  padding?: number;
}

interface NumericScaleProps {
  domain: Array<number>;
  varType: "int" | "float" | "numeric";
  scaleType?: "sqrt" | "linear" | null;
  axisLength: number | [number, number];
}

interface GenericScaleProps<
  T extends string | number | Date = string | number | Date
> {
  domain: Array<T>;
  varType: VarType;
  scaleType?: ScaleType;
  axisLength?: number | [number, number];
}

function get_scale(
  props: ColorScaleProps
): ScaleOrdinal<string, ReadonlyArray<string>>;
function get_scale(props: DateScaleProps): ScaleTime<Date[], number>;
function get_scale(props: CharScaleProps): ScaleBand<string | number>;
function get_scale(
  props: NumericScaleProps
): ScaleContinuousNumeric<number, number>;
function get_scale(props: GenericScaleProps): Scale<string | number | Date>;
function get_scale({
  varType,
  domain,
  axisLength,
  scaleType,
  padding
}: any): any {
  if (scaleType === "color") {
    return scaleOrdinal()
      .domain(domain)
      .range(domain.length <= 10 ? schemeCategory10 : schemePaired);
  }

  const range = typeof axisLength === "number" ? [0, axisLength] : axisLength;

  if (varType === "date" || varType === "time") {
    return scaleTime()
      .range(range)
      .domain(domain.map((d: number | string | Date) => new Date(d)))
      .clamp(true);
  }

  if (varType === "categorical" || varType === "char" || varType === "factor") {
    if (padding === undefined) {
      padding = 0.1;
    }
    const scale_b = scaleBand()
      .range(range)
      .domain(domain)
      .padding(padding);
    return scale_b;
  }

  if (scaleType === "sqrt") {
    return scaleSqrt()
      .range(range)
      .domain(domain)
      .clamp(true);
  }

  return scaleLinear()
    .range(range)
    .domain(domain)
    .clamp(true);
}

export default get_scale;
