import { VarType, GridCell, isCategorical } from "Visualizations";

import { TimeResolution, NumTSD, CatTSD } from "./types";
import { timeResolutionDelta, parseGrouping } from "utils";

function generateHistogram(
  distribution: NumTSD & CatTSD,
  resolution: TimeResolution,
  colType: VarType
): GridCell[] {
  const time = distribution["__time__"];
  const grouping = distribution["__group__"] || [];

  let chartData: GridCell[] = [];

  if (isCategorical(colType)) {
    const levels = Object.keys(distribution).filter(
      k => k !== "__time__" && k !== "__group__"
    );

    if (!distribution["__group__"]) {
      chartData = time.map((t: string, i: number) => {
        const rightEdge = new Date(t);
        const leftEdge = timeResolutionDelta(t, resolution, -1);
        const size = levels
          // @ts-ignore
          .map((l: string) => (distribution[l] || [])[i])
          .reduce((a, b) => a + b, 0);
        return { leftEdge, rightEdge, level: "", value: size };
      });
    } else {
      const uniqueEdges = new Set(time);
      const groups = Array.from(
        new Set(distribution["__group__"].map(parseGrouping))
      );
      uniqueEdges.forEach(t => {
        const rightEdge = new Date(t);
        const leftEdge = timeResolutionDelta(t, resolution, -1);

        const d = groups.map(g => {
          const indices = time
            .map((tt, i) =>
              t === tt &&
              parseGrouping((distribution["__group__"] || [])[i]) === g
                ? i
                : -1
            )
            .filter(i => i >= 0);
          const value = levels
            .map(l =>
              // @ts-ignore
              indices.map(i => distribution[l][i]).reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0);
          return {
            leftEdge,
            rightEdge,
            value: value,
            level: g === undefined ? "" : g
          };
        });
        chartData.push(...d);
      });
    }
  } else {
    const size = distribution["size"] || [];
    chartData = time.map((t: string, i: number) => {
      const rightEdge = new Date(t);
      const leftEdge = timeResolutionDelta(t, resolution, -1);
      return {
        leftEdge,
        rightEdge,
        value: size[i],
        level:
          parseGrouping(grouping[i]) === undefined
            ? ""
            : parseGrouping(grouping[i])
      };
    });
  }

  return chartData;
}

export default generateHistogram;
