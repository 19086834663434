import React from "react";

import { Margin } from "Visualizations/types";

interface MouseContainerProps {
  width: number;
  height: number;
  margin: Margin;
  stopDrag(event: React.MouseEvent): void;
}

export default ({ width, height, margin, stopDrag }: MouseContainerProps) => {
  const c_w = width - (margin.left + margin.right);
  const c_h = height - (margin.top + margin.bottom);
  if (c_w <= 0 || c_h <= 0) {
    return null;
  }
  return (
    <rect
      x={0}
      y={0}
      stroke="none"
      shapeRendering="crispEdges"
      width={c_w}
      height={c_h}
      fill="none"
      onMouseUp={stopDrag}
      onMouseOut={stopDrag}
    />
  );
};
