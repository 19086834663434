import { isCategorical, VarType } from "Visualizations";

import { Cell } from "./types";

const annotater = (
  d: Cell,
  column1: string,
  column1_type: VarType,
  column2: string,
  column2_type: VarType
) => {
  if (isCategorical(column1_type) && isCategorical(column2_type)) {
    return `${d.count} rows where ${column1} is ${d.x} and ${column2} is ${d.y}`;
  } else if (!isCategorical(column1_type) && !isCategorical(column2_type)) {
    return `${d.count} rows where ${column1} is between ${(
      d.xLeftEdge || ""
    ).toLocaleString()} and ${(
      d.xRightEdge || ""
    ).toLocaleString()}, and ${column2} is between ${(
      d.yLeftEdge || ""
    ).toLocaleString()} and ${(d.yRightEdge || "").toLocaleString()}`;
  } else {
    const numCol = isCategorical(column1_type) ? column2 : column1;
    const catCol = isCategorical(column1_type) ? column1 : column2;
    return `${d.count} rows where ${catCol} is ${
      d.level
    } and ${numCol} is between ${(d.leftEdge || "").toLocaleString()} and ${(
      d.rightEdge || ""
    ).toLocaleString()}`;
  }
};

export default (
  column1: string,
  column1_type: VarType,
  column2: string,
  column2_type: VarType
) => (d: Cell) => annotater(d, column1, column1_type, column2, column2_type);
