import React from "react";

import SelectField from "../Select-Field";

import SetTreatmentOption from "./treatment-options";
import FillValue from "./fill-value";
import SubstituteTreatment from "./substitute-treatment";

type UserDefinedTypes = { [column: string]: "char" | "numeric" | "date" };

interface ColumnStats {
  count: number;
  size: number;
}

interface MissingPatterns {
  [column: string]: {
    "present::present": number;
    "present::missing": number;
    "missing::present": number;
    "missing::missing": number;
  };
}

interface Treatment<T> {
  treatment?: "drop-rows" | "fill-value" | "substitute" | "ignore";
  value?: T;
  "substitute-params"?: {
    substitute?: string;
    treatment?: Treatment<T>;
  };
}

interface Error {
  value?: string;
  substitute?: string;
  "substitute-treatment"?: string;
  "substitute-fill"?: string;
}

interface PropsInterface<T> {
  column: string;
  stats: ColumnStats;
  missingPatterns: MissingPatterns;
  columnTypes: UserDefinedTypes;
  treatment: Treatment<T>;
  onChange: (treatment: Treatment<T>) => any;
  error?: Error;
}

function SetMissingTreatment<T extends string | number | Date>({
  column,
  stats,
  missingPatterns,
  columnTypes,
  treatment,
  onChange,
  error = {}
}: PropsInterface<T>) {
  if (stats.count === stats.size) {
    return null;
  }
  const potentialSubstitutes = Object.keys(columnTypes)
    .filter(c => c !== column && columnTypes[c] === columnTypes[column])
    .filter(c => missingPatterns[c]["missing::present"] >= 1);

  return (
    <div>
      <SetTreatmentOption
        treatment={treatment.treatment}
        onChange={t => {
          const newTreatment = { ...treatment, treatment: t };
          onChange(newTreatment);
        }}
        showSubstituteOption={potentialSubstitutes.length >= 1}
      />
      {treatment.treatment === "drop-rows" && (
        <p>
          {(stats.size - stats.count).toLocaleString()} rows are going to be
          dropped.
        </p>
      )}
      {treatment.treatment === "fill-value" && (
        <FillValue
          colType={columnTypes[column]}
          treatment={treatment}
          onChange={onChange}
          error={error.value}
        />
      )}
      {treatment.treatment === "substitute" && (
        <>
          <SelectField
            label="Substitute Column:"
            value={
              (treatment["substitute-params"] || { substitute: undefined })[
                "substitute"
              ]
            }
            options={potentialSubstitutes.map(v => ({ value: v, label: v }))}
            onValueChange={v => {
              if (v !== null) {
                const newSub = {
                  ...(treatment["substitute-params"] || {}),
                  substitute: v
                };
                const newTreatment = {
                  ...treatment,
                  "substitute-params": newSub
                };
                onChange(newTreatment);
              }
            }}
            error={error.substitute}
          />
          {treatment["substitute-params"] &&
            treatment["substitute-params"]["substitute"] && (
              <SubstituteTreatment
                colType={columnTypes[column]}
                missingPatterns={
                  missingPatterns[treatment["substitute-params"]["substitute"]]
                }
                // @ts-ignore
                treatment={(treatment["substitute-params"] || {})["treatment"]}
                onChange={t => {
                  const newSub = {
                    ...(treatment["substitute-params"] || {}),
                    treatment: t
                  };
                  const newTreatment = {
                    ...treatment,
                    "substitute-params": newSub
                  };
                  // @ts-ignore
                  onChange(newTreatment);
                }}
                treatmentError={error["substitute-treatment"]}
                valueError={error["substitute-fill"]}
              />
            )}
        </>
      )}
    </div>
  );
}

export default SetMissingTreatment;
