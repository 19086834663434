import React from "react";

import { Value, UpdateProps } from "./types";

function Excluded<T extends Value>({
  selected,
  options,
  onValueChange
}: UpdateProps<T>): JSX.Element | null {
  if (selected.length === options.length) {
    return null;
  }

  const excluded = options.filter(opt => !selected.includes(opt.value));

  return (
    <p className="tags">
      <span style={{ marginBottom: "0.5rem", marginRight: "0.5rem" }}>
        Excluded Values:{" "}
      </span>
      {excluded.map(({ value, label }) => (
        <span className="tag is-danger" key={value.toString()}>
          {label}
          <button
            className="delete is-small"
            onClick={() => onValueChange([...selected, value])}
          />
        </span>
      ))}
    </p>
  );
}
export default Excluded