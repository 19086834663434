import React from "react";

import { Route, RouteProps } from "react-router-dom";

import { useStoreState } from "store";
import { LoadingIndicator } from "UI-Components";

import Login from "App/Login";

const PrivateRoute: React.FC<RouteProps> = ({ children, exact, ...rest }) => {
  const logged_in = useStoreState(state => state.auth.logged_in);
  const user_details = useStoreState(state => state.auth.user_details);
  if (logged_in) {
    return user_details ? (
      <Route {...rest}>{children}</Route>
    ) : (
      <LoadingIndicator />
    );
  } else {
    return <Login />;
  }
};

export default PrivateRoute;
