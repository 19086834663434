import React, { useEffect } from "react";

import Tour, { ReactourStep } from "reactour";

import { useStoreState, useStoreActions } from "store";

interface Props {
  tourKey: string;
  steps: ReactourStep[];
  onStart?: () => any;
  onFinish?: () => any;
}

const TourGuide: React.FC<Props> = ({ tourKey, steps, onStart, onFinish }) => {
  const tourSeen = useStoreState((store) => store.tour_guide.status[tourKey]);
  const updateSeen = useStoreActions(
    (actions) => actions.tour_guide.updateStatus
  );
  const updateCurrent = useStoreActions(
    (actions) => actions.tour_guide.updateCurrent
  );

  useEffect(() => {
    updateCurrent(tourKey);
  }, [updateCurrent, tourKey]);

  if (steps.length === 0) {
    return null;
  }

  if (tourSeen) {
    return null;
  }

  return (
    <Tour
      steps={steps}
      badgeContent={(curr, tot) => `${curr} of ${tot}`}
      isOpen={true}
      onAfterOpen={onStart}
      onRequestClose={() => {
        updateSeen({ key: tourKey, seen: true });
        if (onFinish) {
          onFinish();
        }
      }}
      lastStepNextButton={
        <button className="is-primary button" type="button">
          Done!
        </button>
      }
    />
  );
};

export default TourGuide;
