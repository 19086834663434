import { action, thunk, actionOn } from "easy-peasy";
import { delay } from "utils";

import StateInterface from "./types";

const model: StateInterface = {
  // State
  recipes: {},

  recipeFetchInProgress: false,

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  updateRecipes: action((state, recipes) => {
    recipes.forEach(r => {
      state.recipes[r.key] = r;
    });
  }),

  updateRecipeFetchFlag: action((state, flag) => {
    state.recipeFetchInProgress = flag;
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.recipes = {};
      state.recipeFetchInProgress = false;
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  fetchRecipes: thunk(
    async (actions, page_number = 1, { injections, getStoreActions }) => {
      if (page_number === undefined || page_number === null) {
        page_number = 1;
      }
      const { urls } = injections;
      if (page_number === 1) {
        actions.updateRecipeFetchFlag(true);
      }

      const offset = 10 * (page_number - 1);
      const limit = 10;

      const url = `${urls.recipes}?offset=${offset}&limit=${limit}`;

      getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: async ({ success, data }) => {
          if (success) {
            actions.updateRecipes(data.results);
            actions.updateRecipeFetchFlag(false);
            if (data.next) {
              actions.fetchRecipes(page_number + 1);
            }
          } else {
            await delay(5000);
            actions.fetchRecipes(page_number);
          }
        }
      });
    }
  ),

  fetchRecipe: thunk(
    async (
      actions,
      recipe,
      { getState, getStoreState, getStoreActions, injections }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }
      const localState = getState();
      if (localState.recipes[recipe] !== undefined) {
        return;
      }

      const { urls } = injections;
      getStoreActions().api_client.fetch({
        url: `${urls.recipes}/${recipe}`,
        method: "GET",
        onComplete: async ({ success, data }) => {
          if (success) {
            actions.updateRecipes([data]);
          } else {
            await delay(5000);
            actions.fetchRecipe(recipe);
          }
        }
      });
    }
  )

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
};

export default model;
