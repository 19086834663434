import { getScale, isNumber } from "../utils";
import { filterObjectsByValidity } from "../data-utils";

interface Data {
  value: number;
  [key: string]: any;
}

export default (data: Data[], valueRange: number[], varyOpacity: boolean) => {
  let opacityScale = (x: number): number => 1;
  valueRange = valueRange.filter(isNumber);

  if (varyOpacity) {
    if (valueRange.length !== 2) {
      data = filterObjectsByValidity(data, "value", "numeric");
      valueRange = [
        Math.min(...data.map(({ value }) => value)),
        Math.max(...data.map(({ value }) => value))
      ];
    }

    opacityScale = getScale({
      varType: "float",
      domain: valueRange,
      axisLength: [0.1, 0.9],
      scaleType: "sqrt"
    });
  }

  return opacityScale;
};
