import { action, thunk, computed, actionOn, thunkOn } from "easy-peasy";

import { delay } from "utils";

import StateInterface from "./types";
import { DataFilter } from "../types";
import { make_spec, validate_spec } from "./spec";

const model: StateInterface = {
  // State

  input_data: {},
  variable_mapping: {},
  model_params: {},
  submit_error: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})

  updateInputData: action((state, { recipe, input_data }) => {
    const { location, data } = input_data;
    if (state.input_data[recipe] === undefined) {
      state.input_data[recipe] = {};
    }
    if (data !== undefined && data.length > 0) {
      state.input_data[recipe][location] = data;
    } else {
      delete state.input_data[recipe][location];
    }
  }),

  updateVariableMapping: action((state, { recipe, variable_mapping }) => {
    const { key, value } = variable_mapping;
    if (state.variable_mapping[recipe] === undefined) {
      state.variable_mapping[recipe] = {};
    }
    if (value !== undefined && value.length > 0) {
      state.variable_mapping[recipe][key] = value;
    } else {
      delete state.variable_mapping[recipe][key];
    }
  }),

  updateModelParams: action((state, { recipe, model_params }) => {
    const { key, value } = model_params;

    if (state.model_params[recipe] === undefined) {
      state.model_params[recipe] = {};
    }
    if (value === undefined) {
      delete state.model_params[recipe][key];
    } else {
      state.model_params[recipe][key] = value;
    }
  }),

  setSubmitError: action((state, error) => {
    state.submit_error = error || {};
  }),

  setResult: action((state, result) => {
    state.result = result;
  }),

  resetTaskStatus: action((state) => {
    delete state.task_status;
    delete state.result;
    state.submit_error = {};
  }),

  updateTaskStatus: action((state, { status, result }) => {
    state.task_status = status;
    if (result) {
      state.result = result;
    }
  }),

  onParamChange: actionOn(
    (actions) => [
      actions.updateInputData,
      actions.updateVariableMapping,
      actions.updateModelParams,
    ],
    (state) => {
      delete state.task_status;
      delete state.result;
      state.submit_error = {};
    }
  ),

  onGlobalSettingsChange: thunkOn(
    (_, globalActions) => [
      globalActions.data_files_user_settings.setColumnType,
      globalActions.data_files_user_settings.setColumnDropStatus,
    ],
    async (actions, target, { getState, getStoreState }) => {
      const state = getState();
      const recipes = getStoreState().recipes.recipes;
      const payload = target.payload;
      const { file_id, column } = payload;

      const { input_data, variable_mapping, model_params } = state;

      Object.keys(input_data).forEach((recipe) => {
        Object.keys(input_data[recipe]).forEach((loc_key) => {
          if (input_data[recipe][loc_key].includes(file_id)) {
            const recipe_details = recipes[recipe];
            if (variable_mapping[recipe]) {
              recipe_details?.variable_mapping.forEach(({ key }) => {
                const l = variable_mapping[recipe][key] || [];
                if (l.includes(column)) {
                  actions.updateVariableMapping({
                    recipe,
                    variable_mapping: {
                      key,
                      value: variable_mapping[recipe][key].filter(
                        (v) => v !== column
                      ),
                    },
                  });
                }
              });
            }
            if (model_params[recipe]) {
              recipe_details?.model_params.forEach(({ key, value_type }) => {
                if (value_type === "filters") {
                  // @ts-ignore
                  const selected: DataFilter[] =
                    model_params[recipe][key] || [];

                  const new_filters = selected.filter(
                    (f) => f.column !== column
                  );
                  if (new_filters.length < selected.length) {
                    actions.updateModelParams({
                      recipe,
                      model_params: { key, value: new_filters },
                    });
                  }
                }
              });
            }
          }
        });
      });
    }
  ),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout,
    ],
    (state) => {
      state.input_data = {};
      state.variable_mapping = {};
      state.model_params = {};
      delete state.task_status;
      delete state.result;
      state.submit_error = {};
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  create: thunk(
    async (
      actions,
      recipe,
      { injections, getState, getStoreState, getStoreActions }
    ) => {
      const localState = getState();
      const globalState = getStoreState();

      const validity = validate_spec(recipe, localState, globalState);
      if (validity["error"]) {
        const k = validity["field"] || "recipe";
        actions.setSubmitError({
          [k]: validity["message"] || "Invalid Settings",
        });
        return;
      }

      const spec = make_spec(recipe, localState, globalState);
      if (!spec) {
        return;
      }
      const { api_client } = getStoreActions();

      await actions.updateTaskStatus({
        status: { completed: false, error: false },
      });
      await api_client.fetch({
        url: injections.urls.create_new_model,
        method: "post",
        data: spec,
        onComplete: async ({ success, data, error }) => {
          if (success) {
            if (data.request_id) {
              // Fetch Task Status
              actions.fetchTaskStatus(data.request_id);
            } else {
              actions.updateTaskStatus({
                status: data.compute_status,
                result:
                  data.data && Array.isArray(data.data) ? data.data : undefined,
              });
            }
          } else if (error === "Login Failed") {
            actions.updateTaskStatus({
              status: { error: true, error_message: error },
            });
          } else {
            await delay(5000);
            actions.create(recipe);
          }
        },
      });
    }
  ),

  fetchTaskStatus: thunk(
    async (actions, task_id, { getStoreActions, injections }) => {
      const url = `${injections.urls.task_status}/${task_id}`;
      await getStoreActions().api_client.fetch({
        url: url,
        method: "GET",
        onComplete: async ({ success, error, data }) => {
          if (!success) {
            if (error === "Login Failed") {
              await delay(5000);
              actions.fetchTaskStatus(task_id);
            } else {
              actions.updateTaskStatus({
                status: { error: true, error_message: error },
              });
            }
            return;
          }

          if (data.status === "FAILURE") {
            actions.updateTaskStatus({
              status: { error: true, error_message: "Unable to compute..." },
            });
          } else if (data.status === "SUCCESS") {
            if (data.result.error) {
              actions.updateTaskStatus({
                status: {
                  error: true,
                  error_message:
                    data.result.error_message || data.result.message,
                },
              });
            } else if (data.result.completed === true) {
              actions.updateTaskStatus({
                status: { error: false, completed: true },
                result: data.result.data,
              });
            }
          } else {
            await delay(2000);
            actions.fetchTaskStatus(task_id);
          }
        },
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getComputeStatus: computed((state) => () => {
    return state.task_status;
  }),
};

export default model;
