import React from "react";

import GroupedSwitch from "../Grouped-Switch";

interface PropsInterface {
  groups: string[];
  color_func: (g: string) => string;
  showing_groups?: string[];
  onDisplayStatusChange?: (showing_groups: string[]) => any;
  orientation?: "vertical" | "horizontal";
}

const OPTIONS = [
  { label: "SHOW", key: "show" },
  { label: "HIDE", key: "hide" }
];

const Legend: React.FC<PropsInterface> = ({
  groups,
  color_func,
  showing_groups = [],
  onDisplayStatusChange,
  orientation = "vertical"
}) => {
  if (!onDisplayStatusChange) {
    return (
      <div className={orientation === "vertical" ? "" : "columns"}>
        {groups.map(g => (
          <p key={g}>
            <svg width={20} height={20}>
              <g transform="translate(0,5)">
                <circle cx={10} cy={7.5} r={7.5} fill={color_func(g)} />
              </g>
            </svg>{" "}
            <b>{g}</b>
          </p>
        ))}
      </div>
    );
  }

  return (
    <div className={orientation === "vertical" ? "" : "columns"}>
      {groups.map(g => (
        <GroupedSwitch
          key={g}
          options={OPTIONS}
          className={orientation === "vertical" ? "" : "column"}
          label={
            <p>
              <span>
                <svg width={20} height={20}>
                  <g transform="translate(0,5)">
                    <circle cx={10} cy={7.5} r={7.5} fill={color_func(g)} />
                  </g>
                </svg>
              </span>{" "}
              {g}
            </p>
          }
          onClick={({ key }) => {
            if (key === "show" && !showing_groups.includes(g)) {
              onDisplayStatusChange([...showing_groups, g]);
            } else if (key === "hide" && showing_groups.includes(g)) {
              onDisplayStatusChange(showing_groups.filter(s => s !== g));
            }
          }}
          active={showing_groups.includes(g) ? "show" : "hide"}
        />
      ))}
    </div>
  );
};

export default Legend;
