import { ScatterPointDatum } from "Visualizations";

import { TimeResolution } from "./types";
import { annotateTimeResolution } from "utils";

const annotater = (resolution: TimeResolution) => {
  return (p: ScatterPointDatum) => {
    const group =
      p.group === undefined ? "" : `; Group: ${p.group.toLocaleString()}`;
    const value = `Value: ${p.y.toLocaleString()}${group}`;
    const time = annotateTimeResolution(p.x, resolution);
    return `${time}: ${value}`;
  };
};

export default annotater;
