import React, { useState } from "react";

import { useForm, FormContext } from "react-hook-form";
import { faAt, faLock } from "@fortawesome/free-solid-svg-icons";

import { Error, LoadingIndicator, InputField } from "UI-Components";

import { useStoreState, useStoreActions } from "store";

type FormData = { email: string; password: string };

const Login: React.FC = () => {
  const [touched, setTouched] = useState<boolean>(false);

  const loginStarted = useStoreState(store => store.auth.login_started);
  const loginError = useStoreState(store => store.auth.login_error);
  const loggedIn = useStoreState(store => store.auth.logged_in);

  const login = useStoreActions(actions => actions.auth.startLogin);

  const methods = useForm<FormData>();
  const { handleSubmit, watch, errors } = methods;

  return (
    <section className="section">
      {loginStarted && !loginError && !loggedIn && <LoadingIndicator />}
      <div className="container">
        <FormContext {...methods}>
          <form
            onSubmit={handleSubmit(data => {
              login(data);
              setTouched(false);
            })}
          >
            {loginStarted && loginError && !touched && (
              <Error error={loginError} />
            )}

            <InputField
              label="E-Mail"
              name="email"
              type="email"
              icon={faAt}
              required={true}
              value={watch("email")}
              error={errors?.email?.message}
              onValueChange={() => setTouched(true)}
            />
            <InputField
              label="Password"
              name="password"
              type="password"
              icon={faLock}
              required={true}
              value={watch("password")}
              error={errors?.password?.message}
              onValueChange={() => setTouched(true)}
            />
            <div className="field">
              <p className="control">
                <button className="button is-success" type="submit">
                  Login
                </button>
              </p>
            </div>
          </form>
        </FormContext>
      </div>
    </section>
  );
};

export default Login;
