import React from "react";

import SelectField from "../Select-Field";

const CONDITIONS = [
  { value: "exclude", label: "Exclude ALL values in range" },
  { value: "include", label: "Include ANY values in range" }
];

const Condition: React.FC<{ onValueChange: (condition: string) => any }> = ({
  onValueChange
}) => (
  <SelectField
    label="Filter Condition:"
    name="condition"
    // placeholder="Choose Condition: Include/Exclude"
    options={CONDITIONS}
    required={true}
    onValueChange={v => {
      if (v !== null) {
        onValueChange(v);
      }
    }}
  />
);

export default Condition;
