import { action, actionOn } from "easy-peasy";

import StateInterface from "./types";

const model: StateInterface = {
  crumbs: [],

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})
  push_crumb: action((state, payload) => {
    const existing = state.crumbs.find(
      c => c.link === payload.link && c.text === payload.text
    );
    if (!existing) {
      state.crumbs.push(payload);
    }
  }),
  pop_crumb: action(state => {
    state.crumbs.pop();
  }),
  replace: action((state, payload) => {
    const last = state.crumbs.pop();
    state.crumbs.push({ ...last, ...payload });
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.crumbs = [];
    }
  )
};

export default model;
