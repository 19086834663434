import React, { useState } from "react";

interface Dict {
  [index: string]: string | string[] | Dict;
}

interface PropsInterface {
  error: string | Dict | string[];
  /** Defaults to false */
  enable_hide?: boolean;
}

const Error: React.FC<PropsInterface> = ({ error, enable_hide }) => {
  const [show, updateShow] = useState(true);
  if (!show) {
    return null;
  }

  if (enable_hide === null) {
    enable_hide = false;
  }

  let message;

  if (typeof error === "string") {
    message = error;
  } else if (Array.isArray(error)) {
    message =
      error.length === 1
        ? error[0]
        : error.map(e => <p key={e.toString()}>{e.toString()}</p>);
  } else if (typeof error === "object") {
    message = Object.keys(error).map(k =>
      k === "non_field_errors" ? (
        <p key={k}>{error[k]}</p>
      ) : (
        <p key={k}>
          {k}: {error[k]}
        </p>
      )
    );
  } else {
    message = JSON.stringify(error);
  }

  return (
    <div className={"notification is-danger"}>
      {enable_hide && (
        <button
          className="delete"
          onClick={() => updateShow(false)}
          data-testid="hideButton"
        />
      )}
      {message}
    </div>
  );
};

export default Error;
