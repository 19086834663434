import { Margin } from "Visualizations/types";

export default (
  position: "vertical" | "horizontal",
  margin: Margin,
  width: number,
  offsetX: number,
  offsetY: number,
  toolTipHeight: number,
  toolTipWidth: number
) => {
  let x: number;
  let y: number;

  if (position === "vertical") {
    x =
      offsetX + toolTipWidth / 2 > width - (margin.left + margin.right)
        ? offsetX - toolTipWidth
        : offsetX - toolTipWidth / 2 < margin.left
        ? margin.left
        : offsetX - toolTipWidth / 2;
    if (x < 0) {
      x = offsetX;
    }
  } else {
    x =
      offsetX + 10 + toolTipWidth > width
        ? offsetX - toolTipWidth - 10
        : offsetX + 10;
    if (x < 0) {
      x = offsetX + 10;
    }
  }
  y = offsetY < toolTipHeight + 5 ? offsetY + 5 : offsetY - (toolTipHeight + 5);

  return [x, y];
};
