import { action, thunk, computed, actionOn } from "easy-peasy";

import { delay } from "utils";
import StateInterface from "./types";

const model: StateInterface = {
  // State
  insights: {},
  tasks: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})

  updateTask: action((state, payload) => {
    const { task_id, task_details } = payload;
    state.tasks[task_id] = task_details;
  }),

  updateInsight: action((state, payload) => {
    const { file_id, insight_type, spec_string, insight } = payload;
    if (!state.insights[file_id]) {
      state.insights[file_id] = {};
    }
    if (!state.insights[file_id][insight_type]) {
      state.insights[file_id][insight_type] = {};
    }
    state.insights[file_id][insight_type][spec_string] = insight;
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [storeActions.reset.reset],
    state => {
      state.insights = {};
      state.tasks = {};
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.

  createInsight: thunk(async (actions, payload, { getStoreActions }) => {
    const { url, file_id, insight_type, spec, spec_string = "" } = payload;
    actions.updateInsight({
      file_id,
      insight_type,
      spec_string,
      insight: { compute_status: { error: false, completed: false } }
    });

    await getStoreActions().api_client.fetch({
      url,
      method: "POST",
      data: spec,
      onComplete: async ({ success, data, error }) => {
        if (success) {
          if (data.request_id) {
            actions.updateTask({
              task_id: data.request_id,
              task_details: payload
            });
            // Fetch task status
            await delay(20);
            actions.fetchTaskStatus(data.request_id);
          } else {
            actions.updateInsight({
              file_id,
              insight_type,
              spec_string,
              insight: data
            });
          }
        } else if (error !== "Login Failed") {
          actions.updateInsight({
            file_id,
            insight_type,
            spec_string,
            insight: { compute_status: { error: true, error_message: error } }
          });
        }
      }
    });
  }),

  fetchTaskStatus: thunk(
    async (actions, task_id, { injections, getState, getStoreActions }) => {
      const { urls } = injections;
      const url = `${urls.task_status}/${task_id}`;

      const task_details = getState().tasks[task_id];
      if (!task_details) {
        return;
      }
      const { file_id, insight_type, spec_string = "" } = task_details;

      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: async ({ success, error, data }) => {
          if (!success) {
            if (error === "Login Failed") {
              await delay(5000);
              actions.fetchTaskStatus(task_id);
            } else {
              actions.updateInsight({
                file_id,
                insight_type,
                spec_string,
                insight: {
                  compute_status: { error: true, error_message: error }
                }
              });
            }
            return;
          }

          if (data.status === "FAILURE") {
            actions.updateInsight({
              file_id,
              insight_type,
              spec_string,
              insight: {
                compute_status: {
                  error: true,
                  error_message: "Unable to compute..."
                }
              }
            });
          } else if (data.status === "SUCCESS") {
            if (data.result.error) {
              actions.updateInsight({
                file_id,
                insight_type,
                spec_string,
                insight: {
                  compute_status: {
                    error: true,
                    error_message:
                      data.result.error_message || data.result.message
                  }
                }
              });
            } else if (data.result.completed === true) {
              if (data.result.data) {
                actions.updateInsight({
                  file_id,
                  insight_type,
                  spec_string,
                  insight: {
                    data: data.result.data,
                    compute_status: { error: false, completed: true }
                  }
                });
              } else {
                actions.createInsight(task_details);
              }
            } else {
              await delay(2000);
              actions.fetchTaskStatus(task_id);
            }
          } else {
            await delay(2000);
            actions.fetchTaskStatus(task_id);
          }
        }
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getInsight: computed(state => (file_id, insight_type, spec_string) => {
    if (!state.insights[file_id]) {
      return undefined;
    }
    if (!state.insights[file_id][insight_type]) {
      return undefined;
    }
    return state.insights[file_id][insight_type][spec_string];
  })
};

export default model;
