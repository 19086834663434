import React from "react";

import InputRange from "../Input-Range";
import InputField from "../Input-Field";

import SetTreatmentOption from "./treatment-options";

interface ColumnStats {
  count: number;
  size: number;
  "outlier-ranges": (number | Date | string)[];
}

interface Treatment<T> {
  treatment: "drop" | "ignore" | "clamp";
  range?: T[];
  floor?: T | undefined | null;
  ceiling?: T | undefined | null;
  replacement?: string | undefined | null;
}

interface Error {
  range?: string;
  floor?: string;
  ceiling?: string;
  replacement?: string;
}

interface PropsInterface<T> {
  stats: ColumnStats;
  colType: "numeric" | "date" | "char";
  treatment: Treatment<T>;
  onChange: (treatment: Treatment<T>) => any;
  error?: Error;
}

function SetOutlierTreatment<T extends string | number | Date>({
  stats,
  colType,
  treatment,
  onChange,
  error = {}
}: PropsInterface<T>) {
  if (stats.count <= 1) {
    return null;
  }

  return (
    <div>
      <SetTreatmentOption
        treatment={treatment.treatment}
        onChange={t => {
          const newTreatment = { ...treatment, treatment: t };
          onChange(newTreatment);
        }}
      />
      {treatment.treatment && treatment.treatment !== "ignore" && (
        <InputRange
          label={"Outlier Range:"}
          type={
            colType === "char" ? "text" : colType === "date" ? "date" : "number"
          }
          range={treatment.range}
          onValueChange={range => {
            const newTreatment = { ...treatment, range };
            // @ts-ignore
            onChange(newTreatment);
          }}
          help={
            treatment.treatment === "drop"
              ? colType === "char"
                ? "All SELECTED values will be DROPPED"
                : "Values OUTSIDE the selected range will be DROPPED"
              : colType === "char"
              ? "All SELECTED values will be REPLACED by replacement value."
              : "Values OUTSIDE the selected range will be CLAMPED to floor and ceiling."
          }
          error={error.range}
        />
      )}
      {treatment.treatment === "clamp" &&
        (colType === "char" ? (
          <InputField
            type="text"
            label="Replacement Value"
            value={
              treatment.replacement ? treatment.replacement.toString() : null
            }
            onValueChange={replacement => {
              onChange({
                ...treatment,
                replacement:
                  replacement !== null && replacement !== undefined
                    ? replacement.toString()
                    : null
              });
            }}
            error={error.replacement}
          />
        ) : (
          <div className="columns">
            <div className="column is-half">
              <InputField
                type={colType === "date" ? "date" : "number"}
                value={treatment.floor}
                label="Floor"
                help="If floor is not passed, all values in the lower bound will be dropped."
                onValueChange={floor => {
                  // @ts-ignore
                  onChange({ ...treatment, floor });
                }}
                error={error.floor}
              />
            </div>
            <div className="column is-half">
              <InputField
                type={colType === "date" ? "date" : "number"}
                value={treatment.ceiling}
                label="Ceiling"
                help="If ceiling is not passed, all values in the upper bound will be dropped."
                onValueChange={ceiling => {
                  // @ts-ignore
                  onChange({ ...treatment, ceiling });
                }}
                error={error.ceiling}
              />
            </div>
          </div>
        ))}
    </div>
  );
}

export default SetOutlierTreatment;
