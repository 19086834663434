import { useState, useEffect } from "react";

import {
  filterObjectsByRange,
  filterObjectsByValidity,
  isCategorical,
  VarType
} from "Visualizations";

import { Cell } from "./types";

type Domain = (string | number | Date)[];

export default (
  data: Cell[],
  dense_domain1: Domain,
  dense_domain2: Domain,
  col_type1: VarType,
  col_type2: VarType,
  data_count: number
): [
  Cell[],
  Domain,
  Domain,
  (d: Domain) => any,
  (d: Domain) => any,
  number,
  string
] => {
  const [domain1, setDomain1] = useState<Domain>(dense_domain1);
  const [domain2, setDomain2] = useState<Domain>(dense_domain2);

  useEffect(() => {
    setDomain1(dense_domain1);
    setDomain2(dense_domain2);
  }, [dense_domain1, dense_domain2]);

  data = filterObjectsByValidity(data, "value", "numeric");

  if (isCategorical(col_type1) && isCategorical(col_type2)) {
    data = filterObjectsByRange(data, "x", col_type1, domain1);
    data = filterObjectsByRange(data, "y", col_type2, domain2);
  } else if (!isCategorical(col_type1) && !isCategorical(col_type2)) {
    data = filterObjectsByRange(data, "xLeftEdge", col_type1, domain1);
    data = filterObjectsByRange(data, "xRightEdge", col_type1, domain1);

    data = filterObjectsByRange(data, "yLeftEdge", col_type2, domain2);
    data = filterObjectsByRange(data, "yRightEdge", col_type2, domain2);
  } else {
    const catType = isCategorical(col_type1) ? col_type1 : col_type2;
    const numType = isCategorical(col_type1) ? col_type2 : col_type1;

    const catDomain = isCategorical(col_type1) ? domain1 : domain2;
    const numDomain = isCategorical(col_type1) ? domain2 : domain1;

    data = filterObjectsByRange(data, "level", catType, catDomain);
    data = filterObjectsByRange(data, "leftEdge", numType, numDomain);
    data = filterObjectsByRange(data, "rightEdge", numType, numDomain);
  }

  const total_shown = data.map(({ count }) => count).reduce((a, b) => a + b, 0);

  const p = ((total_shown / data_count) * 100).toLocaleString();

  return [data, domain1, domain2, setDomain1, setDomain2, total_shown, p];
};
