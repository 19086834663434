import { action, thunk } from "easy-peasy";

import { delay } from "utils";
import StateInterface, { Download } from "./types";

const model: StateInterface = {
  // State
  downloads: {},
  downloadTokens: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})
  updateDownloads: action((state, downloads) => {
    downloads.forEach((d) => {
      state.downloads[d.id] = d;
    });
  }),

  setDownloadToken: action((state, { downloadID, token }) => {
    state.downloadTokens[downloadID] = token;
  }),

  removeDownloadToken: action((state, downloadID) => {
    delete state.downloadTokens[downloadID];
  }),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  fetchDownloads: thunk(
    async (actions, url, { injections, getStoreActions }) => {
      const { urls } = injections;
      await getStoreActions().api_client.fetch({
        url: url || urls.downloads,
        method: "GET",
        onComplete: async ({ success, data }) => {
          if (success) {
            actions.updateDownloads(data.results);
            if (data.results) {
              data.results
                .filter(
                  (r: Download) =>
                    r.compute_status.error === false &&
                    r.compute_status.completed === false
                )
                .map((r: Download) => actions.fetchDownloadStatus(r.id));
            }
            if (data && data.next) {
              actions.fetchDownloads(data.next);
            }
          } else {
            await delay(1000);
            actions.fetchDownloads(url);
          }
        },
      });
    }
  ),

  fetchDownloadStatus: thunk(
    async (actions, download_id, { injections, getStoreActions }) => {
      const { urls } = injections;
      const url = `${urls.downloads}/${download_id}`;
      await getStoreActions().api_client.fetch({
        url,
        method: "GET",
        onComplete: async ({ success = false, data }) => {
          if (success) {
            actions.updateDownloads([data]);
            const { completed, error } = data.compute_status;
            if (!completed && !error) {
              await delay(1000);
              actions.fetchDownloadStatus(download_id);
            }
          } else {
            await delay(2500);
            actions.fetchDownloadStatus(download_id);
          }
        },
      });
    }
  ),

  generateDownloadToken: thunk(
    async (actions, downloadID, { injections, getState, getStoreActions }) => {
      const download = getState().downloads[downloadID];
      if (!download) {
        return;
      }
      if (!download.compute_status.completed || download.compute_status.error) {
        return;
      }

      await getStoreActions().api_client.fetch({
        url: download.download_token_link,
        method: "POST",
        onComplete: async ({ success, data }) => {
          if (success) {
            actions.setDownloadToken({ downloadID, token: data.token });
          } else {
            actions.removeDownloadToken(downloadID);
          }
        },
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
};

export default model;
