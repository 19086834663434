import React from "react";

import { ColumnType, Operator, Stats } from "./types";

import FormInputRange from "../Input-Range";
import InputField from "../Input-Field";
import MultiSelect from "../Multi-Select";

const InputRange = ({
  columnType,
  operator,
  stats,
}: {
  columnType: ColumnType;
  operator: Operator;
  stats: Stats;
}) => {
  const rangeType = ["int", "float", "numeric"].includes(columnType)
    ? "number"
    : columnType === "date"
    ? "date"
    : "text";

  stats = stats || {};

  if (rangeType === "text") {
    const uniqueValues = stats["unique-values"] || [];

    if (uniqueValues.length === (stats["unique-count"] || 0)) {
      return (
        <MultiSelect
          name="range"
          label="Filter Range:"
          options={uniqueValues.map((value) => ({
            value: value.toString(),
            label: value.toString(),
          }))}
          minInputs={1}
          required={true}
        />
      );
    } else {
      return (
        <FormInputRange
          name="range"
          label="Filter Range:"
          type={rangeType}
          range={
            uniqueValues.length === stats["unique-count"]
              ? stats["unique-values"]
              : undefined
          }
          required={true}
          onValueChange={() => {}}
          help={
            (stats["unique-count"] || 0) > uniqueValues.length &&
            uniqueValues.length >= 1
              ? `${
                  stats["unique-count"]
                } unique values. Sample Values: ${uniqueValues.join(", ")}`
              : undefined
          }
        />
      );
    }
  }

  const helpText =
    stats.min !== null &&
    stats.min !== undefined &&
    stats.max !== null &&
    stats.max !== undefined
      ? `Values lie between ${stats.min.toLocaleString()} and ${stats.max.toLocaleString()}`
      : "";

  if (["in", "not in", "between"].includes(operator)) {
    return (
      <FormInputRange
        name="range"
        label="Filter Range:"
        type={rangeType}
        required={true}
        onValueChange={() => {}}
        help={helpText}
      />
    );
  }

  return (
    <InputField
      name="range"
      type={rangeType}
      label="Filter Range:"
      required={true}
      onValueChange={() => {}}
      help={helpText}
    />
  );
};

export default InputRange;
