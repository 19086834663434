import { useState } from "react";

type Sorter =
  | "ante-count"
  | "cons-count"
  | "joint-count"
  | "certainty"
  | "confidence";

const useSort = () => {
  const [sorter, setSorter] = useState<Sorter>("certainty");
  const [sortD, setSortD] = useState<"asc" | "dsc">("dsc");

  const set_sorter = (s: Sorter) => {
    if (s === sorter) {
      if (sortD === "asc") {
        setSortD("dsc");
      } else {
        setSortD("asc");
      }
    } else {
      setSorter(s);
    }
  };
  return { sorter, sortD, set_sorter };
};

export default useSort;
