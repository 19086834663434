import React from "react";

import { Value, UpdateProps } from "./types";

function Selected<T extends Value>({
  selected,
  options,
  onValueChange
}: UpdateProps<T>): JSX.Element {
  if (!selected || selected.length === 0) {
    return <p>No values selected!!!</p>;
  }

  return (
    <p className="tags" style={{ paddingTop: "0.5rem" }}>
      <span style={{ marginBottom: "0.5rem", marginRight: "0.5rem" }}>
        {selected.length === options.length
          ? "Selected ALL values: "
          : "Selected values: "}
      </span>
      {selected
        .filter(value => options.find(opt => opt.value === value))
        .map(value => (
          <span className="tag is-info" key={value}>
            {
              // @ts-ignore
              options.find(opt => opt.value === value).label
            }
            <button
              className="delete is-small"
              onClick={() => onValueChange(selected.filter(v => v !== value))}
            />
          </span>
        ))}
    </p>
  );
}

export default Selected;
