import React from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Controller, useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TagsInput from "./Tag-Input";

import DatePicker from "../Date-Picker";

type ValueType = string | number | Date | undefined | null;

const getDate = (value: string | number | Date | null | undefined) => {
  if (value === undefined || value === null) {
    return value;
  } else if (value instanceof Date) {
    return value;
  } else {
    return new Date(value);
  }
};

const getString = (value: string | number | Date | null | undefined) => {
  if (value === undefined || value === null) {
    return;
  } else if (value instanceof Date) {
    return value.toLocaleString();
  } else {
    return value;
  }
};

const DEFAULT_VALUE_LIST: ValueType[] = [];

interface PropsInterface {
  label: React.ReactNode;
  /** If inside a form, this must be set, and be unique across all form-fields. */
  name?: string;
  /** Defaults to "text" */
  type?: "number" | "text" | "date";
  /** Initial Value */
  range?: ValueType[];
  /** Icon to place along with the field. If passed, the icon is on the left. */
  icon?: IconProp;
  /** Icons for non-char fields */
  startIcon?: IconProp;
  endIcon?: IconProp;
  required?: boolean;
  /** Placeholder for text type */
  placeholder?: string;
  /** Placeholders for non-text type */
  startPlaceholder?: string;
  endPlaceholder?: string;
  error?: string;
  help?: React.ReactNode;
  onValueChange(value: ValueType[] | null): any;
  tags?: React.ReactNode;
}

const InputRange: React.FC<PropsInterface> = ({
  label,
  name = "range",
  type = "text",
  range = DEFAULT_VALUE_LIST,
  required = false,
  icon,
  startIcon,
  endIcon,
  placeholder,
  startPlaceholder,
  endPlaceholder,
  help,
  error,
  onValueChange,
  tags,
}) => {
  const { register, control, watch, errors } = useFormContext() || {};

  if (control) {
    const watchedRange = watch(name);
    if (Array.isArray(watchedRange) && watchedRange.length >= 1) {
      range = watchedRange;
    }

    const formError = errors[name];
    if (Array.isArray(formError)) {
      // @ts-ignore
      error = error || formError[0]?.message;
    } else {
      // @ts-ignore
      error = error || errors[name]?.message;
    }
  }

  // @ts-ignore
  startIcon = startIcon || icon;
  // @ts-ignore
  endIcon = endIcon || icon;

  startPlaceholder = startPlaceholder || placeholder;
  endPlaceholder = endPlaceholder || placeholder;

  let comp;

  if (type === "date") {
    if (control) {
      comp = (
        <div className="columns">
          <div className="column is-half">
            <div className={icon ? "control has-icons-left" : "control"}>
              {startIcon && (
                <span className="icon is-small is-left has-text-info">
                  <FontAwesomeIcon icon={startIcon} className="fas" />
                </span>
              )}
              <Controller
                as={
                  <DatePicker
                    selected={getDate(range[0])}
                    selectsStart
                    startDate={getDate(range[0])}
                    endDate={getDate(range[1])}
                    onChange={() => {}}
                    isClearable={true}
                    placeholderText={startPlaceholder || "Start Date"}
                    className={error ? "input is-danger" : "input"}
                  />
                }
                name={`${name || "range"}[0]`}
                rules={{ required: required }}
                onChange={(d) => {
                  const v = d[0];
                  onValueChange([v, range[1]]);
                  return v;
                }}
                defaultValue={range[0]}
              />
            </div>
          </div>
          <div className="column is-half">
            <div className={icon ? "control has-icons-left" : "control"}>
              {endIcon && (
                <span className="icon is-small is-left has-text-info">
                  <FontAwesomeIcon icon={endIcon} className="fas" />
                </span>
              )}
              <Controller
                as={
                  <DatePicker
                    selected={getDate(range[1])}
                    selectsStart
                    startDate={getDate(range[0])}
                    endDate={getDate(range[1])}
                    onChange={() => {}}
                    isClearable={true}
                    placeholderText={startPlaceholder || "Start Date"}
                    className={error ? "input is-danger" : "input"}
                  />
                }
                name={`${name || "range"}[1]`}
                rules={{ required: required }}
                onChange={(d) => {
                  const v = d[0];
                  onValueChange([range[0], v]);
                  return v;
                }}
                defaultValue={range[1]}
              />
            </div>
          </div>
        </div>
      );
    } else {
      comp = (
        <div className="columns">
          <div className="column is-half">
            <div className={icon ? "control has-icons-left" : "control"}>
              {startIcon && (
                <span className="icon is-small is-left has-text-info">
                  <FontAwesomeIcon icon={startIcon} className="fas" />
                </span>
              )}
              <DatePicker
                selected={getDate(range[0])}
                selectsStart
                startDate={getDate(range[0])}
                endDate={getDate(range[1])}
                onChange={(v) => onValueChange([v, range[1]])}
                isClearable={true}
                placeholderText={startPlaceholder || "Start Date"}
                className={error ? "input is-danger" : "input"}
                required={required}
              />
            </div>
          </div>
          <div className="column is-half">
            <div className={icon ? "control has-icons-left" : "control"}>
              {endIcon && (
                <span className="icon is-small is-left has-text-info">
                  <FontAwesomeIcon icon={endIcon} className="fas" />
                </span>
              )}
              <DatePicker
                selected={getDate(range[1])}
                selectsEnd
                startDate={getDate(range[0])}
                endDate={getDate(range[1])}
                onChange={(v) => onValueChange([range[0], v])}
                isClearable={true}
                placeholderText={endPlaceholder || "End Date"}
                className={error ? "input is-danger" : "input"}
                required={required}
              />
            </div>
          </div>
        </div>
      );
    }
  } else if (type === "number") {
    comp = (
      <div className="columns">
        <div className="column is-half">
          <div className={icon ? "control has-icons-left" : "control"}>
            {startIcon && (
              <span className="icon is-small is-left has-text-info">
                <FontAwesomeIcon icon={startIcon} className="fas" />
              </span>
            )}
            <input
              ref={register}
              name={`${name || "range"}[0]`}
              data-testid="input-1"
              type="text"
              className={error ? "input is-danger" : "input"}
              value={getString(range[0])}
              placeholder={startPlaceholder || "Min Value"}
              required={required}
              onChange={(e) =>
                onValueChange([
                  isNaN(parseFloat(e.target.value))
                    ? e.target.value
                    : parseFloat(e.target.value),
                  range[1],
                ])
              }
            />
          </div>
        </div>
        <div className="column is-half">
          <div className={icon ? "control has-icons-left" : "control"}>
            {endIcon && (
              <span className="icon is-small is-left has-text-info">
                <FontAwesomeIcon icon={endIcon} className="fas" />
              </span>
            )}
            <input
              ref={register}
              name={`${name || "range"}[1]`}
              data-testid="input-2"
              type="text"
              className={error ? "input is-danger" : "input"}
              value={getString(range[1])}
              placeholder={startPlaceholder || "Max Value"}
              required={required}
              onChange={(e) =>
                onValueChange([
                  range[0],
                  isNaN(parseFloat(e.target.value))
                    ? e.target.value
                    : parseFloat(e.target.value),
                ])
              }
            />
          </div>
        </div>
      </div>
    );
  } else if (type === "text") {
    comp = (
      <div className={icon ? "control has-icons-left" : "control"}>
        {endIcon && (
          <span className="icon is-small is-left has-text-info">
            <FontAwesomeIcon icon={endIcon} className="fas" />
          </span>
        )}
        {control ? (
          <Controller
            as={
              <TagsInput
                value={range || DEFAULT_VALUE_LIST}
                onChange={() => {}}
                className={
                  error
                    ? "react-tagsinput input is-danger"
                    : "react-tagsinput input "
                }
              />
            }
            name={name || "range"}
            rules={{ required: required }}
            onChange={(d) => {
              const v = d[0];
              if (onValueChange) {
                onValueChange(v);
              }
              return v;
            }}
            defaultValue={range || DEFAULT_VALUE_LIST}
          />
        ) : (
          <TagsInput
            value={range || DEFAULT_VALUE_LIST}
            onChange={onValueChange}
            className={error ? "is-danger" : ""}
          />
        )}
      </div>
    );
  }

  return (
    <div className="field">
      <label className="label">
        {label} {required && <span className="tag is-primary">MANDATORY</span>}
        {tags && tags}
      </label>
      {comp}
      {help && <div className="help">{help}</div>}
      {error && <p className={"help is-danger"}>{error}</p>}
    </div>
  );
};

export default InputRange;
