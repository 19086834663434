import React from "react";

import formatValue from "utils/format-value";

interface Stats {
  type: "int" | "float" | "char" | "date";
  count: number;
  "unique-count": number;
  "unique-values": (string | number | Date)[];
}

interface NumericStats extends Stats {
  min: number | string | Date;
  max: number | string | Date;
  mean: number | string | Date;
  median: number | string | Date;
  "25%": number | string | Date;
  "75%": number | string | Date;
  "2.5%": number | string | Date;
  "97.5%": number | string | Date;
}

interface DSNUmericProps {
  stats: NumericStats;
  type: "numeric" | "date";
}

const DSNumeric = ({ stats, type }: DSNUmericProps) => {
  const min = formatValue(stats["min"], type);
  const max = formatValue(stats["max"], type);
  const mean = formatValue(stats["mean"], type);
  const median = formatValue(stats["median"], type);
  const p25 = formatValue(stats["25%"], type);
  const p75 = formatValue(stats["75%"], type);

  const p025 = formatValue(stats["2.5%"], type);
  const p975 = formatValue(stats["97.5%"], type);

  let range: string;
  if (stats["2.5%"] === stats["97.5%"]) {
    range = `95% of the data is a SINGLE VALUE: ${p025}`;
  } else {
    range = `95% of the data lies between ${p025} and ${p975}`;
  }

  return (
    <div>
      <p className="help is-info">
        Data ranges between {min} and {max}. {range}.
      </p>
      <p className="help is-info">
        Mean: {mean}, Median: {median}, 25th percentile: {p25}, 75th percentile:{" "}
        {p75}.
      </p>
    </div>
  );
};

interface Distribution {
  "dense-levels": (number | string)[];
  "dense-level-points": number;
}

interface DSCharProps {
  stats: Stats;
  type: "char";
  distribution?: Distribution;
}

const DSChar = ({ stats, distribution }: DSCharProps) => {
  let range: string = "";
  if (distribution) {
    const denseLevels = distribution["dense-levels"];
    if (denseLevels && denseLevels.length < stats["unique-count"]) {
      const p = (distribution["dense-level-points"] / stats["count"]) * 100;

      let top_values, contr;

      if (p >= 1) {
        top_values = `The top ${
          denseLevels.length >= 20 ? 20 : ""
        } values are [${denseLevels.slice(0, 20).join(", ")}]`;
        contr = `The top ${denseLevels.length.toLocaleString()} unique values contribute to ${p.toLocaleString()}% of the data. `;
      } else {
        contr = "The data seems to be extremely widely distributed. ";
        top_values = `Some sample values: [${denseLevels
          .slice(0, 20)
          .join(", ")}]`;
      }

      range = contr + top_values;
    }
  } else {
    range = `Sample Values: ${stats["unique-values"].slice(0, 20).join(", ")}`;
  }

  return (
    <div>
      <p>
        The column contains {stats["unique-count"].toLocaleString()} unique
        values.
      </p>
      {range !== "" && <p className="help is-info">{range}</p>}
    </div>
  );
};

function DescriptiveStats(props: DSCharProps): React.ReactElement;
function DescriptiveStats(props: DSNUmericProps): React.ReactElement;
function DescriptiveStats(props: {
  type: string;
  stats: Stats;
  distribution?: Distribution;
}): any {
  let { stats, type, distribution } = props;
  type = type || stats["type"];

  if (!["int", "float", "date", "time", "numeric", "char"].includes(type)) {
    type = "char";
  }

  if (stats["unique-count"] === 0) {
    return (
      <div>
        <p>NO valid values present in the data.</p>
      </div>
    );
  }

  if (stats["unique-count"] === 1) {
    return (
      <div>
        <p>
          The entire column is a constant:{" "}
          <b>
            <i>
              {formatValue(
                stats["unique-values"][0],
                // @ts-ignore
                type
              )}
            </i>
          </b>
        </p>
      </div>
    );
  }

  if (type === "char") {
    if (stats["unique-count"] === 1) {
      return (
        <div>
          <p>
            The entire column is a constant:{" "}
            <b>
              <i>{formatValue(stats["unique-values"][0], type)}</i>
            </b>
          </p>
        </div>
      );
    }
    return <DSChar stats={stats} type={type} distribution={distribution} />;
  }

  if (
    type === "int" ||
    type === "float" ||
    type === "numeric" ||
    type === "date" ||
    type === "time"
  ) {
    if (stats["unique-count"] === 1) {
      return (
        <div>
          <p>
            The entire column is a constant:{" "}
            <b>
              <i>{formatValue(stats["unique-values"][0], type)}</i>
            </b>
          </p>
        </div>
      );
    }
    return (
      <DSNumeric
        // @ts-ignore
        stats={stats}
        type={type === "date" || type === "time" ? "date" : "numeric"}
      />
    );
  } else {
    return null;
  }
}

export default DescriptiveStats;
