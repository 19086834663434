import { VarType } from "Visualizations/types";

import { GridCell } from "./types";
import getGroups from "./groups";

const getSizes = (
  data: GridCell[],
  axisVariableType: VarType,
  levels: (string | number | Date)[],
  key: string
) =>
  levels.map(l =>
    data
      .filter(d =>
        axisVariableType === "date" || axisVariableType === "time"
          ? d[key].toString() === l.toString()
          : d[key] === l
      )
      .map(d => d.value)
      .reduce((a, b) => a + b, 0)
  );

const getStackSizes = (
  data: GridCell[],
  axisVariableType: VarType,
  stackVariableType: VarType
): number[] => {
  const [levels, key] = getGroups(data, axisVariableType, stackVariableType);

  const groups = Array.from(
    new Set(
      data.map(({ group }) =>
        group === undefined || group === null ? group : group.toString()
      )
    )
  );

  if (groups.length === 1) {
    return getSizes(data, axisVariableType, levels, key);
  } else {
    let sizes: number[] = [];

    groups.forEach(g => {
      const fd = data.filter(({ group }) =>
        group === undefined || group === null
          ? g === null || g === undefined
          : group.toString() === g
      );
      const groupSizes = getSizes(fd, axisVariableType, levels, key);
      sizes.push(...groupSizes);
    });

    return sizes;
  }
};

export default getStackSizes;
