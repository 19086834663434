import { action, thunk, computed, actionOn } from "easy-peasy";

import { OutlierTreatment, MissingTreatment } from "./prepare-spec";
import StateInterface, { Spec, StringifySpec } from "./types";

import { delay } from "utils";

import {
  getInitMissingTreatment,
  getInitOutlierTreatment,
  getInitDropStatus
} from "./init-treatment";

import validateSpec from "./validate-spec";

const stringifySpec: StringifySpec = spec => JSON.stringify(spec);

const model: StateInterface = {
  name: {},
  outlier_treatment: {},
  missing_treatment: {},
  string_transforms: {},
  outlierErrors: {},
  missingErrors: {},
  stringTransformErrors: {},
  taskStatus: {},
  result: null,

  // Store-Updaters
  // For e.g., an action that has params as a parameter type.
  // update_hygiene: action((state, payload) => {}),

  setParams: action((state, { file_id, params }) => {
    const { name } = params;
    if (name) {
      state.name[file_id] = name;
    }
  }),

  resetParams: action(state => {
    state.name = {};
    state.outlier_treatment = {};
    state.missing_treatment = {};
    state.string_transforms = {};
    state.outlierErrors = {};
    state.missingErrors = {};
    state.stringTransformErrors = {};
    state.taskStatus = {};
    state.result = null;
  }),

  set_outlier_treatment: action(
    (state, { source, column, treatment, option, colType }) => {
      if (!state.outlier_treatment[source]) {
        state.outlier_treatment[source] = {};
      }

      if (!state.outlier_treatment[source][column]) {
        state.outlier_treatment[source][column] = { treatment: "ignore" };
      } else if (option) {
        state.outlier_treatment[source][column]["treatment"] = option;
      }

      if (colType && treatment) {
        // @ts-ignore
        state.outlier_treatment[source][column][colType] = treatment;
      }

      if (state.outlierErrors[source]) {
        delete state.outlierErrors[source][column];
      }
    }
  ),

  set_outlier_errors: action((state, { file_id, errors }) => {
    state.outlierErrors[file_id] = errors;
  }),

  set_missing_treatment: action(
    (state, { source, column, treatment, option, colType }) => {
      if (!state.missing_treatment[source]) {
        state.missing_treatment[source] = {};
      }

      if (!state.missing_treatment[source][column]) {
        state.missing_treatment[source][column] = { treatment: "ignore" };
      } else if (option) {
        state.missing_treatment[source][column]["treatment"] = option;
      }

      if (colType && treatment) {
        // @ts-ignore
        state.missing_treatment[source][column][colType] = treatment;
      }

      if (state.missingErrors[source]) {
        delete state.missingErrors[source][column];
      }
    }
  ),

  set_missing_errors: action((state, { file_id, errors }) => {
    state.missingErrors[file_id] = errors;
  }),

  set_string_transform: action((state, { source, column, treatment }) => {
    if (!state.string_transforms[source]) {
      state.string_transforms[source] = {};
    }

    if (treatment === null) {
      delete state.string_transforms[source][column];
    } else {
      state.string_transforms[source][column] = treatment;
    }
  }),

  set_string_transform_errors: action((state, { file_id, errors }) => {
    state.stringTransformErrors[file_id] = errors;
  }),

  initTreatmentForColumn: thunk(
    async (
      actions,
      { file_id, column, colType },
      { getState, getStoreState, getStoreActions }
    ) => {
      if (!file_id) {
        return;
      }

      if (!getState().initRequired(file_id, column, colType)) {
        return;
      }

      const globalActions = getStoreActions();
      await globalActions.data_file_column_profiles.create({
        file_id,
        column,
        col_type: colType
      });
      while (true) {
        const globalState = getStoreState();
        const profile = globalState.data_file_column_profiles.getData(
          file_id,
          column
        );

        if (profile) {
          // Do Something
          const { stats } = profile;
          const state = getState();
          const outlier_treatment = state.outlier_treatment[file_id] || {};
          const missing_treatment = state.missing_treatment[file_id] || {};
          const outlierTreatmentForColumn = outlier_treatment[column];
          const outlierTreatment = (outlierTreatmentForColumn || {})[colType];
          if (!outlierTreatment) {
            const { option, range } = getInitOutlierTreatment(colType, stats);
            if (outlierTreatmentForColumn) {
              actions.set_outlier_treatment({
                source: file_id,
                column,
                colType,
                // @ts-ignore
                treatment: { range }
              });
            } else {
              actions.set_outlier_treatment({
                source: file_id,
                column,
                colType,
                option,
                // @ts-ignore
                treatment: { range }
              });
            }
          }
          const missingTreatmentForColumn = missing_treatment[column];
          const missingTreatment = (missingTreatmentForColumn || {})[colType];
          if (!missingTreatment) {
            const { option, ...rest } = getInitMissingTreatment(colType, stats);
            if (missingTreatmentForColumn) {
              actions.set_missing_treatment({
                source: file_id,
                column,
                colType,
                // @ts-ignore
                treatment: rest
              });
            } else {
              actions.set_missing_treatment({
                source: file_id,
                column,
                colType,
                option,
                // @ts-ignore
                treatment: rest
              });
            }
          }
          if (!missingTreatmentForColumn && !outlierTreatmentForColumn) {
            const status = getInitDropStatus(stats);
            globalActions.data_files_user_settings.setColumnDropStatus({
              file_id,
              column,
              status
            });
          }
          return;
        }

        await delay(300);
      }
    }
  ),

  onSourceDataChange: thunk(async (actions, file_id, { getStoreState }) => {
    if (!file_id) {
      return;
    }

    let globalState = getStoreState();
    let fileDetails = globalState.data_files.getFileDetails(file_id);
    let deducedTypes = globalState.data_file_column_types.getData(file_id);

    while (!fileDetails || !deducedTypes) {
      await delay(300);
      globalState = getStoreState();
      fileDetails = globalState.data_files.getFileDetails(file_id);
      deducedTypes = globalState.data_file_column_types.getData(file_id);
    }

    (fileDetails?.columns || []).forEach(async column => {
      if ((deducedTypes || {})[column] === "int") {
        actions.initTreatmentForColumn({
          file_id,
          column,
          colType: "numeric"
        });
        actions.initTreatmentForColumn({
          file_id,
          column,
          colType: "char"
        });
      } else if ((deducedTypes || {})[column] === "float") {
        actions.initTreatmentForColumn({
          file_id,
          column,
          colType: "numeric"
        });
      } else if ((deducedTypes || {})[column] === "date") {
        actions.initTreatmentForColumn({
          file_id,
          column,
          colType: "date"
        });
      } else {
        actions.initTreatmentForColumn({
          file_id,
          column,
          colType: "char"
        });
      }
    });

    return;
  }),

  setResult: action((state, result) => {
    state.result = result;
  }),

  resetTaskStatus: action(state => {
    state.taskStatus = {};
  }),

  // Update Store
  updateTaskStatus: action((state, { spec, status, result }) => {
    state.taskStatus[stringifySpec(spec)] = status;
    if (result) {
      state.result = result;
    }
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.name = {};
      state.outlier_treatment = {};
      state.missing_treatment = {};
      state.string_transforms = {};
      state.outlierErrors = {};
      state.missingErrors = {};
      state.stringTransformErrors = {};
      state.taskStatus = {};
      state.result = null;
    }
  ),

  // Create Request
  create: thunk(
    async (actions, file_id, { injections, getState, getStoreActions }) => {
      // Get Spec.
      const spec = getState().getSpec(file_id);

      // Spec is null: There was an error.
      if (!spec) {
        return;
      }

      // Return immediately if task already requested once.
      if (getState().taskStatus[stringifySpec(spec)]) {
        return;
      }

      const errors = validateSpec(spec);
      if (
        Object.keys(errors.outlierErrors).length >= 1 ||
        Object.keys(errors.missingErrors).length >= 1
      ) {
        actions.set_outlier_errors({ file_id, errors: errors.outlierErrors });
        actions.set_missing_errors({ file_id, errors: errors.missingErrors });
        return;
      }

      const url = injections.urls.create_data_clean;
      await getStoreActions().data_file_create_status.createFile({
        url,
        spec,
        // @ts-ignore
        updateTaskStatus: actions.updateTaskStatus
      });
    }
  ),

  // Selectors
  // For e.g.:
  // get_data_quality_for_file: select(state => file_id => state.resources[file_id])

  initRequired: computed(
    [state => state.outlier_treatment, state => state.missing_treatment],
    (outlier_treatment, missing_treatment) => (file_id, column, colType) => {
      const ot = ((outlier_treatment[file_id] || {})[column] || {})[colType];
      const mt = ((missing_treatment[file_id] || {})[column] || {})[colType];

      return ot === undefined && mt === undefined;
    }
  ),

  getSpec: computed(
    [
      state => state.outlier_treatment,
      state => state.missing_treatment,
      state => state.string_transforms,
      state => state.name,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (
      outlier_treatment,
      missing_treatment,
      string_transforms,
      name,
      settings
    ) => source_data => {
      const types = settings.getTypesForFile(source_data);

      const outlierTreatment = outlier_treatment[source_data] || {};

      let newOT: OutlierTreatment[] = [];
      Object.keys(outlierTreatment).forEach(c => {
        const colType = types[c];
        const { treatment } = outlierTreatment[c];
        const ot = outlierTreatment[c][colType];
        if (ot && treatment) {
          newOT.push({ column: c, treatment, ...ot });
        }
      });

      const missingTreatment = missing_treatment[source_data] || {};

      let newMT: MissingTreatment[] = [];
      Object.keys(missingTreatment).forEach(c => {
        const colType = types[c];
        const { treatment } = missingTreatment[c];
        const mt = missingTreatment[c][colType];
        if (mt && treatment) {
          newMT.push({ column: c, treatment, ...mt });
        }
      });

      const st_spec = Object.keys(string_transforms[source_data] || {})
        .filter(k => types[k] === "char")
        .map(column => ({
          column,
          treatment: (string_transforms[source_data] || {})[column]
        }));

      const spec: Spec = {
        name: name[source_data] || "",
        source_data,
        column_types: types,
        columns_to_drop: settings.getDroppedColumnsForFile(source_data),
        column_renamers: settings.getColumnRenamerForFile(source_data),
        outlier_treatment: newOT,
        missing_treatment: newMT,
        string_transforms: st_spec
      };

      return spec;
    }
  ),

  getComputeStatus: computed(state => file_id => {
    const spec = state.getSpec(file_id);
    if (!spec) {
      return undefined;
    }
    return state.taskStatus[stringifySpec(spec)];
  })
};

export default model;
