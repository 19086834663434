import React, { useState } from "react";

import { ScaleOrdinal } from "d3-scale";

import {
  GroupedStackedBarChart,
  ScatterLineChart,
  Scale,
  VarType,
  isCategorical,
} from "Visualizations";
import { useComponentWidth } from "utils";

import { NumTSD, CatTSD, TimeResolution } from "./types";
import { generateNumTS, generateCatTS } from "./generate-time-series-dist";
import generateHistogram from "./generate-histogram";
import makeColorScale from "./color-scale";
import makeCatAnnotator from "./cat-annotater";
import makeNumAnnotator from "./num-annotater";
import { FILL_COLOR } from "Visualizations/default-style-settings";

const LINE_MARGIN = { top: 20, bottom: 80, left: 80, right: 20 };
const HIST_MARGIN = { top: 0, bottom: 30, left: 80, right: 20 };

interface PropsInterface {
  distribution: NumTSD & CatTSD;
  colType: VarType;
  resolution: TimeResolution;
  groupsToShow?: (string | number)[];
  timeDomain: [Date, Date];
  setTimeDomain?: (d: [Date, Date]) => any;
  colorScale?:
    | ScaleOrdinal<string, ReadonlyArray<string>>
    | Scale<string | number | Date>
    | undefined;
  tsTitle?: React.ReactNode;
  histTitle?: React.ReactNode;
}

const TimeSeriesDistribution: React.FC<PropsInterface> = ({
  distribution,
  colType,
  resolution,
  groupsToShow,
  timeDomain,
  setTimeDomain,
  colorScale,
  tsTitle,
  histTitle,
}) => {
  const { ref, width } = useComponentWidth();
  const [normalized, setNormalized] = useState(false);
  if (!distribution) {
    return null;
  }

  colorScale = colorScale || makeColorScale(distribution);

  let chart;
  let showingCount: number;
  let totalCount: number;

  let normalizeSwitch;

  if (isCategorical(colType)) {
    const data = generateCatTS(
      distribution,
      timeDomain,
      resolution,
      groupsToShow,
      normalized
    );
    normalizeSwitch = (
      <p className="has-text-right normalize-switch">
        <span className="is-link" onClick={() => setNormalized(false)}>
          SHOW RAW
        </span>{" "}
        |{" "}
        <span className="is-link" onClick={() => setNormalized(true)}>
          NORMALIZED
        </span>
      </p>
    );
    chart = (
      <GroupedStackedBarChart
        data={data}
        width={width}
        margin={LINE_MARGIN}
        height={200}
        axisVariableType="date"
        axisDomain={timeDomain}
        // @ts-ignore
        fillColor={colorScale}
        valueAxisType={normalized ? "percent" : "numeric"}
        annotater={makeCatAnnotator(resolution)}
      />
    );
    const levels = Object.keys(distribution).filter(
      (k) => k !== "__time__" && k !== "__group__"
    );
    totalCount = levels
      .map((l) => distribution[l].reduce((a, b) => a + b, 0))
      .reduce((a, b) => a + b, 0);
    showingCount = data.map(({ size }) => size).reduce((a, b) => a + b, 0);
  } else {
    const data = generateNumTS(distribution, timeDomain, groupsToShow);
    chart = (
      <ScatterLineChart
        data={data}
        width={width}
        height={200}
        xType="date"
        yType={colType}
        xDomain={timeDomain}
        margin={LINE_MARGIN}
        lineColor={(p) =>
          colorScale === undefined
            ? FILL_COLOR
            : colorScale(p.group?.toString() || "") || FILL_COLOR
        }
        pointColor={(p) =>
          colorScale === undefined
            ? FILL_COLOR
            : colorScale(p.group?.toString() || "") || FILL_COLOR
        }
        pointAnnotater={makeNumAnnotator(resolution)}
        lineAnnotater={
          distribution["__group__"]
            ? (d) => `Line for ${(d === undefined ? "" : d).toString()}`
            : ""
        }
      />
    );

    totalCount = (distribution["size"] || []).reduce((a, b) => a + b, 0);
    showingCount = data.map(({ size }) => size).reduce((a, b) => a + b, 0);
  }

  return (
    <div ref={ref}>
      {normalizeSwitch}
      <p className="has-text-right help is-danger has-text-weight-bold">
        Showing {showingCount} out of {totalCount} data points.
      </p>
      <div className="tsd-ts">
        {tsTitle && tsTitle}
        {chart}
      </div>
      <div className="tsd-hist">
        {histTitle && histTitle}
        <GroupedStackedBarChart
          data={generateHistogram(distribution, resolution, colType)}
          chartType="vertical"
          axisVariableType="date"
          stackVariableType="categorical"
          // @ts-ignore
          fillColor={colorScale}
          showBrush={setTimeDomain !== undefined}
          brushDomain={timeDomain}
          // @ts-ignore
          setBrushDomain={setTimeDomain}
          width={width}
          height={100}
          margin={HIST_MARGIN}
          showToolTip={false}
        />
      </div>
    </div>
  );
};

export default TimeSeriesDistribution;
