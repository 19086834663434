import React from "react";

import { ScaleOrdinal } from "d3-scale";

import InputRange from "../Input-Range";
import GroupedSwitch from "../Grouped-Switch";

type Domain = [Date, Date];

const OPTIONS = [
  { label: "SHOW", key: "show" },
  { label: "HIDE", key: "hide" }
];

interface Props {
  timeDomain: Domain;
  setTimeDomain(domain: Domain): any;
  groups: (string | number)[];
  showingGroups: (string | number)[];
  setShowingGroups: (groups: (string | number)[]) => any;
  colorScale: ScaleOrdinal<string, ReadonlyArray<string>> | undefined;
}

const Controls: React.FC<Props> = ({
  timeDomain,
  setTimeDomain,
  groups,
  showingGroups,
  setShowingGroups,
  colorScale
}) => {
  if (timeDomain === null || timeDomain === undefined) {
    return null;
  }
  if (timeDomain[0].getTime() === timeDomain[1].getTime()) {
    return null;
  }

  showingGroups = showingGroups || [];

  const showGroup = (g: string | number) => {
    if (!showingGroups.includes(g)) {
      setShowingGroups([...showingGroups, g]);
    }
  };

  const hideGroup = (g: string | number) => {
    if (showingGroups.includes(g)) {
      setShowingGroups(showingGroups.filter(gr => gr !== g));
    }
  };

  return (
    <div>
      <div id="time-filter">
        <InputRange
          label="Filter Data to Zoom"
          onValueChange={setTimeDomain}
          type="date"
          range={timeDomain}
        />
      </div>
      {groups.length >= 2 && (
        <p
          className="is-link has-text-right"
          onClick={() => setShowingGroups(groups)}
        >
          RESET
        </p>
      )}
      {groups.length >= 2 && (
        <>
          <hr />
          <p>
            <b>Show/Hide Groups</b>
          </p>
        </>
      )}
      {groups.map((g: string | number) => (
        <GroupedSwitch
          key={g}
          options={OPTIONS}
          label={
            <p>
              <svg width={10} height={10}>
                <circle
                  cx={5}
                  cy={5}
                  r={5}
                  // @ts-ignore
                  fill={
                    colorScale === undefined ? "" : colorScale(g.toString())
                  }
                />
              </svg>{" "}
              <span>{g}</span>
            </p>
          }
          active={showingGroups.includes(g) ? "show" : "hide"}
          onClick={({ key }) => (key === "show" ? showGroup(g) : hideGroup(g))}
        />
      ))}
    </div>
  );
};

export default Controls;
