import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Error from "../Error";
import ProgressBar from "../Progress-Bar";

import "./upload-listing.scss";

interface UploadListingInterface {
  files: File[];
  removeFile(name: string): any;
}

interface UploadStatus {
  completed?: boolean;
  error?: boolean;
  progress?: number;
  error_message?: string | string[] | { [key: string]: string | string[] };
  status?: string;
}

/**
 * Publishes a list of files along with their sizes. Also has an option to remove files.
 */
const UploadListing = ({ files, removeFile }: UploadListingInterface) => (
  <div>
    <ul className="upload-file-listing">
      {files.map(file => (
        <li key={file.name} className="upload-file-item">
          <div className="upload-file-name-div">
            <div
              className="upload-file-remove"
              onClick={() => removeFile(file.name)}
            >
              x
            </div>
            <div className="upload-file-name">{file.name}</div>
          </div>
          <div className="upload-file-size">
            {file.size > 1024 * 1024
              ? `${Math.round(file.size / (1024 * 1024))} MB`
              : `${Math.round(file.size / 1024)} KB`}
          </div>
        </li>
      ))}
    </ul>
  </div>
);

interface PropsInterface {
  handleSubmit: (files: File[]) => any;
  onFileSelect?: (files: File[]) => any;
  max_files: number;
  upload_status: { [index: string]: UploadStatus };
}

const LocalFileUpload: React.FC<PropsInterface> = ({
  handleSubmit,
  onFileSelect,
  max_files,
  upload_status
}) => {
  const [files, set_files] = useState<File[]>([]);

  const updateFiles = useCallback(
    listing => {
      set_files(listing);
      if (onFileSelect) {
        onFileSelect(listing);
      }
    },
    [set_files, onFileSelect]
  );

  const onDrop = useCallback(
    acceptedFiles => {
      updateFiles([...files, ...acceptedFiles]);
    },
    [files, updateFiles]
  );

  const loading =
    Object.values(upload_status).filter(s => (s["error"] || false) === false)
      .length >= 1;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="content">
      {max_files >= 1 && files.length > max_files && (
        <Error
          error={`You can only upload ${max_files} more data. You are uploading ${files.length} more.`}
        />
      )}
      {files
        .filter(f => upload_status[f.name])
        .map(f => (
          <ProgressBar
            key={f.name}
            name={f.name}
            error={upload_status[f.name]["error"] || false}
            progress={upload_status[f.name]["progress"] || 0}
            completed={upload_status[f.name]["completed"] || false}
            error_message={upload_status[f.name]["error_message"] || ""}
            status_message={upload_status[f.name]["status"]}
          />
        ))}
      <div
        className="box"
        style={{ height: "200px", border: "2px dashed grey" }}
        {...getRootProps()}
      >
        <input {...getInputProps()} data-testid={"inputSelector"} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p
            className="columns is-vcentered is-centered"
            style={{ height: "100%" }}
          >
            Drag 'n' drop some files here, or click to select files
          </p>
        )}
      </div>
      <UploadListing
        files={files}
        removeFile={name => updateFiles(files.filter(f => f.name !== name))}
      />
      <div>
        <button
          data-testid={"submitButton"}
          className={`button is-success${loading ? " is-loading" : ""}`}
          disabled={files.length === 0}
          onClick={() => {
            const files_to_be_uploaded = files.filter(f => {
              if (!upload_status[f.name]) {
                return true;
              }
              const { error } = upload_status[f.name];
              return !!error;
            });
            handleSubmit(files_to_be_uploaded);
          }}
        >
          Upload Selected Files
        </button>
      </div>
    </div>
  );
};

export default LocalFileUpload;
