interface Stringable {
  toString(): string;
}

export default (text: Stringable, characters: number): string[] => {
  let words = text.toString().split(/\s+/);
  let currentSentenceWords: string[] = [],
    currentSentence;

  let sentences = [];

  words.forEach(word => {
    if (currentSentenceWords.length === 0) {
      currentSentenceWords.push(word);
    } else {
      currentSentence = currentSentenceWords.join(" ");
      if (currentSentence.length + word.length > characters) {
        sentences.push(currentSentence);
        currentSentenceWords = [word];
      } else {
        currentSentenceWords.push(word);
      }
    }
  });

  sentences.push(currentSentenceWords.join(" "));

  return sentences;
};
