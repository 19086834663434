import { isCategorical, VarType } from "Visualizations";

import { FlatBiVariateDistribution } from "./types";

const m_num_cat = (l: number | Date, r: number | Date, count: number) => {
  // @ts-ignore
  const interval = r - l;
  return count / interval;
};

const m_num_num = (
  l1: number | Date,
  r1: number | Date,
  l2: number | Date,
  r2: number | Date,
  count: number
) => {
  if (l1 instanceof Date) {
    l1 = l1.valueOf();
  }
  if (r1 instanceof Date) {
    r1 = r1.valueOf();
  }
  if (l2 instanceof Date) {
    l2 = l2.valueOf();
  }
  if (r2 instanceof Date) {
    r2 = r2.valueOf();
  }

  const interval = (r2 - l2) * (r1 - l1);
  return count / interval;
};

const generate_distribution = (
  distribution: FlatBiVariateDistribution,
  column1_type: VarType,
  column2_type: VarType
) => {
  if (!distribution["count"]) {
    return [];
  }
  const { count } = distribution;
  if (isCategorical(column1_type) && isCategorical(column2_type)) {
    if (!distribution["level-1"] || !distribution["level-2"]) {
      return [];
    }
    const l1 = distribution["level-1"];
    const l2 = distribution["level-2"];
    return l1.map((x, i) => ({
      x: x,
      y: l2[i],
      value: count[i],
      count: count[i]
    }));
  } else if (isCategorical(column1_type) !== isCategorical(column2_type)) {
    if (
      !distribution["left-edges"] ||
      !distribution["right-edges"] ||
      !distribution["level"]
    ) {
      return [];
    }

    let le = distribution["left-edges"];
    let re = distribution["right-edges"];
    const l = distribution["level"];

    if (column1_type === "date" || column2_type === "date") {
      le = le.map(l => new Date(l));
      re = re.map(l => new Date(l));
    }

    return le.map((leftEdge, i) => ({
      leftEdge: leftEdge,
      rightEdge: re[i],
      level: l[i],
      count: count[i],
      value: m_num_cat(leftEdge, re[i], count[i])
    }));
  } else {
    if (
      !distribution["left-edges-1"] ||
      !distribution["right-edges-1"] ||
      !distribution["left-edges-2"] ||
      !distribution["right-edges-2"]
    ) {
      return [];
    }
    let le1 = distribution["left-edges-1"];
    let re1 = distribution["right-edges-1"];
    let le2 = distribution["left-edges-2"];
    let re2 = distribution["right-edges-2"];

    if (column1_type === "date" || column1_type === "time") {
      le1 = le1.map(l => new Date(l));
      re1 = re1.map(l => new Date(l));
    }

    if (column2_type === "date" || column2_type === "time") {
      le2 = le2.map(l => new Date(l));
      re2 = re2.map(l => new Date(l));
    }

    return le1.map((xLeftEdge, i) => ({
      xLeftEdge: xLeftEdge,
      xRightEdge: re1[i],
      yLeftEdge: le2[i],
      yRightEdge: re2[i],
      value: m_num_num(xLeftEdge, re1[i], le2[i], re2[i], count[i]),
      count: count[i]
    }));
  }
};

export default generate_distribution;
