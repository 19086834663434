import React from "react";

import { scaleLinear, scaleSqrt, scaleBand, scaleTime } from "d3-scale";

import {
  ViewPort,
  VarType,
  FillProps,
  InteractionProps,
  UnivariateDatum
} from "Visualizations/types";

import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR
} from "Visualizations/default-style-settings";

import {
  generateDomains,
  filterObjectsByValidity,
  filterObjectsByRange
} from "Visualizations/data-utils";

import { isCategorical, getMargin } from "Visualizations/utils";

import SingleBar from "./_Single-Bar";

interface PropsInterface
  extends ViewPort,
    FillProps<UnivariateDatum>,
    InteractionProps<UnivariateDatum> {
  data: UnivariateDatum[];
  barType: "vertical" | "horizontal";
  varType?: VarType;
  valueDomain?: Array<string | number | Date>;
  heightDomain?: [number, number];
  startHeightAtZero?: boolean;
  heightScaleType?: "linear" | "sqrt";
  centerHeightAtZero?: boolean;
}

const DEFAULT_FUNC = () => {};

const Bars: React.FunctionComponent<PropsInterface> = ({
  data,
  barType,
  varType = "numeric",
  valueDomain,
  heightDomain,
  startHeightAtZero = true,
  centerHeightAtZero = false,
  heightScaleType = "linear",
  fillColor = FILL_COLOR,
  hoverColor = HOVER_COLOR,
  width,
  height,
  margin = DEFAULT_MARGIN,
  onClick = DEFAULT_FUNC,
  onMouseOver = DEFAULT_FUNC,
  onMouseOut = DEFAULT_FUNC
}) => {
  data = filterObjectsByValidity(data, "height", "numeric");

  if (isCategorical(varType)) {
    data = filterObjectsByValidity(data, "level", varType);
  } else {
    data = filterObjectsByValidity(data, "leftEdge", varType);
    data = filterObjectsByValidity(data, "rightEdge", varType);
  }

  if (data.length === 0) {
    return null;
  }

  // xDomain could be a subset of the full data. Filtering the full data
  if (isCategorical(varType)) {
    data = filterObjectsByRange(data, "level", varType, valueDomain);
  } else {
    data = filterObjectsByRange(data, "leftEdge", varType, valueDomain);
    data = filterObjectsByRange(data, "rightEdge", varType, valueDomain);
  }

  // yDomain could be a subset of the full data. Filtering the full data
  data = filterObjectsByRange(data, "height", "numeric", heightDomain);

  if (data.length < 1) {
    return <text>Unable to Generate Distributions: No points...</text>;
  }

  const [vD, hD] = generateDomains(
    data,
    varType,
    valueDomain,
    heightDomain,
    startHeightAtZero
  );

  const new_margin = getMargin(margin, DEFAULT_MARGIN);
  const yLength = height - (new_margin.top + new_margin.bottom);
  const xLength = width - (new_margin.left + new_margin.right);

  if (yLength <= 0 || xLength <= 0) {
    return null;
  }

  const value_range = [0, barType === "vertical" ? xLength : yLength];

  const valueScale = isCategorical(varType)
    ? scaleBand()
        .range([0, barType === "vertical" ? xLength : yLength])
        .domain(vD.map(x => x.toString()))
        .padding(0.1)
    : varType === "date" || varType === "time"
    ? scaleTime()
        .range(value_range)
        .domain(vD.map((d: number | string | Date) => new Date(d)))
        .clamp(true)
    : scaleLinear()
        .range(value_range)
        .domain(vD.map(d => parseFloat(d.toString())));

  const heightScale =
    heightScaleType === "linear"
      ? scaleLinear()
          .range([0, barType === "vertical" ? yLength : xLength])
          .domain(hD)
      : scaleSqrt()
          .range([0, barType === "vertical" ? yLength : xLength])
          .domain(hD);

  return (
    <g transform={`translate(${new_margin.left}, ${new_margin.top})`}>
      {data.map((d, i) => (
        <SingleBar
          key={i}
          d={d}
          barType={barType}
          varType={varType}
          height={height}
          width={width}
          margin={new_margin}
          valueScale={valueScale}
          heightScale={heightScale}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          fillColor={fillColor}
          hoverColor={hoverColor}
          centerHeightAtZero={centerHeightAtZero}
        />
      ))}
    </g>
  );
};

export default Bars;
