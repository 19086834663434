import React from "react";

import {
  ViewPort,
  UnivariateDatum,
  ScatterPointDatum,
  VarType,
  ScaleType,
  Fill,
  FillProps
} from "Visualizations/types";

import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR,
  MARKER_COLOR
} from "Visualizations/default-style-settings";

import { useToolTip } from "Visualizations/components/Tool-Tip";
import {
  XAxis,
  YAxis,
  Brush,
  Bars,
  LineScatter,
  ToolTip
} from "Visualizations/components";

import {
  generateDomains,
  filterObjectsByValidity,
  getDomainFromArray
} from "Visualizations/data-utils";

interface PropsInterface extends ViewPort, FillProps<UnivariateDatum> {
  data: { bars: UnivariateDatum[]; lines: ScatterPointDatum[] };
  xMarkers?: (string | number | Date)[];
  yMarkers?: number[];
  xMarkerColor?: Fill<string | number | Date>;
  yMarkerColor?: Fill<number>;
  varType: VarType;
  valueDomain?: (string | number | Date)[];
  lineDomain?: number[];
  valueScaleType?: ScaleType;
  lineAxisType?: VarType;
  showBrush?: boolean;
  brushDomain?: (string | number | Date)[];
  showValueAxis?: boolean;
  showValueAxisLabels?: boolean;
  startHeightAtZero?: boolean;
  showLineAxis?: boolean;
  pointColor?: Fill<ScatterPointDatum>;
  lineColor?: Fill<ScatterPointDatum>;
  pointAnnotater?(d: ScatterPointDatum): React.ReactNode;
  lineAnnotater?: ((p: ScatterPointDatum) => React.ReactNode) | React.ReactNode;
  showToolTip?: boolean;
  annotater?(d: any): React.ReactNode;
  onBarClick?(d: any, e?: React.MouseEvent): any;
  onBarMouseOver?(d: any, e?: React.MouseEvent): any;
  onBarMouseOut?(): any;
  onPointClick?: (p: ScatterPointDatum, e?: React.MouseEvent) => any;
  onPointMouseOver?: (p: ScatterPointDatum, e?: React.MouseEvent) => any;
  onPointMouseOut?: (p?: ScatterPointDatum, e?: React.MouseEvent) => any;
  onLineClick?(e: React.MouseEvent): any;
  onLineMouseOver?: (e?: React.MouseEvent) => any;
  onLineMouseOut?: (e?: React.MouseEvent) => any;
  onZoomChange?(domain: (string | number | Date)[]): any;
}

const INIT_VALUE_DOMAIN: (string | number | Date)[] = [];
const INIT_BRUSH_DOMAIN: (string | number | Date)[] = [];

const DEFAULT_ANNOTATER = (d: any) => d.toLocaleString();
const DEFAULT_LINE_ANNOTATER = () => null;
const DEFAULT_INTERACTION = () => {};

const BarChartWithLines: React.FC<PropsInterface> = ({
  data,
  varType,
  valueScaleType = "linear",
  lineAxisType = "numeric",
  valueDomain = INIT_VALUE_DOMAIN,
  lineDomain = INIT_VALUE_DOMAIN,
  showBrush = false,
  brushDomain = INIT_BRUSH_DOMAIN,
  xMarkers,
  yMarkers,
  xMarkerColor,
  yMarkerColor,
  showValueAxis = true,
  showValueAxisLabels = true,
  startHeightAtZero = false,
  showLineAxis = true,
  fillColor = FILL_COLOR,
  hoverColor = HOVER_COLOR,
  pointColor,
  lineColor = MARKER_COLOR,
  showToolTip = true,
  annotater = DEFAULT_ANNOTATER,
  pointAnnotater = DEFAULT_ANNOTATER,
  lineAnnotater = DEFAULT_LINE_ANNOTATER,
  onBarClick = DEFAULT_INTERACTION,
  onBarMouseOver = DEFAULT_INTERACTION,
  onBarMouseOut = DEFAULT_INTERACTION,
  onPointClick = DEFAULT_INTERACTION,
  onPointMouseOver = DEFAULT_INTERACTION,
  onPointMouseOut = DEFAULT_INTERACTION,
  onLineClick = DEFAULT_INTERACTION,
  onLineMouseOver = DEFAULT_INTERACTION,
  onLineMouseOut = DEFAULT_INTERACTION,
  onZoomChange = DEFAULT_INTERACTION,
  width,
  height,
  margin = DEFAULT_MARGIN
}) => {
  const [barHoverStatus, barHoverD, onBarHover, offBarHover] = useToolTip(
    onBarMouseOver,
    onBarMouseOut
  );

  const [
    pointHoverStatus,
    pointHoverD,
    onPointHover,
    offPointHover
  ] = useToolTip(onPointMouseOver, onPointMouseOut);

  const [lineHoverStatus, lineHoverD, onLineHover, offLineHover] = useToolTip(
    (_, e) => onLineMouseOver(e),
    () => onLineMouseOut()
  );

  let heightDomain: [number, number];

  let { bars, lines } = data;

  [valueDomain, heightDomain] = generateDomains(
    bars,
    varType,
    valueDomain,
    undefined,
    startHeightAtZero
  );

  lines = filterObjectsByValidity(lines, "x", varType);
  lines = filterObjectsByValidity(lines, "y", "numeric");

  const groups = Array.from(
    new Set(
      lines.map(({ group }) =>
        group === undefined || group === null ? group : group.toString()
      )
    )
  );

  const xDomain = getDomainFromArray(
    lines.map(({ x }) => x),
    varType,
    valueDomain
  );
  const yDomain = getDomainFromArray(
    lines.map(({ y }) => y),
    "numeric",
    lineDomain
  );

  const pointGroups =
    groups.length === 1
      ? [lines]
      : groups.map(g =>
          lines.filter(({ group }) =>
            group === undefined || group === null
              ? g === undefined || g === null
              : g === group.toString()
          )
        );

  pointColor = pointColor || lineColor;

  return (
    <svg width={width} height={height}>
      <Bars
        data={bars}
        barType="vertical"
        varType={varType}
        valueDomain={valueDomain}
        heightDomain={heightDomain}
        startHeightAtZero={startHeightAtZero}
        fillColor={fillColor || FILL_COLOR}
        hoverColor={hoverColor}
        onClick={onBarClick}
        onMouseOver={onBarHover}
        onMouseOut={offBarHover}
        width={width}
        height={height}
        margin={margin}
      />
      {groups.length === 1 && (
        <LineScatter
          data={lines}
          xType={varType}
          yType={"numeric"}
          xDomain={xDomain}
          yDomain={yDomain}
          xMarkers={xMarkers}
          yMarkers={yMarkers}
          xMarkerColor={xMarkerColor}
          // What's expected in string | number | Date. What goes in is number.
          // @ts-ignore
          yMarkerColor={yMarkerColor}
          showLine={true}
          showPoints={true}
          pointColor={pointColor}
          pointHoverColor={hoverColor}
          lineColor={
            typeof lineColor === "function"
              ? lineColor(lines[0])
              : lineColor || MARKER_COLOR
          }
          onPointClick={onPointClick}
          onPointMouseOver={onPointHover}
          onPointMouseOut={offPointHover}
          onLineClick={onLineClick}
          onLineMouseOver={e => onLineHover({}, e)}
          onLineMouseOut={e => offLineHover({}, e)}
          width={width}
          height={height}
          margin={margin}
        />
      )}
      {groups.length > 1 &&
        pointGroups.map((d, i) => (
          <LineScatter
            key={
              groups[i] === undefined || groups[i] === null
                ? "NULL--UND"
                : groups[i]
            }
            data={d}
            xType={varType}
            yType={"numeric"}
            xDomain={xDomain}
            yDomain={yDomain}
            showLine={true}
            showPoints={true}
            pointColor={
              typeof lineColor === "function"
                ? lineColor(d[0])
                : lineColor || MARKER_COLOR
            }
            pointHoverColor={hoverColor}
            lineColor={
              typeof lineColor === "function"
                ? lineColor(d[0])
                : lineColor || MARKER_COLOR
            }
            onPointClick={onPointClick}
            onPointMouseOver={onPointHover}
            onPointMouseOut={offPointHover}
            onLineClick={onLineClick}
            onLineMouseOver={e => onLineHover(d[0].group, e)}
            onLineMouseOut={e => offLineHover(d[0].group, e)}
            width={width}
            height={height}
            margin={margin}
          />
        ))}
      {showLineAxis && (
        <YAxis
          varType={lineAxisType}
          scaleType={"linear"}
          domain={yDomain}
          width={width}
          height={height}
          margin={margin}
          showText={true}
        />
      )}
      {showValueAxis && (
        <XAxis
          varType={varType}
          scaleType={valueScaleType}
          domain={valueDomain}
          width={width}
          height={height}
          margin={margin}
          showText={showValueAxisLabels}
        />
      )}
      {showBrush && (
        <Brush
          domain={valueDomain}
          zoomedDomain={brushDomain}
          varType={varType}
          brushType={"horizontal"}
          height={height}
          width={width}
          margin={margin}
          onZoom={onZoomChange}
        />
      )}
      {showToolTip && barHoverStatus && (
        <ToolTip
          d={annotater(barHoverD.d)}
          offsetX={barHoverD.offsetX}
          offsetY={barHoverD.offsetY}
          width={width}
          margin={margin}
          position={"horizontal"}
        />
      )}
      {showToolTip && pointHoverStatus && (
        <ToolTip
          d={pointAnnotater(pointHoverD.d)}
          offsetX={pointHoverD.offsetX}
          offsetY={pointHoverD.offsetY}
          width={width}
          margin={margin}
          position={"horizontal"}
        />
      )}
      {showToolTip && lineHoverStatus && (
        <ToolTip
          d={
            typeof lineAnnotater === "function"
              ? lineAnnotater(lineHoverD.d)
              : lineAnnotater
          }
          offsetX={lineHoverD.offsetX}
          offsetY={lineHoverD.offsetY}
          width={width}
          margin={margin}
          position={"horizontal"}
        />
      )}
    </svg>
  );
};

export default BarChartWithLines;
