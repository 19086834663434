import React, { useState } from "react";

import { Margin } from "Visualizations/types";

import getSentences from "Visualizations/utils/sentences";

const lineHeight = 1.1; // ems

interface XAxisLabelProps {
  tickY: { y: number; dy: number };
  value: string;
  bandWidth: number;
  margin: Margin;
  orientation: "top" | "bottom";
}

export default ({
  tickY,
  value,
  bandWidth,
  margin,
  orientation
}: XAxisLabelProps) => {
  const [hover, setHover] = useState(false);

  let dy = orientation === "top" ? -0.71 * 2 : 0.71;

  let labels;
  const characters = bandWidth / 10;
  if (value.length < characters) {
    labels = (
      <tspan x={0} y={9} dy={`${dy}em`}>
        {value}
      </tspan>
    );
  } else {
    const maxLines =
      orientation === "top" ? margin.top / 20 : margin.bottom / 20;
    let sentences = getSentences(value, characters);
    if (hover === false) {
      sentences = sentences.slice(0, maxLines);
    }
    if (orientation === "top") {
      dy = dy - lineHeight * (sentences.length - 1);
    }
    labels = sentences.map((sentence, i) => (
      <tspan x={0} y={9} dy={`${dy + i * lineHeight}em`} key={i}>
        {sentence.slice(0, characters)}
      </tspan>
    ));
  }

  return (
    <text
      y={tickY.y}
      x="0.5"
      dy={`${tickY.dy}em`}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onMouseLeave={() => setHover(false)}
    >
      {labels}
    </text>
  );
};
