import { action, thunk, computed, actionOn } from "easy-peasy";

import { delay } from "utils";
import StateInterface from "./types";

const model: StateInterface = {
  // State

  // Prediction State
  model_prediction_parameters: {},
  predictions_setup_status: {},

  // Auto Learn State
  auto_learn_setup_status: {},
  model_update_status: {},

  // Predictions Data
  data: {},
  result: {},
  uploadStatus: {},

  // File Mapper
  file_mappers: {},

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})

  update_predictions_setup_status: action(
    (state, { model_id, compute_status }) => {
      state.predictions_setup_status[model_id] = compute_status;
    }
  ),

  update_auto_learn_setup_status: action(
    (state, { model_id, compute_status }) => {
      state.auto_learn_setup_status[model_id] = compute_status;
    }
  ),

  update_model_update_status: action((state, { model_id, compute_status }) => {
    state.model_update_status[model_id] = compute_status;
  }),

  update_model_prediction_parameters: action(
    (state, { model_id, parameters }) => {
      state.model_prediction_parameters[model_id] = parameters;
    }
  ),

  update_data: action((state, { model_id, column, value }) => {
    if (state.data[model_id] === undefined) {
      state.data[model_id] = {};
    }
    state.data[model_id][column] = value;
  }),

  update_file_mappers: action((state, { model_id, location, files }) => {
    if (!state.file_mappers) {
      state.file_mappers = {};
    }

    if (!state.file_mappers[model_id]) {
      state.file_mappers[model_id] = { [location]: files };
    } else {
      state.file_mappers[model_id][location] = files;
    }
  }),

  update_result: action((state, { model_id, result }) => {
    if (state.result[model_id] === undefined) {
      state.result[model_id] = {};
    }
    const d = JSON.stringify(state.data[model_id]);
    state.result[model_id][d] = result;
  }),

  update_upload_status: action((state, status) => {
    state.uploadStatus = status;
  }),

  reset_upload_status: action(state => {
    state.uploadStatus = {};
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [
      storeActions.reset.reset,
      storeActions.auth.startLogout
    ],
    state => {
      state.model_prediction_parameters = {};
      state.predictions_setup_status = {};
      state.auto_learn_setup_status = {};
      state.model_update_status = {};
      state.file_mappers = {};
      state.data = {};
      state.result = {};
      state.uploadStatus = {};
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.
  setup_predictions: thunk(
    async (
      actions,
      model_id,
      { getStoreState, getStoreActions, injections }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const model_details =
        globalState.predictive_model_listing.models[model_id];
      if (!model_details) {
        return;
      }
      if (model_details.predictions_enabled) {
        return;
      }
      actions.update_predictions_setup_status({
        model_id,
        compute_status: { error: false, completed: false }
      });
      await getStoreActions().api_client.fetch({
        url: `${injections.urls.predictive_models}/models/${model_id}/enable-predictions`,
        method: "POST",
        onComplete: async ({ success, data, error }) => {
          if (success) {
            if (data.request_id) {
              // Fetch Task Status
              actions.fetchTaskStatus({
                model_id,
                task_id: data.request_id,
                type: "setup-predictions"
              });
            } else {
              actions.update_predictions_setup_status({
                model_id,
                compute_status: data.compute_status
              });
            }
          } else if (error === "Login Failed") {
            actions.update_predictions_setup_status({
              model_id,
              compute_status: { error: true, error_message: error }
            });
          } else {
            await delay(5000);
            actions.setup_predictions(model_id);
          }
        }
      });
    }
  ),

  setup_auto_learn: thunk(
    async (
      actions,
      model_id,
      { getStoreState, getStoreActions, injections }
    ) => {
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const model_details =
        globalState.predictive_model_listing.models[model_id];
      if (!model_details) {
        return;
      }
      if (model_details.auto_learn_enabled) {
        return;
      }
      actions.update_auto_learn_setup_status({
        model_id,
        compute_status: { error: false, completed: false }
      });

      await getStoreActions().api_client.fetch({
        url: `${injections.urls.predictive_models}/models/${model_id}/enable-auto-learn`,
        method: "POST",
        onComplete: async ({ success, data, error }) => {
          if (success) {
            if (data.request_id) {
              // Fetch Task Status
              actions.fetchTaskStatus({
                model_id,
                task_id: data.request_id,
                type: "setup-auto-learn"
              });
            } else {
              actions.update_auto_learn_setup_status({
                model_id,
                compute_status: data.compute_status
              });
            }
          } else if (error === "Login Failed") {
            actions.update_auto_learn_setup_status({
              model_id,
              compute_status: { error: true, error_message: error }
            });
          } else {
            await delay(5000);
            actions.setup_auto_learn(model_id);
          }
        }
      });
    }
  ),

  update_model: thunk(
    async (
      actions,
      { model_id, files },
      { injections, getState, getStoreState, getStoreActions }
    ) => {
      const state = getState();

      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const model_details =
        globalState.predictive_model_listing.models[model_id];
      if (!model_details) {
        return;
      }
      if (!model_details.auto_learn_enabled) {
        return;
      }

      const mapping = state.file_mappers[model_id];
      if (!mapping) {
        actions.update_upload_status({
          error: true,
          error_message: "Mapping is MANDATORY!!!"
        });
        return;
      }

      const missing_mapping = files.filter(f => {
        return (
          Object.values(mapping).filter(m => m.includes(f.name)).length !== 1
        );
      });

      if (missing_mapping.length >= 1) {
        actions.update_upload_status({
          error: true,
          error_message: `You havent mapped ${missing_mapping.join(", ")}`
        });
        return;
      }

      const { urls } = injections;
      const url = `${urls.predictive_models}/models/${model_id}/update-model`;
      actions.update_model_update_status({
        model_id,
        compute_status: { error: false, completed: false }
      });
      getStoreActions().api_client.uploadMultipleFiles({
        url,
        files,
        params: { mapping },
        onComplete: async ({ success, data, error }) => {
          if (success) {
            if (data.request_id) {
              // Fetch Task Status
              actions.fetchTaskStatus({
                model_id,
                task_id: data.request_id,
                type: "model-update"
              });
            } else {
              actions.update_model_update_status({
                model_id,
                compute_status: data.compute_status
              });
            }
          } else if (error === "Login Failed") {
            actions.update_model_update_status({
              model_id,
              compute_status: { error: true, error_message: error }
            });
          } else {
            await delay(5000);
            actions.update_model({ model_id, files });
          }
        }
      });
    }
  ),

  fetchTaskStatus: thunk(
    async (actions, payload, { getStoreActions, injections }) => {
      const { task_id, model_id, type } = payload;
      const updateTaskStatus =
        type === "setup-predictions"
          ? actions.update_predictions_setup_status
          : type === "setup-auto-learn"
          ? actions.update_auto_learn_setup_status
          : type === "batch-predictions"
          ? actions.update_upload_status
          : actions.update_model_update_status;
      const url = `${injections.urls.task_status}/${task_id}`;
      await getStoreActions().api_client.fetch({
        url: url,
        method: "GET",
        onComplete: async ({ success, error, data }) => {
          if (!success) {
            if (error === "Login Failed") {
              await delay(5000);
              actions.fetchTaskStatus(payload);
            } else {
              updateTaskStatus({
                model_id,
                compute_status: {
                  error: error ? true : false,
                  error_message: error
                }
              });
            }
            return;
          }

          if (data.status === "FAILURE") {
            updateTaskStatus({
              model_id,
              compute_status: {
                error: true,
                error_message: "Unable to compute..."
              }
            });
          } else if (data.status === "SUCCESS") {
            if (data.result.error) {
              updateTaskStatus({
                model_id,
                compute_status: {
                  error: true,
                  error_message:
                    data.result.error_message || data.result.message
                }
              });
            } else if (data.result.completed === true) {
              updateTaskStatus({
                model_id,
                compute_status: { error: false, completed: true }
              });
              getStoreActions().predictive_model_listing.fetchModels({
                model_id
              });
            }
          } else {
            await delay(2000);
            actions.fetchTaskStatus(payload);
          }
        }
      });
    }
  ),

  fetch_prediction_parameters: thunk(
    async (
      actions,
      model_id,
      { getStoreState, getStoreActions, injections }
    ) => {
      if (!model_id) {
        return;
      }
      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      getStoreActions().api_client.fetch({
        url: `${injections.urls.predictive_models}/models/${model_id}/feature-details-for-predictions`,
        method: "POST",
        onComplete: ({ success, data }) => {
          if (success) {
            actions.update_model_prediction_parameters({
              model_id,
              parameters: data
            });
          }
        }
      });
    }
  ),

  fetch_predictions: thunk(
    async (
      actions,
      model_id,
      { getState, getStoreState, getStoreActions, injections }
    ) => {
      const state = getState();

      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const model_details =
        globalState.predictive_model_listing.models[model_id];
      if (!model_details) {
        return;
      }
      if (!model_details.predictions_enabled) {
        return;
      }

      const d = JSON.stringify(state.data[model_id]);
      if (state.result[d] !== undefined) {
        return;
      }

      const { urls } = injections;
      const url = `${urls.predictive_models}/models/${model_id}/predict-row`;

      getStoreActions().api_client.fetch({
        url,
        method: "POST",
        data: { data: state.data[model_id] },
        onComplete: ({ success, data }) => {
          if (success) {
            actions.update_result({ model_id, result: data });
          } else {
            actions.update_result({
              model_id,
              result: {
                error: true,
                message: "Unable to generate predictions. Check your inputs."
              }
            });
          }
        }
      });
    }
  ),

  generate_batch_predictions: thunk(
    async (
      actions,
      { model_id, files },
      { getState, injections, getStoreState, getStoreActions }
    ) => {
      const state = getState();

      const globalState = getStoreState();
      if (!globalState.auth.logged_in) {
        return;
      }

      const model_details =
        globalState.predictive_model_listing.models[model_id];
      if (!model_details) {
        return;
      }
      if (!model_details.predictions_enabled) {
        return;
      }

      const mapping = state.file_mappers[model_id];
      if (!mapping) {
        actions.update_upload_status({
          error: true,
          error_message: "Mapping is MANDTORY!!!"
        });
        return;
      }

      const missing_mapping = files.filter(f => {
        return (
          Object.values(mapping).filter(m => m.includes(f.name)).length !== 1
        );
      });

      if (missing_mapping.length >= 1) {
        actions.update_upload_status({
          error: true,
          error_message: `You havent mapped ${missing_mapping.join(", ")}`
        });
        return;
      }

      const { urls } = injections;
      const url = `${urls.predictive_models}/models/${model_id}/predict-file`;
      actions.update_upload_status({ completed: false, error: false });

      getStoreActions().api_client.uploadMultipleFiles({
        url,
        files,
        params: { mapping },
        onComplete: ({ success }) => {
          actions.update_upload_status({ completed: true, error: !success });
        }
      });
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getPredictionResult: computed(state => model_id => {
    const { data, result } = state;

    if (!result[model_id]) {
      return;
    }
    const d = JSON.stringify(data[model_id]);
    return result[model_id][d];
  })
};

export default model;
