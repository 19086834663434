import { useState, useCallback } from "react";

const useComponentWidth = () => {
  const [width, setWidth] = useState(0);

  const ref = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return { ref, width };
};

export default useComponentWidth;
