import React from "react";

import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker: React.FC<ReactDatePickerProps> = ({
  selected,
  className,
  placeholderText,
  ...rest
}) => {
  return (
    <ReactDatePicker
      {...rest}
      selected={selected}
      className={className || "input"}
      placeholderText={placeholderText || "Select Date"}
    />
  );
};

export default DatePicker;
