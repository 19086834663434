import React from "react";

import SelectField from "../Select-Field";

type Treatment = "drop-rows" | "fill-value" | "substitute" | "ignore";

const TREATMENT_OPTIONS: { value: Treatment; label: string }[] = [
  {
    value: "drop-rows",
    label: "Drop ALL missing rows"
  },
  { value: "fill-value", label: "Fill ALL missing rows with ENTERED value" },
  { value: "ignore", label: "Leave MISSING rows as MISSING." },
  {
    value: "substitute",
    label: "Use values from ANOTHER column as a SUBSTITUTE"
  }
];

interface Props {
  treatment?: Treatment;
  onChange: (t: "drop-rows" | "fill-value" | "substitute" | "ignore") => any;
  showSubstituteOption: boolean;
  label?: string;
  error?: string;
}

const TreatmentOptions: React.FC<Props> = ({
  treatment,
  onChange,
  showSubstituteOption,
  label = "Missing Treatment:",
  error
}) => {
  return (
    <SelectField
      label={label}
      placeholder="Select an Option"
      value={treatment}
      options={
        showSubstituteOption
          ? TREATMENT_OPTIONS
          : TREATMENT_OPTIONS.filter(({ value }) => value !== "substitute")
      }
      onValueChange={t => {
        t && onChange(t);
      }}
      help="Missing Value Treatment"
      error={error}
    />
  );
};

export default TreatmentOptions;
