import React from "react";

import { ViewPort, VarType, InteractionProps, FillProps } from "../../types";

import { DEFAULT_MARGIN } from "../../default-style-settings";
import { FILL_COLOR, HOVER_COLOR } from "../../default-style-settings";

import { isCategorical, getMargin } from "../../utils";

import ToolTip, { useToolTip } from "../../components/Tool-Tip";
import { NumNumGrid, NumCatGrid, CatCatGrid } from "../../components";
import { XAxis, YAxis } from "../../components";

import { filterObjectsByValidity } from "../../data-utils";

import filterByValidity from "./filter-by-validity";
import generateDomains from "./generate-domains";
import { GridCell } from "./types";

interface PropsInterface
  extends ViewPort,
    InteractionProps<GridCell>,
    FillProps<GridCell> {
  data: GridCell[];
  xType: VarType;
  yType: VarType;
  xDomain?: (string | Date | number | undefined)[];
  yDomain?: (string | Date | number | undefined)[];
  showXAxis?: boolean;
  showYAxis?: boolean;
  showXAxisLabels?: boolean;
  showYAxisLabels?: boolean;
  valueRange?: number[];
  varyOpacity?: boolean;
  showToolTip?: boolean;
  annotater?(d: any): React.ReactNode;
}

const DEFAULT_DOMAIN: (string | Date | number | undefined)[] = [];
const DEFAULT_VALUE_RANGE: number[] = [];

const DEFAULT_ANNOTATER = (d: any) => d.toLocaleString();
const DEFAULT_INTERACTION = () => {};

const Gridchart: React.FC<PropsInterface> = ({
  data,
  xType,
  yType,
  xDomain = DEFAULT_DOMAIN,
  yDomain = DEFAULT_DOMAIN,
  showXAxis = true,
  showYAxis = true,
  showXAxisLabels = true,
  showYAxisLabels = true,
  fillColor = FILL_COLOR,
  hoverColor = HOVER_COLOR,
  valueRange = DEFAULT_VALUE_RANGE,
  varyOpacity = true,
  showToolTip = true,
  annotater = DEFAULT_ANNOTATER,
  onClick = DEFAULT_INTERACTION,
  onMouseOver = DEFAULT_INTERACTION,
  onMouseOut = DEFAULT_INTERACTION,
  width,
  height,
  margin = DEFAULT_MARGIN
}) => {
  const newMargin = getMargin(margin, DEFAULT_MARGIN);
  const [hoverStatus, hoverD, onHover, offHover] = useToolTip(
    onMouseOver,
    onMouseOut
  );
  data = filterByValidity(data, xType, yType);

  if (varyOpacity) {
    data = filterObjectsByValidity(data, "value", "numeric");
  }

  if (data.length === 0) {
    return <p>Unable to Plot Chart: No Valid Points!!!</p>;
  }

  [xDomain, yDomain] = generateDomains(data, xType, yType, xDomain, yDomain);

  let chart;
  if (isCategorical(xType) && isCategorical(yType)) {
    chart = (
      <CatCatGrid
        // @ts-ignore
        data={data}
        // @ts-ignore
        xDomain={xDomain}
        // @ts-ignore
        yDomain={yDomain}
        valueRange={valueRange}
        varyOpacity={varyOpacity}
        fillColor={fillColor}
        hoverColor={hoverColor}
        width={width}
        height={height}
        margin={newMargin}
        onClick={onClick}
        onMouseOver={onHover}
        onMouseOut={offHover}
      />
    );
  } else if (!isCategorical(xType) && !isCategorical(yType)) {
    chart = (
      <NumNumGrid
        // @ts-ignore
        data={data}
        // @ts-ignore
        xType={xType === "percent" ? "float" : xType}
        // @ts-ignore
        yType={yType === "percent" ? "float" : yType}
        // @ts-ignore
        xDomain={xDomain}
        // @ts-ignore
        yDomain={yDomain}
        densityDomain={valueRange}
        varyOpacity={varyOpacity}
        fillColor={fillColor}
        hoverColor={hoverColor}
        width={width}
        height={height}
        margin={newMargin}
        onClick={onClick}
        onMouseOver={onHover}
        onMouseOut={offHover}
      />
    );
  } else {
    const numDomain = isCategorical(xType) ? yDomain : xDomain;
    const catDomain = isCategorical(xType) ? xDomain : yDomain;
    chart = (
      <NumCatGrid
        // @ts-ignore
        data={data}
        xType={xType === "percent" ? "float" : xType}
        yType={yType === "percent" ? "float" : yType}
        // @ts-ignore
        numDomain={numDomain}
        // @ts-ignore
        catDomain={catDomain}
        valueRange={valueRange}
        varyOpacity={varyOpacity}
        fillColor={fillColor}
        hoverColor={hoverColor}
        width={width}
        height={height}
        margin={newMargin}
        onClick={onClick}
        onMouseOver={onHover}
        onMouseOut={offHover}
      />
    );
  }

  return (
    <svg width={width} height={height}>
      {chart}
      {showXAxis && (
        <XAxis
          domain={xDomain}
          varType={xType}
          scaleType={isCategorical(xType) ? "categorical" : "linear"}
          width={width}
          height={height}
          margin={newMargin}
          showText={showXAxisLabels}
        />
      )}
      {showYAxis && (
        <YAxis
          domain={yDomain}
          varType={yType}
          scaleType={isCategorical(yType) ? "categorical" : "linear"}
          width={width}
          height={height}
          margin={newMargin}
          showText={showYAxisLabels}
        />
      )}
      {showToolTip && hoverStatus && (
        <ToolTip
          d={annotater(hoverD.d)}
          offsetX={hoverD.offsetX}
          offsetY={hoverD.offsetY}
          width={width}
          margin={newMargin}
          position="horizontal"
        />
      )}
    </svg>
  );
};

export default Gridchart;
