import { Scale } from "Visualizations/types";

export default (
  scale: Scale<string | number | Date>,
  zoomedDomain?: (string | number | Date)[]
): [number, number] => {
  let min, max;
  if (Array.isArray(zoomedDomain) && zoomedDomain.length >= 1) {
    if (scale.bandwidth && scale.padding) {
      const yValues = zoomedDomain.map(d => scale(d));
      const barWidth = Math.max(scale.bandwidth(), 1);

      min = Math.min(...yValues) - scale.padding();
      max = Math.max(...yValues) + barWidth + scale.padding();
    } else {
      min = scale(zoomedDomain[0]);
      max = scale(zoomedDomain[1]);
      if (min > max) {
        [min, max] = [max, min];
      }
    }
  } else {
    min = scale.range()[0];
    max = scale.range()[1];
  }

  return [min, max];
};
