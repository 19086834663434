import { thunk, computed } from "easy-peasy";

import StateInterface, { Spec } from "./types";

const stringifySpec = (spec: Spec): string => JSON.stringify(spec);

// Change key
const INSIGHT_TYPE = "data-file-time-series-distributions";

const model: StateInterface = {
  // Add params. Each of them: param: {[file_id]: ...}

  // Set Params

  // Create Request
  create: thunk(
    async (actions, payload, { injections, getState, getStoreActions }) => {
      const { file_id, value_column } = payload;
      const state = getState();

      const spec = state.getSpec(file_id, value_column);
      if (!spec) {
        return;
      }

      const { urls } = injections;
      const spec_string = stringifySpec(spec);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_time_series_distributions}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec,
        spec_string
      });
    }
  ),

  // Reset on a Global reset signal

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getSpec: computed(
    [(_, globalState) => globalState.data_files_user_settings],
    settings => (file, value_column) => {
      const { timeColumn, resolution } = settings.getTimeSeriesParamsForFile(
        file
      );
      if (!timeColumn || !resolution) {
        return null;
      }
      // Change spec as required.
      const types = settings.getTypesForFile(file);
      const filters = settings.getFiltersForFile(file);
      const groupingColumns = settings.getGroupingColumnsForFile(file);

      return {
        value_column,
        value_column_type: types[value_column],
        filters,
        time_column: timeColumn,
        resolution,
        grouping_columns: groupingColumns
      };
    }
  ),

  resource: computed(
    [
      state => state.getSpec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (getSpec, getInsight) => (file_id, value_column) => {
      const spec = getSpec(file_id, value_column);
      if (spec === null) {
        return;
      }
      const spec_string = stringifySpec(spec);
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getData: computed(state => (file_id, value_column) =>
    state.resource(file_id, value_column)?.data
  ),

  getComputeStatus: computed(state => (file_id, value_column) =>
    state.resource(file_id, value_column)?.compute_status
  )
};

export default model;
