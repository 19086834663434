import { action, computed, actionOn } from "easy-peasy";

import StateInterface from "./types";

const model: StateInterface = {
  status: {},
  current: null,

  // Store-Updaters
  // e.g.,
  // updateHygiene: action((state, payload) => {})
  // updateTypes: action((state, payload) => {})
  updateStatus: action((state, { key, seen }) => {
    state["status"][key] = seen;
  }),

  updateCurrent: action((state, current) => {
    state.current = current;
  }),

  showCurrent: action((state) => {
    if (state.current !== null && state.current !== undefined) {
      state.status[state.current] = false;
    }
  }),

  // Reset on a Global reset signal
  reset: actionOn(
    (actions, storeActions) => [storeActions.reset.reset],
    (state) => {
      state.status = {};
    }
  ),

  // Server Requests
  // e.g.,
  // fetchHygiene: thunk(async (actions, payload, {getState, getStoreState, getStoreActions, injections }) => {}),
  // actions expose LOCAL actions. getState() exposes LOCAL state, getStoreState() exposes GLOBAL state, and getStoreActions() exposes GLOBAL actions
  // apiClient and urls are present on injections.

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getStatus: computed((state) => (key) => state.status[key] || false),
};

export default model;
