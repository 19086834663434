import React from "react";

import { DragDropContext } from "react-beautiful-dnd";

import { Item } from "./types";
import CategoryBox from "./Category-Box";
import doDrag from "./do-drag";

interface PropsInterface {
  categories: string[];
  items: Item[];
  valueCategoryMap: { [value: string]: string };
  onChange: (item: string, category: string | null) => any;
  categoryClassName?: string;
  categoryLabels?: { [category: string]: string };
  orientation?: "vertical" | "horizontal";
}

const CategoriesDragDrop: React.FC<PropsInterface> = ({
  categories,
  items,
  valueCategoryMap,
  onChange,
  categoryClassName,
  categoryLabels,
  orientation = "vertical"
}) => {
  if (orientation === "vertical") {
    return (
      <DragDropContext onDragEnd={p => doDrag(p, items, onChange)}>
        {categories.map(category => (
          <CategoryBox
            key={category}
            label={(categoryLabels || {})[category] || category}
            category={category}
            items={items}
            catItems={Object.keys(valueCategoryMap).filter(
              v => valueCategoryMap[v] === category
            )}
            categoryClassName={categoryClassName}
          />
        ))}
      </DragDropContext>
    );
  } else {
    return (
      <div className="columns">
        <DragDropContext onDragEnd={p => doDrag(p, items, onChange)}>
          {categories.map(category => (
            <div
              className="column"
              key={category}
              style={{ maxHeight: "400px", overflow: "scroll" }}
            >
              <CategoryBox
                key={category}
                label={(categoryLabels || {})[category] || category}
                category={category}
                items={items}
                catItems={Object.keys(valueCategoryMap).filter(
                  v => valueCategoryMap[v] === category
                )}
                categoryClassName={categoryClassName}
              />
            </div>
          ))}
        </DragDropContext>
      </div>
    );
  }
};

export default CategoriesDragDrop;
