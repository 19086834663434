import { extent } from "d3-array";
import { Scale } from "Visualizations/types";

export default (
  v: number,
  moveLower: boolean,
  scale: Scale<string | number | Date>,
  domain: (string | number | Date)[],
  zoomedDomain?: (string | number | Date)[]
): (string | number | Date)[] => {
  zoomedDomain = zoomedDomain || domain;

  if (scale.bandwidth) {
    const barWidth = Math.max(scale.bandwidth(), 1);

    const domain = scale.domain();

    const r = zoomedDomain
      .filter(d => d !== null && d !== undefined)
      .map(scale)
      .filter(d => d !== null && d !== undefined);

    const left = Math.min(...r);
    const right = Math.max(...r);

    if (moveLower === true) {
      return domain.filter(d => scale(d) >= v && scale(d) <= right);
    } else {
      return domain.filter(d => scale(d) + barWidth <= v && scale(d) >= left);
    }
  } else {
    let [left, right] = zoomedDomain;
    if (left === undefined) {
      left = domain[0];
    }
    if (right === undefined) {
      right = domain[1];
    }
    const d = scale.invert(v);
    const r = moveLower ? [d, right] : [left, d];
    // @ts-ignore
    return extent(r);
  }
};
