import React, { useRef, useEffect, useState } from "react";

import { TOOL_TIP_WIDTH, DEFAULT_MARGIN } from "../../default-style-settings";
import { Margin } from "../../types";

import { getMargin } from "../../utils";

import getToolTipCoords from "./co-ords";

interface PropsInterface<T = any> {
  /** Element to be rendered. Will be rendered as-is. */
  d: T;
  /** Where should the tool-tip be? */
  offsetX: number;
  /** Where should the tool-tip be? */
  offsetY: number;
  /** g width. Defaults to 1000 */
  width?: number;
  /** g margin. Defaults to DEFAULT_MARGIN */
  margin?: Partial<Margin>;
  position?: "horizontal" | "vertical";
  toolTipWidth?: number;
}

/** Publishes Tool-Tip. Auto-adjusts the height. */
const ToolTip: React.FC<PropsInterface> = ({
  d,
  offsetX,
  offsetY,
  width = 1000,
  margin = DEFAULT_MARGIN,
  position = "vertical",
  toolTipWidth = TOOL_TIP_WIDTH
}) => {
  const toolTipEl: React.RefObject<any> = useRef(null);
  const [toolTipHeight, setHeight] = useState(100);

  useEffect(() => {
    if (toolTipEl.current) {
      setHeight(toolTipEl.current.offsetHeight);
    }
  }, [setHeight]);

  const new_margin = getMargin(margin);

  const [x, y] = getToolTipCoords(
    position,
    new_margin,
    width,
    offsetX,
    offsetY,
    toolTipHeight,
    toolTipWidth
  );

  if (typeof d === "object") {
    return null;
  }

  return (
    <foreignObject
      x={x}
      y={y}
      height={toolTipHeight}
      width={toolTipWidth}
      style={{ overflow: "hidden", background: "white" }}
    >
      <div ref={toolTipEl}>{d}</div>
    </foreignObject>
  );
};

export default ToolTip;
