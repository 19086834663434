import React from "react";

import { Link } from "react-router-dom";
import paths from "App/paths";

const Footer: React.FC = () => (
  <footer className="footer" style={{ marginTop: "2rem" }}>
    <div className="content has-text-centered">
      <p>
        <span>
          <Link to={paths.toc}>Terms and Conditions</Link>
        </span>{" "}
        |{" "}
        <span>
          <Link to={paths.privacy}>Privacy Policy</Link>
        </span>
      </p>
      <p>
        Made with <span style={{ color: "#e25555" }}>&#9829;</span> in India.
      </p>
    </div>
  </footer>
);

export default Footer;
