import React, { useState } from "react";

import {
  ViewPort,
  InteractionProps,
  FillProps,
  Fill
} from "Visualizations/types";

import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR
} from "Visualizations/default-style-settings";

import { filterObjectsByRange } from "Visualizations/data-utils";
import { getMargin, getScale, getOpacityScale } from "Visualizations/utils";

interface CatCatGridCell {
  x: string | number;
  y: string | number;
  value: number;
}

const extract_domain = (
  data: CatCatGridCell[],
  passedDomain: (string | number)[],
  domainAxis: "x" | "y"
): (string | number)[] => {
  if (passedDomain.length >= 1) {
    return passedDomain;
  }

  const values = data.map(d => d[domainAxis]);
  const s = new Set(values);

  return Array.from(s.values());
};

interface PropsInterface
  extends ViewPort,
    InteractionProps<CatCatGridCell>,
    FillProps<CatCatGridCell> {
  data: CatCatGridCell[];
  /** If passed, filters data by this. Else uses the full data. */
  xDomain?: (string | number)[];
  /** If passed, filters data by this. Else uses the full data. */
  yDomain?: (string | number)[];
  /** Range for color/opacity */
  valueRange?: number[];
  /** If true, shades the cell color using d.value */
  varyOpacity?: boolean;
}

const Cell = ({
  item,
  yLength,
  xScale,
  yScale,
  opacityScale,
  fill,
  hover,
  onClick,
  onMouseOver,
  onMouseOut
}: {
  item: CatCatGridCell;
  yLength: number;
  xScale: any;
  yScale: any;
  opacityScale: any;
  fill: Fill<CatCatGridCell>;
  hover: Fill<CatCatGridCell>;
  onClick: (d: CatCatGridCell) => any;
  onMouseOver: (d: CatCatGridCell, e?: React.MouseEvent) => any;
  onMouseOut: () => any;
}) => {
  const [hoverStatus, setHover] = useState(false);

  if (!(typeof fill === "string")) {
    fill = fill(item);
  }

  if (!(typeof hover === "string")) {
    hover = hover(item);
  }

  return (
    <rect
      x={xScale(item.x)}
      y={yLength - yScale(item.y) - yScale.bandwidth()}
      width={xScale.bandwidth()}
      height={yScale.bandwidth()}
      fillOpacity={opacityScale(item.value)}
      onClick={() => onClick(item)}
      onMouseOver={e => {
        setHover(true);
        return onMouseOver(item, e);
      }}
      onMouseMove={e => onMouseOver(item, e)}
      onMouseOut={() => {
        setHover(false);
        onMouseOut();
      }}
      fill={hoverStatus ? hover : fill}
    />
  );
};

const DEFAULT_DOMAIN: (string | number)[] = [];
const DEFAULT_VALUE_RANGE: number[] = [];

const DEFAULT_INTERACTION = () => {};

const CatCatGrid: React.FunctionComponent<PropsInterface> = ({
  data,
  xDomain = DEFAULT_DOMAIN,
  yDomain = DEFAULT_DOMAIN,
  valueRange = DEFAULT_VALUE_RANGE,
  fillColor = FILL_COLOR,
  hoverColor = HOVER_COLOR,
  varyOpacity = true,
  width,
  height,
  margin = DEFAULT_MARGIN,
  onClick = DEFAULT_INTERACTION,
  onMouseOver = DEFAULT_INTERACTION,
  onMouseOut = DEFAULT_INTERACTION
}) => {
  const new_margin = getMargin(margin, DEFAULT_MARGIN);

  xDomain = extract_domain(data, xDomain, "x");
  yDomain = extract_domain(data, yDomain, "y");

  data = filterObjectsByRange(data, "x", "categorical", xDomain);
  data = filterObjectsByRange(data, "y", "categorical", yDomain);

  const xLength = width - (new_margin.left + new_margin.right);
  const yLength = height - (new_margin.top + new_margin.bottom);

  const xScale = getScale({
    varType: "char",
    domain: xDomain,
    axisLength: xLength
  });
  const yScale = getScale({
    varType: "char",
    domain: yDomain,
    axisLength: yLength
  });

  const opacityScale = getOpacityScale(data, valueRange, varyOpacity);

  return (
    <g transform={`translate(${new_margin.left}, ${new_margin.top})`}>
      {data.map((item, i) => (
        <Cell
          key={i}
          item={item}
          yLength={yLength}
          xScale={xScale}
          yScale={yScale}
          opacityScale={opacityScale}
          fill={fillColor}
          hover={hoverColor}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      ))}
    </g>
  );
};

export default CatCatGrid;
