import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PropsInterface {
  title: string;
  subtitle: string;
  text: string;
  icon: IconProp;
  tags?: string[];
  front_class_name?: string;
  back_class_name?: string;
}

const TwoSidedCard: React.FunctionComponent<PropsInterface> = ({
  title,
  subtitle,
  text,
  icon,
  tags = [],
  front_class_name = "is-info",
  back_class_name = "is-success"
}) => {
  const [side, setSide] = useState("front");
  return (
    <div
      className="tile is-parent"
      onMouseEnter={() => setSide("back")}
      onMouseLeave={() => setSide("front")}
      style={{ width: "380px", maxWidth: "380px" }}
    >
      {side === "front" ? (
        <article
          className={[
            "tile",
            "is-child",
            front_class_name,
            "notification"
          ].join(" ")}
        >
          <p className="has-text-centered title">
            <FontAwesomeIcon icon={icon} className="fas fa-2x" />
          </p>
          <p className="title has-text-centered">{title}</p>
          <div className="tags is-pulled-right">
            {tags.map(t => (
              <span className="tag is-warning" key={t}>
                {t}
              </span>
            ))}
          </div>
        </article>
      ) : (
        <article
          className={["tile", "is-child", back_class_name, "notification"].join(
            " "
          )}
        >
          <p className="has-text-centered subtitle">{subtitle}</p>
          <p className="has-text-centered">{text}</p>
        </article>
      )}
    </div>
  );
};

export default TwoSidedCard;
