import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec } from "./types";

const stringifySpec = (spec: Spec): string => JSON.stringify(spec);

// Change key
const INSIGHT_TYPE = "data-file-group-differences";

const model: StateInterface = {
  // Add params. Each of them: param: {[file_id]: ...}
  group_definitions: {},
  ignore_ungrouped_data: {},

  // Set Params
  setParams: action((state, payload) => {
    const { file_id, params } = payload;
    // Add other params as required.
    const { group_definitions, ignore_ungrouped_data } = params;
    if (group_definitions !== undefined) {
      state.group_definitions[file_id] = group_definitions;
    }
    if (ignore_ungrouped_data !== undefined) {
      state.ignore_ungrouped_data[file_id] = ignore_ungrouped_data;
    }
  }),

  // Create Request
  createSummary: thunk(
    async (actions, file_id, { injections, getState, getStoreActions }) => {
      const state = getState();

      if (state.resource(file_id)) {
        return;
      }

      const spec = state.getSpec(file_id);
      if (!spec) {
        return;
      }

      const { urls } = injections;
      const spec_string = stringifySpec(spec);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_group_differences_summary}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec,
        spec_string
      });
    }
  ),

  createColumnDetails: thunk(
    async (actions, payload, { injections, getState, getStoreActions }) => {
      const state = getState();

      const { file_id, column } = payload;

      if (state.column_resource(file_id, column)) {
        return;
      }

      const spec = state.getSpec(file_id);
      if (!spec) {
        return;
      }

      const { urls } = injections;
      const spec_string = stringifySpec({ ...spec, column });

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_group_differences_column_details}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec: { ...spec, column },
        spec_string
      });
    }
  ),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [storeActions.reset.reset],
    state => {
      // Reset params
      state.group_definitions = {};
      state.ignore_ungrouped_data = {};
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])
  getSpec: computed(
    [state => state.group_definitions, state => state.ignore_ungrouped_data],
    (group_definitions, ignore_ungrouped_data) => file => {
      // Change spec as required.
      const spec: Spec = {
        group_definitions: group_definitions[file],
        ignore_ungrouped_data: ignore_ungrouped_data[file]
      };
      return spec;
    }
  ),

  resource: computed(
    [
      state => state.getSpec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (getSpec, getInsight) => file_id => {
      const spec = getSpec(file_id);
      if (spec === null) {
        return;
      }
      const spec_string = stringifySpec(spec);
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  column_resource: computed(
    [
      state => state.getSpec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (getSpec, getInsight) => (file_id, column) => {
      const spec = getSpec(file_id);
      if (spec === null) {
        return;
      }
      const spec_string = stringifySpec({ ...spec, column });
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getSummaryData: computed(state => file_id => state.resource(file_id)?.data),

  getSummaryComputeStatus: computed(state => file_id =>
    state.resource(file_id)?.compute_status
  ),

  getColumnDetailsData: computed(state => (file_id, column) =>
    state.column_resource(file_id, column)?.data
  ),

  getColumnDetailsComputeStatus: computed(state => (file_id, column) =>
    state.column_resource(file_id, column)?.compute_status
  )
};

export default model;
