import { DropResult } from "react-beautiful-dnd";

import { Item } from "./types";

const doDrag = (
  { draggableId, destination, source }: DropResult,
  items: Item[],
  callback: (item: string, category: string | null) => any
) => {
  if (destination === undefined || destination === null) {
    return callback(draggableId, null);
  }
  if (destination.droppableId === source.droppableId) {
    return;
  }
  const item = items.find(i => i.value === draggableId);
  if (!item) {
    return;
  }

  if (item.allowed_categories) {
    if (!item.allowed_categories.includes(destination.droppableId)) {
      return;
    }
  }
  return callback(draggableId, destination.droppableId);
};

export default doDrag;
