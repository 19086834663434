import { action, thunk, computed, actionOn } from "easy-peasy";

import StateInterface, { Spec } from "./types";

const stringifySpec = (spec: Spec): string => JSON.stringify(spec);

// Change key
const INSIGHT_TYPE = "data-file-grouped-distributions";

const model: StateInterface = {
  value_column: {},
  // Set Params
  setParams: action((state, payload) => {
    const { file_id, params } = payload;

    const { value_column } = params;
    if (value_column) {
      state.value_column[file_id] = value_column;
    } else {
      delete state.value_column[file_id];
    }
  }),

  // Create Request
  create: thunk(
    async (actions, file_id, { injections, getState, getStoreActions }) => {
      const state = getState();

      if (state.resource(file_id)) {
        return;
      }

      const { urls } = injections;

      const spec = state.getSpec(file_id);
      if (!spec) {
        return;
      }
      const spec_string = stringifySpec(spec);

      // CHANGE URL TO THE APPROPRIATE ONE.
      const url = `${urls.data}/${file_id}/${urls.data_grouped_distributions}`;

      const createInsight = getStoreActions().data_file_insights.createInsight;
      await createInsight({
        insight_type: INSIGHT_TYPE,
        url,
        file_id,
        spec,
        spec_string
      });
    }
  ),

  // Reset on a Global reset signal
  reset: actionOn(
    (_, storeActions) => [storeActions.reset.reset],
    state => {
      // Reset other params as well
      state.value_column = {};
    }
  ),

  // Selectors
  // For e.g.:
  // getDataQualityForFile: computed(state => file_id => state.resources[file_id])
  // OR
  // getSomething: computed([state => state.resources, (state, storeState) => storeState.x[state.y], (first, second) => some_arg => {}])

  getSpec: computed(
    [
      state => state.value_column,
      (_, globalState) => globalState.data_files_user_settings
    ],
    (params, settings) => file => {
      // Change spec as required.
      const value_column = params[file];
      const filters = settings.getFiltersForFile(file);
      const grouping_columns = settings.getGroupingColumnsForFile(file);
      const col_type = settings.getColumnType(file, value_column);

      if (!value_column || !col_type) {
        return null;
      }

      const spec: Spec = { value_column, grouping_columns, col_type, filters };
      return spec;
    }
  ),

  resource: computed(
    [
      state => state.getSpec,
      (_, storeState) => storeState.data_file_insights.getInsight
    ],
    (getSpec, getInsight) => file_id => {
      const spec = getSpec(file_id);
      if (spec === null) {
        return;
      }
      const spec_string = stringifySpec(spec);
      return getInsight(file_id, INSIGHT_TYPE, spec_string);
    }
  ),

  getData: computed(state => file_id => state.resource(file_id)?.data),

  getComputeStatus: computed(state => file_id =>
    state.resource(file_id)?.compute_status
  )
};

export default model;
