import React from "react";

import { Link } from "react-router-dom";
import { useStoreState } from "store";

const BreadCrumbs = () => {
  const crumbs = useStoreState(store => store.crumbs.crumbs);

  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className="container is-fluid" style={{ marginBottom: "1rem" }}>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          {crumbs
            .sort((a, b) => a.link.length - b.link.length)
            .map(({ text, link }, i) =>
              i === crumbs.length - 1 ? (
                <li className="is-active" key={text || `text-${i}`}>
                  <p
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: "0.75em",
                      paddingRight: "0.75em"
                    }}
                  >
                    {text}
                  </p>
                </li>
              ) : (
                <li key={`${text}-link`}>
                  <Link to={link}>{text}</Link>
                </li>
              )
            )}
        </ul>
      </nav>
    </div>
  );
};

export default BreadCrumbs;
