import React from "react";

import {
  Fill,
  ViewPort,
  VarType,
  ScatterPointDatum
} from "Visualizations/types";

import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR
} from "Visualizations/default-style-settings";

import { useToolTip } from "Visualizations/components/Tool-Tip";
import { LineScatter, XAxis, YAxis, ToolTip } from "Visualizations/components";

import {
  filterObjectsByValidity,
  getDomainFromArray
} from "Visualizations/data-utils";

import { getMargin } from "Visualizations/utils";

interface PropsInterface<T extends ScatterPointDatum = ScatterPointDatum>
  extends ViewPort {
  data: T[];
  xType: VarType;
  yType: VarType;
  showScatter?: boolean;
  showLine?: boolean;
  xDomain?: (string | number | Date)[];
  yDomain?: (string | number | Date)[];
  xMarkers?: (string | number | Date)[];
  yMarkers?: (string | number | Date)[];
  xMarkerColor?: Fill<string | number | Date>;
  yMarkerColor?: Fill<string | number | Date>;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showXAxisLabels?: boolean;
  showYAxisLabels?: boolean;
  pointSize?: ((d: T) => number) | number;
  lineWidth?: number;
  pointColor?: Fill<T>;
  pointHoverColor?: Fill<T>;
  lineColor?: Fill<T>;
  lineHoverColor?: Fill<T>;
  showToolTip?: boolean;
  pointAnnotater?(d: T): React.ReactNode;
  lineAnnotater?: ((p: T) => React.ReactNode) | React.ReactNode;
  onPointClick?: (p: T, e?: React.MouseEvent) => any;
  onPointMouseOver?: (p: T, e?: React.MouseEvent) => any;
  onPointMouseOut?: (p?: T, e?: React.MouseEvent) => any;
  onLineClick?(e: React.MouseEvent): any;
  onLineMouseOver?(e?: React.MouseEvent): any;
  onLineMouseOut?(e?: React.MouseEvent): any;
  onZoomChange?(domain: (string | number | Date)[]): any;
  title?: React.ReactNode;
}

const DEFAULT_POINT_ANNOTATER = (d: ScatterPointDatum) => d.toLocaleString();
const DEFAULT_LINE_ANNOTATER = () => null;

const DUMMY_FUNC = () => {};

const Scatterlinechart: React.FC<PropsInterface> = ({
  data,
  xType,
  yType,
  showScatter = true,
  showLine = true,
  xDomain,
  yDomain,
  xMarkers,
  yMarkers,
  xMarkerColor,
  yMarkerColor,
  showXAxis = true,
  showYAxis = true,
  showXAxisLabels = true,
  showYAxisLabels = true,
  pointSize = 3,
  lineWidth = 1,
  pointColor: fillColor = FILL_COLOR,
  pointHoverColor: hoverColor = HOVER_COLOR,
  lineColor = FILL_COLOR,
  lineHoverColor = HOVER_COLOR,
  showToolTip = true,
  pointAnnotater = DEFAULT_POINT_ANNOTATER,
  lineAnnotater = DEFAULT_LINE_ANNOTATER,
  onPointClick: onClick = DUMMY_FUNC,
  onPointMouseOver = DUMMY_FUNC,
  onPointMouseOut = DUMMY_FUNC,
  onLineClick = DUMMY_FUNC,
  onLineMouseOver = DUMMY_FUNC,
  onLineMouseOut = DUMMY_FUNC,
  width,
  height,
  margin = DEFAULT_MARGIN,
  title
}) => {
  const [hoverStatus, hoverD, onPointHover, offPointHover] = useToolTip(
    onPointMouseOver,
    onPointMouseOut
  );

  const [lineHoverStatus, lineHoverD, onLineHover, offLineHover] = useToolTip<
    string | number | Date | null | undefined
  >(
    (_, e) => onLineMouseOver(e),
    () => onLineMouseOut()
  );

  if (!showLine && !showScatter) {
    console.log("Passed with both showLine and showPoints as false");
    return null;
  }

  data = filterObjectsByValidity(data, "x", xType);
  data = filterObjectsByValidity(data, "y", yType);
  if (data.length === 0) {
    return <p>Unable to Plot Chart: No Valid Points!!!</p>;
  }

  const groups = Array.from(
    new Set(
      data.map(({ group }) =>
        group === undefined || group === null ? group : group.toString()
      )
    )
  );

  xDomain = getDomainFromArray(
    data.map(({ x }) => x),
    xType,
    xDomain
  );
  yDomain = getDomainFromArray(
    data.map(({ y }) => y),
    yType,
    yDomain
  );

  const pointGroups =
    groups.length === 1
      ? [data]
      : groups.map(g =>
          data.filter(({ group }) =>
            group === undefined || group === null
              ? g === undefined || g === null
              : g === group.toString()
          )
        );

  const new_margin = getMargin(margin);

  return (
    <svg width={width} height={height}>
      {title && (
        <foreignObject
          x={0}
          y={0}
          width={width}
          height={margin.top}
          className="has-text-centered has-text-weight-bold"
          style={{ wordWrap: "normal", textDecoration: "underline" }}
        >
          {title}
        </foreignObject>
      )}
      {groups.length === 1 && (
        <LineScatter
          data={data}
          xType={xType}
          yType={yType}
          xDomain={xDomain}
          yDomain={yDomain}
          xMarkers={xMarkers}
          yMarkers={yMarkers}
          xMarkerColor={xMarkerColor}
          yMarkerColor={yMarkerColor}
          showLine={showLine}
          showPoints={showScatter}
          pointSize={pointSize}
          lineWidth={lineWidth}
          pointColor={fillColor}
          pointHoverColor={hoverColor}
          lineColor={
            typeof lineColor === "string" ? lineColor : lineColor(data[0])
          }
          lineHoverColor={lineHoverColor}
          onPointClick={onClick}
          onPointMouseOver={onPointHover}
          onPointMouseOut={offPointHover}
          onLineClick={onLineClick}
          onLineMouseOver={e => onLineHover(null, e)}
          onLineMouseOut={e => offLineHover(null, e)}
          width={width}
          height={height}
          margin={new_margin}
        />
      )}
      {groups.length > 1 &&
        pointGroups.map((d, i) => (
          <LineScatter
            key={
              groups[i] === undefined || groups[i] === null
                ? "NULL--UND"
                : groups[i]
            }
            data={d}
            xType={xType}
            yType={yType}
            xDomain={xDomain}
            yDomain={yDomain}
            xMarkers={xMarkers}
            yMarkers={yMarkers}
            xMarkerColor={xMarkerColor}
            yMarkerColor={yMarkerColor}
            showLine={showLine}
            showPoints={showScatter}
            pointSize={pointSize}
            lineWidth={lineWidth}
            pointColor={fillColor}
            pointHoverColor={hoverColor}
            lineColor={
              typeof lineColor === "string" ? lineColor : lineColor(d[0])
            }
            lineHoverColor={lineHoverColor}
            onPointClick={onClick}
            onPointMouseOver={onPointHover}
            onPointMouseOut={offPointHover}
            onLineClick={onLineClick}
            onLineMouseOver={e => onLineHover(d[0].group, e)}
            onLineMouseOut={e => offLineHover(d[0].group, e)}
            width={width}
            height={height}
            margin={new_margin}
          />
        ))}
      {showXAxis && (
        <XAxis
          varType={xType}
          scaleType={"linear"}
          domain={xDomain}
          width={width}
          height={height}
          margin={new_margin}
          showText={showXAxisLabels}
        />
      )}
      {showYAxis && (
        <YAxis
          varType={yType}
          scaleType={"linear"}
          domain={yDomain}
          width={width}
          height={height}
          margin={new_margin}
          showText={showYAxisLabels}
        />
      )}
      {showToolTip && hoverStatus && (
        <ToolTip
          d={pointAnnotater(hoverD.d)}
          offsetX={hoverD.offsetX}
          offsetY={hoverD.offsetY}
          width={width}
          margin={new_margin}
          position={"horizontal"}
        />
      )}
      {showToolTip && lineHoverStatus && (
        <ToolTip
          d={
            typeof lineAnnotater === "function"
              ? lineAnnotater(lineHoverD.d)
              : lineAnnotater
          }
          offsetX={lineHoverD.offsetX}
          offsetY={lineHoverD.offsetY}
          width={width}
          margin={new_margin}
          position={"horizontal"}
        />
      )}
    </svg>
  );
};

export default Scatterlinechart;
