import React, { useState } from "react";

import { line } from "d3-shape";

import {
  ViewPort,
  FillProps,
  InteractionProps,
  VarType,
  ScatterPointDatum
} from "Visualizations/types";
import { Fill } from "Visualizations/types";

import {
  DEFAULT_MARGIN,
  FILL_COLOR,
  HOVER_COLOR,
  MARKER_COLOR
} from "Visualizations/default-style-settings";

import {
  filterObjectsByValidity,
  filterObjectsByRange,
  getDomainFromArray
} from "Visualizations/data-utils";

import { getMargin, getScale, isCategorical } from "Visualizations/utils";

interface PropsInterface<
  X = string | number | Date,
  Y = string | number | Date
>
  extends ViewPort,
    InteractionProps<ScatterPointDatum<X, Y>>,
    FillProps<ScatterPointDatum<X, Y>> {
  data: ScatterPointDatum<X, Y>[];
  xType?: VarType;
  yType?: VarType;
  xDomain?: X[];
  yDomain?: Y[];
  xMarkers?: X[];
  yMarkers?: Y[];
  xMarkerColor?: ((d: X) => string) | string;
  yMarkerColor?: ((d: Y) => string) | string;
  showPoints?: boolean;
  pointColor?: Fill<ScatterPointDatum<X, Y>>;
  pointHoverColor?: Fill<ScatterPointDatum<X, Y>>;
  pointSize?: ((d: ScatterPointDatum<X, Y>) => number) | number;
  strokeWidth?: number;
  showLine?: boolean;
  lineColor?: string;
  lineHoverColor?: string;
  lineWidth?: number;
  onPointClick?: (p: ScatterPointDatum<X, Y>, e: React.MouseEvent) => any;
  onPointMouseOver?: (p: ScatterPointDatum<X, Y>, e: React.MouseEvent) => any;
  onPointMouseOut?: (p: ScatterPointDatum<X, Y>, e: React.MouseEvent) => any;
  onLineClick?: (e: React.MouseEvent) => any;
  onLineMouseOver?: (e: React.MouseEvent) => any;
  onLineMouseOut?: (e: React.MouseEvent) => any;
}

const DUMMY_FUNC = () => {};

const Scatter: React.FunctionComponent<PropsInterface> = ({
  data,
  xType = "numeric",
  yType = "numeric",
  xDomain,
  yDomain,
  xMarkers,
  yMarkers,
  xMarkerColor = MARKER_COLOR,
  yMarkerColor = MARKER_COLOR,
  showPoints = true,
  pointColor = FILL_COLOR,
  pointHoverColor = HOVER_COLOR,
  pointSize = 3,
  strokeWidth = 1,
  showLine = false,
  lineColor = FILL_COLOR,
  lineHoverColor = HOVER_COLOR,
  lineWidth = 1,
  onPointClick = DUMMY_FUNC,
  onPointMouseOver = DUMMY_FUNC,
  onPointMouseOut = DUMMY_FUNC,
  onLineClick = DUMMY_FUNC,
  onLineMouseOver = DUMMY_FUNC,
  onLineMouseOut = DUMMY_FUNC,
  height,
  width,
  margin = DEFAULT_MARGIN
}) => {
  const [hoverIndex, setHover] = useState<number>(-1);
  const [lineHover, setLineHover] = useState<boolean>(false);
  if (!showLine && !showPoints) {
    console.log("Passed with both showLine and showPoints as false");
    return null;
  }
  data = filterObjectsByValidity(data, "x", xType);
  data = filterObjectsByValidity(data, "y", yType);
  if (data.length === 0) {
    return null;
  }

  // Filtering by domains
  data = filterObjectsByRange(data, "x", xType, xDomain);
  data = filterObjectsByRange(data, "y", yType, yDomain);

  if (data.length === 0) {
    return <text>Too little data after filtering!!!</text>;
  }

  const new_xDomain = getDomainFromArray(
    data.map(({ x }) => x),
    xType,
    xDomain
  );
  const new_yDomain = getDomainFromArray(
    data.map(({ y }) => y),
    yType,
    yDomain
  );

  const new_margin = getMargin(margin, DEFAULT_MARGIN);
  const yLength = height - (new_margin.top + new_margin.bottom);
  const xLength = width - (new_margin.left + new_margin.right);

  const xScale = getScale({
    varType: xType,
    domain: new_xDomain,
    axisLength: xLength
  });

  const yScale = getScale({
    varType: yType,
    domain: new_yDomain,
    axisLength: [yLength, 0]
  });

  const linePath = line<ScatterPointDatum>()
    .x(p =>
      isCategorical(xType) && xScale.bandwidth
        ? xScale(p.x) + xScale.bandwidth() / 2
        : xScale(p.x)
    )
    .y(p =>
      isCategorical(yType) && yScale.bandwidth
        ? yScale(p.y) + yScale.bandwidth() / 2
        : yScale(p.y)
    );

  return (
    <g transform={`translate(${new_margin.left}, ${new_margin.top})`}>
      {Array.isArray(xMarkers) &&
        xMarkers.length >= 1 &&
        xMarkers.map(m => (
          <path
            key={m.toString()}
            d={linePath(new_yDomain.map(y => ({ x: m, y }))) || ""}
            stroke={
              (typeof xMarkerColor === "function"
                ? xMarkerColor(m)
                : xMarkerColor) || MARKER_COLOR
            }
            strokeWidth={lineWidth}
            fill="none"
          />
        ))}
      {Array.isArray(yMarkers) &&
        yMarkers.length >= 1 &&
        yMarkers.map(m => (
          <path
            key={m.toString()}
            d={linePath(new_xDomain.map(x => ({ x, y: m }))) || ""}
            stroke={
              (typeof yMarkerColor === "function"
                ? yMarkerColor(m)
                : yMarkerColor) || MARKER_COLOR
            }
            strokeWidth={lineWidth}
            fill="none"
          />
        ))}
      {showLine && (
        <path
          d={linePath(data) || ""}
          stroke={lineHover ? lineHoverColor : lineColor}
          strokeWidth={lineWidth}
          fill="none"
          onClick={onLineClick}
          onMouseOver={e => {
            setLineHover(true);
            onLineMouseOver(e);
          }}
          onMouseOut={e => {
            setLineHover(false);
            onLineMouseOut(e);
          }}
        />
      )}
      {showPoints &&
        data.map((p, i) => (
          <circle
            key={i}
            cx={
              isCategorical(xType) && xScale.bandwidth
                ? xScale(p.x) + xScale.bandwidth() / 2
                : xScale(p.x)
            }
            cy={
              isCategorical(yType) && yScale.bandwidth
                ? yScale(p.y) + yScale.bandwidth() / 2
                : yScale(p.y)
            }
            r={typeof pointSize === "number" ? pointSize : pointSize(p)}
            fill={
              hoverIndex === i
                ? typeof pointHoverColor === "function"
                  ? pointHoverColor(p) || HOVER_COLOR
                  : pointHoverColor || HOVER_COLOR
                : typeof pointColor === "function"
                ? pointColor(p) || FILL_COLOR
                : pointColor || FILL_COLOR
            }
            strokeWidth={strokeWidth}
            onClick={e => onPointClick(p, e)}
            onMouseOver={e => {
              setHover(i);
              onPointMouseOver(p, e);
            }}
            onMouseOut={e => {
              setHover(-1);
              onPointMouseOut(p, e);
            }}
          />
        ))}
    </g>
  );
};

export default Scatter;
