type TimeResolution =
  | "second"
  | "minute"
  | "hour"
  | "4-hours"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

// From https://stackoverflow.com/questions/1197928/how-to-add-30-minutes-to-a-javascript-date-object

/**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 *
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
function dateAdd(
  date: string | Date,
  interval: TimeResolution,
  units = 1
): Date | undefined {
  const d = new Date(date);
  let ret = new Date(date); //don't change original date
  let checkRollover = function() {
    if (ret.getDate() !== d.getDate()) ret.setDate(0);
  };
  switch (interval.toLowerCase()) {
    case "year":
      ret.setFullYear(ret.getFullYear() + units);
      checkRollover();
      break;
    case "quarter":
      ret.setMonth(ret.getMonth() + 3 * units);
      checkRollover();
      break;
    case "month":
      ret.setMonth(ret.getMonth() + units);
      checkRollover();
      break;
    case "week":
      ret.setDate(ret.getDate() + 7 * units);
      break;
    case "day":
      ret.setDate(ret.getDate() + units);
      break;
    case "hour":
      ret.setTime(ret.getTime() + units * 3600000);
      break;
    case "4hour":
    case "4-hours":
      ret.setTime(ret.getTime() + units * 4 * 3600000);
      break;
    case "minute":
      ret.setTime(ret.getTime() + units * 60000);
      break;
    case "second":
      ret.setTime(ret.getTime() + units * 1000);
      break;
    default:
      return undefined;
  }
  return ret;
}

export default dateAdd;
